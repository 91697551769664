import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import {
  FormItem,
  HappeningPartner,
  HappeningsListPartner,
  UpdateHappening,
  updateHappeningFormSchema
} from 'api/models'
import { Button, Grid, Typography, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormInputProps } from 'app/components/form/controlled-form.component'
import dayjs from 'dayjs'

export const HappeningEditView = (): React.JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { getHappeningsDetails, updateHappening, getHappeningsPartners } = useFetcher()
  const { handleMutation } = useFeedback()
  const [happening, setHappening] = useState({} as UpdateHappening)
  const [partners, setPartners] = useState({} as HappeningsListPartner)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { id } = useParams()

  const fetch = useCallback(async () => {
    setIsLoading(true)
    if (!id) return
    return await getHappeningsDetails.mutateAsync(Number(id))
  }, [id, setIsLoading, setHappening, handleMutation, partners])

  const methods = useForm<UpdateHappening>({
    defaultValues: {
      organizedBy: { id: '', label: '' },
      begin: null,
      end: null
    },
    mode: 'onChange',
    resolver: zodResolver(updateHappeningFormSchema)
  })

  const { isValid, isSubmitting } = methods.formState

  const handleSubmit = useCallback(
    async (data: UpdateHappening) => {
      await handleMutation({
        confirm: {
          content: t('confirm_edit_happening')
        },
        onStart: () => setIsLoading(true),
        mutation: updateHappening,
        data: { id: Number(id), data: data },
        toastSuccess: t('update_happening_success'),
        toastError: t('update_happening_error'),
        onEnd: () => {
          setIsLoading(false)
          navigate(`/happenings/${id}`)
        }
      })
    },
    [happening, navigate]
  )
  const initPartners = useCallback(async () => {
    return await getHappeningsPartners.mutateAsync()
  }, [getHappeningsPartners])

  const fetchAll = async () => {
    const data = await fetch()
    const partnersData = await initPartners()
    if (data) {
      data.begin = dayjs.utc(data.begin)
      data.end = dayjs.utc(data.end)
      data.maxUsers = data.maxUsers || 0
      data.nbUsers = data.nbUsers || 0
      methods.reset({ ...data, isActive: data.isActive ?? true })
      setHappening(data)
    }
    if (partnersData) {
      methods.setValue('organizedBy', {
        id: data?.partnerId || '0',
        label: data?.partnerName || 'Aucun'
      })
      setPartners(partnersData)
    }
    setIsLoading(false)
  }

  const organizedByValue = methods.watch('organizedBy')
  console.log(organizedByValue)
  const items = useMemo(() => {
    return [
      { type: 'textfield', label: t('name'), name: 'name', required: true },
      {
        type: 'centers',
        label: t('center'),
        name: 'center',
        inputProps: {
          allCenters: true,
          required: true
        }
      },
      {
        type: 'autocomplete',
        label: t('organized_by'),
        name: 'organizedBy',
        formItem: {
          values: partners.items
            ? [
                { id: '0', label: 'Aucun' },
                ...partners.items.map((partner: HappeningPartner) => ({
                  id: partner.id,
                  label: partner.name
                }))
              ]
            : []
        } as FormItem
      },
      organizedByValue?.id !== '0'
        ? {
            type: 'textfield',
            label: t('link_partners'),
            name: 'partnerLink'
          }
        : { type: 'blank' },
      { type: 'textfield', label: t('description'), name: 'description', required: true },
      { type: 'textfield', label: t('place'), name: 'place', required: true },
      {
        type: 'datetimepicker',
        label: t('begin_at') + '*',
        name: 'begin',
        inputProps: { required: true }
      },
      { type: 'datetimepicker', label: t('end_at') + '*', name: 'end', required: true },
      {
        type: 'number',
        label: t('number_of_participants'),
        name: 'nbUsers',
        inputProps: { step: 1 }
      },
      {
        type: 'number',
        label: t('number_max_of_participants'),
        name: 'maxUsers',
        inputProps: { step: 1 }
      },
      { type: 'textfield', label: t('banner'), name: 'banner' },
      { type: 'checkbox', label: t('is_active'), name: 'isActive' }
    ] as FormInputProps[]
  }, [partners, organizedByValue])

  useEffect(() => {
    fetchAll().then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('edit_informations')} | {happening.name}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} md={12}>
            <FormCard
              isLoading={isLoading}
              title={t('main_informations')}
              control={methods.control}
              items={items}
            />
          </Grid>
          <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
            <Grid item xs={6} textAlign={'right'}>
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={() => navigate(`/happenings/${id}`)}
              >
                {t('cancel')}
              </Button>
            </Grid>
            <Grid item xs={6} textAlign={'left'}>
              <Button
                disabled={isSubmitting || !isValid}
                variant={'contained'}
                color={'primary'}
                onClick={methods.control.handleSubmit(handleSubmit)}
              >
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  )
}
