import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { OptionList } from '../models'

export const optionsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<OptionList>({
      url: `${BASE_URL}${API.GET_OPTIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
}
