import { type Ctx, fetcher, fetcherParse, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  CreateUpdateStaffForm,
  staffDefaultSchema,
  StaffDetails,
  StaffsList
} from 'api/models/staffs'
import { IdReturn } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const staffsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<StaffsList>({
      url: `${BASE_URL}${API.STAFFS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async ({ token }: Ctx, id: number) =>
    fetcher<StaffDetails>({
      url: `${BASE_URL}${urlHelper(API.STAFF, { id })}`,
      method: 'GET',
      token: token
    }),
  getDefault: async ({ token }: Ctx) =>
    fetcherParse({
      url: `${BASE_URL}${urlHelper(API.GET_DEFAULT_STAFF_ATTRIBUTABLE, {})}`,
      method: 'GET',
      token: token,
      schema: staffDefaultSchema
    }),
  updateDefault: async ({ token }: Ctx, staffId: number) => {
    return fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_DEFAULT_STAFF_ATTRIBUTABLE, {})}`,
      method: 'POST',
      token: token,
      body: { staff: staffId }
    })
  },
  create: async ({ token }: Ctx, data: CreateUpdateStaffForm) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.STAFFS}`,
      method: 'POST',
      token: token,
      body: data
    }),
  update: async ({ token }: Ctx, data: CreateUpdateStaffForm) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.STAFF, { id: data.id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  updateLanguage: async ({ token }: Ctx, language: number, id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_STAFF_LANGUAGE, { id })}`,
      method: 'PATCH',
      token: token,
      body: { language: language }
    }),
  updateIsAttributable: async ({ token }: Ctx, isAttributable: boolean, id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_STAFF_IS_ATTRIBUTABLE, { id })}`,
      method: 'PATCH',
      token: token,
      body: { isAttributable: isAttributable }
    }),
  createOrUpdatePhoneSystem: async ({ token }: Ctx, data: CreateUpdateStaffForm) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.STAFF_PHONE_SYSTEM, { id: data.id })}`,
      method: 'PATCH',
      token: token,
      body: data
    })
}
