import { Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ServiceShapes } from 'api/models'

interface IProps {
  displaySurface: boolean
  displayPrice: boolean
  displayName: boolean
  setDisplaySurface: (prevState: any) => void
  setDisplayPrice: (prevState: any) => void
  setDisplayName: (prevState: any) => void
  shapes: ServiceShapes
  image: undefined | HTMLImageElement
}

const InfosbarComponent = ({
  displaySurface,
  displayPrice,
  displayName,
  setDisplaySurface,
  setDisplayPrice,
  setDisplayName,
  shapes,
  image
}: IProps) => {
  const { t } = useTranslation()
  const handleSurface = () => {
    setDisplaySurface((prevState: any) => !prevState)
  }

  const handlePrice = () => {
    setDisplayPrice((prevState: any) => !prevState)
  }

  const handleName = () => {
    setDisplayName((prevState: any) => !prevState)
  }

  return (
    <Grid container gap={4}>
      {shapes && shapes.length > 0 && image && (
        <>
          <Button variant="contained" size="small" onClick={handleSurface}>
            {!displaySurface ? t('display_surface') : t('hide_surface')}
          </Button>
          <Button variant="contained" size="small" onClick={handlePrice}>
            {!displayPrice ? t('display_meter_price') : t('hide_meter_price')}
          </Button>
          <Button variant="contained" size="small" onClick={handleName}>
            {!displayName ? t('display_social_reason') : t('hide_social_reason')}
          </Button>
        </>
      )}
    </Grid>
  )
}

export default InfosbarComponent
