import { Card, CardContent, Typography, Table, TableCell, TableRow, TableBody } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { DashboardVisits } from 'api/models/dashboard'

interface IDashboardCardVisits {
  visits: DashboardVisits
  isLoading: boolean
}
export function DashboardCardVisits({ visits, isLoading }: IDashboardCardVisits) {
  if (isLoading) {
    return <CardSkeleton height={160}></CardSkeleton>
  }
  return (
    <Card sx={{ minHeight: 'calc(100% - 35px)' }}>
      <CardContent>
        <Table size="small">
          <TableBody>
            {visits?.map((visit, index: number) => (
              <TableRow hover key={index}>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{visit.beginDate}</TableCell>
                <TableCell>
                  <Typography component={'span'} variant={'body1'} sx={{ opacity: 0.75 }}>
                    {visit.beginTime}
                  </Typography>
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  <RouterLink
                    to={`/enterprises/${visit.clientId}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {visit.clientName}
                  </RouterLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
