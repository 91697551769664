export const calculateServiceFinalPriceTotal = (rows: any): number =>
  rows ? Number(rows.reduce((prev: number, item: any) => prev + item.finalPrice, 0)) : 0

export const calculateServiceGuarantee = (rows: any): number => {
  if (!rows) return 0
  return rows.reduce((prev: number, item: any) => {
    if (item.serviceTypeReference !== 'COWORKING') {
      return Number(prev + item.finalPrice * 2)
    }
    return Number(prev)
  }, 0)
}
