import { Payment } from 'api/models'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { WarningAmberOutlined } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'

interface IPaymentDangerZoneProps {
  payment: Payment | undefined
  isLoading?: boolean
}

export const PaymentWarningZone = ({ payment, isLoading }: IPaymentDangerZoneProps) => {
  const { user } = useApp()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getPayment } = useFetcher()
  const { deletePayment } = useFetcher()
  const { handleMutation } = useFeedback()
  const handleConfirmDelete = useCallback(async () => {
    if (!payment) return
    await handleMutation({
      confirm: {
        content: t('confirm_delete_payment'),
        title: t('delete_payment'),
        variant: 'error'
      },
      mutation: deletePayment,
      data: { id: payment.id },
      toastSuccess: t('delete_success'),
      toastError: t('delete_error'),
      onSuccess: () => navigate('/payments')
    })
  }, [handleMutation, getPayment, t, payment])

  const isAbleToDelete = useMemo(() => {
    if (!payment) return false
    const isPaymentManual = payment.paymentMode != 3 && payment.paymentMode != 4
    const hasInvoice = !!payment.invoiceId
    const isAuthorised = user?.rights.payment.isDelete
    return isPaymentManual && !hasInvoice && isAuthorised
  }, [payment])

  return (
    <>
      <Paper variant="outlined" sx={{ marginTop: 4 }}>
        <Box m={3}>
          <TitleComponent
            text={t('warning_zone')}
            variant={'h3'}
            icon={<WarningAmberOutlined color="action" fontSize="small" />}
          />
        </Box>
        <Box>
          <Divider />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingBlock={4}
            paddingInline={6}
          >
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }} color="error">
                {t('delete_payment_action')}
              </Typography>
              <Typography variant="body1" color="error">
                {t('delete_payment_action_info')}
              </Typography>
              <Typography variant="body1" color="error">
                {t('delete_payment_action_info_2')}
              </Typography>
            </Box>
            <Button
              color="error"
              variant="contained"
              sx={{ width: 120 }}
              onClick={handleConfirmDelete}
              disabled={!isAbleToDelete || isLoading}
            >
              {t('delete_action')}
            </Button>
          </Stack>
        </Box>
      </Paper>
    </>
  )
}
