import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Button, Container, Grid, Stack, Typography } from '@mui/material'
import { Link } from 'app/components/link.component'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Document } from 'api/models'
import { Viewer } from '@react-pdf-viewer/core'
import { useFeedback } from 'app/providers/feedback.provider'
import base64ConverterHelper from 'app/helpers/base64_converter.helper'

export const DocumentView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id, documentId } = useParams()
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const { getDocument, invalidateDocument, validateDocument } = useFetcher()

  const [documentEnterprise, setDocumentEnterprise] = useState({} as Document)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const getInformations = useCallback(async () => {
    if (!id || !documentId) return
    setIsLoading(true)
    const _document = await getDocument.mutateAsync({ id: String(documentId) })
    setDocumentEnterprise(_document)
    setIsLoading(false)
  }, [id, documentId])

  useEffect(() => {
    getInformations().then()
  }, [id, documentId])

  const onInvalidate = useCallback(async () => {
    if (documentId === undefined) return
    await handleMutation({
      confirm: {
        content: t('document_invalidate_confirm')
      },
      mutation: invalidateDocument,
      data: { id: String(documentId) },
      toastSuccess: t('document_invalidate_success'),
      toastError: t('error'),
      onSuccess: () => navigate(0)
    })
  }, [documentId])

  const onValidate = useCallback(async () => {
    if (documentId === undefined) return
    await handleMutation({
      confirm: {
        content: t('document_validate_confirm')
      },
      mutation: validateDocument,
      data: { id: String(documentId) },
      toastSuccess: t('document_validate_success'),
      toastError: t('error'),
      onSuccess: () => navigate(0)
    })
  }, [documentId])

  const onDownload = useCallback(() => {
    if (
      documentEnterprise !== undefined &&
      documentEnterprise.file !== null &&
      documentEnterprise.file !== undefined &&
      documentEnterprise.mimeType !== null &&
      documentEnterprise.mimeType !== undefined
    ) {
      const _blob = base64ConverterHelper().base64ToBlob(
        documentEnterprise.file,
        documentEnterprise.mimeType
      )
      const _link = URL.createObjectURL(_blob)
      const a = document.createElement('a')
      a.href = _link
      a.download = String(documentEnterprise.reference)
      a.click()
      window.URL.revokeObjectURL(_link)
    }
  }, [documentEnterprise])

  return (
    <>
      {isLoading ? (
        <CardSkeleton height={500} />
      ) : (
        <Container>
          <Grid container>
            <Grid item xs={12} marginBottom={5}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginBottom={2}
              >
                <Typography variant="h1">
                  {t('document')} |{' '}
                  <Link to={`/enterprises/${id}`}>{documentEnterprise.clientName}</Link>
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography>{documentEnterprise.reference}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} marginBottom={4}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                <Typography>
                  {t('status')}: {documentEnterprise.statusLabel}
                </Typography>
                <Button color={'secondary'} onClick={onValidate}>
                  {t('validate')}
                </Button>
                <Button color={'warning'} onClick={onInvalidate}>
                  {t('invalidate')}
                </Button>
                <Button color={'warning'} onClick={onDownload}>
                  {t('download')}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {documentEnterprise.mimeType === 'application/pdf' && (
                <Viewer fileUrl={`data:application/pdf;base64,${documentEnterprise.file}`} />
              )}

              {documentEnterprise.mimeType?.match('image/.+') && (
                <img
                  src={`data:${documentEnterprise.mimeType};base64,${documentEnterprise.file}`}
                  style={{ maxWidth: '100%' }}
                  alt={'document'}
                />
              )}

              {documentEnterprise.mimeType !== 'application/pdf' &&
                !documentEnterprise.mimeType?.match('image/.+') && (
                  <Typography color={'error'}>{t('document_not_supported')}</Typography>
                )}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  )
}
