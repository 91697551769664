import React, { useEffect, useState } from 'react'
import { Container, Grid, Typography, Button, Card, CardContent, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Box, Stack } from '@mui/system'
import { CenterBp } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { UploadButton } from 'app/components/form/uploadbutton.component'
import { formatDateWithFormat } from 'app/utils/format'
import { useNavigate } from 'react-router-dom'

export const BusinessPlanView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getBusinessPlan, uploadBusinessPlanCSV } = useFetcher()
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [file, setFile] = useState<File | FileList | null>(null)
  const [centerBps, setCenterBps] = useState([] as CenterBp[])

  const handleFile = (file: File | FileList | null) => {
    setFile(file)
  }

  const navigate = useNavigate()
  const onSubmit = () => {
    if (file) {
      handleMutation({
        onStart: () => setIsLoading(true),
        mutation: uploadBusinessPlanCSV,
        data: file,
        confirm: {
          title: t('confirm'),
          content: t('confirm_add_business_plan')
        },
        toastSuccess: t('success'),
        onEnd: () => setIsLoading(false),
        onSuccess: () => {
          navigate(0)
        }
      }).then()
    } else {
      return
    }
  }

  const getCenterBp = async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getBusinessPlan,
      onSuccess: (data) => {
        setCenterBps(data)
      },
      onEnd: () => setIsLoading(false)
    })
  }

  useEffect(() => {
    getCenterBp().then()
  }, [])

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">{t('add_bp')}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" justifyContent="space-between" spacing={2} mt={4}>
            <Card sx={{ flex: 1 }}>
              <CardContent>
                <Typography variant="h5" mb={3}>
                  {t('file_csv')}
                  {'*'}
                </Typography>
                <Divider />
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3 }}>
                  <Grid item xs={5} alignItems={'center'}>
                    <UploadButton
                      name={'file'}
                      onChange={handleFile}
                      accept={'text/csv, text/plain'}
                    />
                  </Grid>
                  <Grid item xs={1} sx={{ ml: 4 }}>
                    <Button
                      color="secondary"
                      fullWidth
                      variant="contained"
                      disabled={!file}
                      onClick={onSubmit}
                    >
                      {t('send')}
                    </Button>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ flex: 1 }}>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid container>
                    {centerBps.map((centerBp, index) => (
                      <Stack direction="row" spacing={1} mr={5} key={index}>
                        <Typography sx={{ fontSize: '0.2em', fontWeight: 'bold' }}>
                          {centerBp.center}
                        </Typography>
                        <Typography sx={{ fontSize: '0.2em', fontWeight: 'bold' }}>:</Typography>
                        <Typography sx={{ fontSize: '0.2em', fontWeight: 'bold' }}>
                          {formatDateWithFormat(centerBp.begin, 'YYYY/MM')}
                        </Typography>
                      </Stack>
                    ))}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}
