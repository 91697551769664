import React, { useCallback, useState } from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import type { EnterpriseType } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { formatDateWithTime } from 'app/utils/format'
import { AddCircle, PermContactCalendar } from '@mui/icons-material'
import { Box, Container } from '@mui/system'
import { SearchInput } from 'app/components/filters/search-input'
import { FiltersContainer } from 'app/components/filters/filters-container'

export const EnterprisesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getEnterprises, setSearchParams, searchParams } = useFetcher()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const [enterprises, setEnterprises] = useState<EnterpriseType[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['client_status', 'status'],
      ['enterprise_big_account', 'key_account']
    ])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      getEnterprises
        .mutateAsync()
        .then((data) => {
          setIsLast(data.last)
          setOffset(data.actual)
          setTotal(data.total)
          setEnterprises(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getEnterprises, setIsLast, setOffset, setTotal]
  )

  return (
    <Container>
      <Grid item xs={12}>
        <Box marginBottom="2rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('enterprises_list')}
            <IconButton
              title={t('add_enterprise')}
              aria-label={t('add_enterprise')}
              color="primary"
              href={'/enterprises/add'}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          </Typography>
        </Box>
        <Grid>
          <FiltersContainer
            commonFilters={commonFilters}
            onChange={() => handleFilter(refreshList, true)}
            initialOrder={[{ property: 'createdAt', order: 'desc' }]}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          >
            <SearchCenterInput
              slug="center"
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              byPassAllCenters={true}
            />
            <SearchInput
              slug={'name'}
              label={'enterprise'}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
            <SearchInput
              slug={'reference'}
              label={'reference'}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
          </FiltersContainer>
        </Grid>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom>
            {enterprises.length} / {total}
          </Typography>
        </Grid>
        <List
          items={enterprises}
          columns={[
            {
              label: t('reference'),
              slug: 'reference',
              link: { base: '/enterprises', slug: 'id' }
            },
            { label: t('name'), slug: 'name' },
            { label: t('city'), slug: 'city' },
            {
              label: PermContactCalendar,
              tooltip: t('created_at'),
              slug: 'createdAt',
              valueFormatter: formatDateWithTime
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {enterprises.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
