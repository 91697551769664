import { AcquisitionCost } from 'api/models/sources'
import React, { RefObject, useEffect } from 'react'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { useTranslation } from 'react-i18next'
import { Button, Grid, InputAdornment, Typography } from '@mui/material'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { useForm } from 'react-hook-form'
import { Euro } from '@mui/icons-material'
import { useFetcher } from 'app/providers/fetcher.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { UpdateAcquisitionCostProps, updateAcquisitionCostProps } from 'api/models/forms/sources'
import { useFeedback } from 'app/providers/feedback.provider'
import { useNavigate } from 'react-router-dom'

interface IDialogEditAcquisitionCost {
  dialogRef: RefObject<DialogRef>
  acquisitionCost: AcquisitionCost | null
}
const keys = ['source', 'day'] as Array<keyof AcquisitionCost>
export const DialogEditAcquisitionCost = function ({
  dialogRef,
  acquisitionCost
}: IDialogEditAcquisitionCost) {
  const { t } = useTranslation()
  const { updateAcquisitionCost } = useFetcher()
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const method = useForm<UpdateAcquisitionCostProps>({
    defaultValues: { cost: 0 },
    mode: 'onChange',
    resolver: zodResolver(updateAcquisitionCostProps)
  })

  useEffect(() => {
    if (acquisitionCost)
      method.reset({
        cost: acquisitionCost.cost
      })
  }, [acquisitionCost])

  const handleSubmit = async (data: UpdateAcquisitionCostProps) => {
    if (!acquisitionCost) return
    await handleMutation({
      confirm: {
        content: t('confirm_update_acquisition_cost')
      },
      mutation: updateAcquisitionCost,
      data: {
        id: acquisitionCost.id,
        data: data
      },
      toastSuccess: t('update_success'),
      toastError: t('error'),
      onSuccess: () => {
        dialogRef.current?.close()
        navigate(0)
      }
    })
  }

  return (
    <Dialog
      ref={dialogRef}
      title={t('update_acquisition_cost')}
      actions={
        <>
          <Button variant={'outlined'} onClick={dialogRef.current?.close}>
            {t('cancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!method.formState.isValid || method.formState.isSubmitting}
            onClick={method.handleSubmit(handleSubmit)}
          >
            {t('update')}
          </Button>
        </>
      }
    >
      {keys.map((key) => (
        <Grid key={key} container spacing={2} paddingBottom={4} columns={12} alignItems={'center'}>
          <Grid item xs={2}>
            <Typography fontWeight={'bold'}>{t(key)}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              {acquisitionCost ? acquisitionCost[key as keyof AcquisitionCost] : ''}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={2} columns={12} alignItems={'center'}>
        <Grid item xs={2}>
          <Typography fontWeight={'bold'}>{t('cost')}</Typography>
        </Grid>
        <Grid item xs={10}>
          <ControlledTextField
            control={method.control}
            type={'numeric'}
            required={true}
            name={'cost'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Euro />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
