import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { MainService } from 'api/models'
import { formatCurrency, formatDate, formatYesNo } from 'app/utils/format'
import { useApp } from 'app/providers/app.provider'
import InfoCard from 'app/components/card/info-card.component'
import { AlertsInfo } from 'modules/services/components/alerts-info.component'

interface IMainServiceDetailsProps {
  mainService: MainService
  children?: React.ReactNode
}

export const ParkingServiceDetails = ({ mainService }: IMainServiceDetailsProps) => {
  const { t } = useTranslation()
  const { getLanguages } = useApp()

  const { savedLabels, info } = useMemo(() => {
    let savedLabels = getLanguages().map((language) => ({
      label: language.label,
      value:
        mainService.savedLabels.find((savedLabel) => savedLabel.language.id === language.id)
          ?.label ?? ''
    }))

    const info = [
      {
        label: t('monthly_price'),
        value: mainService.monthlyPrice ? formatCurrency(mainService.monthlyPrice) : null
      },
      {
        label: '',
        value: ''
      },
      {
        label: t('center'),
        value: mainService.centerName
      },
      {
        label: t('floor'),
        value: mainService.floor
      },
      {
        label: t('capacity'),
        value: mainService.maxCapacity
      },
      {
        label: t('building'),
        value: mainService.building
      },
      {
        label: t('typology'),
        value: mainService.typologyLabel
      },
      {
        label: '',
        value: ''
      },
      {
        label: t('begin'),
        value: formatDate(mainService.begin)
      },
      {
        label: t('end'),
        value: mainService.end ? formatDate(mainService.end) : ''
      },
      {
        label: t('available_online'),
        value: t(formatYesNo(mainService.isOnline))
      }
    ]

    return { savedLabels, info }
  }, [mainService, t])

  return (
    <>
      <InfoCard title={t('label')} columns={savedLabels} />
      <InfoCard title={t('informations')} columns={info}>
        <AlertsInfo alerts={mainService.alerts} />
      </InfoCard>
    </>
  )
}
