import { UseFormReturn } from 'react-hook-form'
import * as React from 'react'
import { FormItems } from 'api/models'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { FormCard } from 'app/components/form/form-card.component'
const LinkExistingUserForm = ({
  methods,
  options
}: {
  methods: UseFormReturn<any>
  options: FormItems
}) => {
  const { t } = useTranslation()

  return (
    <Grid item xs={12}>
      <FormCard
        itemXS={4}
        items={[
          {
            label: t('client'),
            name: 'individual',
            type: 'clients',
            inputProps: {
              defaultIsIndividual: true,
              defaultIsEnterprise: false
            }
          },
          {
            label: t('role'),
            name: 'role',
            type: 'select',
            formItem: options.individual_roles
          },
          {
            label: t('job'),
            name: 'job',
            type: 'textfield'
          }
        ]}
        control={methods.control}
        isLoading={false}
      />
    </Grid>
  )
}

export default LinkExistingUserForm
