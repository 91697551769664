import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react'
import { FormControl, InputAdornment, TextField } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

interface IControlledNumberFieldProps {
  label?: string
  name: string
  control: Control<any>
  icon?: React.FC<any>
  required?: boolean
  handleChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  type?: HTMLInputTypeAttribute
  disabled?: boolean
  min?: number
  step?: number
}

export function ControlledNumberField({
  name,
  label,
  control,
  icon: Icon,
  required,
  handleChange,
  type = 'number',
  disabled,
  min,
  step = 0.01
}: IControlledNumberFieldProps) {
  return (
    <Controller
      render={({ field: { name, ref, value, onChange }, formState: { errors } }) => {
        return (
          <FormControl fullWidth>
            <TextField
              required={required}
              type={type}
              InputLabelProps={{ shrink: true }}
              error={!!errors[name]}
              InputProps={{
                inputProps: {
                  step: step,
                  min: min
                },
                endAdornment: Icon ? (
                  <InputAdornment position="end">
                    <Icon fontSize={'2rem'} />
                  </InputAdornment>
                ) : null
              }}
              size={'small'}
              inputRef={ref}
              disabled={disabled}
              fullWidth
              label={`${label ? label : ''}`}
              id={name}
              value={value || ''}
              onChange={(e) => {
                if (min !== undefined && e.target.value && min > Number(e.target.value)) {
                  e.target.value = String(min)
                }

                onChange(e)
                if (handleChange) handleChange(e)
              }}
            />
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
