import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { BankAccount, MandatesList, PaymentMethod, Payments } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const mandatesRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<MandatesList>({
      url: `${BASE_URL}${API.GET_MANDATES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  paymentMethod: async ({ token }: Ctx, id: number) =>
    fetcher<PaymentMethod>({
      url: `${BASE_URL}${urlHelper(API.GET_MANDATE_PAYMENT_METHOD, { id })}`,
      method: 'GET',
      token: token
    }),
  bankAccount: async ({ token }: Ctx, id: number) =>
    fetcher<BankAccount>({
      url: `${BASE_URL}${urlHelper(API.GET_MANDATE_BANK_ACCOUNT, { id })}`,
      method: 'GET',
      token: token
    }),
  payments: async ({ token }: Ctx, id: number) =>
    fetcher<Payments>({
      url: `${BASE_URL}${urlHelper(API.GET_MANDATE_PAYMENT, { id })}`,
      method: 'GET',
      token: token
    }),
  cancelled: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.MANDATE_CANCELLED, { id })}`,
      method: 'PATCH',
      token: token
    })
}
