import { useCallback, useState } from 'react'
import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  TextField
} from '@mui/material'
import { Switch } from 'app/components/switch/switch'
import { Box, Stack, darken, lighten } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useDebouce } from 'app/hooks/use-debounce'
import type { Center } from 'api/models'
import styled from '@emotion/styled'
import { useApp } from 'app/providers/app.provider'
import { useFeedback } from 'app/providers/feedback.provider'

export type CentersSelectInputProps = {
  slug: string
  slugCluster?: string
  onChange?: (item: Center) => void
  defaultIsCluster?: boolean
  defaultIsCenter?: boolean
  canSwitchCluster?: boolean
  canSwitchCenter?: boolean
  withDefaultValue?: boolean
  allCenters?: boolean
  initialValue?: number
  initialValueIsCluster?: boolean
  hidden?: (item: Center) => boolean
  disableClearable?: boolean
  byPassAllCenters?: boolean
  required?: boolean
  disabled?: boolean
  setIsLoading?: (value: boolean) => void
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}))

const GroupItems = styled('ul')({
  padding: 0
})

export function CentersSelect({
  slug,
  onChange,
  defaultIsCluster = false,
  defaultIsCenter = true,
  canSwitchCluster = false,
  canSwitchCenter = false,
  withDefaultValue = false,
  allCenters = true,
  initialValue,
  initialValueIsCluster = false,
  hidden,
  disableClearable = false,
  byPassAllCenters = undefined,
  required,
  disabled = false
}: CentersSelectInputProps) {
  const { getCentersSelect } = useFetcher()
  const { t } = useTranslation()
  const [selectedCenter, setSelectedCenter] = useState<Center | null>(null)
  const [centers, setCenters] = useState<Center[]>([])
  const [isCluster, setIsCluster] = useState<boolean>(defaultIsCluster)
  const [isCenter, setIsCenter] = useState<boolean>(defaultIsCenter)
  const [loading, setLoading] = useState<boolean>(false)
  const { user } = useApp()
  const [defaultValue, setDefaultValue] = useState<number | null>(
    initialValue ?? (withDefaultValue && user ? user.mainCenter : null)
  )
  const { handleMutation } = useFeedback()

  const selectDefaultValue = useCallback(
    (id: number, centers: Center[]) => {
      if (!centers || !id) return
      const center = centers.find((obj) => {
        return obj.id === String(id) && obj.type === (initialValueIsCluster ? 'cluster' : 'center')
      })

      if (center) {
        setDefaultValue(null)
        handleOnChange(null, center)
      }
    },
    [initialValueIsCluster]
  )

  useDebouce(
    async () => {
      await handleMutation({
        onStart: () => {
          setLoading(true)
        },
        mutation: getCentersSelect,
        data: {
          name: '',
          is_center: isCenter,
          is_cluster: isCluster,
          all_centers: allCenters,
          bypass_all_centers: byPassAllCenters
        },
        onSuccess: (data) => {
          const _centers = data
            .map((obj: any) => {
              if (!obj.children) return null
              return obj.children
            })
            .filter((obj: any) => obj !== null)
            .flat()
            .filter((obj) => !hidden || !hidden(obj)) as unknown as Center[]
          setCenters(_centers)
          if (defaultValue) {
            selectDefaultValue(defaultValue, _centers)
          }
        },
        onEnd: () => {
          setLoading(false)
        }
      })
    },
    [defaultValue, selectDefaultValue, withDefaultValue, user],
    150
  )

  const handleOnChange = useCallback(
    (event: any, newValue: any) => {
      setSelectedCenter(newValue)
      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange]
  )

  return (
    <Stack>
      <Autocomplete
        size={'small'}
        id={slug ?? 'center-select'}
        disableClearable={disableClearable}
        disabled={disabled}
        onChange={handleOnChange}
        value={selectedCenter}
        groupBy={(option) => option.type}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{t(params.group)}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        loadingText={t('loading')}
        noOptionsText={t('no_options')}
        isOptionEqualToValue={(option, value) =>
          option.id == value.id && option.type === value.type
        }
        getOptionLabel={(option) => option.text}
        options={centers}
        renderInput={(params) => {
          return (
            <TextField
              {...(params as any)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={13} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
              required={required}
              label={defaultIsCluster && !defaultIsCenter ? t('cluster') : t('center')}
            />
          )
        }}
        data-cy="search-center-input"
        data-cy-value={selectedCenter?.id}
      />
      {(canSwitchCenter || canSwitchCluster) && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {canSwitchCenter && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCenter}
                    onChange={() => setIsCenter(!isCenter)}
                    name="isCenter"
                  />
                }
                label={t('center')}
              />
            </FormGroup>
          )}
          {canSwitchCluster && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCluster}
                    onChange={() => setIsCluster(!isCluster)}
                    name="isCluster"
                  />
                }
                label={t('cluster')}
              />
            </FormGroup>
          )}
        </Box>
      )}
    </Stack>
  )
}
