import {
  Box,
  Grid,
  Typography,
  Card,
  Container,
  CardContent,
  useTheme,
  Switch,
  FormControlLabel,
  Dialog,
  DialogContent
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { dates, RangePicker } from 'app/components/filters/range-picker'
import { AccountingStats } from 'api/models/stats'
import dayjs from 'dayjs'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Fullscreen, FullscreenExit } from '@mui/icons-material'
import Grow from '@mui/material/Grow'

const colorPalette = {
  1: '#BAE1FF',
  2: '#DCEDC1',
  3: '#FFC107',
  4: '#FFD3B6',
  5: '#FFC6FF'
}

const colorPaletteCredits = {
  1: '#FFC107',
  2: '#FFC6FF'
}

export const AccountingDashboardView = (): React.JSX.Element => {
  const { t, i18n } = useTranslation()
  const { filtersList, handleFilter, initFilters } = useList()
  const { searchParams, setSearchParams, getStatsAccounting } = useFetcher()
  const [accountingStats, setAccountingStats] = useState<AccountingStats>({
    totalInvoicesPaid: [],
    totalInvoicesUnpaid: [],
    totalCredits: [],
    totalMethodPayments: []
  })
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const theme = useTheme()
  const { handleMutation } = useFeedback()
  const [displayMode, setDisplayMode] = useState<'amount' | 'count'>('amount')
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['companies', 'company'],
      ['centers', 'center']
    ])
  )
  const [fullScreen, setFullScreen] = useState(false)
  const [open, setOpen] = useState(false)
  type ChartsTypes = 'invoices' | 'unpaids' | 'credits' | 'payments' | null
  const [name, setName] = useState<ChartsTypes>(null)

  const handleFullScreenToggle = (name: ChartsTypes) => {
    if (name) {
      setFullScreen(!fullScreen)
      setOpen(!open)
      setName(name)
    }
  }

  const fetch = useCallback(async () => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getStatsAccounting,
      onSuccess: (data: AccountingStats) => setAccountingStats(data),
      onEnd: () => setListIsLoading(false)
    }).then()
  }, [])

  useEffect(() => {
    initFilters(commonOptions, { companies: { all: true } }).then(() => fetch().then())
  }, [commonOptions])

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
        fontFamily: theme.typography.fontFamily,
        animations: {
          enabled: true,
          easing: 'easeout',
          speed: 800,
          animateGradually: {
            enabled: false
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      states: {
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none'
          }
        },
        normal: {
          filter: {
            type: 'none'
          }
        },
        hover: {
          filter: {
            type: 'darken',
            value: 0.85
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%'
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      dataLabels: {
        enabled: false
      },
      yaxis: {
        title: {
          text: displayMode === 'amount' ? t('euro') : t('number'),
          style: {
            fontSize: '10px'
          }
        },
        labels: {
          formatter: (value) =>
            displayMode === 'amount' ? `${value.toFixed(0)} €` : value.toFixed(0).toString()
        }
      },
      tooltip: {
        y: {
          formatter: (value) =>
            displayMode === 'amount' ? `${value.toFixed(2)} €` : value.toFixed(0).toString()
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: false
        }
      },
      grid: {
        borderColor: theme.palette.divider
      },
      theme: {
        mode: theme.palette.mode
      }
    }
    return data
  }, [i18n.language, displayMode, theme])

  const prepareChartData = useCallback(
    <T extends { month: string; [key: string]: any }>(
      data: T[],
      colorPalette: Record<string, string>,
      nameMap: Record<string, string>,
      typeKey: keyof T,
      valueKey: keyof T,
      countKey: keyof T
    ) => {
      const groupedByMonth = data.reduce((acc, item) => {
        const month = dayjs(item.month).format('MMMM')
        if (!acc[month]) {
          acc[month] = {}
        }
        const key = item[typeKey] as string
        acc[month][key] =
          displayMode === 'amount'
            ? parseFloat(item[valueKey] as string)
            : parseInt(item[countKey] as string, 10)
        return acc
      }, {} as Record<string, Record<string, number>>)

      return Object.keys(colorPalette).map((type) => ({
        name: nameMap[type] || type,
        data: Object.entries(groupedByMonth).map(([month, types]) => ({
          x: month,
          y: types[type] || 0
        })),
        color: colorPalette[type]
      }))
    },
    [displayMode, t, accountingStats, colorPalette]
  )

  const invoicesPaidData = useMemo(() => {
    return prepareChartData(
      accountingStats.totalInvoicesPaid,
      colorPalette,
      {
        '1': t('monthly'),
        '2': t('first_month'),
        '3': t('one_time'),
        '4': t('guarantees'),
        '5': t('quarterly')
      },
      'type',
      'totalSum',
      'totalInvoices'
    )
  }, [accountingStats.totalInvoicesPaid, colorPalette, displayMode, t])

  const invoicesUnpaidData = useMemo(() => {
    return prepareChartData(
      accountingStats.totalInvoicesUnpaid,
      colorPalette,
      {
        '1': t('monthly'),
        '2': t('first_month'),
        '3': t('one_time'),
        '4': t('guarantees'),
        '5': t('quarterly')
      },
      'type',
      'totalSum',
      'totalInvoices'
    )
  }, [accountingStats.totalInvoicesUnpaid, colorPalette, displayMode, t])

  const creditsData = useMemo(() => {
    return prepareChartData(
      accountingStats.totalCredits,
      colorPaletteCredits,
      {
        '1': t('product'),
        '2': t('guarantees')
      },
      'type',
      'totalAmount',
      'numberOfPayment'
    )
  }, [accountingStats.totalCredits, colorPaletteCredits, displayMode, t])

  const paymentData = useMemo(() => {
    return prepareChartData(
      accountingStats.totalMethodPayments,
      colorPalette,
      {
        '1': t('unknown'),
        '2': t('bank_check'),
        '3': t('bank_transfer'),
        '4': t('bank_card'),
        '5': t('bank_card_tpe'),
        '6': t('Régularisation')
      },
      'mode',
      'totalSum',
      'numberOfPayment'
    )
  }, [accountingStats.totalMethodPayments, colorPalette, displayMode, t])

  const charts = useMemo(() => {
    return {
      invoices: <Chart options={options} series={invoicesPaidData} type="bar" height={'100%'} />,
      payments: <Chart options={options} series={paymentData} type="bar" height="100%" />,
      credits: <Chart options={options} series={creditsData} type="bar" height="100%" />,
      unpaids: <Chart options={options} series={invoicesUnpaidData} type="bar" height="100%" />
    }
  }, [invoicesPaidData, paymentData, creditsData, options, invoicesUnpaidData])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('accounting_dashboard')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(fetch, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            label={'period'}
            slug={'begin'}
            slugs={{ begin: 'begin', end: 'end' }}
            valueLabel={dates.LAST_12_MONTHS}
            onChange={() => handleFilter(fetch, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <FormControlLabel
            control={
              <Switch
                checked={displayMode === 'count'}
                onChange={() => setDisplayMode((prev) => (prev === 'amount' ? 'count' : 'amount'))}
                name="displayMode"
                color="primary"
              />
            }
            label={
              displayMode === 'amount' ? t('display_invoices_stats') : t('display_amount_total')
            }
          />
        </FiltersBox>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={6} md={6} lg={6}>
          <Card elevation={2}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h2" gutterBottom>
                  {t('invoices')}
                </Typography>
                <OpenInFullIcon onClick={() => handleFullScreenToggle('invoices')}>
                  fullScreen ? <Fullscreen /> : <FullscreenExit />
                </OpenInFullIcon>
              </Box>
              <Box height={350}>{charts['invoices']}</Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Card elevation={2}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h2" gutterBottom>
                  {t('payments')}
                </Typography>
                <OpenInFullIcon onClick={() => handleFullScreenToggle('payments')}>
                  fullScreen ? <Fullscreen /> : <FullscreenExit />
                </OpenInFullIcon>
              </Box>
              <Box height={350}>{charts['payments']}</Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Card elevation={2}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h2" gutterBottom>
                  {t('credits')}
                </Typography>
                <OpenInFullIcon onClick={() => handleFullScreenToggle('credits')}>
                  fullScreen ? <Fullscreen /> : <FullscreenExit />
                </OpenInFullIcon>
              </Box>
              <Box height={350}>{charts['credits']}</Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card elevation={2}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h2" gutterBottom>
                  {t('unpaids')}
                </Typography>
                <OpenInFullIcon onClick={() => handleFullScreenToggle('unpaids')}>
                  fullScreen ? <Fullscreen /> : <FullscreenExit />
                </OpenInFullIcon>
              </Box>
              <Box height={350}>{charts['unpaids']}</Box>
            </CardContent>
          </Card>
          {name != null && open && (
            <Dialog fullScreen={fullScreen} open={open} TransitionComponent={Grow}>
              <DialogContent>
                <Box p={2} height="50%">
                  <Typography variant="h2" gutterBottom>
                    {t(name)}
                  </Typography>
                  <Box display="flex" justifyContent="flex-end">
                    <OpenInFullIcon onClick={() => handleFullScreenToggle(name)}>
                      fullScreen ? <Fullscreen /> : <FullscreenExit />
                    </OpenInFullIcon>
                  </Box>
                  {charts[name]}
                </Box>
              </DialogContent>
            </Dialog>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
