import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { CustomerReservation, CustomerReservationSum } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import {
  formatCurrency,
  formatCustomerReservationStatus,
  formatDateWithTime
} from 'app/utils/format'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { SummaryComponent } from 'app/components/lists/summary.component'
import { dates, RangePicker } from 'app/components/filters/range-picker'
import { AddCircle } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'

export const CustomerReservationsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    initSort,
    handleSort,
    handleFilter
  } = useList()
  const { user } = useApp()
  const { getCustomerReservations, getCustomerReservationSum, searchParams, setSearchParams } =
    useFetcher()
  const [customerReservations, setCustomerReservations] = useState<CustomerReservation[]>([])
  const [summary, setSummary] = useState<CustomerReservationSum>({} as CustomerReservationSum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['customer_reservations_types', 'type'],
      ['customer_reservations_status', 'status']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getCustomerReservations
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setCustomerReservations(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))

      setSummaryIsLoading(true)
      getCustomerReservationSum
        .mutateAsync()
        .then((sum) => setSummary(sum))
        .finally(() => setSummaryIsLoading(false))
    },
    [getCustomerReservationSum, getCustomerReservations, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    newSummaryItems.set('reservation_count', summary.count)
    newSummaryItems.set('services', summary.services)
    newSummaryItems.set('amount', String(summary.amount))
    setSummaryItems(newSummaryItems)
  }, [summary])

  useEffect(() => {
    initFilters(commonFilters).then(() =>
      initSort([{ property: 'begin', order: 'desc' }]).then(() => refreshList(true))
    )
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('customer_reservations')}
          <IconButton
            title={t('add_reservation')}
            aria-label={t('add_reservation')}
            color="primary"
            href={'/customer_reservations/add'}
            style={{ marginLeft: 7 }}
          >
            <AddCircle fontSize="small" />
          </IconButton>
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            initialValue={user?.mainCenter}
          />
          <SearchClientInput
            slug="client"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="begin"
            label={'begin'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            valueLabel={dates.CURRENT_MONTH}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginTop="8px">
          <Typography variant="body2" gutterBottom>
            {customerReservations.length} / {total}
          </Typography>
        </Grid>
        <List
          items={customerReservations}
          columns={[
            {
              label: '#',
              slug: 'status',
              id: 'id',
              valueFormatter: formatCustomerReservationStatus,
              text: 'statusLabel',
              link: { base: '/customer_reservations', slug: 'id' }
            },
            { label: t('type'), slug: 'typeLabel' },
            { label: t('center'), slug: 'center' },
            {
              label: t('enterprise'),
              slug: 'enterprise',
              link: { base: '/enterprises', slug: 'enterpriseId' }
            },
            {
              label: t('user'),
              slug: 'individual',
              link: { base: '/individuals', slug: 'individualId' }
            },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDateWithTime },
            { label: t('end'), slug: 'end', valueFormatter: formatDateWithTime },
            { label: t('services'), slug: 'services' },
            { label: t('amount'), slug: 'amount', valueFormatter: formatCurrency }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {customerReservations.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
