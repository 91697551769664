import { z } from 'zod'

export const rightSchema = z.object({
  id: z.number(),
  role: z.string(),
  resource: z.string(),
  isDisplay: z.boolean(),
  isShow: z.boolean(),
  isAdd: z.boolean(),
  isEdit: z.boolean(),
  isDelete: z.boolean(),
  isExportable: z.boolean(),
  isRegenerable: z.boolean(),
  isAllCenters: z.boolean()
})

export type Right = z.infer<typeof rightSchema>

const rightListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(rightSchema)
})

export type RightList = z.infer<typeof rightListSchema>
