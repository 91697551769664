import { z } from 'zod'
import dayjs from 'dayjs'

const endorsementServiceItemSchema = z.object({
  id: z.number(),
  finalPrice: z.number({ coerce: true }),
  sellPrice: z.number({ coerce: true }),
  workforce: z.number({ coerce: true }),
  priceDifference: z.number({ coerce: true }),
  serviceId: z.number()
})

const endorsementOptionsItemSchema = z.object({
  id: z.number({ coerce: true }),
  workforce: z.number({ coerce: true }),
  sellPrice: z.number({ coerce: true }),
  finalPrice: z.number({ coerce: true }),
  priceDifference: z.number({ coerce: true }),
  serviceId: z.number()
})

export type EndorsementServiceItem = z.infer<typeof endorsementServiceItemSchema>

export const endorsementUpdateSchema = z
  .object({
    begin: z.instanceof(dayjs as any).optional(),
    commitment: z.number({ coerce: true }),
    commitment_difference: z.number(),
    deadline: z.instanceof(dayjs as any).optional(),
    endorsement_reason: z.number(),
    endorsement_guarantees: z.number(),
    endorsement_price: z.number(),
    endorsement_state: z.number(),
    grid_type: z.number(),
    guarantees_difference: z.number(),
    price_difference: z.number(),
    price_difference_rate: z.number(),
    serviceId: z.array(z.number()),
    next_indexation_transfer: z.boolean().optional(),
    workforce_difference: z.number(),
    services: z.array(endorsementServiceItemSchema),
    options: z.array(endorsementOptionsItemSchema)
  })
  .transform((data) => {
    const { services, options } = data

    let formattedData = {
      ...data,
      services: undefined,
      options: undefined
    }

    for (let service of services) {
      let formattedService = {} as any
      for (let key in service) {
        if (key !== 'serviceId' && key !== 'id')
          formattedService[key + '_' + service.id] = service[key as keyof EndorsementServiceItem]
      }
      formattedData = {
        ...formattedData,
        ...formattedService
      }
      formattedData.serviceId.push(service.serviceId)
    }

    for (let option of options) {
      let formattedService = {} as any
      for (let key in option) {
        if (key !== 'serviceId' && key !== 'id')
          formattedService[key + '_' + option.id] = option[key as keyof EndorsementServiceItem]
      }
      formattedData = {
        ...formattedData,
        ...formattedService
      }
    }

    return {
      ...formattedData,
      begin: data.begin?.format('YYYY-MM-DD'),
      deadline: data.deadline?.format('YYYY-MM')
    }
  })

export type EndorsementUpdateForm = z.input<typeof endorsementUpdateSchema>
export type EndorsementUpdateData = z.output<typeof endorsementUpdateSchema>
