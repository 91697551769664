import { Box, Stack, styled } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { Divider, IconButton, Paper as MuiPaper, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { SvgIconComponent } from '@mui/icons-material'
import { InfoItemsComponent } from 'app/components/card/info-items.component'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const CardItem = styled(Stack)`
  gap: 8px;
  flex-direction: row;
  height: fit-content;
  align-items: center;
`

export const CardItemName = styled(Typography)`
  font-weight: 600;
  word-break: break-all;
`

export type InfoCardColumns = Array<{
  label?: string
  value?: string | number | ReactNode
  link?: string
  extra?: string | React.ReactNode
  custom?: string | React.ReactNode
}>

interface InfoCardProps {
  title: string
  titleAction?: { icon: SvgIconComponent; onClick: () => void }
  children?: ReactNode
  columns: InfoCardColumns
  isLoading?: boolean
  className?: string
}

function InfoCard({ title, children, columns, titleAction, isLoading, className }: InfoCardProps) {
  return (
    <MuiPaper sx={{ marginTop: 4 }} variant="outlined">
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent
            text={title}
            variant={'h3'}
            paddingTop={0}
            icon={<InfoOutlinedIcon color="action" fontSize="small" />}
          />
          {titleAction && (
            <IconButton size="small" color={'primary'} onClick={titleAction.onClick}>
              <titleAction.icon fontSize="small" />
            </IconButton>
          )}
        </Stack>
      </Box>
      <Divider />
      <InfoItemsComponent columns={columns} isLoading={isLoading} className={className} />
      {children}
    </MuiPaper>
  )
}

export default InfoCard
