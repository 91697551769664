import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { TermOfSalesItem, updateTermOfSalesInput } from 'api/models'
import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import InfoCard, { InfoCardColumns } from 'app/components/card/info-card.component'
import dayjs from 'dayjs'
import { useFeedback } from 'app/providers/feedback.provider'
import { PDFViewer } from 'app/components/pdf/PDFViewer'
import { useForm } from 'react-hook-form'
import { useApp } from 'app/providers/app.provider'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import { zodResolver } from '@hookform/resolvers/zod'

export const InternalDocumentView = ({ type }: { type: string }): React.JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const { id } = useParams()
  const { getInternalDocument, getInternalDocumentPdf, updateInternalDocument } = useFetcher()
  const [termOfSales, setTermOfSales] = useState<TermOfSalesItem>({} as TermOfSalesItem)
  const [pdf, setPdf] = useState<any>(null)
  const { handleMutation } = useFeedback()
  const { getRight } = useApp()
  const navigate = useNavigate()
  const [readOnly, setReadOnly] = useState<{ begin: boolean; end: boolean }>({
    begin: true,
    end: true
  })
  const method = useForm({
    mode: 'onChange',
    resolver: zodResolver(
      updateTermOfSalesInput.refine((data) => !data.end || data.end.isAfter(data.begin))
    )
  })

  const { isSubmitting, isValid } = method.formState
  const begin = method.watch('begin')

  const fetch = useCallback(async () => {
    setIsLoading(true)
    await handleMutation({
      mutation: getInternalDocument,
      data: { id: Number(id), type: type },
      onSuccess: (data) => {
        setTermOfSales(data)
      }
    })

    await handleMutation({
      mutation: getInternalDocumentPdf,
      data: { id: Number(id), type: type },
      onSuccess: (data) => {
        setPdf(data.document_pdf)
      }
    })
    setIsLoading(false)
  }, [])

  const handleSubmit = async (data: any) => {
    await handleMutation({
      confirm: {
        content: t('confirm_edit_terms_of_sales')
      },
      mutation: updateInternalDocument,
      data: { type: type, id: termOfSales.id, data: data },
      onSuccess: () => navigate('/terms-of-sales')
    })
  }

  useEffect(() => {
    fetch().then()
  }, [])

  useEffect(() => {
    method.reset({
      begin: termOfSales.begin ? dayjs.utc(termOfSales.begin) : null,
      end: termOfSales.end ? dayjs.utc(termOfSales.end) : null
    })

    setReadOnly({
      begin:
        !getRight('terms_of_sales', 'isEdit') ||
        dayjs(termOfSales.begin).isBefore(dayjs(), 'day') ||
        dayjs(termOfSales.begin).isSame(dayjs(), 'day'),
      end: !getRight('terms_of_sales', 'isEdit') || dayjs(termOfSales.end).isBefore(dayjs(), 'day')
    })
  }, [termOfSales])

  const columns = useMemo(() => {
    let _items = [
      {
        label: t('begin'),
        extra: (
          <ControlledDatePicker
            name={'begin'}
            control={method.control}
            readOnly={readOnly.begin}
            slotProps={{
              textField: {
                size: 'small'
              }
            }}
          />
        )
      },
      {
        label: t('end'),
        extra: (
          <ControlledDatePicker
            name={'end'}
            control={method.control}
            readOnly={readOnly.end}
            minDate={begin}
            slotProps={{
              textField: {
                size: 'small'
              }
            }}
          />
        )
      }
    ] as InfoCardColumns

    if (type === 'term-of-sales') {
      _items = [
        {
          label: t('indexing_type'),
          value: termOfSales.indexationTypeLabel
        },
        {
          custom: ''
        },
        ..._items
      ]
    }

    return _items
  }, [type, termOfSales, t])

  return isLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link
              to={type === 'term-of-sales' ? `/terms-of-sales` : '/rules-of-procedures'}
              style={{ lineHeight: '1em' }}
            >
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">
              {t(type === 'term-of-sales' ? 'terms_of_sales' : 'rules_of_procedure')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <InfoCard title={t('informations')} columns={columns}>
            {getRight('terms_of_sales', 'isEdit') && (
              <Grid paddingY={4} item textAlign={'center'} xs={12}>
                <Button
                  disabled={!isValid || isSubmitting}
                  onClick={method.handleSubmit(handleSubmit)}
                  variant={'contained'}
                >
                  {t('update')}
                </Button>
              </Grid>
            )}
          </InfoCard>
        </Grid>
        <Grid item xs={7}>
          <Card sx={{ marginTop: 4 }}>
            <CardContent>
              {pdf ? (
                <PDFViewer
                  pdf={'data:application/pdf;base64,' + pdf}
                  name={`terms-of-sales-${termOfSales.id}.pdf`}
                />
              ) : (
                <Typography textAlign={'center'}>{t('unavailable_file')}</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
