import React, { ReactNode, useState } from 'react'
import { TitleComponent } from 'app/components/titles/title.component'
import { Collapse, IconButton, Stack, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import styled from '@emotion/styled'
import { darken } from 'polished'

const CollapsableStack = styled(Stack)`
  .collapse-item-header {
    cursor: pointer;
  }
  &.collapse-item-0 {
    font-weight: bold;
  }
  &.collapse-item-1 {
    color: ${(props) => props.theme.palette.primary.light};
    .collapse-item-header {
      border-top: 1px solid ${(props) => darken(0.03, props.theme.palette.background.paper)};
    }
    &:first-of-type > .collapse-item-header {
      border-color: ${(props) => props.theme.sidebar.borderColor};
    }
  }
  &.collapse-item-2 {
    font-weight: normal;
    &:nth-of-type(odd) {
      background-color: ${(props) =>
        props.theme.palette.mode === 'dark'
          ? darken(0.03, props.theme.palette.background.paper)
          : props.theme.palette.grey[100]};
      color: ${(props) =>
        props.theme.palette.getContrastText(
          props.theme.palette.mode === 'dark'
            ? darken(0.03, props.theme.palette.background.paper)
            : props.theme.palette.grey[100]
        )};
    }
    &:nth-of-type(even) {
      background: ${(props) => props.theme.palette.background.default};
      color: ${(props) =>
        props.theme.palette.getContrastText(props.theme.palette.background.default)};
    }
    .collapse-item-header {
      border-top: 1px solid ${(props) => darken(0.03, props.theme.palette.background.paper)};
    }
  }
`

interface ICollapsableItem {
  label: string
  content?: ReactNode | string
  extra?: ReactNode | string
  depth: number
  children?: ReactNode
}

export const CollapsableItem = function ({
  label,
  content,
  extra,
  depth,
  children
}: ICollapsableItem) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <CollapsableStack sx={{ width: '100%' }} className={'collapse-item-' + depth}>
      <Stack
        className="collapse-item-header"
        paddingLeft={4 * (depth + 1)}
        paddingRight={5}
        paddingY={3}
        sx={{ width: '100%', boxSizing: 'border-box' }}
      >
        <Stack flexDirection={'row'} gap={3} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} alignItems={'center'}>
            {depth === 0 ? (
              <TitleComponent text={label} variant={'h3'} paddingLeft={12} />
            ) : (
              <Typography fontWeight={depth < 2 ? 'bold' : undefined}>{label}</Typography>
            )}
            {extra}
          </Stack>
          <IconButton size={'small'} onClick={() => setOpen(!open)}>
            {children && (open ? <ExpandLess /> : <ExpandMore />)}
          </IconButton>
        </Stack>
        <Stack>{content}</Stack>
      </Stack>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </CollapsableStack>
  )
}
