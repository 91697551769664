import { z } from 'zod'

const typeEnum = z.enum(['domiciliation', 'full_coworking', 'full_desktop', 'nomade_plan'])

const contractItemListSchema = z.object({
  id: z.number(),
  state: z.string(),
  reference: z.string(),
  contractTypeEnum: z.string(),
  staff: z.string(),
  staffLight: z.string(),
  client: z.string(),
  clientId: z.number(),
  center: z.string(),
  surface: z.string().nullable(),
  mainPrice: z.string(),
  optionsPrice: z.string().nullable(),
  squareMeterPrice: z.string().nullable(),
  commitment: z.string(),
  begin: z.string(),
  end: z.string(),
  deadline: z.string(),
  agreement: z.string(),
  confirm: z.string(),
  priorNotice: z.string(),
  stateLabel: z.string(),
  type: typeEnum,
  typeLabel: z.string(),
  remainingMonth: z.number()
})

export type ContractItem = z.infer<typeof contractItemListSchema>

const contractListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(contractItemListSchema)
})

export type ContractList = z.infer<typeof contractListSchema>

const contractSumSchema = z.object({
  mainPrice: z.string(),
  optionsPrice: z.string(),
  totalPrice: z.string(),
  surface: z.string()
})

export type ContractSum = z.infer<typeof contractSumSchema>

const contractSchema = z.object({
  id: z.number(),
  reference: z.string(),
  clientId: z.number(),
  clientName: z.string(),
  staffFirstName: z.string(),
  staffLastName: z.string(),
  centerId: z.number(),
  centerName: z.string(),
  contractBlocked: z.boolean(),
  hasEndorsement: z.boolean(),
  state: z.number(),
  createdAt: z.string(),
  directionAgreement: z.string(),
  agreement: z.string(),
  confirm: z.string(),
  begin: z.string(),
  deadline: z.string(),
  end: z.string(),
  type: z.number(),
  typeCancel: z.string(),
  hasRCS: z.boolean(),
  commitmentReal: z.number(),
  remainingMonth: z.number(),
  surface: z.number(),
  priorNotice: z.string(),
  nextIndexation: z.string(),
  invoicingType: z.string(),
  invoicingTypeLabel: z.string(),
  individuals: z.array(
    z.object({
      id: z.number(),
      labelledName: z.string()
    })
  ),
  isCoworking: z.boolean(),
  mainPrice: z.string(),
  optionsPrice: z.string(),
  reduction: z.string(),
  squareMeterPrice: z.string(),
  guaranteesInformation: z.object({
    current: z.number(),
    diff: z.number(),
    previous: z.number()
  }),
  signatoryId: z.number().optional(),
  signatoryName: z.string().optional(),
  language: z.number().optional()
})

export type Contract = z.infer<typeof contractSchema>

const contractMainSchema = z.object({
  id: z.number(),
  type: z.number(),
  label: z.string(),
  serviceType: z.string(),
  price: z.number(),
  surface: z.number(),
  occupants: z.number(),
  client: z.string(),
  clientId: z.number().nullable(),
  clientUrl: z.string(),
  counter: z.string(),
  counterPrevious: z.string(),
  counterNext: z.string()
})

const contractMainListSchema = z.array(contractMainSchema)

export type ContractMainList = z.infer<typeof contractMainListSchema>

const contractServiceSchema = z.object({
  id: z.number(),
  label: z.string(),
  serviceType: z.string(),
  begin: z.string(),
  end: z.string(),
  price: z.string(),
  isRemovable: z.boolean()
})

const contractServiceListSchema = z.array(contractServiceSchema)

export type ContractService = z.infer<typeof contractServiceSchema>
export type ContractOptionList = z.infer<typeof contractServiceListSchema>
export type ContractConsumptionList = z.infer<typeof contractServiceListSchema>

const contractInvoiceSchema = z.object({
  id: z.number(),
  link: z.string(),
  state: z.number(),
  daysLate: z.number(),
  reference: z.string(),
  type: z.string(),
  dueDate: z.boolean(),
  totalCost: z.number()
})

const contractInvoiceListSchema = z.array(contractInvoiceSchema)

export type ContractInvoiceList = z.infer<typeof contractInvoiceListSchema>

const contractLitigationSchema = z.object({
  id: z.number(),
  reference: z.string(),
  invoices: z.array(
    z.object({
      id: z.number(),
      reference: z.string()
    })
  ),
  createdAt: z.string(),
  status: z.number()
})

const contractLitigationListSchema = z.array(contractLitigationSchema)

export type ContractLitigationList = z.infer<typeof contractLitigationListSchema>

const contractPaymentSchema = z.object({
  id: z.number(),
  reference: z.string(),
  period: z.string(),
  amount: z.number()
})

const contractPaymentListSchema = z.array(contractPaymentSchema)

export type ContractPaymentList = z.infer<typeof contractPaymentListSchema>

const contractIndexingAnniversarySchema = z.array(
  z.object({
    date: z.string(),
    rate: z.number(),
    oldPrice: z.number(),
    newPrice: z.number()
  })
)

export type ContractIndexingAnniversaryList = z.infer<typeof contractIndexingAnniversarySchema>

//BOF - Contract consumptions
const contractConsumptionSchema = z.object({
  begin: z.string(),
  center: z.string(),
  client: z.string(),
  clientId: z.number(),
  clientType: z.string(),
  contractId: z.number(),
  contractType: z.string(),
  end: z.string(),
  invoice: z.string(),
  invoiceId: z.number(),
  paid: z.string(),
  price: z.number(),
  quantity: z.number(),
  reference: z.string(),
  type: z.string()
})

export type ContractConsumption = z.infer<typeof contractConsumptionSchema>

const contractsConsumptionsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(contractConsumptionSchema)
})

export type ContractsConsumptionsList = z.infer<typeof contractsConsumptionsListSchema>

const contractsConsumptionsSumSchema = z.object({
  totalAmount: z.number()
})

export type ContractsConsumptionsSum = z.infer<typeof contractsConsumptionsSumSchema>
//EOF - Contract consumption

const clientContractSchema = z.object({
  id: z.string(),
  reference: z.string(),
  centerId: z.number(),
  centerName: z.string(),
  begin: z.string(),
  type: z.number(),
  typeLabel: z.string()
})

export type ClientContract = z.infer<typeof clientContractSchema>

const clientContractListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(clientContractSchema)
})

export type ClientContractList = z.infer<typeof clientContractListSchema>

const clientContractParamsSchema = z.object({
  clientId: z.number(),
  centerId: z.number(),
  individualId: z.number()
})

export type ClientContractParams = z.infer<typeof clientContractParamsSchema>

export const contractServiceLinkUserForm = z.object({
  individual: z.number({ coerce: true }),
  contractService: z.number()
})

export type ContractServiceLinkUser = z.infer<typeof contractServiceLinkUserForm>

const contractUserSchema = z.object({
  id: z.number(),
  client: z.string()
})
const contractUsers = z.array(contractUserSchema)
export type ContractUsers = z.infer<typeof contractUsers>

const contractUpdateSchema = z.object({
  individual: z.string().optional(),
  language: z.number().optional()
})

export type ContractUpdate = z.infer<typeof contractUpdateSchema>
