import {
  Card,
  CardContent,
  Typography,
  useTheme,
  Chip as MuiChip,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'
import styled from '@emotion/styled'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import dayjs from 'dayjs'
import { DashboardMeetings } from 'api/models/dashboard'
import { Link } from 'app/components/link.component'

const StyledChip = styled(MuiChip)`
  padding: 2px;
  height: auto;

  span {
    padding: 0 2px;
    font-size: 9px;
    font-weight: bold;
  }
`

interface IDashboardCardMeetings {
  meetings: DashboardMeetings
  isLoading: boolean
}
export function DashboardCardMeetings({ meetings, isLoading }: IDashboardCardMeetings) {
  const theme = useTheme()
  if (isLoading) {
    return <CardSkeleton height={160}></CardSkeleton>
  }
  return (
    <Card sx={{ marginBottom: theme.spacing(6) }}>
      <CardContent>
        <TableContainer>
          <Table size="small" sx={{ minWidth: 650 }}>
            <TableBody>
              {meetings.map((meeting: any, index: number) => (
                <TableRow hover key={index}>
                  <TableCell style={{ whiteSpace: 'nowrap', color: theme.palette.primary.light }}>
                    {meeting.reservationId ? (
                      <Link to={`/customer_reservations/${meeting.reservationId}`}>
                        {dayjs(meeting.begin).format('L LT')}
                      </Link>
                    ) : (
                      dayjs(meeting.begin).format('L LT')
                    )}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap', color: theme.palette.primary.light }}>
                    {meeting.reservationId ? (
                      <Link to={`/customer_reservations/${meeting.reservationId}`}>
                        {meeting.end && dayjs(meeting.end).format('L LT')}
                      </Link>
                    ) : (
                      meeting.end && dayjs(meeting.end).format('L LT')
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography component={'span'} variant={'body1'} sx={{ opacity: 0.75 }}>
                      {meeting.reservationId ? (
                        <Link to={`/customer_reservations/${meeting.reservationId}`}>
                          {meeting.room}
                        </Link>
                      ) : (
                        meeting.room
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <StyledChip label={meeting.capacity} size="small" color="primary" />
                  </TableCell>
                  <TableCell>
                    <RouterLink
                      to={
                        meeting.clientType === 'enterprise'
                          ? `/enterprises/${meeting.clientId}`
                          : `/individuals/${meeting.clientId}`
                      }
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {meeting.clientName}
                    </RouterLink>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}
