import { useCallback, useState, useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { useFetcher } from 'app/providers/fetcher.provider'

export enum DefaultItemsType {
  LAST_12_MONTH = '1',
  ROLLING_12_MONTHS = '2',
  CURRENT_YEAR = '3',
  NEXT_YEAR = '4',
  THREE_YEARS_AGO_DATE = '5',
  TWO_YEARS_AGO_DATE = '6',
  LAST_YEAR_DATE = '7',
  CURRENT_YEAR_DATE = '8',
  NEXT_YEAR_DATE = '9'
}

interface IProps {
  onChange: () => void
  defaultValue?: DefaultItemsType
  defaultItems?: DefaultItemsType[]
  slugs?: { begin?: string; end?: string }
}

export default function DateRangeSelector({ defaultValue, onChange, defaultItems, slugs }: IProps) {
  const { t } = useTranslation()
  const [value, setValue] = useState(defaultValue || '')
  const data = useMemo(() => {
    return [
      {
        id: '1',
        label: t('last_12_months'),
        value: () => {
          const now = dayjs().startOf('day')
          return {
            begin: now.subtract(12, 'months').startOf('month'),
            end: now.endOf('month')
          }
        }
      },
      {
        id: '2',
        label: t('12_rolling_months'),
        value: () => {
          const now = dayjs()
          return {
            begin: now.subtract(6, 'months').startOf('month'),
            end: now.add(5, 'months').endOf('month')
          }
        }
      },
      {
        id: '3',
        label: t('current_year'),
        value: () => {
          const now = dayjs()
          return {
            begin: now.startOf('year'),
            end: now.endOf('year')
          }
        }
      },
      {
        id: '4',
        label: t('next_year'),
        value: () => {
          const today = dayjs()
          const startOfNextYear = today.endOf('year').add(1, 'day')
          const endOfNextYear = startOfNextYear.endOf('year')
          return {
            begin: startOfNextYear,
            end: endOfNextYear
          }
        }
      },
      {
        id: '5',
        label: dayjs().subtract(3, 'year').year(),
        value: () => {
          const threeYearsAgoDate = dayjs().subtract(3, 'year')
          return {
            begin: threeYearsAgoDate.startOf('year'),
            end: threeYearsAgoDate.endOf('year')
          }
        }
      },
      {
        id: '6',
        label: dayjs().subtract(2, 'year').year(),
        value: () => {
          const twoYearsAgoDate = dayjs().subtract(2, 'year')
          return {
            begin: twoYearsAgoDate.startOf('year'),
            end: twoYearsAgoDate.endOf('year')
          }
        }
      },
      {
        id: '7',
        label: dayjs().subtract(1, 'year').year(),
        value: () => {
          const lastYear = dayjs().subtract(1, 'year')
          return {
            begin: lastYear.startOf('year'),
            end: lastYear.endOf('year')
          }
        }
      },
      {
        id: '8',
        label: dayjs().year(),
        value: () => {
          const now = dayjs()
          return {
            begin: now.startOf('year'),
            end: now.endOf('year')
          }
        }
      },
      {
        id: '9',
        label: dayjs().add(1, 'year').year(),
        value: () => {
          const nextYear = dayjs().add(1, 'year')
          return {
            begin: nextYear.startOf('year'),
            end: nextYear.endOf('year')
          }
        }
      }
    ]
  }, [])
  const [items, setItems] = useState(data)
  const { searchParams, setSearchParams } = useFetcher()

  useEffect(() => {
    if (defaultItems) {
      setItems(items.filter((item) => defaultItems.includes(item.id as DefaultItemsType)))
    }
    if (value) {
      initParams(value)
    }
  }, [])

  const initParams = useCallback(
    (id: string) => {
      const currentItem = items.find((item) => item.id === id)
      const begin = currentItem?.value().begin.format('YYYY-MM-DD')
      const end = currentItem?.value().end.format('YYYY-MM-DD')

      const beginSlug = slugs?.begin || 'date_begin'
      const endSlug = slugs?.end || 'date_end'

      searchParams.set(beginSlug, String(begin))
      searchParams.set(endSlug, String(end))
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const handleChange = useCallback((event: SelectChangeEvent) => {
    const id = String(event.target.value)
    initParams(id)
    onChange()
    setValue(id)
  }, [])

  return (
    <Box sx={{ width: 200 }}>
      <FormControl fullWidth size={'small'}>
        <InputLabel id="date-range-picker-select">{t('period')}</InputLabel>
        <Select
          startAdornment={
            <DateRangeIcon fontSize={'small'} sx={{ marginRight: 2, color: '#857e7e' }} />
          }
          size={'small'}
          labelId="date-range-picker-select"
          value={value}
          label={t('period')}
          onChange={handleChange}
        >
          {items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
