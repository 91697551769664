import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'app/utils/format'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Company, CompanyConfiguration } from 'api/models/companies'
import { useApp } from 'app/providers/app.provider'
import { RolesEnum } from 'app/constants/roles'
import InfoCard from 'app/components/card/info-card.component'

interface ICompanyCenters {
  company: Company
}

export function CompanyConfigurationsComponent({ company }: ICompanyCenters) {
  const { t } = useTranslation()
  const [configuration, setConfiguration] = useState<CompanyConfiguration>(
    {} as CompanyConfiguration
  )
  const [isLoading, setIsLoading] = useState(false)
  const { getCompanyConfigurations } = useFetcher()
  const { isRole } = useApp()
  const refreshList = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await getCompanyConfigurations.mutateAsync(company.id)
      if (data) {
        setConfiguration(data)
      }
    } finally {
      setIsLoading(false)
    }
  }, [company.id])

  const columns = useMemo(() => {
    const _items = [
      {
        label: t('max_amount_direct_debit'),
        value:
          configuration.maxAmountDirectDebit !== undefined
            ? formatCurrency(configuration.maxAmountDirectDebit)
            : null
      }
    ]

    if (isRole(RolesEnum.ADMIN)) {
      _items.push({
        label: t('gc_access_token'),
        value: configuration.gcAccessToken ?? '-'
      })
      _items.push({
        label: t('stripe_public_key'),
        value: configuration.stripePublicKey ?? '-'
      })

      _items.push({
        label: t('stripe_secret_key'),
        value: configuration.stripeSecretKey ?? '-'
      })
    }

    return _items
  }, [configuration, isRole])

  useEffect(() => {
    refreshList().then()
  }, [refreshList])

  return <InfoCard title={t('configuration')} columns={columns} isLoading={isLoading}></InfoCard>
}
