import React, { HTMLInputTypeAttribute } from 'react'
import { FormControl } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import { DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker.types'

type IControlledDateFieldProps<TDate> = {
  type?: HTMLInputTypeAttribute
  label?: string
  name: string
  control: Control<any>
  icon?: React.FC<any>
  required?: boolean
} & DateTimePickerProps<TDate>

export function ControlledDateTimePicker<TDate>({
  name,
  label,
  control,
  required,
  ...otherProps
}: IControlledDateFieldProps<TDate>) {
  return (
    <Controller
      render={({ field: { ref, value, onChange } }) => {
        return (
          <FormControl fullWidth size={'small'} required={required}>
            <DateTimePicker
              format={'L HH:mm'}
              label={`${label || ''}`}
              defaultValue={value}
              value={value}
              inputRef={ref}
              onChange={onChange}
              slotProps={{
                textField: { size: 'small' }
              }}
              {...otherProps}
            />
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
