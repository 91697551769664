import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import type {
  FiltersInput,
  Filters,
  FilterClientInput,
  FilterClient,
  FilterCenterInput,
  FilterCenter,
  FilterStaffInput,
  FilterStaff,
  FilterMemberInput,
  SelectCenters,
  FilterSelectSearch
} from 'api/models'
import { FilterSelectInput } from 'api/models'

export const filtersRouter = {
  list: async ({ token }: Ctx, filters: FiltersInput, params: any) =>
    fetcher<Filters>({
      url: `${BASE_URL}${API.GET_FILTERS}?references=${JSON.stringify(
        filters
      )}&params=${JSON.stringify(params)}`,
      method: 'GET',
      token: token
    }),
  getClient: async (
    { token }: Ctx,
    { name, is_individual, is_enterprise, id }: FilterClientInput
  ) =>
    fetcher<FilterClient>({
      url: `${BASE_URL}${API.GET_CLIENTS_FILTER}?name=${name}${
        is_individual ? '&is_individual=true' : ''
      }${is_enterprise ? '&is_enterprise=true' : ''}${id ? `&id=${id}` : ''}`,
      method: 'GET',
      token: token
    }),
  getCenter: async ({ token }: Ctx, { name, is_cluster, is_center }: FilterCenterInput) =>
    fetcher<FilterCenter>({
      url: `${BASE_URL}${API.GET_CENTER_FILTER}?name=${name}${
        is_cluster ? '&is_cluster=true' : ''
      }${is_center ? '&is_center=true' : ''}`,
      method: 'GET',
      token: token
    }),
  getCenters: async (
    { token }: Ctx,
    { name, is_cluster, is_center, all_centers, bypass_all_centers }: FilterCenterInput
  ) =>
    fetcher<SelectCenters>({
      url: `${BASE_URL}${API.GET_CENTER_FILTER_SELECT}?name=${name}${
        is_cluster ? '&is_cluster=true' : ''
      }${is_center ? '&is_center=true' : ''}${all_centers ? '&all_centers_options=true' : ''}${
        bypass_all_centers ? '&bypass_all_centers=true' : ''
      }`,
      method: 'GET',
      token: token
    }),
  getStaff: async ({ token }: Ctx, { name }: FilterStaffInput) =>
    fetcher<FilterStaff>({
      url: `${BASE_URL}${API.GET_STAFF_FILTER}?name=${name}`,
      method: 'GET',
      token: token
    }),
  getEnterpriseMembers: async ({ token }: Ctx, { id, name }: FilterMemberInput) =>
    fetcher<FilterStaff>({
      url: `${BASE_URL}${API.GET_ENTERPRISE_MEMBERS_FILTER.replace(':id', id)}?name=${name}`,
      method: 'GET',
      token: token
    }),
  getWifiSsids: async ({ token }: Ctx, { name, id }: FilterSelectInput) =>
    fetcher<FilterSelectSearch>({
      url: `${BASE_URL}${API.GET_WIFI_FILTER_SSIDS}?name=${name}${id ? `&id=${id}` : ''}`,
      method: 'GET',
      token: token
    }),
  getWifiStations: async ({ token }: Ctx, { name, id }: FilterSelectInput) =>
    fetcher<FilterSelectSearch>({
      url: `${BASE_URL}${API.GET_WIFI_FILTER_STATIONS}?name=${name}${id ? `&id=${id}` : ''}`,
      method: 'GET',
      token: token
    }),
  formItems: async ({ token }: Ctx, filters: FiltersInput) =>
    fetcher<Filters>({
      url: `${BASE_URL}${API.GET_FORM_ITEMS}?references=${JSON.stringify(filters)}`,
      method: 'GET',
      token: token
    })
}
