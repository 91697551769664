import { Card, CardContent, CardHeader, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Stack } from '@mui/system'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import StatsComponent from 'modules/dashboard/components/stats.component'
import { deepOrange, green, yellow } from '@mui/material/colors'
import { useTranslation } from 'react-i18next'
import { DashboardOccupation, DashboardOccupations } from 'api/models/dashboard'
import { Link } from 'react-router-dom'

interface IDashboardCardOccupancy {
  occupations: DashboardOccupations
  center: string
  isLoading: boolean
}

export function DashboardCardOccupancy({
  occupations,
  center,
  isLoading
}: IDashboardCardOccupancy) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [occupationRates, setOccupationRates] = useState({
    toDate: 0,
    toThreeMonth: 0,
    toSixMonth: 0
  })

  const findItem = useCallback(
    (item: DashboardOccupation) => {
      let currentCenterId = Number(center)
      if (currentCenterId === -1) {
        currentCenterId = 0
      }
      return item.centerId === currentCenterId
    },
    [center]
  )

  useEffect(() => {
    if (!occupations || occupations.length <= 0) return

    const toDate = occupations[0].items.find(findItem)
    const toThreeMonth = occupations[2].items.find(findItem)
    const toSixMonth = occupations[5].items.find(findItem)

    setOccupationRates({
      toDate: toDate ? toDate.occupiedRate : 0,
      toThreeMonth: toThreeMonth ? toThreeMonth.occupiedRate : 0,
      toSixMonth: toSixMonth ? toSixMonth.occupiedRate : 0
    })
  }, [occupations])

  if (isLoading) {
    return <CardSkeleton height={'100%'}></CardSkeleton>
  }

  return (
    <Link to={`/statsrateoccupancy?center=${center}`} style={{ textDecoration: 'none' }}>
      <Card sx={{ background: theme.palette.secondary.main }}>
        <CardHeader
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }}
          title={
            <React.Fragment>
              <Typography sx={{ display: 'inline' }} component="span" variant="h3" color="white">
                {t('occupation_rate')}
              </Typography>
            </React.Fragment>
          }
        ></CardHeader>
        <CardContent sx={{ paddingTop: '8px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
            <StatsComponent
              title={t('to_date')}
              value={occupationRates.toDate}
              circularColor={yellow[500]}
            />
            <StatsComponent
              title={t('to_three_month')}
              value={occupationRates.toThreeMonth}
              circularColor={deepOrange[400]}
            />
            <StatsComponent
              title={t('to_six_month')}
              value={occupationRates.toSixMonth}
              circularColor={green[100]}
            />
          </Stack>
        </CardContent>
      </Card>
    </Link>
  )
}
