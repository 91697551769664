import {
  Card,
  CardContent,
  CardHeader,
  Typography as MuiTypography,
  useTheme,
  Chip as MuiChip
} from '@mui/material'
import React from 'react'
import { Stack } from '@mui/system'
import styled from '@emotion/styled'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { DashboardContract } from 'api/models/dashboard'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const StyledChipHighlight = styled(MuiChip)`
  height: 20px;
  font-size: 85%;
  font-weight: bold;
  background-color: ${(props) => props.theme.chip.background};
  color: ${(props) => props.theme.chip.color};

  & .MuiChip-icon {
    color: ${(props) => props.theme.chip.color};
  }
  &:hover {
    color: ${(props) => props.theme.palette.secondary.main};
  }

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`

const StyledTypography = styled(MuiTypography)`
  color: white;
  &:hover {
    text-decoration: underline;
  }
`

interface IDashboardCardContract {
  contracts: DashboardContract
  isLoading: boolean
}

export function DashboardCardContract({ contracts, isLoading }: IDashboardCardContract) {
  const theme = useTheme()
  const { t } = useTranslation()

  if (isLoading) {
    return <CardSkeleton height={'100%'}></CardSkeleton>
  }

  return (
    <Card sx={{ background: theme.palette.secondary.main, minHeight: '100%' }}>
      <CardHeader
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }}
        title={
          <React.Fragment>
            <MuiTypography sx={{ display: 'inline' }} component="span" variant="h3" color="white">
              {t('contracts')}
            </MuiTypography>
          </React.Fragment>
        }
      ></CardHeader>
      <CardContent>
        <Stack
          sx={{ marginBottom: theme.spacing(3) }}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0}
        >
          <Link
            to={`/contracts?state=[${contracts.createdContractStatus?.join(',')}]`}
            style={{ textDecoration: 'none' }}
          >
            <StyledTypography variant={'body2'} color="white">
              {t('waiting_agreement')}
            </StyledTypography>
          </Link>
          <Link
            to={`/contracts?state=[${contracts.createdContractStatus?.join(',')}]`}
            style={{ textDecoration: 'none' }}
          >
            <StyledChipHighlight
              label={contracts.createdContract} //createdContractStatus
              size={'small'}
              sx={{ color: 'white', fontWeight: 'bold' }}
            ></StyledChipHighlight>
          </Link>
        </Stack>
        <Stack
          sx={{ marginBottom: theme.spacing(3) }}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0}
        >
          <Link
            to={`/contracts?state=[${contracts.signedContractStatus?.join(',')}]`}
            style={{ textDecoration: 'none' }}
          >
            <StyledTypography variant={'body2'} color="white">
              {t('waiting_payment')}
            </StyledTypography>
          </Link>
          <Link
            to={`/contracts?state=[${contracts.signedContractStatus?.join(',')}]`}
            style={{ textDecoration: 'none' }}
          >
            <StyledChipHighlight
              label={contracts.signedContract}
              size={'small'}
              sx={{ color: 'white', fontWeight: 'bold' }}
            ></StyledChipHighlight>
          </Link>
        </Stack>
      </CardContent>
    </Card>
  )
}
