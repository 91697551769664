import React from 'react'
import { Box, Stack } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { Link } from 'app/components/link.component'
import { IconButton, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import type { IndividualDetails } from 'api/models'
import styled from '@emotion/styled'
import { formatYesNo } from 'app/utils/format'

const CustomGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;
  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Item = styled(Stack)`
  gap: 8px;
  flex-direction: row;
  height: fit-content;
  align-items: center;
`

const ItemName = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
`

const ErrorText = styled(Typography)`
  color: red;
  font-weight: 600;
`

const ITEMS = Array.from(
  new Map<string, keyof IndividualDetails>([
    ['firstname', 'firstName'],
    ['lastname', 'lastName'],
    ['center', 'centerName'],
    ['email', 'email'],
    ['phone', 'phone'],
    ['mobile', 'mobile'],
    ['address', 'address'],
    ['zipcode', 'zipCode'],
    ['city', 'city'],
    ['country', 'countryLabel'],
    ['language', 'language'],
    ['welcomrUuid', 'welcomrUuid']
  ])
)

export interface IProps {
  individual: IndividualDetails
}

export const InformationCardComponent = ({ individual }: IProps): React.JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent text={t('informations')} variant={'h3'} paddingTop={0} paddingLeft={12} />
          <Link to={`/individuals/${individual.id}/edit`}>
            <IconButton size="small" color={'primary'}>
              <Edit fontSize="small" />
            </IconButton>
          </Link>
        </Stack>
      </Box>
      <CustomGrid>
        {ITEMS.map(([label, key]) => (
          <Item key={key}>
            <Stack>
              <Typography variant="caption" sx={{ opacity: 0.85 }}>
                {t(label)}
              </Typography>
              {key === 'email' ? (
                <>
                  <ItemName variant="body2">{individual[key]}</ItemName>
                  {individual.isInvalidEmail && (
                    <ErrorText variant="body2">{t('invalid_blocked_email')}</ErrorText>
                  )}
                </>
              ) : (
                <ItemName variant="body2">{individual[key]}</ItemName>
              )}
            </Stack>
          </Item>
        ))}
        <Item>
          <Stack>
            <Typography>{t('on_site')}</Typography>
            <ItemName variant="body2">{t(formatYesNo(individual.isPresent ?? false))}</ItemName>
          </Stack>
        </Item>
        <Item>
          <Stack>
            <Typography>{t('remote_opening')}</Typography>
            <ItemName variant="body2">
              {t(formatYesNo(individual.isRemoteOpening ?? false))}
            </ItemName>
          </Stack>
        </Item>
      </CustomGrid>
    </>
  )
}
