import { z } from 'zod'

export const clientCenterFormSchema = z.object({
  accountingName: z.string().min(1),
  accountingNumber: z.string().min(1).max(8),
  isAccounted: z.boolean()
})

export type ClientCenterForm = z.infer<typeof clientCenterFormSchema>

export const clientCenterMailFormSchema = z.object({
  accountingMail: z.string().email()
})

export type ClientCenterMailForm = z.infer<typeof clientCenterMailFormSchema>
