import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { OccupancyList, OccupancySum } from 'api/models/occupancy'

export const occupancyRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<OccupancyList>({
      url: `${BASE_URL}${API.GET_SERVICE_OCCUPANCY}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async ({ token }: Ctx, params: string) =>
    fetcher<OccupancySum>({
      url: `${BASE_URL}${API.GET_SERVICE_OCCUPANCY_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getExport: async ({ token }: Ctx, params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.GET_SERVICE_OCCUPANCY_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'occupants.xlsx'
    )
}
