import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { PaymentsSum, type PaymentsList, Payment } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const paymentsRouter = {
  read: async ({ token }: Ctx, id: number) =>
    fetcher<Payment>({
      url: `${BASE_URL}${urlHelper(API.GET_PAYMENT_READ, { id })}`,
      method: 'GET',
      token: token
    }),
  list: async ({ token }: Ctx, params: string) =>
    fetcher<PaymentsList>({
      url: `${BASE_URL}${API.GET_PAYMENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async ({ token }: Ctx, params: string) =>
    fetcher<PaymentsSum>({
      url: `${BASE_URL}${API.GET_PAYMENTS_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  update: async ({ token }: Ctx, id: number, state: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_PAYMENT, { id })}`,
      method: 'PATCH',
      token: token,
      body: { state: state }
    }),
  delete: async ({ token }: Ctx, id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.DELETE_PAYMENT, { id })}`,
      method: 'DELETE',
      token: token
    })
}
