import { type Ctx, fetcher, formatParams, uploader } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  TermOfSalesItem,
  TermsOfSalesList,
  UpdateTermOfSalesInput,
  UploadTermOfSalesInput
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const termsOfSalesRouter = {
  list: async ({ token }: Ctx, type: string | undefined, params: string) =>
    fetcher<TermsOfSalesList>({
      url: `${BASE_URL}${urlHelper(API.TERMS_OF_SALES, {
        type: type ?? 'terms-of-sales'
      })}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  get: async ({ token }: Ctx, type: string, id: number) =>
    fetcher<TermOfSalesItem>({
      url: `${BASE_URL}${urlHelper(API.TERM_OF_SALES, { type, id })}`,
      method: 'GET',
      token: token
    }),
  pdf: async ({ token }: Ctx, type: string, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.TERM_OF_SALES, { type, id })}/pdf`,
      method: 'GET',
      token: token
    }),
  update: async ({ token }: Ctx, type: string, id: number, data: UpdateTermOfSalesInput) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.TERM_OF_SALES, { type, id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  upload: async ({ token }: Ctx, type: string, data: UploadTermOfSalesInput) => {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('begin', data.begin.format('YYYY-MM-DD'))

    return uploader<void>({
      url: `${BASE_URL}${urlHelper(API.TERMS_OF_SALES, { type })}/upload`,
      method: 'POST',
      token: token,
      body: formData
    })
  }
}
