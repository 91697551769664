import { Skeleton, Typography } from '@mui/material'
import { Stack, styled } from '@mui/system'
import { Link } from 'app/components/link.component'
import React, { ReactNode } from 'react'
import { CardItem, CardItemName } from 'app/components/card/info-card.component'

type InfoCardColumns = Array<{
  label?: string
  value?: string | number | ReactNode
  link?: string
  extra?: string | React.ReactNode
  custom?: string | React.ReactNode
}>

interface InfoCardProps {
  columns: InfoCardColumns
  isLoading?: boolean
  className?: string
}

const CustomGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(1, 1fr);
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    &.Grid3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`

const ItemTypography = styled(Typography)`
  opacity: 0.85;
`

export const InfoItemsComponent = function ({ columns, isLoading, className }: InfoCardProps) {
  return (
    <CustomGrid className={className}>
      {columns.map((column, index) => {
        if (isLoading) return <Skeleton key={index} height={32}></Skeleton>
        return column.custom ? (
          <React.Fragment key={index}>{column.custom}</React.Fragment>
        ) : (
          <CardItem key={index}>
            <Stack>
              <ItemTypography variant="caption">{column.label}</ItemTypography>
              <CardItemName variant="body2">
                {column.link ? <Link to={column.link}>{column.value}</Link> : column.value}
              </CardItemName>
              {column.extra ? column.extra : null}
            </Stack>
          </CardItem>
        )
      })}
    </CustomGrid>
  )
}
