import { z } from 'zod'

export const EmailTemplateFormSchema = z.object({
  name: z.string(),
  html: z.string()
})

const templateFormSchema = z.object({
  id: z.string(),
  name: z.string(),
  html: z.string()
})

export type EmailTemplateForm = z.infer<typeof templateFormSchema>
