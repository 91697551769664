import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'
import { EnterpriseInformation, FormItem, FormItems } from 'api/models'
import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { SquareFoot } from '@mui/icons-material'
import { FormCard } from 'app/components/form/form-card.component'
import { OpportunityCreateFormData, opportunityCreateSchema } from 'api/models/forms/opportunities'
import dayjs from 'dayjs'
import { useApp } from 'app/providers/app.provider'

export const OpportunityAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getFormItemsWithFilters, createEnterpriseOpportunity } = useFetcher()
  const { id } = useParams()
  const { user } = useApp()
  const [enterprise, setEnterprise] = useState({} as EnterpriseInformation)
  const { getEnterprise } = useFetcher()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()

  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['languages', 'language'],
      ['countries', 'country'],
      ['centers', 'center'],
      ['clients', 'client'],
      ['canals', 'canal'],
      ['subcanals', 'subcanal'],
      ['sources', 'source'],
      ['opportunity_services', 'type'],
      ['opportunity_status', 'status'],
      ['opportunity_steps', 'step'],
      ['opportunity_qualifications', 'qualification'],
      ['commitments', 'commitment']
    ])
  )

  const [formItems, setFormItems] = useState({} as FormItems)

  const getDefaultValue = useCallback(async () => {
    setIsLoading(true)
    const data = await getEnterprise.mutateAsync(String(id))
    setEnterprise(data)
    methods.setValue('center', String(data.mainCenter))
    setIsLoading(false)
  }, [id])

  const methods = useForm<OpportunityCreateFormData>({
    resolver: zodResolver(opportunityCreateSchema),
    mode: 'onChange'
  })
  const begin = methods.watch('begin')

  const initOptions = useCallback(
    async (commonOptions: Map<string, string>) => {
      const { canal, subcanal } = methods.getValues()
      const optionsData = (await getFormItemsWithFilters.mutateAsync({
        filters: Array.from(commonOptions.keys() as any),
        references_filters: { subcanals: { canal }, sources: { subcanal: subcanal } }
      })) as FormItems

      Object.keys(optionsData).forEach((key) => {
        let value = optionsData[key as keyof FormItems] as FormItem
        if (!value) return

        if (commonOptions.has(value.reference))
          optionsData[key as keyof FormItems] = {
            ...value,
            name: String(commonOptions.get(value.reference))
          }
      })

      setFormItems(optionsData as FormItems)
      return optionsData
    },
    [methods.getValues]
  )

  const handleSubmit = async (data: OpportunityCreateFormData) => {
    await handleMutation({
      confirm: {
        content: t('confirm_create_opportunity')
      },
      data: { id: String(enterprise.id), data },
      mutation: createEnterpriseOpportunity,
      onSuccess: ({ id }) => navigate(`/opportunities/${id}`),
      toastSuccess: t('add-opportunity-success')
    })
  }

  useEffect(() => {
    initOptions(commonOptions).then(async (optionsData) => {
      if (methods.getValues().canal) {
        methods.setValue(
          'subcanal',
          optionsData?.subcanals?.values && optionsData?.subcanals?.values.length > 0
            ? Number(optionsData?.subcanals?.values[0].id)
            : null,
          { shouldValidate: true }
        )
        methods.setValue('source', null, { shouldValidate: true })
      } else {
        methods.setValue('subcanal', null, { shouldValidate: true })
        methods.setValue('source', null, { shouldValidate: true })
      }
    })
  }, [methods.watch('canal'), methods.getValues, methods.setValue])

  useEffect(() => {
    if (methods.getValues().subcanal) {
      initOptions(commonOptions).then((optionsData) => {
        methods.setValue(
          'source',
          optionsData?.sources?.values && optionsData?.sources?.values.length > 0
            ? Number(optionsData?.sources?.values[0].id)
            : null,
          { shouldValidate: true }
        )
      })
    } else {
      methods.setValue('source', null, { shouldValidate: true })
    }
  }, [methods.watch('subcanal'), methods.getValues, methods.setValue])

  useEffect(() => {
    initOptions(commonOptions).then((optionsData) => {
      getDefaultValue()
      for (let key in optionsData) {
        const option = optionsData[key as keyof FormItems]
        if (!option || option.default[0] === undefined) continue
        methods.setValue(option.name as keyof OpportunityCreateFormData, option.default[0])
      }
      methods.setValue('canal', null)
      methods.setValue('step', '1')
      methods.setValue('status', '2')
      methods.setValue('city', '')
      methods.setValue('begin', dayjs())
      methods.setValue('staff', user?.id ?? 0)
      methods.setValue('surface', undefined)
    })
  }, [])

  return (
    <div>
      <Grid container>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h1">{t('create_opportunity')}</Typography>
          </Grid>
        </Grid>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)} style={{ width: '100%' }}>
            <FormCard
              isLoading={isLoading}
              title={t('opportunity')}
              control={methods.control}
              items={[
                {
                  type: 'centers',
                  label: t('center'),
                  name: 'center',
                  inputProps: {
                    defaultIsIndividual: true,
                    defaultIsEnterprise: false,
                    byPassAllCenters: true
                  }
                },
                { type: 'textfield', label: t('city'), name: 'city' },
                { type: 'textfield', label: t('capacity'), name: 'capacity', inputType: 'number' },
                {
                  type: 'textfield',
                  label: t('surface'),
                  name: 'surface',
                  inputType: 'number',
                  icon: SquareFoot
                },
                {
                  type: 'textfield',
                  label: t('service_number'),
                  name: 'serviceNumber',
                  inputType: 'number'
                },
                {
                  type: 'select',
                  label: t('commitment'),
                  name: 'commitment',
                  formItem: formItems.commitments
                },
                {
                  type: 'datepicker',
                  label: t('begin'),
                  name: 'begin',
                  inputProps: { isUTC: false }
                },
                {
                  type: 'datepicker',
                  label: t('end'),
                  name: 'end',
                  inputProps: {
                    isUTC: true,
                    clearable: true,
                    minDate: begin
                  }
                },
                { type: 'select', label: t('canal'), name: 'canal', formItem: formItems.canals },
                {
                  type: 'select',
                  label: t('qualification'),
                  name: 'qualification',
                  formItem: formItems.opportunity_qualifications
                },
                {
                  type: 'select',
                  label: t('subcanal'),
                  name: 'subcanal',
                  formItem: formItems.subcanals
                },
                { type: 'blank' },
                { type: 'select', label: t('source'), name: 'source', formItem: formItems.sources },
                { type: 'blank' },
                {
                  type: 'select',
                  label: t('step'),
                  name: 'step',
                  formItem: formItems.opportunity_steps
                },
                {
                  type: 'select',
                  label: t('type'),
                  name: 'type',
                  formItem: formItems.opportunity_services
                },
                {
                  type: 'staffs',
                  label: t('staff'),
                  name: 'staff'
                },
                {
                  type: 'members',
                  label: t('client'),
                  name: 'individuals',
                  inputProps: {
                    companyId: enterprise.id
                  }
                },
                {
                  type: 'clients',
                  label: t('prescriber'),
                  name: 'prescriber'
                },
                {
                  type: 'members',
                  label: t('signatory'),
                  name: 'signatory',
                  inputProps: {
                    companyId: enterprise.id
                  }
                },
                {
                  type: 'members',
                  label: t('decider'),
                  name: 'decider',
                  inputProps: {
                    companyId: enterprise.id
                  }
                }
              ]}
            />
            <Grid item xs={12} textAlign={'center'}>
              <Button
                type="submit"
                disabled={methods.formState.isSubmitting || !methods.formState.isValid}
                variant={'contained'}
                color={'primary'}
              >
                {t('save')}
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </div>
  )
}
