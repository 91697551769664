export const customBlueStartWay = {
  50: '#f2f6fb',
  100: '#e5eef6',
  200: '#ccdded',
  300: '#b2cce4',
  400: '#99bbdc',
  500: '#7faad3',
  600: '#4c88c1',
  700: '#3378b9',
  800: '#1967b0',
  900: '#0056a7'
}

export const custom0rangeStartWay = {
  50: '#fdfaf3',
  100: '#fcf5e5',
  200: '#faebcc',
  300: '#f6e0b1',
  400: '#f4d69a',
  500: '#f0cc80',
  600: '#eab74c',
  700: '#e2a318',
  800: '#e09900',
  900: '#ad720c'
}
