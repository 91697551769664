import React from 'react'
import { Typography } from '@mui/material'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UnloggedWrapper from 'app/components/UnloggedWrapper'
import { useApp } from 'app/providers/app.provider'
import NewPasswordForm from 'modules/auth/components/NewPasswordForm'

export const NewPasswordView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const { user } = useApp()

  if (user) {
    return <Navigate to="/" />
  }

  if (!searchParams.get('token')) {
    return <Navigate to="/sign-in" />
  }

  return (
    <UnloggedWrapper>
      <Typography variant="h1" align="center" typography={'h1'} gutterBottom></Typography>

      <Typography variant="h4" align="center" typography={'h4'} gutterBottom marginBottom={10}>
        {t('new_password')}
      </Typography>
      <Typography align="center" marginBottom={4}>
        {t('reset_password_info')}
      </Typography>
      <NewPasswordForm />
    </UnloggedWrapper>
  )
}
