import { z } from 'zod'

const checkoutSchema = z.object({
  contractId: z.string(),
  contractReference: z.string(),
  contractDeadline: z.string(),
  contractPriorNotice: z.string(),
  clientName: z.string(),
  clientId: z.string(),
  centerName: z.string(),
  contractCommitment: z.string(),
  contractSurface: z.string(),
  contractPrice: z.string(),
  contractSquareMeterPrice: z.string(),
  endorsementId: z.string(),
  endorsementReference: z.string(),
  endorsementState: z.string(),
  endorsementReason: z.string(),
  endorsementStaff: z.string(),
  endorsementStaffLight: z.string(),
  endorsementCommitment: z.string(),
  endorsementSurface: z.string(),
  endorsementPrice: z.string(),
  endorsementSquareMeterPrice: z.string(),
  endorsementBegin: z.string(),
  amountDifference: z.string(),
  surfaceDifference: z.string(),
  commitmentDifference: z.string(),
  guaranteesDifference: z.string(),
  contractTypeLabel: z.string(),
  contractType: z.string(),
  endorsementReasonLabel: z.string(),
  endorsementStateLabel: z.string(),
  squareMeterDifference: z.string()
})

export type Checkout = z.infer<typeof checkoutSchema>

const checkoutListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(checkoutSchema)
})

export type CheckoutList = z.infer<typeof checkoutListSchema>

const checkoutSumSchema = z.object({
  contractCount: z.string(),
  endorsementCount: z.string(),
  contractPriceSum: z.string(),
  contractSurfaceSum: z.string(),
  endorsementCommitmentDifference: z.string(),
  endorsementAmountDifference: z.string(),
  endorsementSurfaceDifferenceSum: z.string()
})

export type CheckoutSum = z.infer<typeof checkoutSumSchema>
