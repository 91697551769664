import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { ContactsByContractList } from 'api/models/contacts'

export const contactsRouter = {
  getContacts: async ({ token }: Ctx, params: string) =>
    fetcher<ContactsByContractList>({
      url: `${BASE_URL}${API.GET_CONTACTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getContactExport: async ({ token }: Ctx, params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.GET_CONTACTS_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'contacts.xlsx'
    )
}
