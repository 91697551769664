import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CenterClustersItem, EditClusterProps, editClusterProps } from 'api/models'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { Button } from '@mui/material'
import { FormCard } from 'app/components/form/form-card.component'
import React, { RefObject, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { IdLabelItem } from 'api/models/commons'

interface IClusterEditModalProps {
  dialogRef: RefObject<DialogRef>
  cluster: CenterClustersItem | null
  onClose: () => void
  onSave: () => void
}

export const ClusterEditModal = ({
  dialogRef,
  cluster,
  onClose,
  onSave
}: IClusterEditModalProps) => {
  const { t } = useTranslation()
  const { updateCenterCluster, availableCentersCluster } = useFetcher()
  const { handleMutation, toast } = useFeedback()
  const methods = useForm<EditClusterProps>({
    resolver: zodResolver(editClusterProps)
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { isValid, isDirty } = methods.formState
  const [items, setItems] = useState<IdLabelItem[]>([])
  const handleSave = useCallback(async (data: EditClusterProps) => {
    if (!data) return
    await handleMutation({
      confirm: {
        content: t('confirm_edit_cluster')
      },
      mutation: updateCenterCluster,
      data: {
        id: data.id,
        data: data
      },
      toastSuccess: t('edit_cluster_success'),
      onSuccess: () => {
        onClose()
        onSave()
      },
      onError: (error) => {
        if (error.cause === 400) {
          toast({ message: error.message, variant: 'warning' })
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!dialogRef.current) return
    methods.reset(
      cluster
        ? {
            ...cluster,
            centers: cluster.centers.map((center) => center.id)
          }
        : {}
    )
    if (cluster) {
      setIsLoading(true)
      dialogRef.current.open()
      handleMutation({
        mutation: availableCentersCluster,
        data: cluster.id,
        onSuccess: (items) => {
          setItems(items)
          setIsLoading(false)
        }
      }).then()
    } else {
      dialogRef.current.close()
    }
  }, [cluster])

  return (
    <Dialog
      ref={dialogRef}
      title={t('update_cluster')}
      onClose={onClose}
      actions={
        <>
          <Button variant={'outlined'} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!isValid || !isDirty}
            onClick={methods.handleSubmit(handleSave)}
          >
            {t('save')}
          </Button>
        </>
      }
    >
      <FormCard
        isLoading={isLoading}
        title={''}
        control={methods.control}
        items={[
          { type: 'textfield', label: t('name'), name: 'name', required: true, xs: 12 },
          {
            type: 'multiselect',
            label: t('name'),
            name: 'centers',
            required: true,
            xs: 12,
            inputProps: {
              items: items ?? [],
              labelSlug: 'label',
              valueSlug: 'id'
            }
          }
        ]}
      />
    </Dialog>
  )
}
