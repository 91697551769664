import { Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  DashboardContract,
  DashboardMeetings,
  DashboardNextEntries,
  DashboardOccupations,
  DashboardOpenDesktops,
  DashboardOtherInformation,
  DashboardVisits
} from 'api/models/dashboard'

export const dashboardRouter = {
  getContracts: async ({ token }: Ctx, params: string) =>
    fetcher<DashboardContract>({
      url: `${BASE_URL}${API.GET_DASHBOARD_CONTRACTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOccupations: async ({ token }: Ctx, params: string) =>
    fetcher<DashboardOccupations>({
      url: `${BASE_URL}${API.GET_DASHBOARD_OCCUPATIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getVisits: async ({ token }: Ctx, params: string) =>
    fetcher<DashboardVisits>({
      url: `${BASE_URL}${API.GET_DASHBOARD_VISITS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getNexEntries: async ({ token }: Ctx, params: string) =>
    fetcher<DashboardNextEntries>({
      url: `${BASE_URL}${API.GET_DASHBOARD_NEXT_ENTRIES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getMeetings: async ({ token }: Ctx, params: string) =>
    fetcher<DashboardMeetings>({
      url: `${BASE_URL}${API.GET_DASHBOARD_MEETINGS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOpenDesktops: async ({ token }: Ctx, params: string) =>
    fetcher<DashboardOpenDesktops>({
      url: `${BASE_URL}${API.GET_DASHBOARD_OPEN_DESKTOPS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOtherInformation: async ({ token }: Ctx, params: string) =>
    fetcher<DashboardOtherInformation>({
      url: `${BASE_URL}${API.GET_DASHBOARD_OTHER_INFORMATION}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
}
