import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/system'

import { useApp } from 'app/providers/app.provider'
import { SummarySkeleton } from 'app/components/skeletons/summary.skeleton'
import { PaperGreyComponent } from 'app/components/papers/paper-grey.component'

export interface IProps {
  items: Map<string, string>
  isLoading: boolean
}

export const SummaryComponent = ({ items, isLoading }: IProps): React.JSX.Element => {
  const { t } = useTranslation()
  const { Accent } = useApp()
  const theme = useTheme()
  if (isLoading) return <SummarySkeleton />

  return (
    <PaperGreyComponent>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        {Array.from(items).map(([key, value]) => (
          <Grid key={key} item xs={6} sm={3} md={3} sx={{ padding: theme.spacing(4) }}>
            <Typography variant="subtitle2">
              <Accent>{t(key)} : </Accent> {value === 'null' || value === null ? 0 : value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </PaperGreyComponent>
  )
}
