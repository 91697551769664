import { z } from 'zod'

export const clientRequestFormSchema = z.object({
  id: z.number(),
  staff: z.number({ coerce: true }).nullable(),
  status: z.number({ coerce: true }),
  subcategory: z.number({ coerce: true }),
  internalComment: z.string().optional()
})

export type ClientRequestFormProps = z.infer<typeof clientRequestFormSchema>
