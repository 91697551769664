import styled from '@emotion/styled'
import { Edit, AccountCircle } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Typography,
  Link as ActionLink
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import type { EnterpriseInformation, Event } from 'api/models'
import {
  formatBeginWithLate,
  formatDateWithTime,
  formatEventStatus,
  formatStaffBadge
} from 'app/utils/format'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { List } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import { Link } from 'app/components/link.component'
import { TitleComponent } from 'app/components/titles/title.component'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { EditEventDialog } from 'modules/events/views/edit-event-dialog.component'
import { FormItems } from 'api/models'

const Item = styled(Stack)`
  gap: 8px;
  flex-direction: row;
  height: fit-content;
  align-items: center;
`

const ItemName = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
`

const CustomGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const LinkGrid = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  padding: 1rem;
`

const INFO_CARD_ITEMS = Array.from(
  new Map<string, keyof EnterpriseInformation>([
    ['main_center', 'centerName'],
    ['type', 'type'],
    ['workforce', 'workforce'],
    ['ca', 'ca'],
    ['address', 'address'],
    ['zipcode', 'zipCode'],
    ['city', 'city'],
    ['country', 'country'],
    ['accounting_contact', 'accountingContact'],
    ['language', 'language']
  ])
)

interface IEnterpriseDetailsProps {
  enterprise: EnterpriseInformation
  children?: React.ReactNode
  options: FormItems
}

export const EnterpriseDetails = ({ enterprise, options }: IEnterpriseDetailsProps) => {
  const { t } = useTranslation()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { getEvents } = useFetcher()
  const [events, setEvents] = useState([] as Event[])
  const { orderBy, handleSort, handleFilter } = useList()
  const dialogRef = useRef<DialogRef>(null)
  const [selectedEvent, setSelectedEvent] = useState<Event>({} as Event)

  const refreshList = useCallback(async () => {
    if (!enterprise.id) return
    setListIsLoading(true)
    const data = await getEvents.mutateAsync(String(enterprise.id))
    setListIsLoading(false)
    setEvents(data.items)
  }, [enterprise.id])

  useEffect(() => {
    refreshList().then()
  }, [enterprise.id])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent text={t('informations')} variant={'h3'} paddingTop={0} paddingLeft={12} />
          <Link to={`/enterprises/${enterprise.id}/edit`}>
            <IconButton size="small" color={'primary'}>
              <Edit fontSize="small" />
            </IconButton>
          </Link>
        </Stack>
      </Box>
      <CustomGrid>
        {INFO_CARD_ITEMS.map(([label, key]) => {
          return (
            <Item key={key}>
              <Stack>
                <Typography variant="caption" sx={{ opacity: 0.85 }}>
                  {t(label)}
                </Typography>
                <ItemName variant="body2">
                  {enterprise[key] ? String(enterprise[key]) : ''}
                </ItemName>
              </Stack>
            </Item>
          )
        })}
        <Item>
          <ItemName variant="body2">{enterprise.isKeyAccounting ? t('key_account') : ''}</ItemName>
        </Item>
        {enterprise.prescriber && (
          <Item>
            <ItemName variant="body2">
              <Link to={`/enterprises/${enterprise.id}/prescribers/${enterprise.prescriber}`}>
                {t('prescriber_sheet')}
              </Link>
            </ItemName>
          </Item>
        )}
      </CustomGrid>
      <Divider />
      <Box marginBottom={4} marginTop={4} paddingLeft={4}>
        <TitleComponent
          text={t('accounting_documents')}
          variant={'h3'}
          paddingTop={0}
          paddingLeft={12}
        />
      </Box>
      <LinkGrid>
        <Link to={`/invoices?is_mandate=0&client=${enterprise.id}&status=%5B%5D&offset=0`}>
          {t('invoices')}
        </Link>
        <Link to={`/credits?client=${enterprise.id}&status=%5B%5D&types=%5B%5D&offset=0`}>
          {t('credits')}
        </Link>
        <Link
          to={`/payments?client=${enterprise.id}&paymentMode=-1&paymentType=-1&paymentLink=0&offset=0`}
        >
          {t('payments')}
        </Link>
      </LinkGrid>
      <Divider />
      <Box marginBottom={4} marginTop={4} paddingLeft={4}>
        <TitleComponent text={t('events')} variant={'h3'} paddingTop={0} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            items={events}
            selectable={false}
            columns={[
              {
                slug: 'statusId',
                label: '',
                text: 'statusLabel',
                valueFormatter: formatEventStatus
              },
              {
                slug: 'typeLabel',
                label: t('type'),
                condition: (item: Event) => (
                  <ActionLink
                    onClick={() => {
                      setSelectedEvent(item)
                      dialogRef.current?.open()
                    }}
                  >
                    {item.typeLabel}
                  </ActionLink>
                )
              },
              { slug: 'centerName', label: t('center') },
              {
                slug: 'late',
                label: t('begin_at'),
                condition: (row: Event) =>
                  formatBeginWithLate(row.late, row.beginLabel, row.statusId)
              },
              {
                slug: 'endLabel',
                label: t('end_at'),
                valueFormatter: formatDateWithTime
              },
              {
                slug: 'staffInitial',
                label: AccountCircle,
                valueFormatter: formatStaffBadge,
                tooltip: t('staff')
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={listIsLoading}
          />
        </Grid>
      </Grid>
      <EditEventDialog
        options={options}
        dialogRef={dialogRef}
        selectedEvent={selectedEvent}
        onSuccess={() => {
          setSelectedEvent({} as Event)
          refreshList().then()
          dialogRef.current?.close()
        }}
      />
    </MuiPaper>
  )
}
