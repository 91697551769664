import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { MainService } from 'api/models'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { KeyboardBackspace, Edit } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { ParkingServiceDetails } from 'modules/services/components/parking-service-details.component'
import { useFeedback } from 'app/providers/feedback.provider'

export const ParkingServiceView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getParkingService } = useFetcher()
  const { id } = useParams()
  const [mainService, setMainService] = useState<MainService>({} as MainService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const refreshList = useCallback(async () => {
    if (!id) return
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getParkingService,
      data: Number(id),
      onSuccess: (mainService) => setMainService(mainService),
      onEnd: () => setListIsLoading(false)
    })
  }, [id, getParkingService])

  useEffect(() => {
    refreshList().then()
  }, [id])

  return listIsLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/services/parking`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('parking')}</Typography>
            <Link to={`/services/parking/${id}/edit`}>
              <Edit fontSize={'small'} />
            </Link>
          </Stack>
        </Grid>
      </Grid>
      <ParkingServiceDetails mainService={mainService} />
    </Container>
  )
}
