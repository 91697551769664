import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { Button, FormControl } from '@mui/material'
import React, { RefObject, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useFetcher } from 'app/providers/fetcher.provider'
import dayjs, { Dayjs } from 'dayjs'
import { Contract } from 'api/models'
import { useNavigate } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'

interface IDialogCancelContract {
  dialogRef: RefObject<DialogRef>
  contract: Contract
}

export const DialogCancelContract = function ({ dialogRef, contract }: IDialogCancelContract) {
  const { t } = useTranslation()
  const { cancelContract } = useFetcher()
  const [end, setEnd] = useState<Dayjs>(dayjs())
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()

  const handleSave = useCallback(async () => {
    if (!contract) return
    await handleMutation({
      confirm: {
        content: t('confirm_cancel_contract')
      },
      mutation: cancelContract,
      data: {
        id: contract.id,
        data: {
          end: end.format('YYYY-MM-DD'),
          state: contract.state
        }
      },
      onEnd: () => {
        dialogRef.current?.close()
        navigate(0)
      }
    })
  }, [contract, end])

  if (!dialogRef) return

  return (
    <Dialog
      title={t('terminate_contract')}
      ref={dialogRef}
      actions={
        <>
          <Button onClick={dialogRef.current?.close}>{t('cancel')}</Button>
          <Button onClick={handleSave} variant={'contained'}>
            {t('confirm')}
          </Button>
        </>
      }
    >
      <FormControl fullWidth sx={{ marginTop: 7 }}>
        <DatePicker label={t('end')} value={end} onChange={(date) => setEnd(date ?? dayjs())} />
      </FormControl>
    </Dialog>
  )
}
