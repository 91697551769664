import {
  Checkbox,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps
} from '@mui/material'
import { FormItem } from 'api/models'
import { Control, Controller } from 'react-hook-form'

type IControlledTextFieldProps = {
  formItem: FormItem | any
  label?: string
  name: string
  control: Control<any>
  required?: boolean
} & SelectProps

export function ControlledSelectCheckboxField({
  name,
  label,
  control,
  required,
  formItem,
  ...otherProps
}: IControlledTextFieldProps) {
  return (
    <Controller
      render={({ field: { name, ref, value, onChange }, fieldState: { error } }) => {
        return (
          <FormControl fullWidth required={required}>
            <InputLabel size="small" id={`${name}_label`}>
              {label}
            </InputLabel>
            <Select
              labelId={label ? `${name}_label` : ''}
              size={'small'}
              fullWidth
              value={value}
              inputRef={ref}
              multiple
              onChange={onChange}
              label={label || ''}
              error={!!error?.message}
              {...otherProps}
              sx={{ maxHeight: '35px' }}
            >
              {formItem?.values
                ? Array.isArray(formItem?.values)
                  ? formItem.values.map((v: any) => {
                      return (
                        <MenuItem key={v.id} value={Number(v.id)}>
                          <Checkbox size={'small'} checked={value.includes(Number(v.id))} />
                          {v.label}
                        </MenuItem>
                      )
                    })
                  : formItem.values &&
                    Object.keys(formItem?.values).map((category) => {
                      return [
                        <ListSubheader key={category}>{category}</ListSubheader>,
                        formItem.values[category].map((v: any) => {
                          return (
                            <MenuItem key={v.id} value={Number(v.id)}>
                              <Checkbox size={'small'} checked={value.includes(Number(v.id))} />
                            </MenuItem>
                          )
                        })
                      ]
                    })
                : null}
            </Select>
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
