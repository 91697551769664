import { useCallback, useEffect, useState } from 'react'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { StyledChip } from 'app/components/chips/multi-select-chip'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useDebouce } from 'app/hooks/use-debounce'
import styled from '@emotion/styled'
import { useFeedback } from 'app/providers/feedback.provider'
import type { Center, StaffCenters } from 'api/models'

export type CentersSelectInputProps = {
  slug: string
  onChange?: (item: CenterWithIsMainAttribute[]) => void
  initialValue?: StaffCenters
  disableClearable?: boolean
  required?: boolean
}

export type CenterWithIsMainAttribute = Center & {
  isMain: boolean
}

const getSortedValues = (values: CenterWithIsMainAttribute[]) =>
  values.sort((a: CenterWithIsMainAttribute) => (a.isMain ? -1 : 1))

const GroupItems = styled('ul')({
  padding: 0
})

export function MultipleCentersSelect({
  slug,
  onChange,
  initialValue,
  disableClearable = true,
  required
}: CentersSelectInputProps) {
  const { getCentersSelect } = useFetcher()
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const [selectedCenters, setSelectedCenters] = useState<CenterWithIsMainAttribute[]>([])
  const [centers, setCenters] = useState<CenterWithIsMainAttribute[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const selectDefaultValue = useCallback(
    (defaultValues: StaffCenters, centers: CenterWithIsMainAttribute[]) => {
      const selectedValues = defaultValues
        .map((defaultValue) => {
          const center = centers.find((obj) => obj.id === String(defaultValue.center.id))
          if (center) {
            center.isMain = defaultValue.isMain
            return center
          }
          return null
        })
        .filter(Boolean) as CenterWithIsMainAttribute[]

      if (selectedValues.length > 0) {
        setSelectedCenters(getSortedValues(selectedValues))
      }
    },
    []
  )

  useDebouce(
    async () => {
      await handleMutation({
        onStart: () => setLoading(true),
        mutation: getCentersSelect,
        data: {
          name: '',
          is_cluster: false,
          is_center: true,
          all_centers: false,
          bypass_all_centers: false
        },
        onSuccess: (data) => {
          const _centers = data.flatMap((obj: any) => obj.children ?? [])
          setCenters(_centers)
          if (initialValue) selectDefaultValue(initialValue, _centers)
        },
        onEnd: () => setLoading(false)
      })
    },
    [initialValue],
    150
  )

  const handleOnChange = useCallback(
    (event: any, newValue: CenterWithIsMainAttribute[]) => {
      const sortedValues = getSortedValues(newValue)
      setSelectedCenters(sortedValues)
      onChange?.(sortedValues)
    },
    [onChange]
  )

  useEffect(() => {
    if (initialValue && centers.length > 0) selectDefaultValue(initialValue, centers)
  }, [initialValue, centers, selectDefaultValue])

  return (
    <Stack>
      <Autocomplete
        multiple
        size="small"
        id={slug ?? 'center-select'}
        disableClearable={disableClearable}
        onChange={handleOnChange}
        value={selectedCenters}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        loadingText={t('loading')}
        noOptionsText={t('no_options')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => centers.find((center) => center.id === option.id)?.text ?? ''}
        options={centers.filter((center) => !center.isMain)}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <StyledChip label={option.text} {...getTagProps({ index })} disabled={option.isMain} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={13} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            required={required}
            label={slug}
          />
        )}
      />
    </Stack>
  )
}
