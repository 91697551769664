import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { CreateUpdateStaffForm, createUpdateStaffSchema, FormItems } from 'api/models'
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { useApp } from 'app/providers/app.provider'
import { FormInputProps } from 'app/components/form/controlled-form.component'
import { RolesEnum } from 'app/constants/roles'

export const StaffAddEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const { getFormItems, createStaff, updateStaff, getStaff, createOrUpdateStaffPhoneSystems } =
    useFetcher()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [formItems, setFormItems] = useState({} as FormItems)
  const { confirm, handleMutation } = useFeedback()
  const { user, isRole } = useApp()

  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['languages', 'language'],
      ['countries', 'country'],
      ['staff_roles', 'role'],
      ['status', 'status'],
      ['staff_phone_systems', 'phone_system'],
      ['centers', 'center']
    ])
  )
  const methods = useForm<CreateUpdateStaffForm>({
    mode: 'onChange',
    resolver: zodResolver(createUpdateStaffSchema)
  })
  const { replace } = useFieldArray({
    control: methods.control,
    name: 'phoneSystemsStaff'
  })
  const formId = methods.watch('id')
  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setFormItems(optionsData as FormItems)
    })
  }, [])

  const handlePhoneSystems = useCallback(
    async (data: CreateUpdateStaffForm) => {
      if (!methods.formState.dirtyFields.phoneSystemsStaff) return navigate('/staffs')
      await handleMutation({
        mutation: createOrUpdateStaffPhoneSystems,
        data: data,
        onSuccess: () => {
          navigate('/staffs')
        }
      })
    },
    [createOrUpdateStaffPhoneSystems, methods.formState.dirtyFields, navigate]
  )

  const handleSubmit = useCallback(
    (data: CreateUpdateStaffForm) => async () => {
      if (data.id) {
        await handleMutation({
          mutation: updateStaff,
          data: data,
          onSuccess: async () => await handlePhoneSystems(data)
        })
      } else {
        await handleMutation({
          mutation: createStaff,
          data: data,
          onSuccess: async ({ id }) => await handlePhoneSystems({ ...data, id: Number(id) })
        })
      }
    },
    [createStaff, updateStaff, handlePhoneSystems]
  )

  const handleConfirm = useCallback(
    (data: CreateUpdateStaffForm) => {
      confirm({
        content: formId ? t('confirm_edit_staff') : t('confirm_add_staff'),
        onConfirm: handleSubmit(data)
      })
    },
    [t, formId]
  )

  const updateStaffForm = useCallback(async () => {
    if (id) {
      await fetchStaff(Number(id))
    } else if (formItems.staff_phone_systems) {
      methods.setValue(
        'phoneSystemsStaff',
        formItems.staff_phone_systems.values
          ? formItems.staff_phone_systems.values.map((item) => {
              return {
                phoneSystem: Number(item.id),
                reference: undefined
              }
            })
          : []
      )
    }
  }, [id, formItems])

  useEffect(() => {
    if (user?.mainCenter && user?.languages) {
      methods.setValue('language', user?.languages[0].id)
    }
    setIsLoading(true)
    initOptions(commonOptions).then(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    if (formItems && formItems.staff_phone_systems) {
      updateStaffForm().then(() => setIsLoading(false))
    }
  }, [formItems])

  const fetchStaff = useCallback(
    async (id: number) => {
      await handleMutation({
        mutation: getStaff,
        data: id,
        onStart: () => setIsLoading(true),
        onError: () => navigate('/staffs'),
        onSuccess: (staff) => {
          methods.reset({
            id: id,
            firstname: staff.firstname,
            lastname: staff.lastname,
            phone: staff.phone ?? undefined,
            staffCenters: staff.staffCenters.map((center) => ({
              center: { id: center.center.id },
              isMain: center.isMain
            })),
            job: staff.job,
            language: Number(staff.language?.id) ?? 1,
            staffAccount: {
              email: staff.staffAccount?.email,
              role: staff.staffAccount?.role.id,
              isActive: staff.staffAccount?.isActive ?? false
            },
            centerCluster: staff.centerCluster?.id,
            phoneSystemsStaff: formItems.staff_phone_systems
              ? formItems.staff_phone_systems.values.map((item) => {
                  const staffPhone = staff.phoneSystemsStaff.find(
                    (i) => Number(i.phoneSystem.id) === Number(item.id)
                  )
                  return {
                    phoneSystem: Number(item.id),
                    reference: staffPhone?.reference ?? undefined
                  }
                })
              : []
          })
          setIsLoading(false)
        }
      })
    },
    [id, formItems, replace, methods]
  )

  const formInputs = useMemo(() => {
    let items: FormInputProps[] = [
      { type: 'textfield', label: t('firstname'), name: 'firstname', required: true },
      { type: 'textfield', label: t('lastname'), name: 'lastname', required: true },
      {
        type: 'textfield',
        inputProps: { type: 'password' },
        label: t('password'),
        name: 'staffAccount.clearPassword'
      },
      {
        type: 'textfield',
        label: t('email'),
        name: 'staffAccount.email',
        required: true
      },
      { type: 'textfield', label: t('phone'), name: 'phone' },
      {
        type: 'select',
        label: t('role'),
        name: 'staffAccount.role',
        required: true,
        formItem: formItems.staff_roles,
        inputProps: {
          disabled: !isRole(RolesEnum.ADMIN) && methods.getValues().staffAccount?.role === 1
        }
      },
      { type: 'textfield', label: t('job'), name: 'job' },
      {
        type: 'staffCenter',
        label: t('main_center'),
        name: 'staffCenters',
        required: true,
        inputProps: { selectMainCenter: true }
      },
      {
        type: 'centers',
        label: t('cluster'),
        name: 'centerCluster',
        inputProps: {
          defaultIsCenter: false,
          defaultIsCluster: true,
          initialValueIsCluster: true,
          slug: 'cluster'
        }
      },
      {
        type: 'staffCenter',
        label: t('access_centers_staff'),
        name: 'staffCenters',
        required: true
      },
      {
        type: 'select',
        label: t('language'),
        name: 'language',
        formItem: formItems.languages,
        required: true
      },
      {
        type: 'checkbox',
        label: t('active'),
        name: 'staffAccount.isActive'
      }
    ]

    return items
  }, [formItems, formId])

  const canEditAdmin = useCallback(() => {
    return isRole(RolesEnum.ADMIN) || Number(methods.getValues().staffAccount.role) !== 1
  }, [isRole, methods.watch('staffAccount')])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('add_edit_staff')}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading}
              title={t('staff')}
              control={methods.control}
              items={formInputs}
            />
          </Grid>
          {formItems.staff_phone_systems && (
            <Grid item xs={12} md={6}>
              <FormCard
                title={t('phone_systems')}
                items={formItems.staff_phone_systems.values.map(
                  (item, index) =>
                    ({
                      type: 'textfield',
                      name: `phoneSystemsStaff.${index}.reference`,
                      label: item.label
                    } as FormInputProps)
                )}
                control={methods.control}
                isLoading={isLoading}
              />
            </Grid>
          )}
        </Grid>
        <Stack sx={{ marginTop: 4 }} justifyContent={'center'} flexDirection={'row'} gap={4}>
          <Button variant={'outlined'} color={'primary'} onClick={() => navigate('/staffs')}>
            {t('cancel')}
          </Button>
          <Button
            disabled={
              methods.formState.isSubmitting || !methods.formState.isValid || !canEditAdmin()
            }
            variant={'contained'}
            color={'primary'}
            onClick={methods.control.handleSubmit(handleConfirm)}
          >
            {t('save')}
          </Button>
        </Stack>
      </FormProvider>
    </Container>
  )
}
