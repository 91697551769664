import { Ctx, fetcher, formatParams } from 'api/index'
import { API, BASE_URL } from 'api/constants'
import {
  DiscountDetails,
  DiscountsList,
  DiscountExist,
  DiscountAddBody
} from 'api/models/discounts'
import urlHelper from 'app/helpers/url.helper'

export const discountRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<DiscountsList>({
      url: `${BASE_URL}${API.DISCOUNTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  get: async (id: number) =>
    fetcher<DiscountDetails>({
      url: `${BASE_URL}${urlHelper(API.DISCOUNT, { id })}`,
      method: 'GET',
      token: token
    }),
  exist: async (params: string) =>
    fetcher<DiscountExist>({
      url: `${BASE_URL}${API.DISCOUNTS_EXIST}?label=${params}`,
      method: 'GET',
      token: token
    }),
  create: async (data: DiscountAddBody) =>
    fetcher<void>({
      url: `${BASE_URL}${API.DISCOUNTS}`,
      method: 'POST',
      token: token,
      body: data
    })
})
