import { z } from 'zod'

export const tertiarySchema = z.object({
  year: z.number(),
  trimester: z.string(),
  value: z.number({ coerce: true })
})

export const createTertiarySchema = z.object({
  year: z.number(),
  trimester: z.string(),
  value: z.number(),
  id: z.number(),
  createdAt: z.string(),
  updatedAt: z.string().nullable()
})

export type TertiariesList = z.arrayOutputType<typeof tertiarySchema>
export type TertiaryBody = z.infer<typeof tertiarySchema>
export type TertiaryCreate = z.infer<typeof createTertiarySchema>
