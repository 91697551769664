import { Card, CardContent } from '@mui/material'

import { TitleComponent } from 'app/components/titles/title.component'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { InvoiceInformation } from 'api/models'
import { formatCurrency, formatDate } from 'app/utils/format'
interface ILitigationContracts {
  isLoading: boolean
  invoices: InvoiceInformation[]
}

export const LitigationInvoices = ({ isLoading, invoices }: ILitigationContracts) => {
  const { t } = useTranslation()

  const map = useMemo(() => {
    const _map = new Map<any, any>()
    _map.set('items', [
      {
        label: t('reference'),
        value: 'reference',
        link: '/invoices/:id'
      },
      {
        label: t('type'),
        value: 'type'
      },
      {
        label: t('due_date'),
        value: 'dueDate',
        format: formatDate
      },
      {
        label: t('amount_ttc'),
        value: 'totalCost',
        format: formatCurrency
      }
    ])
    _map.set('data', invoices ?? [])
    return _map
  }, [invoices])

  return (
    <Card>
      <CardContent>
        <TitleComponent text={t('invoices')} variant={'h3'} paddingLeft={12} />
        <ListSheetComponent isLoading={isLoading} data={map} />
      </CardContent>
    </Card>
  )
}
