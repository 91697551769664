import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { FormItem, FormItems } from 'api/models'
import { Button, Grid, Typography, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { CreateEnterpriseForm, createEnterpriseFormSchema } from 'api/models/forms/enterprises'

export const EntepriseAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getFormItems, createEnterprise } = useFetcher()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [formItems, setFormItems] = useState({} as FormItems)
  const { handleMutation } = useFeedback()
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['languages', 'language'],
      ['countries', 'country'],
      ['status', 'status'],
      ['centers', 'center']
    ])
  )

  const methods = useForm<CreateEnterpriseForm>({
    defaultValues: {
      welcomeEmail: 'on',
      mobile: '',
      phone: ''
    },
    mode: 'onChange',
    resolver: zodResolver(createEnterpriseFormSchema)
  })

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setFormItems(optionsData as FormItems)
      setIsLoading(false)
    })
  }, [])

  const handleSubmit = async (data: CreateEnterpriseForm) => {
    await handleMutation({
      confirm: {
        content: t('confirm_create_enterprise')
      },
      onStart: () => setIsLoading(true),
      data: data,
      mutation: createEnterprise,
      onSuccess: (response) => navigate(`/enterprises/${response.id}`),
      toastSuccess: t('create_enterprise_success'),
      toastError: t('create_enterprise_error'),
      onEnd: () => setIsLoading(false)
    })
  }

  useEffect(() => {
    initOptions(commonOptions).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('add_enterprise')}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading}
              title={t('enterprise')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('name'), name: 'name', required: true },
                {
                  type: 'select',
                  label: t('center'),
                  name: 'center',
                  formItem: formItems.centers as FormItem,
                  required: true
                },
                {
                  type: 'number',
                  label: t('workforce'),
                  name: 'workforce'
                },
                { type: 'textfield', label: t('address'), name: 'address' },
                { type: 'textfield', label: t('zipcode'), name: 'zipcode' },
                { type: 'textfield', label: t('city'), name: 'city' },
                {
                  type: 'select',
                  label: t('language'),
                  name: 'languageEnterprise',
                  formItem: formItems.languages as FormItem,
                  required: true
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading}
              title={t('contact')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('firstname'), name: 'firstname', required: true },
                { type: 'textfield', label: t('lastname'), name: 'lastname', required: true },
                { type: 'textfield', label: t('email'), name: 'email', required: true },
                { type: 'textfield', label: t('phone'), name: 'phone' },
                { type: 'textfield', label: t('mobile'), name: 'mobile', required: true },
                {
                  type: 'select',
                  label: t('language'),
                  name: 'languageIndividual',
                  formItem: formItems.languages as FormItem,
                  required: true
                },
                {
                  type: 'checkbox',
                  label: t('send_welcome_email'),
                  name: 'welcomeEmail',
                  inputProps: { values: { true: 'on', false: 'off' } }
                }
              ]}
            />
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
          <Grid item xs={12} textAlign={'center'}>
            <Button
              disabled={methods.formState.isSubmitting || !methods.formState.isValid}
              variant={'contained'}
              color={'primary'}
              onClick={methods.control.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  )
}
