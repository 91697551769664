import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { formatCurrency } from 'app/utils/format'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import {
  CreditRowModalComponent,
  RowsDataInterface
} from 'modules/invoices/components/credit_row_modal.component'
import { useParams } from 'react-router-dom'

interface IProps {
  item: any
  disabledSubmitButton: (disabled: boolean) => void
}

export const CreditModalContentComponent = ({
  item,
  disabledSubmitButton
}: IProps): React.JSX.Element => {
  const { t } = useTranslation()
  const { getRowsInvoice } = useFetcher()

  const [priceGlobalTTC, setPriceGlobalTTC] = useState<number>(0)
  const rowsDefault = useRef<RowsDataInterface[][]>([])
  const [rowsData, setRowsData] = useState<RowsDataInterface[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [onCheckApply, setOnCheckApply] = useState<boolean>(false)
  const { id } = useParams()

  const getInvoiceRows = useCallback(async () => {
    const response = await getRowsInvoice.mutateAsync(Number(id))
    const _rowsData: RowsDataInterface[] = []
    const rows = response.rows
    setIsLoading(false)
    if (rows.length === 0) return
    rows.map((row: any) => {
      const _calculatePriceHT =
        Math.round((Number(row.priceTTC) / (1 + Number(row.vatRate) / 100)) * 100) / 100
      const _vat = Math.round((Number(row.priceTTC) - _calculatePriceHT) * 100) / 100
      const _priceRate = Math.round((_calculatePriceHT / Number(row.price)) * 100 * 100) / 100
      _rowsData.push({
        ...row,
        priceRateMax: _priceRate,
        priceRate: _priceRate,
        priceHT: _calculatePriceHT,
        vat: _vat,
        priceTTC: Number(row.priceTTC),
        priceTTCMax: Number(row.priceTTC)
      })
    })
    setRowsData(_rowsData)
    rowsDefault.current = [...rowsDefault.current, JSON.parse(JSON.stringify(_rowsData))]
  }, [id])

  useEffect(() => {
    getInvoiceRows().then()
  }, [])

  useEffect(() => {
    let priceTTC = 0
    rowsData.map((row: any) => {
      priceTTC += Number(row.priceTTC)
    })
    setPriceGlobalTTC(priceTTC)
  }, [rowsData])

  const setData = useCallback(
    (index: number, priceHT: number, TVA: number, priceTTC: number, amountRate: number) => {
      setRowsData((prevData) => {
        const newData = [...prevData]
        newData[index].priceHT = String(priceHT)
        newData[index].vat = String(TVA)
        newData[index].priceTTC = String(priceTTC)
        newData[index].priceRate = String(amountRate)
        return newData
      })
    },
    []
  )

  const calculVat = useCallback((row: any): number => {
    const priceTTC = Number(row.priceTTC)
    const vatRate = Number(row.vatRate)
    let priceHT = Math.round((priceTTC / (1 + vatRate / 100)) * 100) / 100
    return Math.round((priceTTC - priceHT) * 100) / 100
  }, [])

  useEffect(() => {
    const _isDisabled =
      priceGlobalTTC <= 0 ||
      (item.invoice.dueAmount < priceGlobalTTC && item.invoice.dueAmount > 0 && onCheckApply)
    disabledSubmitButton(_isDisabled)
    if (_isDisabled) setOnCheckApply(false)
  }, [priceGlobalTTC, item, onCheckApply])

  const calculVatTotal = useCallback(() => {
    let vatTotal = 0
    rowsData.map((row: any) => {
      vatTotal += calculVat(row)
    })
    return formatCurrency(vatTotal)
  }, [rowsData])

  return (
    <Box maxHeight={'50vh'} overflow={'auto'}>
      {isLoading && <CardSkeleton />}
      {!isLoading && rowsData.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell>{t('services')}</TableCell>
                  <TableCell align="right">{t('period')}</TableCell>
                  <TableCell align="right">
                    {t('price')}€<sup>HT</sup>
                  </TableCell>
                  <TableCell align="right">%</TableCell>
                  <TableCell align="right">
                    {t('amount')}€<sup>HT</sup>
                  </TableCell>
                  <TableCell align="right">{t('vat')}€</TableCell>
                  <TableCell align="right">
                    {t('amount')}€<sup>TTC</sup>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsData.map((row, index) => (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={row.id}>
                    <CreditRowModalComponent
                      row={row}
                      index={index}
                      rowsDefault={rowsDefault}
                      setData={setData}
                    />
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell rowSpan={2} colSpan={5} />
                  <TableCell colSpan={2}>{t('total_vat')}</TableCell>
                  <TableCell align="right">{calculVatTotal()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>{t('total_amount_ttc')}</TableCell>
                  <TableCell align="right">{formatCurrency(priceGlobalTTC)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {item.canApplyCredit && (
            <FormControlLabel
              name={'apply'}
              control={
                <Checkbox
                  disabled={priceGlobalTTC <= 0 || item.invoice.dueAmount < priceGlobalTTC}
                  onChange={(e) => {
                    setOnCheckApply(e.target.checked)
                  }}
                  checked={onCheckApply}
                />
              }
              label={t('apply_on_invoice')}
            />
          )}
        </>
      )}
      {!isLoading && rowsData.length == 0 && (
        <Typography>{t('services_already_credit')}</Typography>
      )}
    </Box>
  )
}
