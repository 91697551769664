import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import React, { RefObject, useCallback } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Contract, ContractService } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

interface IDialogStopOption {
  contract: Contract | undefined
  service: ContractService
  dialogRef: RefObject<DialogRef>
}

export const DialogStopOption = function ({ contract, service, dialogRef }: IDialogStopOption) {
  const { stopContractService } = useFetcher()
  const { handleMutation } = useFeedback()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const methods = useForm<{ end: Dayjs }>({
    defaultValues: {
      end: dayjs()
    }
  })

  const handleStopSubmit = useCallback(
    async (data: { end: Dayjs }) => {
      if (!contract || !service.id || !data.end) return
      await handleMutation({
        confirm: {
          content: t('confirm_close_option')
        },
        mutation: stopContractService,
        data: {
          id: contract.id,
          serviceId: service.id,
          end: data.end.format('YYYY-MM-DD')
        },
        toastSuccess: t('update_success'),
        toastError: t('error'),
        onSuccess: () => navigate(0)
      })
    },
    [contract, service]
  )

  return (
    <Dialog
      ref={dialogRef}
      title={`${t('stop_option')} ${service.label}`}
      actions={
        <>
          <Button variant={'outlined'} onClick={dialogRef.current?.close}>
            {t('cancel')}
          </Button>
          <Button variant={'contained'} onClick={methods.handleSubmit(handleStopSubmit)}>
            {t('save')}
          </Button>
        </>
      }
    >
      <Box marginTop={4}>
        <ControlledDatePicker name={'end'} label={t('end')} control={methods.control} />
      </Box>
    </Dialog>
  )
}
