import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Button, Divider, Grid, Paper as MuiPaper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { formatDateWithTime } from 'app/utils/format'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { ClientRequestInformation, FormItems } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ControlledSelectStaffField } from 'app/components/form/controlled-select-staff.component'
import { useForm } from 'react-hook-form'
import { ControlledSelectField } from 'app/components/form/controlled-select.component'
import { ClientRequestFormProps, clientRequestFormSchema } from 'api/models/forms/client_requests'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'

const Item = styled(Stack)`
  gap: 8px;
  flex-direction: row;
  height: fit-content;
  align-items: center;
`

const ItemName = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
`

const CustomGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(1, 1fr);
  }
`

type CustomGridItemProps = {
  label: string
  value?: string
  link?: string
  custom?: any
}

interface IClientRequestDetailsProps {
  clientRequest: ClientRequestInformation
  options: FormItems
  children?: React.ReactNode
}

export const ClientRequestDetails = ({ clientRequest, options }: IClientRequestDetailsProps) => {
  const { t } = useTranslation()
  const { toast, handleMutation } = useFeedback()
  const navigate = useNavigate()
  const { updateClientRequest } = useFetcher()
  const methods = useForm<ClientRequestFormProps>({
    defaultValues: {
      id: clientRequest.id,
      internalComment: clientRequest.internalComment ?? '',
      subcategory: clientRequest.subcategoryId,
      status: clientRequest.statusId,
      staff: clientRequest.staffId
    },
    resolver: zodResolver(clientRequestFormSchema)
  })

  const customGridItem = useCallback(
    ({ label, value, link, custom }: CustomGridItemProps) => {
      return (
        <Item key={label}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant="caption" sx={{ opacity: 0.85 }}>
              {t(label)}
            </Typography>
            {custom ? (
              custom
            ) : (
              <ItemName variant="body2">{link ? <Link to={link}>{value}</Link> : value}</ItemName>
            )}
          </Stack>
        </Item>
      )
    },
    [t]
  )

  const addComment = useCallback(async () => {
    try {
      await methods.control.handleSubmit(handleSubmit)()
      toast({
        message: t('internal_comment_update_success'),
        variant: 'success'
      })
    } catch {
      toast({
        message: t('internal_comment_update_error'),
        variant: 'error'
      })
    }
  }, [methods.watch, methods.control.handleSubmit, t])

  const handleSubmit = useCallback(
    async (data: any) =>
      await handleMutation({
        confirm: {
          content: t('confirm_update_client_request')
        },
        mutation: updateClientRequest,
        data: data,
        toastSuccess: t('update_success'),
        onEnd: () => navigate(0)
      }),
    [updateClientRequest]
  )

  const submitOnChange = useCallback(
    async (_: any, which: any) => {
      if (which.name === 'internalComment') return
      await methods.control.handleSubmit(handleSubmit)()
    },
    [methods.control.handleSubmit, handleSubmit]
  )

  useEffect(() => {
    const subscription = methods.watch(submitOnChange)
    return () => subscription.unsubscribe()
  }, [methods.watch])

  const items = useMemo(
    () =>
      [
        {
          label: 'reference',
          value: clientRequest.id
        },
        {
          label: 'enterprise',
          value: clientRequest.enterpriseName,
          link: `/enterprises/${clientRequest.enterpriseId}`
        },
        {
          label: 'individual',
          value: clientRequest.individualName,
          link: `/individuals/${clientRequest.individualId}`
        },
        {
          label: 'center',
          value: clientRequest.centerName
        },
        {
          label: 'category',
          value: clientRequest.categoryLabel
        },
        {
          label: 'subcategory',
          custom: (
            <ControlledSelectField
              name={'subcategory'}
              label={''}
              control={methods.control}
              formItem={options.clientrequest_subcategory}
              disabled={clientRequest.statusId === 3 || clientRequest.statusId == 4} //Treated or cancelled
            />
          )
        },
        {
          label: 'created_at',
          value: formatDateWithTime(clientRequest.createdAt)
        },
        {
          label: 'end',
          value: formatDateWithTime(clientRequest.end)
        },
        {
          label: 'status',
          custom: (
            <ControlledSelectField
              name={'status'}
              label={''}
              control={methods.control}
              formItem={options.clientrequest_status}
              disabled={clientRequest.staffId == null}
            />
          )
        },
        {
          label: 'attributed_to',
          custom: (
            <ControlledSelectStaffField
              name={'staff'}
              label={''}
              control={methods.control}
              defaultValue={clientRequest.staffName}
              disabled={clientRequest.statusId === 3 || clientRequest.statusId == 4} //Treated or cancelled
            />
          )
        }
      ] as CustomGridItemProps[],
    [clientRequest]
  )

  return (
    <>
      <MuiPaper sx={{ marginTop: '1rem' }}>
        <CustomGrid>{items.map(customGridItem)}</CustomGrid>
        <Divider />
        <Typography padding={'1rem'}>{clientRequest.comment}</Typography>
      </MuiPaper>
      <MuiPaper sx={{ marginTop: '1rem' }}>
        <Grid container padding={'1rem'} gap={4}>
          <Grid item xs={12}>
            <ControlledTextField
              control={methods.control}
              name={'internalComment'}
              rows={4}
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant={'contained'} onClick={addComment}>
              {t('add_comment')}
            </Button>
          </Grid>
        </Grid>
      </MuiPaper>
    </>
  )
}
