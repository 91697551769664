import { ApexOptions } from 'apexcharts'
import { formatCurrency } from 'app/utils/format'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import getLocales from 'app/utils/apexlocales.util'
import i18n from 'i18next'
import { Card, CardContent, useTheme, Box } from '@mui/material'
import localeData from 'dayjs/plugin/localeData'
import dayjs from 'dayjs'
import { ThemedChart } from 'app/components/charts/timeline.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { StatsCA } from 'api/models/stats'
dayjs.extend(localeData)

export const CaVsBpChartComponent = function ({
  height,
  params
}: {
  height: number
  params: URLSearchParams
}) {
  const [locales, setLocales] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const theme = useTheme()
  const [formatedRows, setFormatedRows] = useState<any>([])
  const { handleMutation } = useFeedback()
  const { getStatsCA } = useFetcher()

  const labels = useMemo(() => {
    let startDate = dayjs().startOf('month')
    const dateBegin = params.get('date_begin')
    if (dateBegin !== null) {
      startDate = dayjs(dateBegin).startOf('month')
    }
    const dates = []
    for (let i = 0; i < 12; i++) {
      const formattedDate = startDate.add(i, 'month').format('MM/DD/YYYY')
      dates.push(formattedDate)
    }
    return dates
  }, [params])

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getStatsCA,
      onSuccess: (data: StatsCA) => {
        if (!data) return

        const formated: any = Object.entries(data)
          .filter(([name]) => name !== 'Total')
          .map(([name, values]) => ({
            name: name,
            data: values.map((value) => value),
            type: 'bar'
          }))
        setFormatedRows(formated)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [getStatsCA, handleMutation])

  useEffect(() => {
    refreshList().then()
  }, [params])

  useEffect(() => {
    getLocales().then((locales: any) => setLocales(locales))
  }, [i18n.language])

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        toolbar: {
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        },
        stacked: true,
        locales: [locales],
        defaultLocale: i18n.language
      },
      fill: {
        opacity: 1
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      tooltip: {
        x: {
          formatter: function (val) {
            return dayjs(val).format('MMMM YYYY')
          }
        },
        y: {
          title: {
            formatter: function (val) {
              return val + ' :'
            }
          },
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return formatCurrency(y)
            }
            return y
          }
        }
      },
      xaxis: {
        type: 'datetime',
        categories: labels,
        labels: {
          format: 'MM.yyyy'
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            return formatCurrency(val)
          }
        }
      },
      legend: {
        position: 'top'
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      theme: {
        palette: 'palette5',
        mode: theme.palette.mode
      }
    }
    return data
  }, [labels, theme.palette.mode, locales])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isLoading ? (
          <CardSkeleton height={height} />
        ) : (
          <Box className="line">
            <ThemedChart options={options} series={formatedRows} type="bar" height={height} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
