import { z } from 'zod'

export const eventSchema = z.object({
  beginLabel: z.string(),
  centerId: z.number(),
  centerName: z.string(),
  comment: z.string(),
  createdAt: z.string(),
  endLabel: z.string(),
  enterpriseId: z.number(),
  enterpriseName: z.string(),
  groundRefusalId: z.number(),
  groundRefusalLabel: z.string(),
  id: z.number(),
  late: z.string(),
  opportunityId: z.number(),
  opportunityReference: z.string(),
  ownerId: z.number(),
  ownerName: z.string(),
  staffId: z.number(),
  staffInitial: z.string(),
  staffName: z.string(),
  statusId: z.string(),
  statusLabel: z.string(),
  typeId: z.number(),
  typeLabel: z.string(),
  brokerName: z.string(),
  brokerId: z.string()
})

export type Event = z.infer<typeof eventSchema>

const eventsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(eventSchema)
})

export type EventsList = z.infer<typeof eventsListSchema>

const eventFormDataSchema = z.object({})

export type EventFormData = z.infer<typeof eventFormDataSchema>
