import { Card, CardContent, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TitleComponent } from 'app/components/titles/title.component'
import React, { useCallback, useEffect, useState } from 'react'
import { Stack } from '@mui/system'
import { Link } from 'app/components/link.component'
import { AddCircle, Delete } from '@mui/icons-material'
import { Contract, ContractConsumptionList } from 'api/models'
import { formatCurrency, formatDateWithTime } from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useNavigate } from 'react-router-dom'

interface IConsumptionProps {
  consumptionValues: ContractConsumptionList
  isLoading: boolean
  contract: Contract | undefined
}

export const ConsumptionComponent = ({
  consumptionValues,
  isLoading,
  contract
}: IConsumptionProps) => {
  const { t } = useTranslation()
  const [initMap, setInitMap] = useState<Map<string, any>>(new Map())
  const { deleteContractService } = useFetcher()
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()

  const handleDelete = useCallback(
    async (row: any) => {
      if (!contract) return
      await handleMutation({
        confirm: {
          content: t('confirm_delete_service')
        },
        mutation: deleteContractService,
        data: {
          contractId: contract.id,
          serviceId: row.id
        },
        onSuccess: () => navigate(0),
        toastSuccess: t('delete_success'),
        toastError: t('error')
      })
    },
    [contract, navigate]
  )

  const formatActions = (row: any) =>
    row.isRemovable && (
      <IconButton size="small" onClick={() => handleDelete(row)}>
        <Delete fontSize={'small'} color={'primary'} />
      </IconButton>
    )

  useEffect(() => {
    setInitMap(() => {
      const map = new Map()
      map.set('items', [
        { label: t('label').toUpperCase(), value: 'label' },
        { label: t('type').toUpperCase(), value: 'serviceType' },
        { label: t('begin').toUpperCase(), format: formatDateWithTime, value: 'begin' },
        { label: t('end').toUpperCase(), format: formatDateWithTime, value: 'end' },
        { label: t('price').toUpperCase(), format: formatCurrency, value: 'price' },
        { label: '', component: formatActions }
      ])
      map.set('data', consumptionValues)
      return map
    })
  }, [consumptionValues])

  return (
    <Card>
      <CardContent>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent text={t('consumptions')} variant={'h3'} paddingTop={0} paddingLeft={12} />
          <Link
            to={`/enterprises/${contract?.clientId}/consumptions/add?center=${contract?.centerId}&contract=${contract?.id}`}
          >
            <IconButton size="small" color={'primary'}>
              <AddCircle fontSize="small" />
            </IconButton>
          </Link>
        </Stack>
        <ListSheetComponent isLoading={isLoading} data={initMap} />
      </CardContent>
    </Card>
  )
}
