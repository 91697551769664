import { z } from 'zod'
import dayjs from 'dayjs'

export const createCustomerReservationSchema = z.object({
  center: z.number({ coerce: true }),
  ownerId: z.number({ coerce: true }),
  clientId: z.number({ coerce: true }),
  clientType: z.string().optional().nullable(),
  serviceId: z.number({ coerce: true }),
  contractId: z.number({ coerce: true }).nullable(),
  serviceType: z.number({ coerce: true }),
  reduction: z.number({ coerce: true }),
  comment: z.string(),
  begin: z.instanceof(dayjs as any).transform((date) => date.format('YYYY-MM-DD HH:mm:ss')),
  end: z.instanceof(dayjs as any).transform((date) => date.format('YYYY-MM-DD HH:mm:ss'))
})

export type CreateCustomerReservation = z.infer<typeof createCustomerReservationSchema>

export const linkToAccessSchema = z.object({
  customerReservationId: z.string()
})

export type LinkToAccess = z.infer<typeof linkToAccessSchema>
