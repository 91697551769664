import { useCallback, useState } from 'react'
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  TableContainer,
  IconButton
} from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import styled from '@emotion/styled'
import { spacing } from '@mui/system'

import { Link } from 'app/components/link.component'
import { ListSkeleton } from '../skeletons/list.skeleton'

export type OrderByType = {
  property: string
  order: 'asc' | 'desc'
}

const Paper = styled(MuiPaper)(spacing)

export type ListColumnsProps<T> = Array<{
  label: string | React.FC
  slug: keyof T
  valueFormatter?: Function
  link?: { base: string; slug?: keyof T; slugs?: Array<keyof T> }
  tooltip?: string
  text?: keyof T
  condition?: Function
  unsorted?: boolean
  id?: keyof T
}>

interface IListAccordionProps<T> {
  columns: ListColumnsProps<T>
  items: Array<T>
  handleReset: () => void
  sort: OrderByType[]
  handleSort: (property: string) => (event: any) => void
  isLoading?: boolean
  formSlug?: string
  onClick?: (data: T) => void
  contentKey: string
}

export function ListAccordion<T>({
  items,
  columns,
  sort,
  handleSort,
  isLoading,
  onClick,
  contentKey
}: IListAccordionProps<T>) {
  const [expanded, setExpanded] = useState<readonly string[]>([])

  const isExpanded = (id: string) => expanded.indexOf(id) !== -1

  const handleExpand = (id: string) => {
    setExpanded((prevExpanded) => {
      if (prevExpanded.indexOf(id) === -1) {
        return [...prevExpanded, id]
      }
      return prevExpanded.filter((item) => item !== id)
    })
  }

  const renderCellContent = (item: T, column: ListColumnsProps<T>[number]) => {
    return column.link &&
      item[column.slug as keyof T] !== undefined &&
      item[column.slug as keyof T] !== null ? (
      <Link
        to={`${column.link.base}/${
          column.link.slug && item[column.link.slug]
            ? item[column.link.slug]
            : column.link.slugs
            ? column.link.slugs.map((s) => item[s]).join('/')
            : ''
        }`}
      >
        {column.valueFormatter && column.text && column.id
          ? column.valueFormatter(item[column.slug], item[column.text], item[column.id])
          : column.valueFormatter && column.text && !column.id
          ? column.valueFormatter(item[column.slug], item[column.text])
          : column.valueFormatter && !column.text
          ? column.valueFormatter(item[column.slug])
          : !column.valueFormatter && !column.text
          ? String(item[column.slug])
          : ''}
      </Link>
    ) : (
      ((item[column.slug as keyof T] !== undefined && item[column.slug as keyof T] !== null) ||
        (column.condition && !column.text && !column.valueFormatter)) && (
        <Typography component={'span'} variant={'body1'} sx={{ opacity: 0.75 }}>
          {column.valueFormatter
            ? column.condition
              ? column.valueFormatter(column.condition(item))
              : column.text
              ? column.valueFormatter(item[column.slug], item[column.text])
              : column.valueFormatter(item[column.slug])
            : !column.text &&
              (column.condition ? column.condition(item) : String(item[column.slug]))}
        </Typography>
      )
    )
  }

  if (isLoading) return <ListSkeleton />

  return (
    <Paper>
      <TableContainer>
        <Table size="small" sx={{ minWidth: 650 }}>
          <TableHead sx={{ fontWeight: 'bold' }}>
            <TableRow>
              <TableCell size="small" />
              {columns.map((headCell, index) => (
                <TableCell key={index} size="small">
                  {headCell.slug ? (
                    <TableSortLabel
                      active={
                        sort.find((item) => item.property === headCell.slug!.toString()) !==
                        undefined
                      }
                      direction={
                        sort.find((item) => item.property === headCell.slug!.toString())?.order ??
                        'asc'
                      }
                      disabled={headCell.unsorted}
                      onClick={handleSort(headCell.slug!.toString())}
                    >
                      {headCell.tooltip ? (
                        <Tooltip title={headCell.tooltip} placement="top-start">
                          <span style={{ display: 'flex', alignItems: 'center' }}>
                            {typeof headCell.label === 'string' ? (
                              headCell.label
                            ) : (
                              <headCell.label />
                            )}
                          </span>
                        </Tooltip>
                      ) : (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {typeof headCell.label === 'string' ? headCell.label : <headCell.label />}
                        </span>
                      )}
                    </TableSortLabel>
                  ) : (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {typeof headCell.label === 'string' ? headCell.label : <headCell.label />}
                    </span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, itemIndex) => {
              const itemId = item['id' as keyof T] as string
              return [
                <TableRow
                  hover
                  key={itemId}
                  sx={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.3)', strokeWidth: '1px' }}
                  onClick={() => onClick && onClick(item)}
                >
                  <TableCell size="small">
                    {(item as any)?.[contentKey]?.length > 0 && (
                      <IconButton onClick={() => handleExpand(itemId)}>
                        {isExpanded(itemId) ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    )}
                  </TableCell>
                  {columns.map(
                    (column, index) =>
                      column.slug && (
                        <TableCell
                          key={`${itemId}-${index}`}
                          sx={{ whiteSpace: 'nowrap' }}
                          size="small"
                        >
                          {renderCellContent(item, column)}
                        </TableCell>
                      )
                  )}
                </TableRow>,
                isExpanded(itemId) && (
                  <TableRow key={`${itemId}-expanded`}>
                    <TableCell colSpan={columns.length + 1}>
                      <Typography variant="body2" sx={{ padding: '16px' }}>
                        {(item as any)[contentKey]}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ]
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
