import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { FormItems, QuotationInformation } from 'api/models'
import { Container, Grid, Typography } from '@mui/material'
import { Link } from 'app/components/link.component'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { QuotationDetails } from 'modules/quotations/components/quotation-details.component'

export const QuotationView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getFormItems } = useFetcher()
  const { id } = useParams()
  const [quotation, setQuotation] = useState<QuotationInformation>({} as QuotationInformation)
  const { getQuotation } = useFetcher()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [options, setOptions] = useState({} as FormItems)
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['languages', 'languages'],
      ['commitments', 'commitment'] //TODO : replace with correct commitment
    ])
  )

  const refreshList = useCallback(async () => {
    if (!id) return
    setListIsLoading(true)

    try {
      const quotation = await getQuotation.mutateAsync(id)
      setQuotation(quotation)
    } finally {
      setListIsLoading(false)
    }
  }, [id, getQuotation])

  const initOptions = useCallback(
    async (commonOptions: Map<string, string>) => {
      await getFormItems
        .mutateAsync(Array.from(commonOptions.keys() as any))
        .then((optionsData) => {
          setOptions(optionsData as FormItems)
        })
    },
    [getFormItems]
  )

  useEffect(() => {
    initOptions(commonOptions).then(() => refreshList())
  }, [id])

  return listIsLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {t('quotation')} | <Link to={`/quotations/${quotation.id}`}>{quotation.id}</Link>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <QuotationDetails quotation={quotation} options={options} />
    </Container>
  )
}
