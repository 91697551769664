import React, { useCallback, useEffect, useState, Fragment } from 'react'
import { Container, Stack } from '@mui/system'
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper
} from '@mui/material'
import { useFetcher } from 'app/providers/fetcher.provider'
import { DiscountGridRead } from 'api/models'
import { useTranslation } from 'react-i18next'
import { formatDiscountGridType, formatMandateState } from 'app/utils/format'
import { Link } from 'app/components/link.component'
import { KeyboardBackspace, ExpandMore } from '@mui/icons-material'
import { useFeedback } from 'app/providers/feedback.provider'
import { useParams } from 'react-router-dom'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { TitleComponent } from 'app/components/titles/title.component'

export const DiscountGridView = (): React.JSX.Element => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { getDiscountGrid } = useFetcher()
  const [discountGrid, setDiscountGrid] = useState<DiscountGridRead>({} as DiscountGridRead)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getData().then()
  }, [])

  const getData = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getDiscountGrid,
      data: { id: String(id) },
      onSuccess: (data) => {
        setDiscountGrid(data)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [id])

  return (
    <Container>
      <Box marginBottom="2rem">
        {isLoading ? (
          <RectangularSkeleton />
        ) : (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/discountgrids`}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h2" gutterBottom display="inline">
              {t('discountgrids') + ' | ' + discountGrid.label}
            </Typography>
          </Stack>
        )}
      </Box>
      {isLoading && <CardSkeleton />}
      <Grid container>
        {!isLoading && (
          <>
            <Paper style={{ padding: '1rem', marginBottom: '1rem', width: '100%' }}>
              <TitleComponent
                text={t('informations')}
                variant={'h3'}
                paddingLeft={12}
                marginBottom={5}
              />
              <Grid item container rowSpacing={5}>
                <Grid item xs={12} sm={3}>
                  <Typography>{t('name')}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography>{discountGrid.label}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography>{t('type')}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  {formatDiscountGridType(String(discountGrid.type))}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography>{t('active')}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  {formatMandateState(Number(discountGrid.isActive))}
                </Grid>
              </Grid>
            </Paper>

            <Paper style={{ padding: '1rem', marginBottom: '1rem', width: '100%' }}>
              <TitleComponent
                text={t('centers_related')}
                variant={'h3'}
                paddingLeft={12}
                marginBottom={5}
              />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant={'subtitle2'}>{t('centers')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item container rowSpacing={5}>
                    {discountGrid.centers?.map((center: any, index) => {
                      return (
                        <Grid item xs={12} key={index}>
                          <Typography>{center.name}</Typography>
                        </Grid>
                      )
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper style={{ padding: '1rem', marginBottom: '1rem', width: '100%' }}>
              <TitleComponent
                text={t('typology_rate')}
                variant={'h3'}
                paddingLeft={12}
                marginBottom={5}
              />
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant={'subtitle2'}>{t('typology')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item container rowSpacing={5}>
                    {discountGrid.typologyRates?.map((typologyRate: any, index) => {
                      return (
                        <Fragment key={index}>
                          <Grid item xs={12} sm={6}>
                            <Typography variant={'subtitle2'}>
                              {typologyRate.label.toUpperCase()}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>{typologyRate.rate} %</Typography>
                          </Grid>
                        </Fragment>
                      )
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper style={{ padding: '1rem', marginBottom: '1rem', width: '100%' }}>
              <TitleComponent
                text={t('commitment_rate')}
                variant={'h3'}
                paddingLeft={12}
                marginBottom={5}
              />
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant={'subtitle2'}>{t('commitment')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item container rowSpacing={5}>
                    {discountGrid.commitmentRates?.map((commitmentRate: any, index) => {
                      return (
                        <Fragment key={index}>
                          <Grid item xs={12} sm={6}>
                            <Typography variant={'subtitle2'}>
                              {commitmentRate.label.toUpperCase()}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>{commitmentRate.rate} %</Typography>
                          </Grid>
                        </Fragment>
                      )
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </>
        )}
      </Grid>
    </Container>
  )
}
