import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Tooltip, Menu, MenuItem, IconButton as MuiIconButton } from '@mui/material'

import { languageOptions } from 'app/services/i18n'
import { useApp } from 'app/providers/app.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`

export function AppBarLanguagesDropdown() {
  const { handleMutation } = useFeedback()
  const { updateStaffLanguage } = useFetcher()
  const [anchorMenu, setAnchorMenu] = useState<any>(null)
  const { i18n, t } = useTranslation()
  const currentLanguage = useMemo(() => i18n.language, [i18n.language])
  const { getLanguages, user } = useApp()
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions[currentLanguage] || languageOptions['fr']
  )

  useEffect(() => {
    dayjs.locale(i18n.language)
  }, [currentLanguage])

  const toggleMenu = useCallback((event: SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
  }, [])

  const closeMenu = useCallback(() => {
    setAnchorMenu(null)
  }, [])

  const handleLanguageChange = useCallback(
    (languageCode: string) => {
      const languages = getLanguages()
      const languageId = languages.find((language) => language.code === languageCode)?.id
      setSelectedLanguage(languageOptions[languageCode])
      closeMenu()
      i18n.changeLanguage(languageCode).then()
      handleMutation({
        mutation: updateStaffLanguage,
        data: { language: Number(languageId), id: Number(user?.id) },
        onSuccess: () => window.location.reload()
      }).then()
    },
    [i18n, getLanguages, user]
  )

  return (
    <>
      <Tooltip title={t('language')}>
        <IconButton
          id="language"
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Flag src={selectedLanguage.icon} alt={selectedLanguage.name} />
        </IconButton>
      </Tooltip>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            sx={[language === currentLanguage && { backgroundColor: '#bbbfc4' }]}
            key={language}
            onClick={() => handleLanguageChange(language)}
            id={language}
          >
            {languageOptions[language].name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
