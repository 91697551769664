import { Alert, AlertProps, Box, CircularProgress } from '@mui/material'
import React from 'react'

type AlertBoxProps = {
  children: React.ReactNode
  isLoading?: boolean
  isVisible?: boolean | null
  severity: 'success' | 'error' | 'warning' | 'info'
} & AlertProps

export const AlertBox = ({
  children,
  severity,
  isVisible = true,
  isLoading = false,
  ...otherProps
}: AlertBoxProps): React.JSX.Element => {
  return (
    <>
      {isLoading && (
        <Box display={'flex'} justifyContent={'center'}>
          <CircularProgress color={'primary'} />
        </Box>
      )}
      {isVisible && (
        <Alert {...otherProps} severity={severity} variant={'outlined'}>
          {children}
        </Alert>
      )}
    </>
  )
}
