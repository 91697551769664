import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { EnterpriseInformation, FormItems } from 'api/models'
import { Badge as MuiBadge, Container, Grid, Tab, Tabs, Typography } from '@mui/material'
import { Link } from 'app/components/link.component'
import { TabPanel } from 'app/components/tab-panel.component'
import { useTranslation } from 'react-i18next'
import { EnterpriseDetails } from 'modules/enterprises/components/enterprise-details.component'
import SplitButton from 'app/components/buttons/split-button.component'
import { EnterpriseDocumentsComponent } from 'modules/enterprises/components/enterprise-documents.component'
import { EnterpriseMembersComponent } from 'modules/enterprises/components/enterprise-members.component'
import { EnterpriseContractsComponent } from 'modules/enterprises/components/enterprise-contracts.component'
import { EnterpriseOpportunitiesComponent } from 'modules/enterprises/components/enterprise-opportunities.component'
import { EnterpriseConsumptionsComponent } from 'modules/enterprises/components/enterprise-consumptions.component'
import { EnterpriseAccountingInformationsComponent } from 'modules/enterprises/components/enterprise-accounting-informations.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { DialogRef } from 'app/components/dialog/dialog.component'
import styled from '@emotion/styled'
import { PaperGreyComponent } from 'app/components/papers/paper-grey.component'
import { AddEventDialog } from 'modules/events/views/add-event-dialog.component'
import dayjs from 'dayjs'
import { useSidebar } from 'app/providers/sidebar.provider'
import { EnterpriseSsidComponent } from 'modules/enterprises/components/enterprise-ssid.component'
import { useApp } from 'app/providers/app.provider'
import { ModulesEnum } from 'app/constants/modules'
import { AddPrescriberDialog } from 'modules/prescribers/components/add-prescriber-dialog'

const Badge = styled(MuiBadge)`
  top: -14px;

  .MuiBadge-badge {
    height: 15px;
    width: 15px;
    min-width: 15px;
    font-size: 10px;
    background-color: ${(props) => props.theme.palette.primary.light};
    color: white;
  }
`

const TAB_TYPE = {
  INFORMATIONS: 0,
  MEMBERS: 1,
  DOCUMENTS: 2,
  CONTRACTS: 3,
  OPPORTUNITIES: 4,
  CONSUMPTIONS: 5,
  ACCOUNTING_INFORMATIONS: 6,
  SSID: 7
}

export const EnterpriseView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getFormItems } = useFetcher()
  const { id } = useParams()
  const { setParams } = useSidebar()
  const [enterprise, setEnterprise] = useState({} as EnterpriseInformation)
  const [tab, setTab] = useState(0)
  const { getEnterprise } = useFetcher()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const dialogRef = useRef<DialogRef>(null)
  const prescriberDialog = useRef<DialogRef>(null)
  const [options, setOptions] = useState({} as FormItems)
  const { getModule, getRight } = useApp()
  const [hasWifiAccess, setHasWifiAccess] = useState<boolean>(false)
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['individual_roles', 'role'],
      ['document_types', 'document_type'],
      ['centers', 'center'],
      ['event_types', 'type'],
      ['event_status', 'status'],
      ['prescriber_types', 'type'],
      ['opportunity_ground_refusals', 'opp_ground_refusal'],
      ['ground_refusal', 'ground_refusal'],
      ['opportunities', 'opportunity'],
      ['prescribers_type', 'type']
    ])
  )

  const refreshList = useCallback(async () => {
    if (!id) return
    setListIsLoading(true)
    getEnterprise
      .mutateAsync(id)
      .then((data) => {
        setEnterprise(data)
      })
      .finally(() => {
        setListIsLoading(false)
      })
  }, [])

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setOptions(optionsData as FormItems)
    })
  }, [])

  useEffect(() => {
    setParams({ id: id, type: 'clients', data: { client: id } })
    const _module = getModule(ModulesEnum.WIFI)
    if (
      _module !== undefined &&
      (getRight('radius_ssid', 'isShow') || getRight('radius_ssid_user_link', 'isDisplay'))
    ) {
      setHasWifiAccess(true)
    }
    initOptions(commonOptions).then(() => refreshList())
  }, [])

  const handleChangeTab = async (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const menuItems = useMemo(() => {
    const _menuItems = [
      {
        label: t('add_opportunity'),
        key: 'create_opp',
        action: () => navigate({ pathname: `/enterprises/${id}/opportunities/add` })
      },
      {
        label: t('add_event'),
        key: 'add_event',
        action: () => dialogRef.current?.open()
      },
      {
        label: t('add_consumption'),
        key: 'add_consumption',
        action: () =>
          navigate({
            pathname: `/enterprises/${id}/consumptions/add`,
            search: `?center=${enterprise.mainCenter}`
          })
      }
    ]

    if (getRight('prescriber', 'isAdd') && !enterprise.prescriber) {
      _menuItems.push({
        label: t('prescriber_add'),
        key: 'prescriber_add',
        action: () => prescriberDialog.current?.open()
      })
    }
    return _menuItems
  }, [enterprise, getRight, dialogRef, navigate, prescriberDialog])

  return listIsLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <AddEventDialog
        onSuccess={refreshList}
        defaultValues={{
          type: String(options?.event_types?.default[0]),
          status: String(options?.event_status?.default[0]),
          staff: 0,
          enterprise: String(enterprise.id),
          enterpriseName: enterprise.name,
          center: String(enterprise.mainCenter),
          begin: dayjs()
        }}
        options={options}
        dialogRef={dialogRef}
      />
      <AddPrescriberDialog
        dialogRef={prescriberDialog}
        enterprise={enterprise}
        options={options}
        onSuccess={() => {
          navigate(0)
        }}
      />
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {t('enterprise')} |{' '}
              <Link to={`/enterprises/${enterprise.id}`}>{enterprise.name}</Link>
            </Typography>
            <SplitButton items={menuItems} />
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="body2">
        {enterprise.reference} | {enterprise.name}
      </Typography>
      <PaperGreyComponent>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="company_tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ marginBottom: 6 }}
        >
          <Tab label={t('informations')} value={TAB_TYPE.INFORMATIONS} />
          <Tab
            label={t('members')}
            icon={<Badge badgeContent={enterprise.nbMember}></Badge>}
            iconPosition={'end'}
            value={TAB_TYPE.MEMBERS}
          />
          <Tab
            label={t('documents')}
            icon={<Badge badgeContent={enterprise.nbDocument}></Badge>}
            iconPosition={'end'}
            value={TAB_TYPE.DOCUMENTS}
          />
          <Tab
            label={t('contracts')}
            icon={<Badge badgeContent={enterprise.nbContract}></Badge>}
            iconPosition={'end'}
            value={TAB_TYPE.CONTRACTS}
          />
          <Tab
            label={t('opportunities')}
            icon={<Badge badgeContent={enterprise.nbOpportunities}></Badge>}
            iconPosition={'end'}
            value={TAB_TYPE.OPPORTUNITIES}
          />
          <Tab
            label={t('consumptions')}
            icon={<Badge />}
            iconPosition={'end'}
            value={TAB_TYPE.CONSUMPTIONS}
          />
          {(getRight('accounting_client', 'isShow') ||
            getRight('accounting_contact_clientcenter', 'isShow')) && (
            <Tab
              label={t('accounting_informations')}
              icon={<Badge />}
              iconPosition={'end'}
              value={TAB_TYPE.ACCOUNTING_INFORMATIONS}
            />
          )}
          {hasWifiAccess && <Tab label={t('ssid')} value={TAB_TYPE.SSID} />}
        </Tabs>
      </PaperGreyComponent>
      <TabPanel value={tab} index={TAB_TYPE.INFORMATIONS}>
        <EnterpriseDetails enterprise={enterprise} options={options} />
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.MEMBERS}>
        <EnterpriseMembersComponent enterprise={enterprise} options={options} />
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.DOCUMENTS}>
        <EnterpriseDocumentsComponent enterprise={enterprise} options={options} />
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.CONTRACTS}>
        <EnterpriseContractsComponent enterprise={enterprise} />
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.OPPORTUNITIES}>
        <EnterpriseOpportunitiesComponent enterprise={enterprise} />
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.CONSUMPTIONS}>
        <EnterpriseConsumptionsComponent />
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.ACCOUNTING_INFORMATIONS}>
        <EnterpriseAccountingInformationsComponent enterprise={enterprise} />
      </TabPanel>
      {hasWifiAccess && (
        <TabPanel value={tab} index={TAB_TYPE.SSID}>
          <EnterpriseSsidComponent enterprise={enterprise} />
        </TabPanel>
      )}
    </Container>
  )
}
