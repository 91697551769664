import React, { useCallback, useEffect, useState } from 'react'
import { Container, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PrescriberInformation } from 'api/models'
import { TabPanel } from 'app/components/tab-panel.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { formatNumbers } from 'app/utils/format'
import { PaperGreyComponent } from 'app/components/papers/paper-grey.component'
import { useApp } from 'app/providers/app.provider'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFeedback } from 'app/providers/feedback.provider'
import { PrescriberInformationCard } from 'modules/prescribers/components/prescriber-information.component'
import { Link } from 'app/components/link.component'
import { PrescriberMembersCard } from 'modules/prescribers/components/prescriber-members.component'
import { PrescriberOpportunitiesCard } from 'modules/prescribers/components/prescriber-opportunities.component'
import { ArrowBack } from '@mui/icons-material'

export const PrescriberView = () => {
  const { Accent } = useApp()
  const { handleMutation } = useFeedback()
  const { t } = useTranslation()
  const { enterpriseId, prescriberId } = useParams()
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [individual, setIndividual] = useState<PrescriberInformation>({} as PrescriberInformation)
  const { getEnterprisePrescriber } = useFetcher()

  const handleTabChange = async (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const fetch = useCallback(async () => {
    await handleMutation({
      mutation: getEnterprisePrescriber,
      data: { prescriberId: Number(prescriberId), enterpriseId: Number(enterpriseId) },
      onSuccess: (data) => {
        setIndividual(data)
      }
    })
  }, [prescriberId, enterpriseId, getEnterprisePrescriber])

  useEffect(() => {
    fetch().then()
  }, [])

  return getEnterprisePrescriber.isPending ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {
                //TODO: Changer lien une fois renommage companies enterprise
              }
              <Link to={`/companies/${enterpriseId}`} style={{ verticalAlign: 'middle' }}>
                <ArrowBack />
              </Link>
              {individual.type} |{' '}
              <Link to={`/enterprises/${enterpriseId}`}>{individual.enterpriseName}</Link>
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <PaperGreyComponent>
        <Grid container>
          <Grid item xs={6} sm={4} md={4} padding={4}>
            <Typography variant="subtitle2">
              <Accent>{t('opportunities')} : </Accent> {formatNumbers(individual.opportunities)}
            </Typography>
          </Grid>
          <Grid item sm={4} md={4} padding={4}>
            <Typography variant="subtitle2">
              <Accent>{t('quotations')} : </Accent> {formatNumbers(individual.quotations)}
            </Typography>
          </Grid>
          <Grid item sm={4} md={4} padding={4}>
            <Typography variant="subtitle2">
              <Accent>{t('contracts')} : </Accent>
              {formatNumbers(individual.contracts)}
            </Typography>
          </Grid>
        </Grid>
      </PaperGreyComponent>

      <PaperGreyComponent>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t('informations')} value={0} />
          <Tab label={t('users')} value={1} />
          <Tab label={t('opportunities')} value={2} />
        </Tabs>
      </PaperGreyComponent>

      <TabPanel value={currentTab} index={0}>
        <PrescriberInformationCard individual={individual} />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <PrescriberMembersCard individual={individual} />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <PrescriberOpportunitiesCard individual={individual} />
      </TabPanel>
    </Container>
  )
}
