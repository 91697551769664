import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Activity } from 'api/models'
import styled from '@emotion/styled'
import { useSidebar } from 'app/providers/sidebar.provider'
import { ActivitiesContainer } from 'modules/activities/components/activities'
import { Box } from '@mui/system'

const ScrollView = styled('div')({
  overflowY: 'auto',
  height: '100%',
  paddingTop: 12,
  paddingLeft: 12,
  paddingRight: 12
})

export function ActivitiesList() {
  const { getActivities } = useFetcher()
  const { params } = useSidebar()
  const [isLastPage, setIsLastPage] = useState(true)
  const [page, setPage] = useState(0)
  const [activityType, setActivityType] = useState<string>('')
  const [activities, setActivities] = useState<Activity[]>([] as Activity[])
  const scrollViewRef = useRef<HTMLDivElement>(null)

  const fetchActivities = useCallback(async () => {
    if (!params || !params.id) return
    const data = await getActivities.mutateAsync({
      id: params.id,
      type: params.type,
      page: page,
      activityType: activityType
    })
    setActivities((a) => (page === 0 ? [...data.activities] : [...a, ...data.activities]))
    setIsLastPage(data.isLastPage)
  }, [params, page, activityType])

  const onScroll = useCallback(() => {
    if (scrollViewRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollViewRef.current
      const offset = 0.5
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - offset

      if (isNearBottom) {
        setPage((page) => {
          return page + 1
        })
      }
    }
  }, [setPage, scrollViewRef])

  useEffect(() => {
    fetchActivities().then()
  }, [page, params, activityType])

  useEffect(() => {
    if (!scrollViewRef.current) return
    if (isLastPage) {
      scrollViewRef?.current?.removeEventListener('scroll', onScroll)
    } else {
      scrollViewRef.current.addEventListener('scroll', onScroll)
    }
    return () => {
      scrollViewRef?.current?.removeEventListener('scroll', onScroll)
    }
  }, [isLastPage])

  const handleChange = (response: Activity) => {
    setActivities((prev: any) => {
      return [
        {
          type: response.type,
          message: response.message,
          createdAt: response.createdAt,
          staff: response.staff
        },
        ...prev
      ]
    })
  }

  return (
    <ScrollView ref={scrollViewRef}>
      <Box height={'calc(100% + 10px)'}>
        <ActivitiesContainer
          isLoading={false}
          activities={activities}
          data={params?.data}
          onChange={handleChange}
          onTypeChange={(type: string) => {
            setActivityType(type)
            setPage(0)
          }}
        />
      </Box>
    </ScrollView>
  )
}
