import { useNavigate, useParams } from 'react-router-dom'
import { useFetcher } from 'app/providers/fetcher.provider'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Contract, FormItem } from 'api/models'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { FormCard } from 'app/components/form/form-card.component'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormInputProps } from 'app/components/form/controlled-form.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { Link } from 'app/components/link.component'
import { zodResolver } from '@hookform/resolvers/zod'
import { UpdateAdminContractProps, updateAdminContractSchema } from 'api/models/forms/contract'
import dayjs from 'dayjs'

export const ContractEditView = () => {
  const { id } = useParams()
  const { getContract, updateContractAdmin, getFormItems } = useFetcher()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [invoicingTypes, setInvoicingTypes] = useState<FormItem>({} as FormItem)
  const [contract, setContract] = useState<Contract>({} as Contract)
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const methods = useForm<UpdateAdminContractProps>({
    mode: 'onChange',
    defaultValues: {
      staff: '',
      invoicing: ''
    },
    resolver: zodResolver(updateAdminContractSchema)
  })

  const fetch = useCallback(async () => {
    setIsLoading(true)
    if (!id) return
    const options = await getFormItems.mutateAsync(['invoicing_types'])
    const contract = await getContract.mutateAsync(Number(id))
    setContract(contract)
    if (options.invoicing_types) setInvoicingTypes(options.invoicing_types)
    setIsLoading(false)
  }, [id])

  const handleSubmit = useCallback(
    async (data: UpdateAdminContractProps) => {
      await handleMutation({
        confirm: {
          content: t('confirm_update_contract')
        },
        mutation: updateContractAdmin,
        data: {
          id: contract.id,
          data: data
        },
        toastSuccess: t('update_contract_success'),
        onEnd: () => navigate(0)
      })
    },
    [contract]
  )

  useEffect(() => {
    fetch().then()
  }, [])

  const items = useMemo(() => {
    const _items = [
      {
        type: 'staffs',
        label: t('contract_created_by'),
        name: 'staff',
        inputProps: { defaultValue: contract.staffFirstName + ' ' + contract.staffLastName }
      },
      { type: 'select', label: t('invoicing'), name: 'invoicing', formItem: invoicingTypes },
      { type: 'datepicker', label: t('end_date_commitment'), name: 'deadline' }
    ] as FormInputProps[]

    if (contract.end) {
      _items.push({ type: 'datepicker', label: t('end'), name: 'end' })
    }
    return _items as FormInputProps[]
  }, [contract, invoicingTypes, t])

  useEffect(() => {
    if (!contract) return

    methods.setValue('deadline', contract.deadline ? dayjs(contract.deadline) : null)
    methods.setValue('end', contract.end ? dayjs(contract.end) : null)
    methods.setValue('invoicing', String(contract.invoicingType))
  }, [contract])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('update_contract')} |{' '}
          <Link to={`/contracts/${contract.id}`}>{contract.reference}</Link>
        </Typography>
        <Typography variant="body2">{contract.clientName}</Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <FormCard
              isLoading={isLoading}
              title={t('contract')}
              control={methods.control}
              methods={methods}
              items={items}
            />
          </Grid>

          <Grid item xs={12} textAlign={'center'}>
            <Button
              disabled={methods.formState.isSubmitting || !methods.formState.isValid}
              variant={'contained'}
              color={'primary'}
              onClick={methods.control.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  )
}
