import { useCallback, useEffect, useState } from 'react'
import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material'

import { Filter } from 'api/models'

interface ISelectGroupedProps {
  filter: Filter
  onChange?: () => void
  searchParams: URLSearchParams
  setSearchParams: (params: URLSearchParams) => void
}

interface IGrouped {
  [category: string]: { id: string; label: string }[]
}

export function SelectGrouped({
  filter,
  onChange,
  searchParams,
  setSearchParams
}: ISelectGroupedProps) {
  const [value, setValue] = useState<string>(String(filter.default[0]))
  const [data, setData] = useState<IGrouped>({})

  useEffect(() => {
    let group = filter.values.reduce((acc: any, item: any) => {
      acc[item.category] = acc[item.category] || []
      acc[item.category].push({ id: item.id, label: item.label })

      return acc
    }, {})
    setData(group)
  }, [filter])

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const targetValue = event.target.value
      setValue(targetValue as string)
      searchParams.set(filter.reference, targetValue)
      setSearchParams(searchParams)

      if (onChange) {
        onChange()
      }
    },
    [filter.reference, onChange, searchParams, setSearchParams]
  )

  return (
    <FormControl sx={{ width: 250, height: '100%' }}>
      <InputLabel>{filter.label}</InputLabel>
      <Select
        native
        value={value}
        onChange={handleChange}
        size={'small'}
        label={filter.label}
        data-cy={`filter-${filter.reference}`}
      >
        <>
          {Object.keys(data).map((category) => {
            if (category === 'undefined') {
              return (
                <option key={data[category][0].id} value={data[category][0].id}>
                  {data[category][0].label}
                </option>
              )
            }
            return (
              <optgroup label={category} key={category}>
                {data[category].map((v) => (
                  <option key={v.id} value={v.id}>
                    {v.label}
                  </option>
                ))}
              </optgroup>
            )
          })}
        </>
      </Select>
    </FormControl>
  )
}
