import React, { useCallback, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'

import type { OpportunityItem } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatAsChip, formatDate, formatOpportunityStatus, formatSurface } from 'app/utils/format'
import { SearchStaffInput } from 'app/components/filters/search-staff-input'
import { useList } from 'app/providers/list.provider'
import {
  AccountCircle,
  PeopleAlt,
  PermContactCalendar,
  PlayArrow,
  SquareFoot
} from '@mui/icons-material'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { useApp } from 'app/providers/app.provider'

export const OpportunitiesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getOpportunities, searchParams, setSearchParams } = useFetcher()
  const [opportunities, setOpportunities] = useState<OpportunityItem[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['opportunity_services', 'types'],
      ['opportunity_status', 'status'],
      ['opportunity_steps', 'step'],
      ['opportunity_surface', 'surface'],
      ['staff_centers', 'center']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getOpportunities
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setOpportunities(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getOpportunities, setIsLast, setOffset, setTotal, searchParams, setSearchParams]
  )

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('opportunities_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          onChange={() => handleFilter(refreshList, true)}
        >
          <SearchStaffInput
            slug="staff"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            defaultValue={user?.id}
          />
        </FiltersContainer>
      </Grid>
      <Grid data-cy="opportunities">
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {opportunities.length} / {total}
          </Typography>
        </Grid>
        <List
          items={opportunities}
          columns={[
            {
              label: '',
              slug: 'status',
              text: 'statusLabel',
              valueFormatter: formatOpportunityStatus
            },
            {
              label: t('reference'),
              slug: 'reference',
              link: { base: '/opportunities', slug: 'id' }
            },
            {
              label: t('client'),
              slug: 'client',
              link: { base: '/enterprises', slug: 'clientId' }
            },
            { label: t('center'), slug: 'center' },
            { label: t('type'), slug: 'type' },
            { label: AccountCircle, slug: 'staffLight', valueFormatter: formatAsChip },
            { label: PeopleAlt, tooltip: t('capacity'), slug: 'capacity' },
            {
              label: SquareFoot,
              tooltip: t('surface'),
              slug: 'surface',
              valueFormatter: formatSurface
            },
            { label: t('canal'), slug: 'canal' },
            { label: t('step'), slug: 'step' },
            { label: t('event'), slug: 'eventTypeLabel' },
            { label: PlayArrow, tooltip: t('begin_at'), slug: 'begin', valueFormatter: formatDate },
            {
              label: PermContactCalendar,
              tooltip: t('created_at'),
              slug: 'createdAt',
              valueFormatter: formatDate
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {opportunities.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
