import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  CustomerReservationInformations,
  CustomerReservationList,
  CustomerReservationServices,
  CustomerReservationSum,
  IdReturn
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'
import { AddReservationConsumptionItems } from 'api/models/forms/consumptions'
import { CreateCustomerReservation, LinkToAccess } from 'api/models/forms/customer_reservations'

export const customerReservationsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<CustomerReservationList>({
      url: `${BASE_URL}${API.GET_CUSTOMER_RESERVATIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async ({ token }: Ctx, params: string) =>
    fetcher<CustomerReservationSum>({
      url: `${BASE_URL}${API.GET_CUSTOMER_RESERVATIONS_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async ({ token }: Ctx, id: string) =>
    fetcher<CustomerReservationInformations>({
      url: `${BASE_URL}${urlHelper(API.GET_CUSTOMER_RESERVATION, { id })}`,
      method: 'GET',
      token: token
    }),
  getServices: async ({ token }: Ctx, id: string) =>
    fetcher<CustomerReservationServices>({
      url: `${BASE_URL}${urlHelper(API.GET_CUSTOMER_RESERVATION_SERVICES, { id })}`,
      method: 'GET',
      token: token
    }),
  getContracts: async ({ token }: Ctx, id: string) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_CUSTOMER_RESERVATION_CONTRACTS, { id })}`,
      method: 'GET',
      token: token
    }),
  getIndividuals: async ({ token }: Ctx, id: string) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_CUSTOMER_RESERVATION_INDIVIDUALS, { id })}`,
      method: 'GET',
      token: token
    }),
  cancel: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CUSTOMER_RESERVATION_CANCEL, { id })}`,
      method: 'PATCH',
      token: token
    }),
  confirm: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CUSTOMER_RESERVATION_CONFIRM, { id })}`,
      method: 'PATCH',
      token: token
    }),
  update: async ({ token }: Ctx, data: any) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CUSTOMER_RESERVATION_UPDATE, { id: data.id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  addService: async ({ token }: Ctx, data: AddReservationConsumptionItems) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_CUSTOMER_RESERVATION_SERVICE, {
        id: data.reservationId,
        serviceId: data.service
      })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  create: async ({ token }: Ctx, data: CreateCustomerReservation) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.POST_CUSTOMER_RESERVATIONS}`,
      method: 'POST',
      token: token,
      body: data
    }),
  linkToAccess: async ({ token }: Ctx, data: LinkToAccess) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_CUSTOMER_RESERVATION_LINK_TO_ACCESS, {
        id: data.customerReservationId
      })}`,
      method: 'POST',
      token: token
    })
}
