import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { rgba } from 'polished'

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Grid,
  Typography,
  Typography as MuiTypography
} from '@mui/material'
import { spacing } from '@mui/system'
import StatsCircularProgressDeterminateComponent from 'modules/dashboard/components/stats-circular-progress-determinate.component'

type StatsProps = {
  title: string
  value: number
  circularColor: string
}

const StatsComponent: React.FC<StatsProps> = ({ title, value, circularColor }) => {
  return (
    <Box>
      <StatsCircularProgressDeterminateComponent value={value} sx={circularColor} />
      <Typography variant="h6" style={{ color: '#FFF', textAlign: 'center' }}>
        {title}
      </Typography>
    </Box>
  )
}

export default StatsComponent
