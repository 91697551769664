import React, { HTMLInputTypeAttribute } from 'react'
import { FormControl } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro'
import dayjs from 'dayjs'

type IControlledDateFieldProps<TDate> = {
  type?: HTMLInputTypeAttribute
  label?: string
  name: string
  control: Control<any>
  icon?: React.FC<any>
  required?: boolean
  isUTC?: boolean
  clearable?: boolean
} & DatePickerProps<TDate>

export function ControlledDatePicker({
  name,
  label,
  control,
  required,
  clearable = false,
  isUTC = false,
  ...otherProps
}: IControlledDateFieldProps<any>) {
  return (
    <Controller
      render={({ field: { ref, value, onChange } }) => {
        return (
          <FormControl fullWidth size={'small'} required={required}>
            <DatePicker
              label={`${label || ''}`}
              defaultValue={value ?? null}
              value={value ?? null}
              inputRef={ref}
              onChange={(value) => {
                if (isUTC) {
                  value = dayjs.utc(value)
                }
                onChange(value)
              }}
              slotProps={{
                field: { clearable: clearable },
                textField: { size: 'small' }
              }}
              {...otherProps}
            />
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
