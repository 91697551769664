import { Button, MenuItem, TextField } from '@mui/material'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import React, { RefObject, useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Contract, EndorsementContract } from 'api/models'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFeedback } from 'app/providers/feedback.provider'

export const DialogAddEndorsementService = function ({
  dialogRef,
  endorsement,
  contract
}: {
  dialogRef: RefObject<DialogRef>
  contract: Contract
  endorsement: EndorsementContract | null
}) {
  const navigate = useNavigate()
  const [selectedService, setSelectedService] = useState<any>(null)
  const [availableServices, setAvailableServices] = useState<any[]>([])
  const { getServicesAvailable, addEndorsementService } = useFetcher()
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()

  const handleChange = useCallback((e: any) => {
    setSelectedService(e.target.value)
  }, [])

  const fetchAvailableServices = useCallback(async () => {
    if (!endorsement) return
    const data = await getServicesAvailable.mutateAsync({
      center: contract.centerId,
      begin: endorsement.begin,
      end: endorsement.deadline,
      commitment: endorsement.commitmentId,
      serviceIds: endorsement.mainContractServices.map((c) => c.serviceId),
      originalServiceIds: endorsement.originalContractServiceIds,
      contractType: contract.type
    })
    setAvailableServices(data)
  }, [endorsement])

  const handleAdd = useCallback(async () => {
    await handleMutation({
      confirm: {
        content: t('confirm_add_service')
      },
      mutation: addEndorsementService,
      data: { id: String(endorsement?.id), service: selectedService },
      toastSuccess: t('update_success'),
      toastError: t('error'),
      onSuccess: () => navigate(0)
    })
  }, [endorsement, selectedService])

  useEffect(() => {
    fetchAvailableServices().then()
  }, [endorsement])

  return (
    <Dialog
      title={t('add_service')}
      ref={dialogRef}
      actions={
        <>
          <Button onClick={() => dialogRef.current?.close()} variant={'outlined'}>
            {t('cancel')}
          </Button>
          <Button onClick={handleAdd} variant={'contained'}>
            {t('save')}
          </Button>
        </>
      }
    >
      <TextField
        sx={{ marginTop: 5 }}
        size={'small'}
        select
        label={t('Service')}
        value={String(selectedService)}
        variant="outlined"
        fullWidth
        onChange={handleChange}
      >
        {availableServices?.map((option) => (
          <MenuItem key={option.id} value={String(option.id)}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Dialog>
  )
}
