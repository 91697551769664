import { Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import {
  formatCurrency,
  formatDate,
  formatDateWithTime,
  formatPaymentStatus
} from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { API } from 'api/constants'

const PaymentsComponent = ({ payments }: any) => {
  const { t } = useTranslation()
  const map = useMemo(() => {
    const map = new Map()
    map.set('items', [
      {
        label: t('reference'),
        component: (row: any) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {formatPaymentStatus(row.state, row.stateLabel)}
            <Typography sx={{ marginLeft: 2 }}>{row.reference}</Typography>
          </Box>
        )
      },
      {
        label: t('accounting_document'),
        value: 'accountingDocument',
        link: `${API.GET_INVOICES}/:accountingDocumentId`
      },
      { label: t('client'), value: 'client', link: `/enterprises/:clientId` },
      { label: t('contract'), value: 'contract', link: `/contracts/:contractId` },
      { label: t('debit_date'), value: 'chargeDate', format: formatDate },
      { label: t('paid_at'), value: 'payed', format: formatDateWithTime },
      { label: t('amount_ttc'), value: 'amount', format: formatCurrency }
    ])
    map.set('data', payments)
    return map
  }, [payments])

  return (
    <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
      <Typography variant="h3" marginBottom="1rem" color="primary">
        {t('payments')}
      </Typography>
      <ListSheetComponent isLoading={map.size === 0} data={map} />
    </Paper>
  )
}

export default PaymentsComponent
