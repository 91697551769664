import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { FormItems, IndividualFormDetails, individualFormDetailsSchema } from 'api/models'
import { Button, Grid, Typography, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'

export const IndividualAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getFormItems } = useFetcher()
  const { enterpriseId } = useParams()
  const { createEnterpriseMember } = useFetcher()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [formItems, setFormItems] = useState({} as FormItems)
  const { handleMutation, toast } = useFeedback()
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['languages', 'language'],
      ['countries', 'country'],
      ['status', 'status'],
      ['civilities', 'civility'],
      ['individual_roles', 'role']
    ])
  )

  const methods = useForm<IndividualFormDetails>({
    defaultValues: {
      civility: 1,
      enterpriseId: enterpriseId,
      role: 'ROLE_ENTERPRISE_ADMIN',
      status: 1,
      language: 1,
      country: 'FR'
    },
    mode: 'onChange',
    resolver: zodResolver(individualFormDetailsSchema)
  })

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems
      .mutateAsync(Array.from(commonOptions.keys() as any))
      .then((optionsData) => {
        setFormItems(optionsData as FormItems)
      })
      .finally(() => setIsLoading(false))
  }, [])

  const handleSubmit = async (data: IndividualFormDetails) => {
    if (!enterpriseId) return
    await handleMutation({
      confirm: {
        content: t('confirm_create_individual')
      },
      onStart: () => setIsLoading(true),
      data: data,
      mutation: createEnterpriseMember,
      toastSuccess: t('create_individual_success'),
      onError: (error) => {
        toast({ message: error.message ?? t('create_individual_error'), variant: 'error' })
        setIsLoading(false)
      },
      onSuccess: () => {
        setIsLoading(false)
        navigate(`/enterprises/${enterpriseId}`)
      }
    })
  }

  useEffect(() => {
    setIsLoading(true)
    initOptions(commonOptions).then(() => setIsLoading(false))
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('add_user')}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading}
              title={t('main_informations')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('firstname'), name: 'firstName' },
                { type: 'textfield', label: t('lastname'), name: 'lastName', required: true },
                {
                  type: 'centers',
                  label: t('center'),
                  name: 'mainCenter',
                  required: true
                },
                {
                  type: 'select',
                  label: t('status'),
                  name: 'status',
                  required: true,
                  formItem: formItems.status
                },
                {
                  type: 'checkbox',
                  label: t('on_site'),
                  name: 'isPresent',
                  inputProps: { isUncheckedFalse: false }
                },
                {
                  type: 'select',
                  label: t('role'),
                  name: 'role',
                  required: true,
                  formItem: formItems.individual_roles
                },
                {
                  type: 'textfield',
                  label: t('job'),
                  name: 'job'
                },
                {
                  type: 'checkbox',
                  label: t('activcorner_access'),
                  name: 'activCornerAccess',
                  inputProps: { isUncheckedFalse: false }
                },
                {
                  type: 'checkbox',
                  label: t('remote_opening'),
                  name: 'isRemoteOpening',
                  inputProps: { isUncheckedFalse: false }
                },
                {
                  type: 'select',
                  label: t('language'),
                  name: 'language',
                  formItem: formItems.languages,
                  required: true
                }
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading}
              title={t('address')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('address'), name: 'address' },
                { type: 'textfield', label: t('zipcode'), name: 'zipCode' },
                { type: 'textfield', label: t('city'), name: 'city' },
                {
                  type: 'select',
                  label: t('country'),
                  name: 'country',
                  formItem: formItems.countries
                }
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading}
              title={t('contact')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('email'), name: 'email', required: true },
                { type: 'textfield', label: t('phone'), name: 'phone' },
                { type: 'textfield', label: t('mobile'), name: 'mobile' },
                { type: 'checkbox', label: t('invalid_email'), name: 'isInvalidEmail' }
              ]}
            />
          </Grid>

          <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
            <Grid item xs={6} textAlign={'right'}>
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={() => navigate(`/enterprises/${enterpriseId}`)}
              >
                {t('cancel')}
              </Button>
            </Grid>
            <Grid item xs={6} textAlign={'left'}>
              <Button
                disabled={methods.formState.isSubmitting || !methods.formState.isValid}
                variant={'contained'}
                color={'primary'}
                onClick={methods.control.handleSubmit(handleSubmit)}
              >
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  )
}
