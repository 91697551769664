import { CreateEndorsementComponent } from 'modules/contracts/components/endorsement/create-endorsement.component'
import { useEffect, useState } from 'react'
import { EndorsementsComponent } from './endorsements.component'
import { Contract } from 'api/models'

interface IEndorsementProps {
  contract: Contract
  isLoading: boolean
  isLitigation: boolean
}

export const EndorsementComponent = ({ contract, isLoading, isLitigation }: IEndorsementProps) => {
  const [isParentLink, setIsParentLink] = useState<boolean>(false)
  useEffect(() => {
    if (contract) {
      setIsParentLink(contract.hasEndorsement)
    }
  }, [contract])

  return (
    <>
      {!isParentLink ? (
        <CreateEndorsementComponent
          isLoading={isLoading}
          contract={contract}
          setIsParentLink={setIsParentLink}
        />
      ) : (
        <EndorsementsComponent contract={contract} isLitigation={isLitigation} />
      )}
    </>
  )
}
