import React, { useEffect } from 'react'
import { Login } from '@microsoft/mgt-react'
import { LocalizationHelper } from '@microsoft/mgt-element'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/system'

const CustomStyle = styled('div')`
  .login {
    --login-signed-out-button-background: ${(props) => props.theme.palette.primary.main};
    --login-signed-out-button-hover-background: ${(props) => props.theme.palette.primary.dark};
    --login-signed-out-button-text-color: ${(props) => props.theme.palette.primary.contrastText};
    --login-signed-in-background: ${(props) => props.theme.palette.background.paper};
    --login-signed-in-hover-background: ${(props) => props.theme.palette.background.default};
    --login-button-padding: 5px;
    --login-popup-background-color: ${(props) => props.theme.palette.background.paper};
    --login-popup-command-button-background-color: ${(props) =>
      props.theme.palette.background.default};
    --login-popup-padding: 8px;
    --login-add-account-button-text-color: ${(props) => props.theme.palette.primary.contrastText};
    --login-add-account-button-background-color: ${(props) => props.theme.palette.primary.main};
    --login-add-account-button-hover-background-color: ${(props) =>
      props.theme.palette.primary.dark};
    --login-command-button-background-color: ${(props) => props.theme.palette.primary.main};
    --login-command-button-hover-background-color: ${(props) => props.theme.palette.primary.dark};
    --login-command-button-text-color: ${(props) => props.theme.palette.primary.contrastText};
    --login-account-item-hover-bg-color: black;
    --login-flyout-command-text-color: ${(props) => props.theme.palette.text.primary};
    /** person component tokens **/
    --person-line1-text-color: ${(props) => props.theme.palette.text.primary};
    --person-line2-text-color: ${(props) => props.theme.palette.text.secondary};
    --person-background-color: blue;
  }
`

export const SignInAzureSsoForm = (): React.JSX.Element => {
  const { t } = useTranslation()

  useEffect(() => {
    LocalizationHelper.strings = {
      _components: {
        login: {
          signInLinkSubtitle: t('sso_connection_azure')
        }
      }
    }
  }, [])

  return (
    <CustomStyle>
      {' '}
      <Login className={'login'} loginCompleted={() => (window.location.href = '/login')} />{' '}
    </CustomStyle>
  )
}
