import React, { useCallback, useEffect, useState } from 'react'
import { Button, Grid, Paper as MuiPaper, Typography } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import { EmailTemplateSheetType } from 'api/models'
import { EmailTemplateFormSchema } from 'api/models/forms/email_template'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'app/components/link.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { KeyboardBackspace } from '@mui/icons-material'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormCard } from 'app/components/form/form-card.component'
import { FormProvider, useForm } from 'react-hook-form'

export const EmailEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { getOneEmailTemplate, updateEmailTemplate } = useFetcher()
  const [emailTemplate, setEmail] = useState({} as EmailTemplateSheetType)
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const navigate = useNavigate()

  const methods = useForm<EmailTemplateSheetType>({
    mode: 'onChange',
    resolver: zodResolver(
      EmailTemplateFormSchema.refine((data) => data.name.trim() !== '' && data.html.trim() !== '')
    )
  })
  const { isDirty, isValid } = methods.formState

  const init = async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getOneEmailTemplate,
      data: id,
      onSuccess: (data) => {
        setEmail(data)
        methods.reset({
          name: data.name,
          html: data.html,
          lenguageLabel: data.lenguageLabel,
          reference: data.reference
        })
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    init().then()
  }, [])

  const handleSubmit = useCallback(
    async (data: EmailTemplateSheetType) => {
      if (!isDirty) return navigate(`/emails/${id}`)
      await handleMutation({
        confirm: {
          content: t('confirm_update_email_template')
        },
        mutation: updateEmailTemplate,
        data: { id: String(id), name: data.name, html: data.html },
        onSuccess: () => {},
        onEnd: () => navigate(`/emails/${id}`)
      })
    },
    [updateEmailTemplate, id, isDirty, handleMutation, navigate, t]
  )

  return isLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/emails/${emailTemplate.id}`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">
              {t('email_template_update')} |{' '}
              <Link to={`/emails/${emailTemplate.id}`}>{emailTemplate.name}</Link>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid>
        <MuiPaper elevation={0} sx={{ padding: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
          <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
            <FormProvider {...methods}>
              <Grid
                container
                rowSpacing={8}
                columnSpacing={{ xs: 2, sm: 4, md: 8 }}
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Grid item xs={12} md={6}>
                  <FormCard
                    isLoading={isLoading}
                    title={t('main_informations')}
                    control={methods.control}
                    items={[
                      { type: 'textfield', label: t('name'), name: 'name' },
                      {
                        type: 'textfield',
                        label: t('language'),
                        name: 'lenguageLabel',
                        inputProps: { disabled: true }
                      },
                      {
                        type: 'textfield',
                        label: t('reference'),
                        name: 'reference',
                        inputProps: { disabled: true }
                      },
                      {
                        type: 'textfield',
                        label: t('Html'),
                        name: 'html',
                        xs: 12,
                        inputProps: { multiline: true, maxRows: 20 }
                      }
                    ]}
                  />
                </Grid>
                <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
                  <Grid item xs={6} textAlign={'right'}>
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      onClick={() => navigate(`/emails/${id}`)}
                    >
                      {t('cancel')}
                    </Button>
                  </Grid>
                  <Grid item xs={6} textAlign={'left'}>
                    <Button
                      disabled={!isValid}
                      variant={'contained'}
                      color={'primary'}
                      onClick={methods.control.handleSubmit(handleSubmit)}
                    >
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </MuiPaper>
      </Grid>
    </Container>
  )
}
