import { useCallback, useEffect, useState } from 'react'
import { Autocomplete, CircularProgress, FormControl, TextField } from '@mui/material'

import { MemberFilter } from 'api/models'
import { Control, Controller, UseFormReturn } from 'react-hook-form'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { useDebouce } from 'app/hooks/use-debounce'
import { Stack } from '@mui/system'

interface IControlledTextFieldProps {
  companyId: string
  label: string
  name: string
  control: Control<any>
  required?: boolean
  defaultValue?: string
  methods?: UseFormReturn<any>
}

export function ControlledSelectMembersField({
  companyId,
  name,
  label,
  control,
  defaultValue,
  required = false,
  methods
}: IControlledTextFieldProps) {
  const { getEnterpriseMembersFilters } = useFetcher()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState<MemberFilter | null>(null)
  const [clients, setClients] = useState<MemberFilter[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(defaultValue || '')

  const handleTyping = useCallback(async (event: any) => {
    if (!event) return
    const value = event.target.value as string
    setInputValue(value)
  }, [])

  useDebouce(
    () => {
      setLoading(true)
      getEnterpriseMembersFilters
        .mutateAsync({
          id: companyId,
          name: inputValue
        })
        .then((data) => {
          const _clients = data
            .map((obj) => {
              if (!obj.children) return null
              return obj.children
            })
            .filter((obj) => obj !== null)
            .flat() as unknown as MemberFilter[]
          setClients(_clients)
          const client = _clients.find((client) => client.text === inputValue)
          if (client) {
            setSelectedClient(client)
            methods?.setValue(name, client.id, { shouldValidate: true })
          }
        })
        .finally(() => setLoading(false))
    },
    [inputValue],
    350
  )

  useEffect(() => {
    setInputValue(defaultValue ?? '')
  }, [defaultValue])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => {
        return (
          <FormControl fullWidth size={'small'} required={required}>
            <Stack>
              <Autocomplete
                disablePortal
                autoComplete
                size={'small'}
                onChange={(_: any, newValue: any) => {
                  setSelectedClient(newValue)
                  onChange(newValue ? newValue.id : '')
                }}
                value={selectedClient}
                onInputChange={handleTyping}
                loadingText={t('loading')}
                noOptionsText={t('no_options')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.text}
                selectOnFocus
                options={clients}
                renderInput={(params) => (
                  <TextField
                    {...(params as any)}
                    required={required}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                    label={label}
                  />
                )}
              />
            </Stack>
          </FormControl>
        )
      }}
    />
  )
}
