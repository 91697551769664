import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import React, { useMemo, useRef } from 'react'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { CenterDetails } from 'api/models'
import { formatDateWithFormat } from 'app/utils/format'
import dayjs from 'dayjs'
import Carousel from 'app/components/carousel/carousel.component'
import { AddCircleOutline, Edit } from '@mui/icons-material'
import {
  DialogRef,
  EditImagesDialog,
  typeTags
} from 'app/components/dialog/edit-images-dialog.component'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useFetcher } from 'app/providers/fetcher.provider'

interface ICenterDisplayCardInfoConfigurationProps {
  center: CenterDetails | undefined
  isLoading?: boolean
}

export const CenterDisplayCardInfoConfiguration = ({
  center,
  isLoading
}: ICenterDisplayCardInfoConfigurationProps) => {
  const { t } = useTranslation()
  const { useGetAssets } = useFetcher()
  const theme = useTheme()

  const mediaQueryLgMatches = useMediaQuery(theme.breakpoints.down('lg'))
  const carouselHeight = useMemo(() => (mediaQueryLgMatches ? 345 : 250), [mediaQueryLgMatches])

  const { data: images, isLoading: isLoadingAssets } = useGetAssets({
    id: String(center?.id ?? ''),
    type: 'centers',
    enabled: !!center
  })
  const dialogRef = useRef<DialogRef>(null)

  const handleOpenDialog = () => {
    dialogRef.current?.open()
  }

  if (isLoadingAssets || isLoading || !center) {
    return (
      <Card variant="outlined">
        <Box p={5}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={carouselHeight}
            animation="wave"
            sx={{ borderRadius: '4px' }}
          />
        </Box>
        <Divider sx={{ marginTop: 10, marginBottom: 5 }} />
        <Stack p={5} gap={4}>
          {[1, 2, 3, 4].map((i) => (
            <Stack direction="row" gap={8} alignItems="center" key={i}>
              <Skeleton
                variant="circular"
                height={40}
                width={40}
                animation="wave"
                sx={{ marginBottom: 2, flexShrink: 0 }}
              />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={30}
                animation="wave"
                sx={{ borderRadius: '4px', marginBottom: 2 }}
              />
            </Stack>
          ))}
        </Stack>
      </Card>
    )
  }

  return (
    <Card
      variant="outlined"
      sx={{
        [theme.breakpoints.down('lg')]: {
          display: 'flex'
        }
      }}
    >
      {(images?.length ?? 0) > 0 ? (
        <Box position="relative">
          {center && (
            <Carousel
              readonly
              height={carouselHeight}
              maxWidth={mediaQueryLgMatches ? 345 : undefined}
              entityId={String(center.id)}
              entityType={'centers'}
            />
          )}
          <Button
            variant="contained"
            onClick={handleOpenDialog}
            data-cy="edit-images-button"
            sx={{
              padding: 1,
              minWidth: 'unset',
              position: 'absolute',
              top: 8,
              right: 8
            }}
          >
            <Edit fontSize="small" />
          </Button>
        </Box>
      ) : (
        <AddImagesButton onClick={handleOpenDialog} height={carouselHeight} />
      )}

      <Divider />
      <CardContent>
        <List>
          <ListItem disableGutters>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <MeetingRoomIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                    {t('opening_day')} :
                  </Typography>
                  <Typography
                    sx={{
                      display: 'inline',
                      paddingLeft: 1,
                      [theme.breakpoints.down('lg')]: {
                        display: 'block',
                        paddingLeft: 0
                      }
                    }}
                    component="span"
                    variant="body2"
                    color="primary"
                  >
                    {center.centerOpeningDays
                      ?.map((dayObj: { openingDay: number }) =>
                        dayjs().day(dayObj.openingDay).format('dddd')
                      )
                      .join(', ')}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <AccessTimeIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                    {t('opening_hours')} :
                  </Typography>
                  <Typography
                    sx={{
                      display: 'inline',
                      paddingLeft: 1,
                      [theme.breakpoints.down('lg')]: {
                        display: 'block',
                        paddingLeft: 0
                      }
                    }}
                    component="span"
                    variant="body2"
                    color="primary"
                  >
                    {formatDateWithFormat(center.openingHour ?? '', 'LT')} -{' '}
                    {formatDateWithFormat(center.closureHour ?? '', 'LT')}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <PhoneAndroidIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                    {t('phone')} :
                  </Typography>
                  <Typography
                    sx={{
                      display: 'inline',
                      paddingLeft: 1,
                      [theme.breakpoints.down('lg')]: {
                        display: 'block',
                        paddingLeft: 0
                      }
                    }}
                    component="span"
                    variant="body2"
                    color="primary"
                  >
                    {center.phone}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <MailOutlineIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                    {t('email')} :
                  </Typography>
                  <Typography
                    sx={{
                      display: 'inline',
                      paddingLeft: 1,
                      [theme.breakpoints.down('lg')]: {
                        display: 'block',
                        paddingLeft: 0
                      }
                    }}
                    component="span"
                    variant="body2"
                    color="primary"
                  >
                    {center.email}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </CardContent>

      {center && (
        <EditImagesDialog
          type={typeTags.centers}
          entityLabel={center.name}
          entityId={String(center.id)}
          ref={dialogRef}
        />
      )}
    </Card>
  )
}
const AddImagesButton = ({ onClick, height }: { onClick: () => void; height: number }) => {
  const { t } = useTranslation()

  return (
    <Button
      onClick={onClick}
      sx={{
        height: height,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
        width: '100%',
        flexDirection: 'column'
      }}
      data-cy="add-images-button"
    >
      <AddCircleOutline sx={{ fontSize: 60 }} color="primary" />
      <Typography fontSize="large" fontWeight="500" color="primary">
        {t('add_pictures')}
      </Typography>
    </Button>
  )
}
