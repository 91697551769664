import { Card, CardContent, Link, List, ListItem } from '@mui/material'

import { TitleComponent } from 'app/components/titles/title.component'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContractLitigationList } from 'api/models'
import { formatDate } from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { API } from 'api/constants'
import urlHelper from 'app/helpers/url.helper'

interface IInvoicesProps {
  litigationValues: ContractLitigationList
  isLoading: boolean
}

export const LitigationsComponent = ({ litigationValues, isLoading }: IInvoicesProps) => {
  const { t } = useTranslation()
  const [initMap, setInitMap] = useState<Map<string, any>>(new Map())

  const formatList = (row: any) => {
    return (
      <List>
        {row.invoices.map((invoice: any) => (
          <ListItem key={invoice.id}>
            <Link href={urlHelper(API.GET_INVOICES_INFORMATIONS, invoice)}>
              {invoice.reference}
            </Link>
          </ListItem>
        ))}
      </List>
    )
  }

  useEffect(() => {
    setInitMap(() => {
      const map = new Map()
      map.set('items', [
        { label: t('reference').toUpperCase(), value: 'reference', link: API.GET_LITIGATION },
        {
          label: t('invoices').toUpperCase(),
          value: 'invoices',
          component: (row: any) => formatList(row)
        },
        { label: t('created_at').toUpperCase(), value: 'createdAt', format: formatDate },
        { label: t('state').toUpperCase(), value: 'stateLabel' }
      ])
      map.set('data', litigationValues)
      return map
    })
  }, [litigationValues])

  return (
    <Card>
      <CardContent>
        <TitleComponent text={t('litigations')} variant={'h3'} paddingLeft={12} />
        <ListSheetComponent isLoading={isLoading} data={initMap} />
      </CardContent>
    </Card>
  )
}
