import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  discountGridAddSchema,
  DiscountGridAdd,
  FormItems,
  TypologiesList,
  CommitmentsList,
  FormItem
} from 'api/models'

import { Container, Stack, Button, Grid, Typography, Divider } from '@mui/material'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormCard } from 'app/components/form/form-card.component'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { DiscountGridForm } from 'modules/discountGrids/components/discount-grid.add.component'
dayjs.extend(localeData)

export const DiscountGridAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { getFormItems, createDiscountGrid, getTypologies, getCommitments } = useFetcher()
  const [formItems, setFormItems] = useState<FormItems>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [typologies, setTypologies] = useState<TypologiesList>([] as TypologiesList)
  const [commitments, setCommitments] = useState<CommitmentsList>({
    total: 0,
    actual: 0,
    last: false,
    items: []
  })

  const methods = useForm<DiscountGridAdd>({
    resolver: zodResolver(discountGridAddSchema),
    defaultValues: {
      isActive: true,
      typologyRates: [],
      commitmentRates: []
    }
  })

  const { isValid } = methods.formState

  useEffect(() => {
    handleMutation({
      mutation: getFormItems,
      data: ['discount_grid_type'],
      onSuccess: (data) => {
        setFormItems(data)
      }
    }).then()
    getTypologiesData().then()
    getCommitmentsData().then()
  }, [])

  const getTypologiesData = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getTypologies,
      onSuccess: (data) => {
        setTypologies(data)
        methods.setValue(
          'typologyRates',
          data.map((t) => ({ id: Number(t.id), rate: 0 }))
        )
      },
      onEnd: () => setIsLoading(false)
    })
  }, [getTypologies, methods.setValue])

  const getCommitmentsData = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getCommitments,
      onSuccess: (data: CommitmentsList) => {
        setCommitments(data)
        methods.setValue(
          'commitmentRates',
          data.items.map((c) => ({ id: Number(c.id), rate: 0 }))
        )
      },
      onEnd: () => setIsLoading(false)
    })
  }, [getCommitments, methods.setValue])

  const handleSubmit = useCallback(
    async (data: DiscountGridAdd) => {
      const formattedData = {
        ...data,
        typologyRates: data.typologyRates?.map((rate) => ({
          id: Number(rate.id),
          rate: Number(rate.rate)
        })),
        commitmentRates: data.commitmentRates?.map((rate) => ({
          id: Number(rate.id),
          rate: Number(rate.rate)
        }))
      }

      await handleMutation({
        mutation: createDiscountGrid,
        data: formattedData,
        confirm: {
          title: t('confirm'),
          content: t('confirm_add_discount_grid')
        },
        onSuccess: () => navigate(`/discountgrids`)
      })
    },
    [createDiscountGrid, handleMutation, navigate, t]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">{t('add_discount_grid')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <FormProvider {...methods}>
        <Grid item xs={12} md={6}>
          <FormCard
            isLoading={isLoading}
            title={t('informations')}
            control={methods.control}
            items={[
              {
                type: 'textfield',
                label: t('name'),
                name: 'label',
                required: true
              },
              {
                type: 'select',
                label: t('type'),
                name: 'type',
                required: true,
                formItem: formItems.discount_grid_type as FormItem
              },
              {
                type: 'checkbox',
                label: t('is_active'),
                name: 'isActive',
                required: true
              }
            ]}
          >
            <Divider />
            <DiscountGridForm methods={methods} typologies={typologies} commitments={commitments} />
            <Grid item xs={12} md={6}>
              <Button onClick={methods.handleSubmit(handleSubmit)} disabled={!isValid}>
                {t('save')}
              </Button>
            </Grid>
          </FormCard>
        </Grid>
      </FormProvider>
    </Container>
  )
}
