import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { TypologiesList } from 'api/models'

export const typologiesRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<TypologiesList>({
      url: `${BASE_URL}${API.TYPOLOGIES}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
}
