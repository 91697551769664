import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { RightList, Right } from 'api/models'

export const rightsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<RightList>({
      url: `${BASE_URL}${API.GET_RIGHTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  get: async ({ token }: Ctx, id: string) =>
    fetcher<Right>({
      url: `${BASE_URL}${API.GET_RIGHTS}/${id}`,
      method: 'GET',
      token: token
    }),
  edit: async ({ token }: Ctx, data: Right) =>
    fetcher({
      url: `${BASE_URL}${API.GET_RIGHTS}/${data.id}`,
      method: 'PATCH',
      token: token,
      body: data
    })
}
