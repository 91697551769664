import { useCallback, useMemo } from 'react'
import type { Center } from 'api/models'
import { CentersSelect, CentersSelectInputProps } from 'app/components/filters/centers-select'
import { Grid } from '@mui/material'
import { SetURLSearchParams } from 'react-router-dom'

type ISearchCenterInputProps = {
  slug: string
  slugCluster?: string
  onChange?: () => void
  setSearchParams: SetURLSearchParams
  searchParams: URLSearchParams
  byPassAllCenters?: boolean
} & CentersSelectInputProps

export function SearchCenterInput({
  slug,
  slugCluster,
  onChange,
  searchParams,
  setSearchParams,
  byPassAllCenters,
  ...otherProps
}: ISearchCenterInputProps) {
  const initialCenter = useMemo(() => {
    if (slugCluster && searchParams.get(slugCluster) !== null) {
      return parseInt(searchParams.get(slugCluster) ?? '')
    } else if (slug && searchParams.get(slug)) {
      return parseInt(searchParams.get(slug) ?? '')
    }
    return undefined
  }, [searchParams, slug, slugCluster])

  const initialIsCluster = useMemo(() => {
    return slugCluster !== undefined && searchParams.get(slugCluster) !== null
  }, [searchParams, slugCluster])

  const handleOnChange = useCallback(
    (newValue: Center) => {
      setSearchParams((searchParams) => {
        if (newValue) {
          if (slugCluster) {
            if (newValue.type === 'cluster') {
              searchParams.delete(slug)
              searchParams.set(slugCluster, newValue.id)
            } else {
              searchParams.delete(slugCluster)
              searchParams.set(slug, newValue.id)
            }
          } else {
            searchParams.set(slug, newValue.id)
          }
        } else {
          searchParams.delete(slugCluster!)
          searchParams.delete(slug)
        }
        return searchParams
      })

      if (onChange) {
        onChange()
      }
    },
    [onChange, searchParams, setSearchParams, slug, slugCluster]
  )

  return (
    <Grid sx={{ width: 200 }}>
      <CentersSelect
        slug={slug}
        onChange={handleOnChange}
        initialValue={initialCenter}
        initialValueIsCluster={initialIsCluster}
        byPassAllCenters={byPassAllCenters}
        {...otherProps}
      />
    </Grid>
  )
}
