import { z } from 'zod'

const planningItemSchema = z.object({
  label: z.string(),
  serviceTypeLabel: z.string(),
  begin: z.number(),
  end: z.number(),
  deadline: z.number(),
  state: z.number(),
  commitmentRef: z.string(),
  surface: z.number(),
  contractReference: z.string(),
  parentContractId: z.number().nullable(),
  parentContractState: z.string().nullable(),
  contractId: z.number(),
  name: z.string(),
  clientId: z.number(),
  x: z.string(),
  y: z.array(z.number())
})
const planningDesktopsSchema = z.object({
  labels: z.array(z.string()),
  planning: z.record(z.string(), z.array(planningItemSchema))
})

export type PlanningDesktops = z.infer<typeof planningDesktopsSchema>
