import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import ReactPerfectScrollbar from 'react-perfect-scrollbar'
import { List } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { MenuItemsTypes } from 'app/constants/navbar'
import { SidebarNavSection } from 'app/components/sidebar/sidebar-nav-section.component'
import 'app/assets/perfect-scrollbar.css'

const baseScrollbar = (props: any) => css`
  background-color: ${props.theme.sidebar.background};
  border-right: ${props.theme.sidebar.scrollbar.borderRight};
  border-right-color: ${props.theme.sidebar.scrollbar.borderColor};
  flex-grow: 1;
  padding-right: ${props.theme.sidebar.scrollbar.paddingRight};
  padding-left: ${props.theme.sidebar.scrollbar.paddingLeft};
  padding-top: 11px;
`

const Scrollbar = styled.div`
  ${baseScrollbar}
`

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`

type SidebarNavProps = {
  items: {
    pages: MenuItemsTypes[]
    title: string
  }[]
}

export function SidebarNav({ items }: SidebarNavProps) {
  const theme = useTheme()

  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const ScrollbarComponent = (matches ? PerfectScrollbar : Scrollbar) as React.ElementType

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items>
          {items &&
            items.map((item, index) => (
              <SidebarNavSection
                component="div"
                key={index}
                pages={item.pages}
                title={item.title}
              />
            ))}
        </Items>
      </List>
    </ScrollbarComponent>
  )
}
