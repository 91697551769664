import React, { useCallback, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'
import { DatePickerProps } from '@mui/x-date-pickers-pro'

export interface IProps {
  slug: string
  label: string
  onChange?: () => void
  setSearchParams: (params: URLSearchParams) => void
  searchParams: URLSearchParams
  views?: DatePickerProps<any>['views']
  format?: string
  clearable?: boolean
  disableFuture?: boolean
}
export const MonthPicker = ({
  slug,
  label,
  onChange,
  setSearchParams,
  searchParams,
  views = ['month'],
  format,
  clearable = false,
  disableFuture = false
}: IProps): React.JSX.Element => {
  const { t } = useTranslation()
  const defaultValue = searchParams.get(slug) ? dayjs(searchParams.get(slug)) : null
  const [value, setValue] = useState<Dayjs | null>(defaultValue)

  const handleChange = useCallback(
    (val: any) => {
      if (val) {
        searchParams.set(slug, val.format(format ?? 'YYYY-MM'))
      } else {
        searchParams.delete(slug)
      }
      setValue(val)

      if (onChange) {
        onChange()
      }
    },
    [onChange, searchParams, setSearchParams, slug]
  )

  return (
    <DatePicker
      label={t(label)}
      views={views}
      value={value}
      onChange={handleChange}
      disableFuture={disableFuture}
      slotProps={{
        field: {
          clearable: clearable,
          onClear: clearable ? () => handleChange(null) : undefined
        },
        textField: {
          size: 'small' // Add size='small' to the slotProps
        }
      }}
    />
  )
}
