import { Payment } from 'api/models'
import React, { useCallback, useState } from 'react'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/system'
import { useQueryClient } from '@tanstack/react-query'

interface IPaymentUpdateStatusModalProps {
  payment: Payment
  modalRef: React.RefObject<DialogRef>
}

export const PaymentUpdateStatusModal = ({ payment, modalRef }: IPaymentUpdateStatusModalProps) => {
  const { t } = useTranslation()
  const { getPayment, useGetFormItems } = useFetcher()
  const { updatePayment } = useFetcher()
  const { handleMutation } = useFeedback()
  const [status, setStatus] = useState<number>(payment.state)
  const queryClient = useQueryClient()

  const { data: formItems } = useGetFormItems(['payment_status'])

  const handleClose = useCallback(() => {
    modalRef.current?.close()
  }, [modalRef])

  const handleChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      setStatus(event.target.value as number)
    },
    [setStatus]
  )

  const handleUpdate = useCallback(async () => {
    await handleMutation({
      mutation: updatePayment,
      data: { id: payment.id, state: status },
      toastSuccess: t('update_success'),
      toastError: t('update_error'),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['query', 'payment', 'get', Number(payment.id)] })
        handleClose()
      }
    })
  }, [handleMutation, getPayment, t, handleClose, status])

  return (
    <Dialog
      title={t('update_payment_status')}
      actions={
        <Stack direction="row" gap={2}>
          <Button variant="outlined" onClick={handleClose} disabled={getPayment.isPending}>
            {t('cancel')}
          </Button>
          <LoadingButton variant="contained" onClick={handleUpdate} loading={getPayment.isPending}>
            {t('update')}
          </LoadingButton>
        </Stack>
      }
      ref={modalRef}
      maxWidth={'xs'}
    >
      <FormControl fullWidth sx={{ marginTop: 4 }}>
        <InputLabel>{t('status')}</InputLabel>
        <Select
          label={t('status')}
          value={status}
          onChange={handleChange}
          disabled={getPayment.isPending}
        >
          {formItems?.payment_status?.values.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Dialog>
  )
}
