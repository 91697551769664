import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SignInForm from 'modules/auth/components/SignInForm'
import UnloggedWrapper from 'app/components/UnloggedWrapper'
import { useApp } from 'app/providers/app.provider'
import { SignInAzureSsoForm } from 'modules/auth/components/SignInAzureSsoForm'
import { Link } from 'app/components/link.component'

const hasAzureSso =
  undefined !== process.env.REACT_APP_AZURE_SSO_CLIENT_ID &&
  undefined !== process.env.REACT_APP_AZURE_SSO_URL

export const SignInView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()

  if (user) {
    return <Navigate to="/" />
  }

  return (
    <UnloggedWrapper>
      <Typography variant="h1" align="center" typography={'h1'} gutterBottom marginBottom={6}>
        {t('welcome')}
      </Typography>
      <Typography variant="h4" align="center" typography={'h4'} gutterBottom marginBottom={6}>
        {t('sign_in_subtitle')}
      </Typography>
      {hasAzureSso && <SignInAzureSsoForm />}
      {!hasAzureSso && (
        <>
          <SignInForm />
          <Typography marginTop={6}>
            <Link data-cy="to-forgot-password" to={'/forgotten_password'}>
              {t('forgotten_password')}
            </Link>
          </Typography>
        </>
      )}
    </UnloggedWrapper>
  )
}
