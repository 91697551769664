import { styled } from '@mui/system'
import { Switch as MUISwitch } from '@mui/material'

export const Switch = styled(MUISwitch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2
  },
  '.Mui-checked + .MuiSwitch-track': {
    opacity: '1!important'
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: theme.palette.primary.contrastText
  }
}))
