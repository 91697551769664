import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { IndexingList } from 'api/models'

export const indexingRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<IndexingList>({
      url: `${BASE_URL}${API.GET_INDEXING}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
}
