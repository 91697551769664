import React, { Fragment, useCallback, useState } from 'react'
import { Link, TableCell, TableRow } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { ColumnData } from 'app/components/lists/deep-list.component'

interface IProps {
  index: number
  item: any
  rowData: {
    columns: ColumnData<any>[]
    children: { slug: string | number | symbol; columns: ColumnData<any>[] }
  }
}

export const DeepListItem = ({ index, item, rowData }: IProps): React.JSX.Element => {
  const [opened, setOpened] = useState(-1)

  const toggleDisplay = useCallback(
    (index: number) => {
      setOpened(opened === index ? -1 : index)
    },
    [opened, index]
  )

  return (
    <>
      <TableRow onClick={() => toggleDisplay(index)}>
        <TableCell width={16} size="small">
          {opened === index ? (
            <ArrowDropUp height={12} width={12}></ArrowDropUp>
          ) : (
            <ArrowDropDown height={12} width={12}></ArrowDropDown>
          )}
        </TableCell>
        {rowData.columns.map((column, index) => {
          return (
            <TableCell key={index} size="small" colSpan={column.colSpan}>
              {column.content
                ? column.content(item, String(column.slug))
                : column.format
                ? column.format(item[column.slug])
                : item[column.slug]}
            </TableCell>
          )
        })}
      </TableRow>
      {opened === index &&
        item[rowData.children.slug] &&
        item[rowData.children.slug].map((subItem: any, index2: number) => (
          <TableRow key={index2}>
            <TableCell size="small"></TableCell>
            {rowData.children.columns.map((column, index3) => {
              return (
                <TableCell key={index3} size="small">
                  {column.content
                    ? column.content(subItem, String(column.slug))
                    : column.format
                    ? column.format(subItem[column.slug])
                    : subItem[column.slug]}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
    </>
  )
}
