import { useTranslation } from 'react-i18next'
import { Grid, Paper as MuiPaper, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { EmailTemplateSheetType } from 'api/models'
import { TitleComponent } from 'app/components/titles/title.component'
import React from 'react'
import { Edit } from '@mui/icons-material'
import { Link } from 'app/components/link.component'

export const EmailTemplateDetails = ({
  emailTemplate
}: {
  emailTemplate: EmailTemplateSheetType
}): React.JSX.Element => {
  const { t } = useTranslation()

  return (
    <MuiPaper elevation={0} sx={{ padding: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack
          marginBottom="0.5rem"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <TitleComponent text={t('informations')} variant={'h3'} paddingTop={0} paddingLeft={12} />
          <Link to={`/emails/${emailTemplate.id}/edit`} style={{ lineHeight: '1em' }}>
            <Edit fontSize={'small'} />
          </Link>
        </Stack>
        <Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" width="50%" spacing={4}>
              <Typography variant="caption" sx={{ opacity: 0.85 }}>
                {t('language')}
              </Typography>
              <Typography variant="body2">{emailTemplate.lenguageLabel}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" width="50%" spacing={4}>
              <Typography variant="caption" sx={{ opacity: 0.85 }}>
                {t('reference')}
              </Typography>
              <Typography variant="body2">{emailTemplate.reference}</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Box>
    </MuiPaper>
  )
}
