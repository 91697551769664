import React, { useCallback, useEffect, useState } from 'react'
import { Stack } from '@mui/system'
import { Box, TableCell, Tooltip } from '@mui/material'
import { MoreHoriz, Remove } from '@mui/icons-material'
import { ServicePricePricer } from 'api/models'

export interface IProps {
  prices: ServicePricePricer[]
  showAllPrices: boolean
}
export const ServicePriceComponent = ({ prices, showAllPrices }: IProps): React.JSX.Element => {
  const [openSpan, setOpenSpan] = useState(false)
  const [orderedPrices, setOrderedPrices] = useState<ServicePricePricer[]>([])
  const [basePrice, setBasePrice] = useState<ServicePricePricer>()

  const spanToggle = useCallback(() => {
    setOpenSpan(!openSpan)
  }, [openSpan])

  useEffect(() => {
    if (prices && prices.length > 0) {
      let _basePrice = prices.find((p) => p.commitmentReference === 'COMMITMENT_BASE')
      if (!_basePrice) {
        _basePrice = prices[0]
      }
      setBasePrice(_basePrice)
      const _otherPrices = prices.filter((p) => p.commitmentReference !== 'COMMITMENT_BASE')
      setOrderedPrices([..._otherPrices])
    }
  }, [prices])

  return (
    <TableCell align="left" size="small">
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Tooltip placement="top" title={basePrice?.label}>
          <Box component={'span'}>
            {basePrice?.price.toFixed(0)}
            {'€ '}
          </Box>
        </Tooltip>
        {openSpan &&
          orderedPrices?.map((price, index) => {
            return (
              <Tooltip key={index} placement="top" title={price.label}>
                <Box component={'span'}>
                  {'-'}
                  {price.price.toFixed(0)}
                  {'€'}
                </Box>
              </Tooltip>
            )
          })}{' '}
        {showAllPrices &&
          orderedPrices?.length > 0 &&
          (openSpan ? (
            <Remove onClick={spanToggle} fontSize={'small'} />
          ) : (
            <MoreHoriz onClick={spanToggle} fontSize={'small'} />
          ))}
      </Stack>
    </TableCell>
  )
}
