import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import type { FormItemsInput, FormItems, FormItemsInputWithFilters } from 'api/models'

export const formItemsRouter = {
  list: async ({ token }: Ctx, filters: FormItemsInput) =>
    fetcher<FormItems>({
      url: `${BASE_URL}${API.GET_FORM_ITEMS}?references=${JSON.stringify(filters)}`,
      method: 'GET',
      token: token
    }),
  listWithFilters: async ({ token }: Ctx, data: FormItemsInputWithFilters) =>
    fetcher<FormItems>({
      url: `${BASE_URL}${API.GET_FORM_ITEMS}?references=${JSON.stringify(
        data.filters
      )}&filters=${JSON.stringify(data.references_filters)}`,
      method: 'GET',
      token: token
    })
}
