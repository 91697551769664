import { z } from 'zod'

const assetSchema = z.object({
  id: z.number(),
  url: z.string(),
  name: z.string(),
  tags: z.array(z.string())
})
export type Asset = z.infer<typeof assetSchema>
const assetsListSchema = z.array(assetSchema)
export type AssetsList = z.infer<typeof assetsListSchema>

const assetAddBodySchema = z.object({
  file: z.string()
})
export type AssetAddBody = z.infer<typeof assetAddBodySchema>
