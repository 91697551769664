import { FormProvider, UseFormReturn } from 'react-hook-form'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { FormItems } from 'api/models'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import ControlledForm, { FormInputProps } from 'app/components/form/controlled-form.component'
import { EventFormData } from 'api/models/forms/events'
const FormAddEvent = ({
  methods,
  options,
  defaultValue,
  isAdd
}: {
  methods: UseFormReturn<any>
  options: FormItems
  defaultValue: EventFormData
  isAdd?: boolean
}) => {
  const status = methods.watch('status')
  const { t } = useTranslation()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  let items = useMemo(() => {
    let items = [
      {
        type: 'select',
        name: 'type',
        label: t('type'),
        formItem: options.event_types,
        required: true,
        inputProps: {
          disabled: isDisabled
        }
      },
      {
        type: 'staffs',
        name: 'staff',
        label: t('member'),
        required: true,
        inputProps: {
          defaultValue: defaultValue.staffName,
          disabled: isDisabled
        }
      },
      {
        type: 'select',
        name: 'status',
        label: t('status'),
        formItem: options.event_status,
        required: true,
        inputProps: {
          disabled: isDisabled
        }
      },
      {
        type: 'datetimepicker',
        name: 'begin',
        label: t('begin_at'),
        required: true,
        inputProps: {
          disabled: isDisabled
        }
      },
      {
        type: 'datetimepicker',
        name: 'end',
        label: t('end_at'),
        required: true,
        inputProps: {
          disabled: isDisabled
        }
      },
      {
        type: 'clients',
        name: 'enterprise',
        label: t('enterprise'),
        inputProps: {
          defaultIsIndividual: false,
          defaultIsEnterprise: true,
          defaultValue: defaultValue.enterpriseName,
          disabled: isDisabled
        }
      },
      {
        type: 'clients',
        name: 'owner',
        label: t('contact'),
        inputProps: {
          defaultIsIndividual: true,
          defaultIsEnterprise: false,
          defaultValue: defaultValue.ownerName,
          disabled: isDisabled
        }
      },
      {
        type: 'select',
        name: 'opportunity',
        label: t('opportunity'),
        formItem: options.opportunities,
        inputProps: {
          disabled: isDisabled
        }
      },
      {
        type: 'centers',
        name: 'center',
        label: t('center'),
        required: true,
        inputProps: {
          disabled: isDisabled
        }
      },
      {
        type: 'textfield',
        name: 'comment',
        label: t('comment'),
        inputProps: { multiline: true, rows: 4, disabled: isDisabled }
      }
    ]

    if (status === '3') {
      items.splice(3, 0, {
        type: 'select',
        name: 'groundRefusal',
        label: t('reason_refusal'),
        formItem: options.ground_refusal,
        required: false,
        inputProps: {
          disabled: isDisabled
        }
      })
    }

    return items
  }, [defaultValue, status, methods, isDisabled])

  useEffect(() => {
    setIsDisabled(Number(defaultValue?.status) !== 1 && !isAdd)
  }, [defaultValue, isAdd])

  return (
    <FormProvider {...methods}>
      <Grid container columns={12} columnSpacing={4}>
        <ControlledForm methods={methods} items={items as FormInputProps[]} />
      </Grid>
    </FormProvider>
  )
}

export default FormAddEvent
