import { grey } from '@mui/material/colors'
import LogoDark from '../assets/Logo_horizontal-white.png'
import LoginLogo from '../assets/Logo_vertical-white.png'
import { custom0rangeStartWay, customBlueStartWay } from './common'
import Background from '../assets/platform-SaaS-StartWay.jpg'

export const darkTheme = {
  name: 'Dark',
  logo: {
    header: LogoDark,
    login: LoginLogo
  },
  mainBackground: Background,
  palette: {
    mode: 'dark',
    primary: {
      main: custom0rangeStartWay[800],
      light: custom0rangeStartWay[700],
      dark: custom0rangeStartWay[900],
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: customBlueStartWay[900],
      light: customBlueStartWay[500],
      dark: customBlueStartWay[600],
      contrastText: '#FFFFFF'
    },
    background: {
      default: grey[900],
      paper: '#2f2f2f'
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.95)',
      secondary: 'rgba(255, 255, 255, 0.5)'
    }
  },
  header: {
    color: grey[300],
    background: grey[900],
    borderBottom: '1px solid',
    borderColor: '#000',
    search: {
      color: grey[200]
    }
  },
  sidebar: {
    color: grey[300],
    background: '#040506',
    borderRight: '1px solid',
    borderColor: '#000',
    linkActive: {
      color: '#ffffff',
      background: custom0rangeStartWay[800]
    },
    linkActiveHover: {
      color: '#ffffff',
      background: custom0rangeStartWay[800]
    },
    linkOpen: {
      background: custom0rangeStartWay[800],
      color: '#ffffff'
    },
    linkOpenHover: {
      background: '#1B2635',
      color: '#ffffff'
    },
    header: {
      color: grey[300],
      background: '#040506',
      brand: {
        color: '#ffffff',
        size: {
          width: '150px',
          height: '39px'
        },
        scale: 2.5,
        marginTop: '30px'
      }
    }
  },
  footer: {
    color: grey[300],
    background: '#233044'
  },
  chip: {
    color: '#ffffff',
    background: customBlueStartWay[600]
  }
}
