import { TableCell } from '@mui/material'
import { CheckboxComponent } from 'app/components/checkboxes/checkbox.component'
import React, { useCallback } from 'react'
import { usePricer } from 'app/providers/pricer.provider'
import { NomadeAndDomiciliationPricer, OfficePricer } from 'api/models'

export interface IProps {
  type: string
  service: OfficePricer | NomadeAndDomiciliationPricer
}

export const ServiceCheckboxes = ({ type, service }: IProps): React.JSX.Element => {
  const { onSelectService, solutions } = usePricer()

  const isDisabled = useCallback(
    (reference: string): boolean => {
      switch (type) {
        case 'nomade':
          return !solutions.get(reference)!.canNomade
        case 'domiciliation':
          return !solutions.get(reference)!.canDomiciliation
        case 'coworking':
          return !solutions.get(reference)!.canCoworking
        case 'office':
          return !solutions.get(reference)!.canOffice
      }
      return false
    },
    [type, solutions, service]
  )

  const isChecked = useCallback(
    (reference: string): boolean => {
      let disabled = isDisabled(reference)
      switch (type) {
        case 'nomade':
          return (
            !disabled &&
            solutions.get(reference)!.nomades.length > 0 &&
            solutions.get(reference)!.nomades.find((_service) => _service.id === service.id) !==
              undefined
          )
        case 'domiciliation':
          return (
            !disabled &&
            solutions.get(reference)!.domiciliations.length > 0 &&
            solutions
              .get(reference)!
              .domiciliations.find((_service) => _service.id === service.id) !== undefined
          )
        case 'coworking':
          return (
            !disabled &&
            solutions.get(reference)!.coworking.length > 0 &&
            solutions.get(reference)!.coworking.find((_service) => _service.id === service.id) !==
              undefined
          )
        case 'office':
          return (
            !disabled &&
            solutions.get(reference)!.offices.length > 0 &&
            solutions.get(reference)!.offices.find((_service) => _service.id === service.id) !==
              undefined
          )
      }
      return false
    },
    [type, solutions, service]
  )

  return (
    <>
      {Array.from({ length: 4 }, (_, index) => index + 1).map((reference, index) => (
        <TableCell key={index} align="center" padding="checkbox" size="small">
          <CheckboxComponent
            disabled={isDisabled(`S${reference}`)}
            onChange={(event) =>
              onSelectService(event.target.checked, service, type, `S${reference}`)
            }
            checked={isChecked(`S${reference}`)}
          />
        </TableCell>
      ))}
    </>
  )
}
