import React, { useMemo, useState } from 'react'
import { Box, Card, CardContent, Grid, IconButton, Stack } from '@mui/material'
import { TitleComponent } from 'app/components/titles/title.component'
import { Add, Close } from '@mui/icons-material'
import { AddSsid } from './add-ssid'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { useTranslation } from 'react-i18next'
import type { EnterpriseInformation } from 'api/models'

export interface IProps {
  enterprise: EnterpriseInformation
  ssids: any
  isLoading: boolean
  refreshList: () => void
}

export const EnterpriseSsidAddForm = ({ enterprise, ssids, isLoading, refreshList }: IProps) => {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const map = useMemo(() => {
    const map = new Map()
    map.set('items', [
      { label: t('ssid'), value: 'name' },
      { label: t('center'), value: 'center' }
    ])
    map.set('data', ssids ?? [])
    return map
  }, [ssids])

  return (
    <Card>
      <CardContent>
        <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <TitleComponent text={t('ssids_list')} variant={'h3'} paddingLeft={12} />
            <IconButton
              size={'small'}
              color={'primary'}
              title={t('add_access')}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <Close /> : <Add />}
            </IconButton>
          </Stack>
        </Box>
        {isMenuOpen && <AddSsid enterprise={enterprise} onSave={refreshList} />}
        <Grid container columns={4}>
          <Grid item xs={4}>
            <ListSheetComponent data={map} isLoading={isLoading} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
