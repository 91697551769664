import React, { useCallback } from 'react'
import {
  FormControl as MuiFormControl,
  TableCell,
  OutlinedInput as MuiOutlinedInput,
  InputBaseComponentProps
} from '@mui/material'
import styled from '@emotion/styled'
import { NomadeAndDomiciliationPricer, OfficePricer } from 'api/models'
import { usePricer } from 'app/providers/pricer.provider'

const StyledOutlinedInputSmall = styled(MuiOutlinedInput)`
  .MuiInputBase-input {
    padding: 4px;
  }
`

const FormControl = styled(MuiFormControl)`
  background: ${(props) => props.theme.palette.background.paper};
  width: 50px;
`

export interface IProps {
  service: OfficePricer | NomadeAndDomiciliationPricer
  type: string
  index: number
}
export const ServiceOccupants = ({ service, type, index }: IProps): React.JSX.Element => {
  const minMax = useCallback((): InputBaseComponentProps | undefined => {
    if (service) {
      if (type === 'nomade') {
        return { min: 1 }
      }
      return { min: 1, max: service.capacity }
    }
    return undefined
  }, [type, service])

  const { updateOccupant } = usePricer()
  return (
    <TableCell align="center" size="small">
      <FormControl fullWidth size={'small'}>
        <StyledOutlinedInputSmall
          type={'number'}
          size={'small'}
          onChange={(event) => updateOccupant(parseInt(event.target.value), service, type, index)}
          value={service.persons}
          inputProps={minMax()}
        />
      </FormControl>
    </TableCell>
  )
}
