import i18n from 'i18next'
import detector, { DetectorOptions } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Backend, { HttpBackendOptions } from 'i18next-http-backend'

import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/de'
import 'dayjs/locale/ar'
import 'dayjs/locale/zh'
import 'dayjs/locale/tl-ph'
import 'dayjs/locale/hi'
import 'dayjs/locale/pt'
import 'dayjs/locale/ru'

type languageOptionsType = {
  [key: string]: {
    name: string
    icon: string
  }
}

const resources: languageOptionsType = {
  fr: {
    name: 'Français',
    icon: '/static/img/flags/fr.png'
  },
  en: {
    name: 'English',
    icon: '/static/img/flags/gb-nir.png'
  },
  es: {
    name: 'Español',
    icon: '/static/img/flags/es.png'
  },
  de: {
    name: 'Deutsch',
    icon: '/static/img/flags/de.png'
  },
  ar: {
    name: 'العربية',
    icon: '/static/img/flags/ae.png'
  },
  zh: {
    name: '中文, 汉语, 漢語',
    icon: '/static/img/flags/cn.png'
  },
  fil: {
    name: 'Filipino',
    icon: '/static/img/flags/ph.png'
  },
  hi: {
    name: 'हिन्दी',
    icon: '/static/img/flags/in.png'
  },
  pt: {
    name: 'Português',
    icon: '/static/img/flags/pt.png'
  },
  ru: {
    name: 'Русский',
    icon: '/static/img/flags/ru.png'
  }
}

const detectorOptions: DetectorOptions = {
  lookupCookie: 'app_lng',
  lookupLocalStorage: 'app_lng',
  lookupSessionStorage: 'app_lng',
  caches: ['localStorage'],
  convertDetectedLanguage: (language) => language.split('-')[0]
}

i18n
  .use(detector)
  .use(initReactI18next)
  .use(Backend)
  .init<HttpBackendOptions>({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    defaultNS: 'translations',
    fallbackNS: 'translations',
    detection: detectorOptions,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false
    }
  })
  .then()

export { resources as languageOptions }
