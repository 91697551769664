import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  InvoiceSum,
  type InvoiceList,
  InvoiceInformation,
  InvoicePdf,
  Activities
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const invoicesRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<InvoiceList>({
      url: `${BASE_URL}${API.GET_INVOICES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async ({ token }: Ctx, params: string) =>
    fetcher<InvoiceSum>({
      url: `${BASE_URL}${API.GET_INVOICE_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getInformation: async ({ token }: Ctx, id: number) =>
    fetcher<InvoiceInformation>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_INFORMATIONS, { id })}`,
      method: 'GET',
      token: token
    }),
  getPdf: async ({ token }: Ctx, id: number) =>
    fetcher<InvoicePdf>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_PDF, { id })}`,
      method: 'GET',
      token: token
    }),
  getActivities: async ({ token }: Ctx, id: number, type: string) =>
    fetcher<Activities>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_ACTIVITIES, { id })}${
        type ? `?type=${type}` : ''
      }`,
      method: 'GET',
      token: token
    }),
  patchRegeneratePdf: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_REGENERATE, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  patchAccount: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_ACCOUNT, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  patchUnAccount: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_UNACCOUNT, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  patchBlock: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_BLOCK, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  patchUnBlock: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_UNBLOCK, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  patchLitigation: async ({ token }: Ctx, id: number, data: { reference: string }) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_LITIGATION, { id: id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  postUnCollectible: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_UNCOLLECTIBLE, { id: id })}`,
      method: 'POST',
      token: token
    }),
  postManualReminder: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_MANUAL_REMINDER, { id: id })}`,
      method: 'POST',
      token: token
    }),
  getRows: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_ROWS, { id: id })}`,
      method: 'GET',
      token: token
    }),
  patchDiscount: async ({ token }: Ctx, id: number, data: any) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_DISCOUNT, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  patchLanguage: async ({ token }: Ctx, id: number, data: { language: number }) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_LANGUAGE, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  generatePartialContractInvoice: async ({ token }: Ctx, id: number, contractServices: number[]) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_CONTRACT_INVOICE_PARTIAL, { id: id })}`,
      method: 'POST',
      token: token,
      body: { contractServices }
    }),
  postManualBankDebit: async ({ token }: Ctx, id: number, data: string) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_MANUAL_BANK_DEBIT, { id })}`,
      method: 'POST',
      token: token,
      body: { chargeDate: data }
    }),
  addPayment: async ({ token }: Ctx, id: number, data: any) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_ADD_PAYMENT, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  linkPayment: async ({ token }: Ctx, id: number, payment: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_LINK_PAYMENT, { id, payment })}`,
      method: 'PATCH',
      token: token
    }),
  unLinkPayment: async ({ token }: Ctx, id: number, payment: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_UNLINK_PAYMENT, { id, payment })}`,
      method: 'PATCH',
      token: token
    }),
  unLinkCredit: async ({ token }: Ctx, id: number, credit: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.DELETE_INVOICES_UNLINK_CREDIT, { id, credit })}`,
      method: 'DELETE',
      token: token
    }),
  cancelledGc: async ({ token }: Ctx, id: number, payment: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_CANCELLED_PAYMENT, { id, payment })}`,
      method: 'PATCH',
      token: token
    }),
  getPayments: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_PAYMENTS, { id })}`,
      method: 'GET',
      token: token
    }),
  getCredits: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_INVOICES_CREDITS, { id })}`,
      method: 'GET',
      token: token
    }),
  patchDueDate: async ({ token }: Ctx, id: number, data: { dueDate: string }) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INVOICES_DUE_DATE, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    })
}
