import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'
import { ListSkeleton } from 'app/components/skeletons/list.skeleton'
import { Fragment, useState } from 'react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { DeepListItem } from './deep-list-item'

export type ColumnData<T> = {
  slug: keyof T
  colSpan?: number
  format?: Function
  content?: Function
}

interface IProps<T> {
  isLoading: boolean
  headers: string[]
  data: T[]
  rows: [
    {
      columns: ColumnData<T>[]
      children: {
        slug: keyof T
        columns: ColumnData<any>[]
      }
    }
  ]
}

export const DeepListComponent = ({ isLoading, headers, rows, data }: IProps<any>) => {
  if (isLoading) return <ListSkeleton />

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 650 }}>
        <TableHead sx={{ fontWeight: 'bold' }}>
          <TableRow>
            <TableCell padding="checkbox" size="small"></TableCell>
            {headers.map((item: any, index: number) => (
              <TableCell key={index}>{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) =>
            rows.map((rowData, index) => (
              <DeepListItem key={index} index={index} item={item} rowData={rowData} />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
