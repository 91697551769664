import { type Ctx, fetcher, uploader } from 'api'
import { BASE_URL, API } from 'api/constants'
import { AssetsList } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const assetsRouter = {
  list: async ({ token }: Ctx, type: string, id: string) =>
    fetcher<AssetsList>({
      url: `${BASE_URL}${urlHelper(API.GET_ASSETS, { type, id })}`,
      method: 'GET',
      token: token
    }),
  delete: async ({ token }: Ctx, type: string, id: string, imageId: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.DELETE_ASSET, { type, id, imageId })}`,
      method: 'DELETE',
      token: token
    }),
  add: async ({ token }: Ctx, id: string, type: string, formData: FormData) =>
    uploader<void>({
      url: `${BASE_URL}${urlHelper(API.ADD_ASSET, { id, type })}`,
      method: 'POST',
      token: token,
      body: formData
    })
}
