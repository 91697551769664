import { z } from 'zod'

const optionSchema = z.object({
  id: z.string(),
  label: z.string(),
  type: z.string(),
  price: z.string(),
  freePrice: z.string(),
  fees: z.number(),
  freeFees: z.string(),
  begin: z.string(),
  end: z.string()
})

export type Option = z.infer<typeof optionSchema>

const optionListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(optionSchema)
})

export type OptionList = z.infer<typeof optionListSchema>

const contractOptionSchema = z.object({
  begin: z.string(),
  center: z.string(),
  client: z.string(),
  clientId: z.number(),
  clientType: z.string(),
  contractId: z.number(),
  contractReference: z.string(),
  contractType: z.string(),
  contractTypeLabel: z.string(),
  end: z.string(),
  label: z.string(),
  price: z.number(),
  quantity: z.number(),
  type: z.string()
})

export type ContractsOption = z.infer<typeof contractOptionSchema>

const contractOptionListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(contractOptionSchema)
})

export type ContractsOptionsList = z.infer<typeof contractOptionListSchema>

const contractOptionsSumSchema = z.object({
  totalAmount: z.number()
})

export type ContractsOptionsSum = z.infer<typeof contractOptionsSumSchema>
