import * as React from 'react'
import Box from '@mui/material/Box'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import DeleteIcon from '@mui/icons-material/Delete'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/system'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { LoadingButton } from '@mui/lab'

export interface ImageCarousel {
  id: number
  url: string
}

interface CarouselProps {
  entityId: string
  entityType: string
  onDelete?: (id: number) => void
  isDeleteLoading?: boolean
  readonly?: boolean
  height?: number
  maxWidth?: number
}

const CarouselContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'height' && prop !== 'maxWidth'
})<{ height: number; maxWidth: number }>(({ height, maxWidth }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: maxWidth,
  height: height,
  overflow: 'hidden',
  margin: 'auto'
}))

const ArrowButton = styled(Button)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  minWidth: 'unset',
  padding: 5,
  borderRadius: 99
})

interface IndicatorDotProps {
  active: boolean
}

const IndicatorDot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active'
})<IndicatorDotProps>(({ theme, active }) => ({
  width: 10,
  height: 10,
  borderRadius: 99,
  backgroundColor: active ? theme.palette.primary.main : '#888',
  cursor: 'pointer'
}))

const IndicatorImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'active'
})<{ active: boolean }>(({ theme, active }) => ({
  height: 100,
  aspectRatio: '1/1',
  objectFit: 'cover',
  cursor: 'pointer',
  border: `2px solid ${active ? theme.palette.primary.main : theme.palette.grey[300]}`,
  borderRadius: 4
}))

const ImageContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative'
})

const StyledImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain'
})

const DeleteButton = styled(LoadingButton)({
  position: 'absolute',
  top: '10px',
  right: '10px'
})

const Carousel: React.FC<CarouselProps> = ({
  entityId,
  entityType,
  onDelete,
  isDeleteLoading,
  readonly,
  height = 455,
  maxWidth = 910
}) => {
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)
  const { useGetAssets } = useFetcher()
  const { data: images } = useGetAssets({ id: entityId, type: entityType })

  const handleNext = () => {
    if (!images) return
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
  }

  const handlePrev = () => {
    if (!images) return
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)
  }

  useEffect(() => {
    if (!images) return
    if (images.indexOf(images[currentIndex]) === -1 && currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }, [images, currentIndex, setCurrentIndex])

  return (
    <Stack padding={3} data-cy="images-carousel">
      <CarouselContainer height={height} maxWidth={maxWidth}>
        {(images ?? []).map((image, index) => (
          <Fade
            key={image.id}
            in={index === currentIndex}
            timeout={300}
            appear={false}
            unmountOnExit
            mountOnEnter
          >
            <ImageContainer>
              {!readonly && (
                <DeleteButton
                  startIcon={<DeleteIcon />}
                  variant="contained"
                  color="error"
                  loading={isDeleteLoading}
                  onClick={() => onDelete && onDelete(image.id)}
                  data-cy="delete-button"
                >
                  {t('delete')}
                </DeleteButton>
              )}
              <StyledImage src={image.url} alt={`Slide ${index + 1}`} />
            </ImageContainer>
          </Fade>
        ))}
        <ArrowButton
          onClick={handlePrev}
          variant="contained"
          sx={{ left: '10px' }}
          data-cy="prev-button"
        >
          <NavigateBeforeIcon />
        </ArrowButton>
        <ArrowButton
          onClick={handleNext}
          variant="contained"
          sx={{ right: '10px' }}
          data-cy="next-button"
        >
          <NavigateNextIcon />
        </ArrowButton>
      </CarouselContainer>
      <Stack
        direction="row"
        gap={3}
        marginTop={4}
        marginBottom={1}
        flexWrap="wrap"
        marginInline="auto"
        width="fit-content"
      >
        {(images ?? []).map((image, index) =>
          readonly ? (
            <IndicatorDot
              key={index}
              active={index === currentIndex}
              onClick={() => setCurrentIndex(index)}
              data-cy="indicator-dot"
            />
          ) : (
            <IndicatorImage
              key={image.id}
              src={image.url}
              alt={`Slide ${image.id}`}
              active={index === currentIndex}
              onClick={() => setCurrentIndex(index)}
              data-cy="indicator-image"
            />
          )
        )}
      </Stack>
    </Stack>
  )
}

export default Carousel
