import React, { useCallback, useEffect, useState } from 'react'
import { Button, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { FormCard } from 'app/components/form/form-card.component'
import { IdLabelItems } from 'api/models/commons'
import { postCompanySchema, PostCompany } from 'api/models'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'

export const CompanyAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [items, setItems] = useState<IdLabelItems>([])
  const { handleMutation, toast } = useFeedback()
  const { availableCentersCompany, postCompany } = useFetcher()
  const methods = useForm<PostCompany>({
    mode: 'onChange',
    defaultValues: { centers: [] },
    resolver: zodResolver(postCompanySchema)
  })
  const { isDirty, isValid } = methods.formState

  const fetchItems = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: availableCentersCompany,
      onSuccess: (data) => {
        setIsLoading(true)
        setItems(data)
        setIsLoading(false)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [availableCentersCompany, handleMutation])

  const handleSubmit = useCallback(
    async (data: PostCompany) => {
      await handleMutation({
        confirm: { content: t('confirm_create_company') },
        mutation: postCompany,
        data: data,
        onSuccess: () => {
          navigate(`/companies`)
        },
        onError: (error) => {
          if (error.cause === 400) {
            toast({ message: error.message, variant: 'error' })
          }
        }
      })
    },
    [postCompany, toast, handleMutation, navigate, t]
  )

  useEffect(() => {
    fetchItems().then()
  }, [])

  return (
    <Container>
      <Grid container marginBottom="2rem">
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">{t('add_company')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <FormCard
            isLoading={isLoading}
            title={t('informations')}
            control={methods.control}
            itemXS={6}
            items={[
              {
                type: 'textfield',
                label: t('reference'),
                name: 'reference',
                required: true
              },
              {
                type: 'textfield',
                label: t('company_name'),
                name: 'companyName',
                required: true
              },
              { type: 'textfield', label: t('address'), name: 'address', required: true },
              { type: 'textfield', label: t('zipcode'), name: 'zipCode', required: true },
              { type: 'textfield', label: t('city'), name: 'city', required: true },
              { type: 'textfield', label: t('country'), name: 'country', required: true },
              { type: 'textfield', label: t('siret'), name: 'siret', required: true },
              { type: 'textfield', label: t('vat'), name: 'vat', required: true }
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCard
            isLoading={isLoading}
            title={t('centers')}
            control={methods.control}
            items={[
              {
                type: 'multiselect',
                label: t('centers'),
                name: 'centers',
                xs: 6,
                inputProps: {
                  items: items,
                  labelSlug: 'label',
                  valueSlug: 'id'
                }
              }
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCard
            isLoading={isLoading}
            title={t('configuration')}
            control={methods.control}
            items={[
              {
                type: 'textfield',
                label: `GoCardless - ${t('max_amount_direct_debit')}`,
                name: 'maxAmountDirectDebit',
                xs: 12
              },
              {
                type: 'textfield',
                label: `GoCardless - Access token`,
                name: 'gcAccessToken',
                xs: 12
              },
              {
                type: 'textfield',
                label: `Stripe - Public key`,
                name: 'stripePublicKey',
                xs: 12
              },
              {
                type: 'textfield',
                label: `Stripe - Secret key`,
                name: 'stripeSecretKey',
                xs: 12
              }
            ]}
          />
        </Grid>
        <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
          <Grid item xs={6} textAlign={'right'}>
            <Button variant={'outlined'} color={'primary'} onClick={() => navigate(`/companies`)}>
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item xs={6} textAlign={'left'}>
            <Button
              disabled={!isDirty && !isValid}
              variant={'contained'}
              color={'primary'}
              onClick={methods.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
