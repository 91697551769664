import { TitleComponent } from 'app/components/titles/title.component'
import { Checkbox, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { MainService, FormItemValue, FormItems } from 'api/models'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'

interface IProps {
  mainService: MainService
  methods: UseFormReturn<any>
  options: FormItems
}

export default function MainInformationsComponent({ methods, mainService, options }: IProps) {
  const { t } = useTranslation()

  return (
    <>
      <TitleComponent
        text={t('main_informations')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={4}
      />
      <Grid container spacing={5} marginBottom={5}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('type')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            {options.main_services_types && options.main_services_types.values.length > 0 ? (
              <Select
                fullWidth
                size={'small'}
                {...methods.register('serviceType')}
                value={methods.getValues().serviceType ?? ''}
              >
                {options.main_services_types.values.map((item: FormItemValue) => (
                  <MenuItem key={item.id} value={Number(item.id)}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <TextField
                disabled
                fullWidth
                size={'small'}
                variant="outlined"
                defaultValue={mainService.serviceTypeLabel}
                InputProps={{
                  readOnly: true
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('center')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            {options.centers && options.centers.values.length > 0 ? (
              <Select
                fullWidth
                size={'small'}
                {...methods.register('center')}
                value={methods.getValues().center ?? ''}
              >
                {options.centers.values.map(
                  (center) =>
                    Number(center.id) !== 0 && (
                      <MenuItem key={center.id} value={Number(center.id)}>
                        {center.label}
                      </MenuItem>
                    )
                )}
              </Select>
            ) : (
              <TextField
                disabled
                fullWidth
                size={'small'}
                variant="outlined"
                defaultValue={mainService.centerName}
                InputProps={{
                  readOnly: true
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('surface')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              type="number"
              InputProps={{
                inputProps: {
                  step: 0.01
                }
              }}
              {...methods.register('surface')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('capacity')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              type="number"
              defaultValue={mainService.maxCapacity}
              {...methods.register('maxCapacity')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('typology')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            {options.main_services_typologies &&
              options.main_services_typologies.values.length > 0 && (
                <Select
                  fullWidth
                  size={'small'}
                  {...methods.register('typology')}
                  value={methods.getValues().typology ?? ''}
                >
                  <MenuItem value={''}>{''}</MenuItem>
                  {options.main_services_typologies.values.map(
                    (typology) =>
                      Number(typology.id) !== 0 && (
                        <MenuItem key={typology.id} value={Number(typology.id)}>
                          {typology.label}
                        </MenuItem>
                      )
                  )}
                </Select>
              )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('floor')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              type="number"
              defaultValue={mainService.floor}
              {...methods.register('floor')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('begin')} *</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <ControlledDatePicker name={'begin'} control={methods.control} />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('end')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <ControlledDatePicker name={'end'} control={methods.control} />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('description')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              variant="outlined"
              defaultValue={mainService.description}
              multiline
              rows={2}
              {...methods.register('description')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('available_online')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Checkbox
              defaultChecked={mainService.isOnline}
              size={'small'}
              {...methods.register('isOnline')}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
