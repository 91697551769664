import React from 'react'
import { Typography } from '@mui/material'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UnloggedWrapper from 'app/components/UnloggedWrapper'
import { useApp } from 'app/providers/app.provider'
import ForgottenPasswordForm from 'modules/auth/components/ForgottenPasswordForm'

export const ForgottenPasswordView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()

  if (user) {
    return <Navigate to="/" />
  }

  return (
    <UnloggedWrapper>
      <Typography
        data-cy="forgot-password-component"
        variant="h1"
        align="center"
        typography={'h1'}
        gutterBottom
      ></Typography>
      <Typography variant="h4" align="center" typography={'h4'} gutterBottom marginBottom={12}>
        {t('forgotten_password')}
      </Typography>
      <Typography align="center" marginBottom={4}>
        {t('forgotten_password_info')}
      </Typography>
      <ForgottenPasswordForm />
    </UnloggedWrapper>
  )
}
