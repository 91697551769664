import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useList } from 'app/providers/list.provider'
import { MatchingTransfersExceptionsList } from 'api/models'
import { formatDateWithTime } from 'app/utils/format'
import { ListPagination } from 'app/components/lists/list-pagination'

export const MatchingTransfersExceptionView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getMatchingTransfersExceptions } = useFetcher()
  const { handleMutation } = useFeedback()

  const {
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initSort,
    handleSort,
    handleFilter
  } = useList()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [transfersExceptions, setTransfersExceptions] = useState<MatchingTransfersExceptionsList>()

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getMatchingTransfersExceptions,
      onSuccess: (data) => {
        setIsLast(data.last)
        setOffset(data.actual)
        setTotal(data.total)
        setTransfersExceptions(data)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [getMatchingTransfersExceptions, setIsLast, setOffset, setTotal, setIsLoading, handleMutation])

  useEffect(() => {
    initSort([{ property: 'id', order: 'asc' }]).then(() => refreshList())
  }, [])

  return (
    <>
      <Container>
        <Box marginBottom="1rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('transfers_exceptions_list')}
          </Typography>
        </Box>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom>
            {transfersExceptions?.items.length} / {total}
          </Typography>
        </Grid>
        <List
          items={transfersExceptions?.items || []}
          columns={[
            { label: t('ID'), slug: 'id' },
            { label: t('created_at'), slug: 'createdAt', valueFormatter: formatDateWithTime },
            { label: t('description'), slug: 'caption' }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={isLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {transfersExceptions?.items.length ?? 0} / {total}
          </Typography>
        </Grid>
        {!isLast && !isLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Container>
    </>
  )
}
