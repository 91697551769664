import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { Litigation, LitigationItem, type LitigationList } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const litigationsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<LitigationList>({
      url: `${BASE_URL}${API.GET_LITIGATIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async ({ token }: Ctx, id: number) =>
    fetcher<Litigation>({
      url: `${BASE_URL}${urlHelper(API.GET_LITIGATION, { id })}`,
      method: 'GET',
      token: token
    }),
  getContracts: async ({ token }: Ctx, id: number) =>
    fetcher<LitigationItem>({
      url: `${BASE_URL}${urlHelper(API.GET_LITIGATION_CONTRACTS, { id })}`,
      method: 'GET',
      token: token
    }),
  getInvoices: async ({ token }: Ctx, id: number) =>
    fetcher<LitigationItem>({
      url: `${BASE_URL}${urlHelper(API.GET_LITIGATION_INVOICES, { id })}`,
      method: 'GET',
      token: token
    }),
  update: async ({ token }: Ctx, id: number, reference: string) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_LITIGATION, { id: id })}`,
      method: 'PATCH',
      token: token,
      body: { reference }
    }),
  close: async ({ token }: Ctx, id: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.CLOSE_LITIGATION, { id: id })}`,
      method: 'PATCH',
      token: token
    })
}
