import React from 'react'
import { Box, IconButton as MuiIconButton } from '@mui/material'
import { Download } from '@mui/icons-material'
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { OpenFile, Viewer } from '@react-pdf-viewer/core'
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

const DownloadIcon = styled(Download)`
  background-color: transparent;
`
interface PDFViewer {
  pdf: string
  name?: string
}

const getFileName = (url: string): string => {
  const str = url.split('/').pop()
  return str ? str.split('#')[0].split('?')[0] : url
}

export function PDFViewer({ pdf, name }: PDFViewer) {
  const { t } = useTranslation()
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file: OpenFile) => {
      return name ? name : file.name ? getFileName(file.name) : 'document.pdf'
    }
  })
  const { Download } = getFilePluginInstance
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', zIndex: 1, top: 2, right: 2 }}>
        <Download>
          {(props: RenderDownloadProps) => (
            <IconButton onClick={props.onClick} color="secondary" size="large">
              <DownloadIcon titleAccess={t('download')} />
            </IconButton>
          )}
        </Download>
      </Box>
      <Viewer fileUrl={pdf} plugins={[getFilePluginInstance]} />
    </Box>
  )
}
