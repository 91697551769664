import { Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'

const BankAccountComponent = ({ bankAccount }: any) => {
  const { t } = useTranslation()

  return (
    <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
      <Typography variant="h3" marginBottom="1rem" color="primary">
        {t('bank_account')}
      </Typography>
      <Grid container rowSpacing={5}>
        <Grid item container spacing={9}>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('holder_name')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>{bankAccount.accountHolderName}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('country_code')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>{bankAccount.countryCode}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={9}>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('bank_name')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>{bankAccount.bankName}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('currency')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>{bankAccount.currency}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={9}>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('bank_account_last_numbers')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>• • • • • • {bankAccount.accountNumberEnding}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('state')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>{bankAccount.stateLabel}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default BankAccountComponent
