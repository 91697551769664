import {
  Card,
  CardContent,
  Typography,
  useTheme,
  Table,
  TableCell,
  TableRow,
  TableBody
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import dayjs from 'dayjs'
import { DashboardNextEntries } from 'api/models/dashboard'

interface IDashboardCardNextEntries {
  nextEntries: DashboardNextEntries
  isLoading: boolean
}
export function DashboardCardNextEntries({ nextEntries, isLoading }: IDashboardCardNextEntries) {
  const theme = useTheme()
  if (isLoading) {
    return <CardSkeleton height={160}></CardSkeleton>
  }
  return (
    <Card sx={{ minHeight: 'calc(100% - 35px)' }}>
      <CardContent>
        <Table size="small">
          <TableBody>
            {nextEntries.map((entries: any, index: number) => (
              <TableRow hover key={index}>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  <RouterLink
                    to={`contracts/${entries.contractId}`}
                    style={{ textDecoration: 'none', color: theme.palette.primary.light }}
                  >
                    {entries.reference}
                  </RouterLink>
                </TableCell>
                <TableCell>
                  <RouterLink
                    to={`enterprises/${entries.enterpriseId}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography component={'span'} variant={'body1'}>
                      {entries.enterpriseName}
                    </Typography>
                  </RouterLink>
                </TableCell>
                <TableCell>
                  <Typography component={'span'} variant={'body1'} sx={{ opacity: 0.75 }}>
                    {dayjs(entries.begin).format('L')}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
