import React, { useCallback, useMemo } from 'react'
import { Box, Button, Grid, IconButton, Skeleton, Tooltip, Typography } from '@mui/material'
import { Container, Stack, styled } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { formatCurrency, formatDateWithTime } from 'app/utils/format'
import InfoCard from 'app/components/card/info-card.component'
import { Link } from 'app/components/link.component'
import { Edit, InfoOutlined, KeyboardBackspace } from '@mui/icons-material'
import { PaymentWarningZone } from 'modules/payments/components/payment-warning-zone.component'
import { PaymentUpdateStatusModal } from 'modules/payments/components/payment-update-status-modal.component'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { useApp } from 'app/providers/app.provider'

export const PaymentView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { useGetPayment } = useFetcher()
  const { data: payment, isPending } = useGetPayment(Number(id))
  const { user } = useApp()

  const updateStatusModalRef = React.useRef<DialogRef>(null)
  const handleUpdateStatus = useCallback(() => {
    updateStatusModalRef.current?.open()
  }, [updateStatusModalRef])

  const { info } = useMemo(() => {
    if (!payment) return { info: [] }

    const isPaymentAutomatic = payment.paymentMode == 3 || payment.paymentMode == 4
    const canEditStatus = user?.rights.payment.isEdit && isPaymentAutomatic

    const info = [
      {
        label: t('payment_type'),
        value: payment.paymentTypeLabel
      },
      {
        label: t('payment_state'),
        value: (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography fontWeight="bold">{payment.stateLabel}</Typography>
            <IconButton
              color="primary"
              size="small"
              onClick={handleUpdateStatus}
              disabled={!canEditStatus}
            >
              <Edit fontSize="small" />
            </IconButton>
            {!isPaymentAutomatic && (
              <Tooltip title={t('cant_change_manual_payment_status')} placement="right" arrow>
                <InfoOutlined />
              </Tooltip>
            )}
          </Stack>
        )
      },
      {
        label: t('amount'),
        value: formatCurrency(Number(payment.amount))
      },
      {
        label: t('payment_mode_label'),
        value: payment.paymentModeLabel
      },
      {
        label: t('center'),
        value: payment.center
      },
      {
        label: t('created_at'),
        value: payment.created ? formatDateWithTime(payment.created) : ''
      },
      {
        label: t('paid_at'),
        value: payment.paid ? formatDateWithTime(payment.paid) : ''
      },
      {
        label: t('accounting_document'),
        value: (
          <Link to={`/${payment.accountingDocumentType}s/${payment.accountingDocumentId}`}>
            {payment.accountingDocument}
          </Link>
        )
      },
      {
        label: t('client'),
        value: <Link to={`/${payment.clientType}s/${payment.clientId}`}>{payment.client}</Link>
      },
      {
        label: t('contract'),
        value: <Link to={`/contracts/${payment.contractId}`}>{payment.contract}</Link>
      }
    ]
    return { info }
  }, [payment, t])

  if (!isPending && !payment) return <div>{t('payment_not_found')}</div>

  return (
    <Container>
      <Box sx={{ mb: 2 }}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Link to={`/payments`} style={{ lineHeight: '1em' }}>
            <ContainedIconButton size="small" variant="contained">
              <KeyboardBackspace fontSize="small" />
            </ContainedIconButton>
          </Link>
          <Box>
            <Typography variant="h3">{t('payment')}</Typography>
            <Typography variant="subtitle1" sx={{ marginTop: '-4px!important' }}>
              {payment?.reference}
              {isPending && <Skeleton width={200} height={26} />}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InfoCard title={t('informations')} columns={info} isLoading={isPending} />
          <PaymentWarningZone payment={payment} isLoading={isPending} />
        </Grid>
      </Grid>
      {payment && <PaymentUpdateStatusModal modalRef={updateStatusModalRef} payment={payment} />}
    </Container>
  )
}

const ContainedIconButton = styled(Button)`
  border-radius: 99px;
  aspect-ratio: 1;
  padding: 5px;
  min-width: auto;
`
