import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HappeningsDetails } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { TabPanel } from 'app/components/tab-panel.component'
import { Link } from 'app/components/link.component'
import { HappeningDetailsComponent } from '../components/happening-details.component'
import { ArrowBack } from '@mui/icons-material'
import { Container, Grid, IconButton, Stack, Typography } from '@mui/material'

export const HappeningDetailsView = () => {
  const { t } = useTranslation()
  const { getHappeningsDetails } = useFetcher()
  const [happeningsDetails, setHappening] = useState({} as HappeningsDetails)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { id } = useParams()

  const refreshList = useCallback(async () => {
    if (!id) return
    setListIsLoading(true)
    getHappeningsDetails
      .mutateAsync(Number(id))
      .then((data) => {
        setHappening(data)
      })
      .finally(() => {
        setListIsLoading(false)
      })
  }, [id])

  useEffect(() => {
    refreshList().then()
  }, [])

  const navigate = useNavigate()

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            mb={6}
          >
            <Typography variant="h1" pb={5}>
              <IconButton
                size="small"
                onClick={() => navigate(`/happenings`)}
                style={{ padding: '0 5px 5px 0' }}
              >
                <ArrowBack />
              </IconButton>
              {t('evenementiel')} |{' '}
              <Link to={`/happenings/${happeningsDetails.id}`}>{happeningsDetails.name}</Link>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <TabPanel value={0} index={0}>
        <HappeningDetailsComponent isLoading={listIsLoading} happening={happeningsDetails} />
      </TabPanel>
    </Container>
  )
}
