import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { centerUpdateLocalisationInput, FormItems } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { CenterUpdateContainer } from 'modules/centers/components/center-update-container.component'

export const CenterLocationEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { getFormItems } = useFetcher()
  const [formItems, setFormItems] = useState<FormItems>({})

  useEffect(() => {
    handleMutation({
      mutation: getFormItems,
      data: ['countries'],
      onSuccess: (data) => {
        if (data.countries) {
          data.countries.values = data.countries.values.map((item) => ({
            id: item.label,
            label: item.label
          }))
        }
        setFormItems(data)
      }
    }).then()
  }, [])

  return (
    <CenterUpdateContainer
      title={'location'}
      items={[
        {
          type: 'textfield',
          label: t('address'),
          name: 'address'
        },
        {
          type: 'textfield',
          label: t('zipcode'),
          name: 'zipcode'
        },
        {
          type: 'textfield',
          label: t('region'),
          name: 'region'
        },
        {
          type: 'textfield',
          label: t('city'),
          name: 'city'
        },
        {
          type: 'select',
          label: t('country'),
          name: 'country',
          formItem: formItems.countries
        },
        {
          type: 'textfield',
          label: t('latitude'),
          name: 'latitude'
        },
        {
          type: 'textfield',
          label: t('longitude'),
          name: 'longitude'
        }
      ]}
      resolver={centerUpdateLocalisationInput}
    />
  )
}
