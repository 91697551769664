import React from 'react'
import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import DefaultImageMember from 'app/assets/default-member.jpg'
import { styled } from '@mui/system'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { CenterStaff } from 'api/models'
import { formatFullName } from 'app/utils/format'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""'
    }
  }
}))
interface ICenterStaffCardCardProps {
  staff: CenterStaff
}

export const CenterStaffCard = ({ staff }: ICenterStaffCardCardProps) => {
  return (
    <Grid item xs={6} sm={6} md={6} lg={3}>
      <Card>
        <CardHeader
          avatar={
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot" // Todo tag for different company
            >
              <Avatar
                alt={formatFullName(staff.firstname, staff.lastname ?? '')}
                src={DefaultImageMember}
                sx={{ width: 56, height: 56 }}
              />
            </StyledBadge>
          }
          title={
            <React.Fragment>
              <Typography component="div" variant="h4">
                {formatFullName(staff.firstname, staff.lastname ?? '')}
              </Typography>
              <Typography component="div" variant="caption">
                {staff.roleLabel}
              </Typography>
            </React.Fragment>
          }
        ></CardHeader>
        <Divider></Divider>
        <CardContent>
          <List disablePadding={true} dense>
            <ListItem disableGutters>
              <ListItemIcon sx={{ minWidth: 35 }}>
                <PhoneAndroidIcon color={'primary'} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography component="span" variant="body2">
                      <Link href={`tel:${staff.phone}`} color={'text.primary'}>
                        {staff.phone}
                      </Link>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon sx={{ minWidth: 35 }}>
                <MailOutlineIcon color={'primary'} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography component="span" variant="body2">
                      <Link href={`mailto:${staff.email}`} color={'text.primary'}>
                        {staff.email}
                      </Link>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Grid>
  )
}
