const base64ConverterHelper = () => {
  const base64ToArrayBuffer = (base64: string) => {
    const binaryString = window.atob(base64)
    const binaryLen = binaryString.length
    const bytes = new Uint8Array(binaryLen)
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes
  }

  const base64ToBlob = (base64: string, type = 'image/jpeg') => {
    const bytes = base64ToArrayBuffer(base64)
    return new Blob([bytes], { type })
  }

  const base64ToFile = (base64: string, filename: string, type = 'image/jpeg') => {
    const blob = base64ToBlob(base64, type)
    return new File([blob], filename, { type })
  }

  const base64ToImage = (base64: string, type = 'image/jpeg') => {
    const blob = base64ToBlob(base64, type)
    return URL.createObjectURL(blob)
  }

  return {
    base64ToArrayBuffer,
    base64ToBlob,
    base64ToFile,
    base64ToImage
  }
}

export default base64ConverterHelper
