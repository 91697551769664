import { MenuItemsTypes } from 'app/constants/navbar'
import reduceChildRoutes from './reduce-child-routes.component'
import { useApp } from 'app/providers/app.provider'
import { useLocation, matchPath } from 'react-router-dom'
import { useState } from 'react'

type SidebarNavListProps = {
  depth: number
  pages: MenuItemsTypes[]
  title: string
}

export function SidebarNavList(props: SidebarNavListProps) {
  const { pages, depth } = props
  const { user } = useApp()
  const router = useLocation()
  const currentRoute = router.pathname
  const [activeIndex, setActiveIndex] = useState(
    pages.findIndex((page) => {
      return (
        depth == 0 &&
        page.children &&
        page.children?.findIndex(
          (c) =>
            c.href &&
            !!matchPath(
              {
                path: c.href,
                end: false
              },
              currentRoute
            )
        ) > -1
      )
    })
  )

  const handleToggle = (index: number) => {
    setActiveIndex(activeIndex == index ? -1 : index)
  }

  const childRoutes = pages.reduce(
    (items, page, currentIndex) =>
      reduceChildRoutes({
        items,
        page,
        currentRoute,
        depth,
        permissions: user!.rights,
        activeIndex,
        index: currentIndex,
        handleToggle
      }),
    [] as JSX.Element[]
  )

  return <>{childRoutes}</>
}
