import { type Ctx, fetcher, formatParams, uploader } from 'api'
import { BASE_URL, API } from 'api/constants'
import type {
  DuplicateEnterprisesList,
  EnterprisesListType,
  EnterpriseContractsList,
  EnterpriseInformation,
  EnterpriseMembersList,
  EnterpriseOpportunitiesList,
  EventsList,
  EnterpriseDocumentsList
} from 'api/models'
import { OpportunityCreateFormData } from 'api/models/forms/opportunities'
import {
  IdReturn,
  EnterpriseDocumentsListParams,
  IndividualFormDetails,
  LinkIndividualProps
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'
import { CreateEnterpriseForm } from 'api/models/forms/enterprises'

export const enterprisesRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<EnterprisesListType>({
      url: `${BASE_URL}${API.GET_ENTERPRISES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async ({ token }: Ctx, id: string) =>
    fetcher<EnterpriseInformation>({
      url: `${BASE_URL}${API.GET_ENTERPRISES}/${id}`,
      method: 'GET',
      token: token
    }),
  listEvents: async ({ token }: Ctx, id: string) =>
    fetcher<EventsList>({
      url: `${BASE_URL}${urlHelper(API.GET_ENTERPRISE_EVENTS, { id })}`,
      method: 'GET',
      token: token
    }),
  listDocuments: async ({ token }: Ctx, params: EnterpriseDocumentsListParams) =>
    fetcher<EnterpriseDocumentsList>({
      url: `${BASE_URL}${urlHelper(API.GET_ENTERPRISE_DOCUMENTS, { id: params.id })}?invalidated=${
        params.invalidated
      }`,
      method: 'GET',
      token: token
    }),
  listMembers: async ({ token }: Ctx, id: string) =>
    fetcher<EnterpriseMembersList>({
      url: `${BASE_URL}${urlHelper(API.GET_ENTERPRISE_MEMBERS, { id })}`,
      method: 'GET',
      token: token
    }),
  listSsids: async ({ token }: Ctx, id: string) =>
    fetcher<EnterpriseMembersList>({
      url: `${BASE_URL}${urlHelper(API.GET_ENTERPRISE_SSIDS, { id })}`,
      method: 'GET',
      token: token
    }),
  listSsidsUsers: async ({ token }: Ctx, id: string, ssid: string) =>
    fetcher<EnterpriseMembersList>({
      url: `${BASE_URL}${urlHelper(API.GET_ENTERPRISE_SSIDS_USERS, { id, ssid })}`,
      method: 'GET',
      token: token
    }),
  createSsid: async ({ token }: Ctx, id: string, data: any) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_ENTERPRISE_SSID, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  createSsidUser: async ({ token }: Ctx, id: string, ssid: string, data: any) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_ENTERPRISE_SSID_USER, { id, ssid })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateSsidUser: async ({ token }: Ctx, id: string, ssid: string, ssidUser: string, data: any) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_ENTERPRISE_SSID_USER, { id, ssid, ssidUser })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  linkMember: async ({ token }: Ctx, id: string, data: LinkIndividualProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_ENTERPRISE_MEMBER_LINK, { id: id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  createMember: async ({ token }: Ctx, id: string, data: IndividualFormDetails) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_ENTERPRISE_MEMBER, { id: id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  listOpportunities: async ({ token }: Ctx, id: string) =>
    fetcher<EnterpriseOpportunitiesList>({
      url: `${BASE_URL}${urlHelper(API.GET_ENTERPRISE_OPPORTUNITIES, { id })}`,
      method: 'GET',
      token: token
    }),
  listContracts: async ({ token }: Ctx, id: string) =>
    fetcher<EnterpriseContractsList>({
      url: `${BASE_URL}${urlHelper(API.GET_ENTERPRISE_CONTRACTS, { id })}`,
      method: 'GET',
      token: token
    }),
  update: async ({ token }: Ctx, data: EnterpriseInformation) =>
    fetcher<void>({
      url: `${BASE_URL}${API.PUT_ENTERPRISE}/${data.id}`,
      method: 'PUT',
      token: token,
      body: data
    }),
  createOpportunity: async ({ token }: Ctx, id: string, data: OpportunityCreateFormData) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${urlHelper(API.POST_ENTERPRISE_OPPORTUNITIES, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  uploadDocument: async ({ token }: Ctx, params: { id: string; data: any }) => {
    const formData = new FormData()
    formData.append('file', params.data.file)
    formData.append('name', params.data.name)
    formData.append('type', params.data.type)

    return uploader<any>({
      url: `${BASE_URL}${urlHelper(API.UPLOAD_ENTERPRISE_DOCUMENT, { id: params.id })}`,
      method: 'POST',
      token: token,
      body: formData
    })
  },
  create: async ({ token }: Ctx, data: CreateEnterpriseForm) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.POST_ENTERPRISE}`,
      method: 'POST',
      token: token,
      body: data
    }),
  createPrescriber: async ({ token }: Ctx, id: number, type: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_ENTERPRISE_PRESCRIBERS, { id })}`,
      method: 'POST',
      token: token,
      body: { type }
    })
}

export const duplicateEnterprisesRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<DuplicateEnterprisesList>({
      url: `${BASE_URL}${API.GET_DUPLICATE_ENTERPRISES}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
}
