import { green, grey } from '@mui/material/colors'
import Logo from 'app/assets/logo_SaasOffice-horizontal_white.svg'
import LogoLogin from 'app/assets/logo_SaasOffice-vertical.svg'
import BgRoot from 'app/assets/platform-SaaS-13032024.jpg'
import { VariantType } from 'app/theme/variants'

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2'
}

export const customBlueFlexo = {
  50: '#d6e2e4',
  100: '#c2d4d7',
  200: '#adc5c9',
  300: '#99b7bc',
  400: '#84a9ae',
  500: '#709aa1',
  600: '#5b8c93',
  700: '#477d86',
  800: '#326F78',
  900: '#2d646c'
}

export const customYellowFlexo = {
  50: '#fffaeb',
  100: '#fff4d7',
  200: '#feefc4',
  300: '#feeab0',
  400: '#fee59c',
  500: '#fedf88',
  600: '#feda74',
  700: '#fdd561',
  800: '#fdcf4d',
  900: '#FDCA39'
}

export const defaultVariant = {
  name: 'Default',
  logo: { header: Logo, login: LogoLogin },
  mainBackground: BgRoot,
  palette: {
    mode: 'light',
    primary: {
      main: customBlue[700],
      light: customBlue[700],
      dark: customBlue[600],
      contrastText: '#ffffff'
    },
    secondary: {
      main: customBlue[500],
      light: customBlue[300],
      dark: '#ff9e80',
      contrastText: '#ffffff'
    },
    background: {
      default: '#F7F9FC',
      paper: '#ffffff'
    },
    grey: {
      50: '#F7F9FC',
      100: '#EEEFF2',
      200: '#dcddde',
      300: '#bbbfc4',
      400: '#adb5bd',
      500: '#8c9094',
      600: '#85909a',
      700: '#7f8a93',
      800: '#6c757d',
      900: '#2a2e32'
    }
  },
  header: {
    color: grey[200],
    background: '#233044',
    search: {
      color: grey[800]
    },
    indicator: {
      background: customBlue[500]
    }
  },
  footer: {
    color: grey[500],
    background: '#ffffff'
  },
  sidebar: {
    color: grey[200],
    background: '#233044',
    borderRight: '0',
    borderRadius: '8px',
    linkActive: {
      color: grey[200],
      background: '#233044'
    },
    linkActiveHover: {
      color: grey[200],
      background: '#233044'
    },
    linkOpen: {
      background: '#233044',
      color: grey[200]
    },
    linkOpenHover: {
      background: '#233044',
      color: grey[200]
    },
    scrollbar: {
      borderRight: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      paddingLeft: '12px',
      paddingRight: '12px'
    },
    header: {
      color: grey[200],
      background: '#233044',
      brand: {
        color: '#ffffff',
        size: {
          width: '105px',
          height: '48px'
        }
      }
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500]
      }
    },
    badge: {
      color: '#ffffff',
      background: customBlue[500]
    }
  },
  chip: {
    color: '#ffffff',
    background: '#ff9e80'
  }
} as VariantType
