import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  DiscountGridsCenterBody,
  DiscountGridsCenterList,
  DiscountGridsCenterResponse
} from 'api/models'

export const discountGridCenterRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<DiscountGridsCenterList>({
      url: `${BASE_URL}${API.GET_DISCOUNT_GRIDS_CENTER}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  add: async ({ token }: Ctx, body: DiscountGridsCenterBody) =>
    fetcher<DiscountGridsCenterResponse>({
      url: `${BASE_URL}${API.GET_DISCOUNT_GRIDS_CENTER}`,
      method: 'POST',
      token: token,
      body: body
    })
}
