import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { OptionService } from 'api/models'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { OptionServiceDetails } from 'modules/options/components/option-details.component'
import { Edit, KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'

export const OptionView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getOptionService } = useFetcher()
  const { id } = useParams()
  const [optionService, setOptionService] = useState<OptionService>({} as OptionService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const refreshList = useCallback(async () => {
    if (!id) return
    await handleMutation({
      onStart: () => setListIsLoading(true),
      data: Number(id),
      mutation: getOptionService,
      onSuccess: (optionService) => setOptionService(optionService),
      onEnd: () => setListIsLoading(false)
    })
  }, [id, getOptionService])

  useEffect(() => {
    refreshList().then()
  }, [id])

  return listIsLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/services/main`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('option')}</Typography>
            <Link to={`/options/${id}/edit`}>
              <Edit fontSize={'small'} />
            </Link>
          </Stack>
        </Grid>
      </Grid>
      <OptionServiceDetails optionService={optionService} />
    </Container>
  )
}
