import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import {
  FormItem,
  QuotationInformation,
  QuotationSolution,
  QuotationSolutionPrice,
  SelectCommitments,
  SolutionCommitment,
  SolutionFormData,
  solutionFormDataSchema
} from 'api/models'
import dayjs, { Dayjs } from 'dayjs'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useDebouce } from 'app/hooks/use-debounce'
import FormEditSolution from 'app/forms/quotations/FormEditSolution'
import { useFeedback } from 'app/providers/feedback.provider'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { isBetween } from 'app/helpers/date.helper'

type FormData = {
  commitment: number
  begin: Dayjs
  end: Dayjs
  discount: number
  services: string[]
  isPriceDegressive: boolean
  prices: QuotationSolutionPrice[]
}

interface IQuotationDetailsProps {
  dialogRef: RefObject<DialogRef>
  quotation: QuotationInformation
  solution: QuotationSolution
}

export const SolutionEditDialog = ({ dialogRef, quotation, solution }: IQuotationDetailsProps) => {
  const { t } = useTranslation()
  const { updateQuotationSolution, getQuotationSolutionPrice, getQuotationSolutionCommitments } =
    useFetcher()
  const methods = useForm<SolutionFormData>({
    mode: 'onChange',
    resolver: zodResolver(solutionFormDataSchema)
  })
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const [isPriceLoading, setIsPriceLoading] = useState<boolean>(false)
  const [commitments, setCommitments] = useState<FormItem>({} as FormItem)
  const [commitmentsData, setCommitmentsData] = useState<SelectCommitments | null>(null)
  const [selectedCommitment, setSelectedCommitment] = useState<SolutionCommitment | null>(null)
  const prices = methods.watch('prices')
  const { isValid, isSubmitting } = methods.formState
  const setPrices = (data: any) => {
    methods.setValue('prices', data)
  }
  useEffect(() => {
    if (!solution) return
    methods.reset({
      commitment: solution.commitmentId,
      begin: dayjs(solution.begin),
      end: dayjs(solution.deadline),
      isPriceDegressive: false,
      discount: solution.reduction * 100,
      prices: [],
      services: solution.services ? solution.services.map((i) => String(i.serviceId)) : []
    })
  }, [solution])

  const fetchCommitments = useCallback(async () => {
    if (!quotation || !solution?.id) return
    const commitments = await getQuotationSolutionCommitments.mutateAsync({
      id: quotation.id,
      solutionId: solution.id
    })
    setCommitmentsData(commitments)
    setCommitments({
      default: [],
      label: t('commitment'),
      multiple: false,
      name: 'commitments',
      reference: 'commitments',
      values: Object.entries(commitments).map((entry) => ({ id: entry[0], ...entry[1] }))
    })
  }, [quotation, solution])

  useDebouce(
    async () => {
      if (!quotation || !solution?.id) return
      const values = methods.getValues()
      if (!values.services || !values.commitment || !values.begin || !values.end) return
      setIsPriceLoading(true)
      try {
        const prices = await getQuotationSolutionPrice.mutateAsync({
          id: quotation.id,
          solutionId: solution.id,
          data: {
            commitment: values.commitment,
            services: values.services,
            begin: values.begin.format('YYYY-MM-DD'),
            end: values.end.format('YYYY-MM'),
            discount: values.discount,
            ispricedegressive: values.isPriceDegressive ? 0 : 1
          }
        })
        setPrices(prices)
      } finally {
        setIsPriceLoading(false)
      }
    },
    [
      methods.watch('discount'),
      methods.watch('commitment'),
      methods.watch('isPriceDegressive'),
      methods.watch('begin'),
      methods.watch('end')
    ],
    150
  )

  useEffect(() => {
    if (!selectedCommitment) return
    const { begin, end } = methods.getValues()
    if (
      !begin ||
      (end &&
        isBetween(
          end,
          begin.clone().add(selectedCommitment.min, 'month'),
          begin.clone().add(selectedCommitment.max, 'month'),
          'month'
        ))
    ) {
      return
    }
    methods.setValue('end', begin.clone().add(selectedCommitment.min, 'month'))
  }, [selectedCommitment, methods.watch('begin')])

  useEffect(() => {
    if (!commitmentsData) return
    const { commitment } = methods.getValues()
    setSelectedCommitment(commitmentsData[commitment])
  }, [methods.watch('commitment'), commitmentsData])

  useEffect(() => {
    fetchCommitments().then()
  }, [fetchCommitments])

  const handleSubmit = useCallback(
    async (data: SolutionFormData) => {
      await handleMutation({
        confirm: {
          content: t('confirm_update_solution')
        },
        mutation: updateQuotationSolution,
        data: {
          id: quotation.id,
          solutionId: solution.id,
          data: {
            ...data,
            begin: data.begin.format('YYYY-MM-DD'),
            end: data.end.format('YYYY-MM-DD'),
            ...data.prices.reduce((prev, p) => {
              const data =
                p.service == 'total'
                  ? {
                      price: p.price,
                      priceReduced: p.priceReduced
                    }
                  : { ['price_' + p.service]: p.priceReduced }
              return { ...prev, ...data }
            }, {})
          }
        },
        toastSuccess: t('update_success'),
        toastError: t('update_error'),
        onSuccess: () => navigate(0)
      })
    },
    [quotation, solution]
  )

  const servicesLabels = useMemo(
    () => solution.services?.map((s) => s.serviceLabel).join(','),
    [solution]
  )

  return (
    <Dialog
      ref={dialogRef}
      title={`${t('solution_update')} ${solution.id}`}
      actions={
        <>
          <Button onClick={() => dialogRef.current?.close()}>{t('cancel')}</Button>
          <Button
            disabled={!isValid || isSubmitting || isPriceLoading}
            onClick={methods.handleSubmit(handleSubmit)}
            variant={'contained'}
          >
            {t('update')}
          </Button>
        </>
      }
    >
      <Typography variant={'h3'} textAlign={'right'}>
        {t('offices_and_services')} : {servicesLabels}
      </Typography>
      <FormEditSolution
        commitments={commitments}
        methods={methods}
        solution={solution}
        prices={prices}
        selectedCommitment={selectedCommitment}
      />
    </Dialog>
  )
}
