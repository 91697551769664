import React, { useCallback, useEffect, useState } from 'react'
import { Tab, Tabs, Typography, Paper as MuiPaper, Container, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  Contract,
  ContractConsumptionList,
  ContractIndexingAnniversaryList,
  ContractInvoiceList,
  ContractLitigationList,
  ContractMainList,
  ContractOptionList,
  ContractPaymentList
} from 'api/models'
import { Link } from 'app/components/link.component'
import { Timeline } from 'modules/contracts/components/timeline.component'
import { ContractDetails } from 'modules/contracts/components/contract-details.component'
import { TabPanel } from 'app/components/tab-panel.component'
import { ServicesComponent } from 'modules/contracts/components/services.component'
import { OptionComponent } from '../components/options.component'
import styled from '@emotion/styled'
import { darken } from 'polished'
import { ConsumptionComponent } from 'modules/contracts/components/consumption.component'
import { InvoicesComponent } from 'modules/contracts/components/invoices.component'
import { PaymentsComponent } from 'modules/contracts/components/payments.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useSidebar } from 'app/providers/sidebar.provider'
import { LitigationsComponent } from 'modules/contracts/components/litigations.component'
import { EndorsementComponent } from 'modules/contracts/components/endorsement/endorsement.component'
import { IndexingComponent } from 'modules/contracts/components/indexing.component'
import { ContractActions } from 'modules/contracts/components/contract-actions'
import { formatDateWithTime } from 'app/utils/format'
import { RecurringServicesComponent } from 'modules/contracts/components/recurring-services.component'

const Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`

const StyledPaper = styled(MuiPaper)`
  width: 100%;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0);
  background-color: ${(props) =>
    props.theme.palette.mode === 'dark'
      ? darken(0.03, props.theme.palette.background.paper)
      : props.theme.palette.grey[100]};
`

export const ContractView = () => {
  const { id } = useParams()
  const {
    getContract,
    getContractMain,
    getContractOption,
    getContractConsumption,
    searchParams,
    getContractInvoice,
    getContractIndexingAnniversary,
    getContractPayments,
    getContractLitigations
  } = useFetcher()
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [contract, setContract] = useState<Contract>()
  const [services, setServices] = useState<ContractMainList>([])
  const [options, setOptions] = useState<ContractOptionList>([])
  const [consumptions, setConsumptions] = useState<ContractConsumptionList>([])
  const [invoices, setInvoices] = useState<ContractInvoiceList>([])
  const [litigations, setLitigations] = useState<ContractLitigationList>([])
  const [payments, setPayments] = useState<ContractPaymentList>([])
  const [indexingAnniversary, setIndexingAnniversary] = useState<ContractIndexingAnniversaryList>(
    [] as ContractIndexingAnniversaryList
  )
  const { setParams } = useSidebar()
  const [servicesIsLoading, setServicesIsLoading] = useState<boolean>(true)
  const [optionsIsLoading, setOptionsIsLoading] = useState<boolean>(true)
  const [consumptionsIsLoading, setConsumptionsIsLoading] = useState<boolean>(true)
  const [invoicesIsLoading, setInvoicesIsLoading] = useState<boolean>(true)
  const [litigationsIsLoading, setLitigationsIsLoading] = useState<boolean>(true)
  const [paymentsIsLoading, setPaymentsIsLoading] = useState<boolean>(true)
  const [indexingIsLoading, setIndexingIsLoading] = useState<boolean>(true)
  const [isLitigation, setIsLitigation] = useState<boolean>(false)

  const fetchFirstTab = useCallback(async () => {
    searchParams.set('id', String(id))
    setServicesIsLoading(true)
    setOptionsIsLoading(true)
    try {
      const results = await Promise.all([
        getContract.mutateAsync(Number(searchParams.get('id'))),
        getContractMain.mutateAsync(),
        getContractOption.mutateAsync()
      ])
      setContract(results[0])
      setParams({
        id: String(results[0].clientId),
        type: 'clients',
        data: { client: String(results[0].clientId), contract: String(id) }
      })
      setIsLitigation(results[0].state === 8)
      setServices(results[1])
      setOptions(results[2])
    } finally {
      setServicesIsLoading(false)
      setOptionsIsLoading(false)
    }
  }, [])

  const fetchConsumptions = useCallback(async () => {
    setConsumptionsIsLoading(true)
    try {
      const response = await getContractConsumption.mutateAsync()
      setConsumptions(response)
    } finally {
      setConsumptionsIsLoading(false)
    }
  }, [])

  const fetchInvoices = useCallback(async () => {
    setInvoicesIsLoading(true)
    try {
      const response = await getContractInvoice.mutateAsync()
      setInvoices(response)
    } finally {
      setInvoicesIsLoading(false)
    }
  }, [])

  const fetchLitigations = useCallback(async () => {
    setLitigationsIsLoading(true)
    if (!contract) return
    try {
      const litigations = await getContractLitigations.mutateAsync(contract.id)
      setLitigations(litigations)
    } finally {
      setLitigationsIsLoading(false)
    }
  }, [contract])

  const fetchPayments = useCallback(async () => {
    setPaymentsIsLoading(true)
    if (!contract) return
    try {
      const payments = await getContractPayments.mutateAsync(contract.id)
      setPayments(payments)
    } finally {
      setPaymentsIsLoading(false)
    }
  }, [contract])

  const fetchIndexingAnniversary = useCallback(async () => {
    setIndexingIsLoading(true)
    try {
      const response = await getContractIndexingAnniversary.mutateAsync()
      setIndexingAnniversary(response)
    } finally {
      setIndexingIsLoading(false)
    }
  }, [])

  const handleTabChange = useCallback(
    async (event: React.SyntheticEvent, newValue: number) => {
      setCurrentTab(newValue)

      switch (newValue) {
        case 0:
          await fetchFirstTab()
          break
        case 1:
          await fetchConsumptions()
          break
        case 2:
          await fetchInvoices()
          break
        case 3:
          await fetchLitigations()
          break
        case 4:
          await fetchPayments()
          break
        case 5:
          await fetchIndexingAnniversary()
          break
      }
    },
    [
      fetchFirstTab,
      fetchConsumptions,
      fetchInvoices,
      fetchLitigations,
      fetchPayments,
      fetchIndexingAnniversary
    ]
  )

  useEffect(() => {
    fetchFirstTab().then()
  }, [])

  return (
    <Container>
      {!contract ? (
        <RectangularSkeleton />
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {t('contracts')} |{' '}
              <Link to={`/enterprises/${contract.clientId}`}>{contract.clientName}</Link>
            </Typography>
            <ContractActions contract={contract} />
          </Stack>
          <Typography variant="body2">{contract.reference}</Typography>
        </>
      )}

      {!contract ? (
        <CardSkeleton />
      ) : (
        <Timeline
          items={{
            created_at: { date: contract.createdAt, completed: !!contract.createdAt },
            agreement_at: {
              date: contract.agreement,
              completed: !!contract.agreement
            },
            paid_at: { date: contract.confirm, completed: !!contract.confirm },
            state: { completed: contract.state === 3 }
          }}
        />
      )}

      {contract?.end && (
        <Stack direction="row" justifyContent="center">
          {contract.typeCancel === 'cancel' ? (
            <Typography variant="body2" fontWeight="bold">
              {t('cancelled_contract_at')} {formatDateWithTime(contract.end)}
            </Typography>
          ) : (
            <Typography variant="body2" fontWeight="bold">
              {t('terminated_contract_at')} {formatDateWithTime(contract.end)}
            </Typography>
          )}
        </Stack>
      )}

      {!contract ? <CardSkeleton /> : <ContractDetails contract={contract} />}

      <StyledPaper>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ marginY: 6 }}
        >
          <Tab label={t('informations')} value={0} />
          <Tab label={t('consumptions')} value={1} />
          <Tab label={t('invoices')} value={2} />
          {isLitigation && <Tab label={t('litigations')} value={3} />}
          <Tab label={t('payments')} value={4} />
          <Tab label={t('index')} value={5} />
          <Tab label={t('endorsement')} value={6} />
        </Tabs>
      </StyledPaper>

      <TabPanel value={currentTab} index={0}>
        <Grid>
          {contract?.isCoworking ? (
            <RecurringServicesComponent
              isLoading={servicesIsLoading}
              mainValues={services}
              contract={contract}
            />
          ) : (
            <ServicesComponent isLoading={servicesIsLoading} mainValues={services} />
          )}

          <OptionComponent
            isLoading={optionsIsLoading}
            optionValues={options}
            contract={contract}
          />
        </Grid>
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <ConsumptionComponent
          isLoading={consumptionsIsLoading}
          contract={contract}
          consumptionValues={consumptions}
        />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <InvoicesComponent isLoading={invoicesIsLoading} invoiceValues={invoices} />
      </TabPanel>

      <TabPanel value={currentTab} index={3}>
        <LitigationsComponent isLoading={litigationsIsLoading} litigationValues={litigations} />
      </TabPanel>

      <TabPanel value={currentTab} index={4}>
        <PaymentsComponent isLoading={paymentsIsLoading} paymentValues={payments} />
      </TabPanel>

      <TabPanel value={currentTab} index={5}>
        <IndexingComponent isLoading={indexingIsLoading} indexingValues={indexingAnniversary} />
      </TabPanel>

      <TabPanel value={currentTab} index={6}>
        <EndorsementComponent
          contract={contract ?? ({} as Contract)}
          isLitigation={isLitigation}
          isLoading={false}
        />
      </TabPanel>
    </Container>
  )
}
