import { CircularProgress, Grid } from '@mui/material'

export const ListSkeleton = () => {
  return (
    <Grid padding={4} container justifyContent={'center'}>
      <Grid item>
        <CircularProgress color={'primary'} />
      </Grid>
    </Grid>
  )
}
