import React, { useCallback, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { AccountCircle } from '@mui/icons-material'

import type { Quotation } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatDate, formatQuotationStatus } from 'app/utils/format'
import { RangePicker } from 'app/components/filters/range-picker'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { useList } from 'app/providers/list.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'
export const QuotationsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getQuotations, searchParams, setSearchParams } = useFetcher()
  const [quotations, setQuotations] = useState<Quotation[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['quotation_status', 'status'],
      ['staff_centers', 'center']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getQuotations
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setQuotations(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getQuotations, setIsLast, setOffset, setTotal]
  )

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('quotations_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          initialOrder={[{ property: 'createdAt', order: 'desc' }]}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilter(refreshList, true)}
        >
          <RangePicker
            slug="created_at"
            label={'created_at'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="begin_at"
            label={'begin_at'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchClientInput
            slug="client"
            defaultIsIndividual={false}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersContainer>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {quotations.length} / {total}
          </Typography>
        </Grid>
        <List
          items={quotations}
          columns={[
            {
              label: t('reference'),
              slug: 'state',
              text: 'stateLabel',
              id: 'id',
              valueFormatter: formatQuotationStatus,
              link: { base: '/quotations', slug: 'id' }
            },
            { label: t('center'), slug: 'centerName' },
            {
              label: t('client'),
              slug: 'client',
              link: { base: '/enterprises', slug: 'clientId' }
            },
            {
              label: t('opportunity'),
              slug: 'opportunity',
              link: { base: '/opportunity', slug: 'opportunityId' }
            },
            { label: AccountCircle, slug: 'staffLight', tooltip: 'staffLabel' },
            { label: t('begin_at'), slug: 'begin', valueFormatter: formatDate },
            { label: t('created_at'), slug: 'createdAt', valueFormatter: formatDate }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {quotations.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
