import React, { useMemo } from 'react'
import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Inventories } from 'api/models/stats'

interface IProps {
  data: Inventories
  centers: any
}

const TableInventoriesComponent = ({ data, centers }: IProps) => {
  const { t } = useTranslation()
  const tableData = useMemo(() => {
    let tableData: any[] = []
    centers.map((center: any) => {
      const items = data.map((item) => {
        return item.items.find((item) => item.centerId === Number(center.id))
      })
      tableData.push(items)
    })
    return tableData
  }, [])
  const globalData = useMemo(() => {
    return data.map((item) => item.items[item.items.length - 1])
  }, [])

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('center')}</TableCell>
            {data.map((item, index) => (
              <TableCell align="right" key={index}>
                {item.period}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {item[0].centerName}
              </TableCell>
              <TableCell align="right">{item[0].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[1].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[2].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[3].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[4].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[5].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[6].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[7].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[8].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[9].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[10].value.toFixed(2)}</TableCell>
              <TableCell align="right">{item[11].value.toFixed(2)}</TableCell>
            </TableRow>
          ))}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Typography fontWeight="bold">{t('grand_total')}</Typography>
            </TableCell>
            {globalData.map((item, index) => (
              <TableCell align="right" key={index}>
                {item.value.toFixed(2)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableInventoriesComponent
