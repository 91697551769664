import { Typography, Box, Button } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Contract, EndorsementContract } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'
import { AlertBox } from 'app/components/papers/alert-box.component'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { ReadonlyEndorsementComponent } from './readonly-endorsement.component'

interface IProps {
  contract: Contract
  isLoading: boolean
  setIsParentLink: (value: boolean) => void
}

export const CreateEndorsementComponent = ({ contract, isLoading }: IProps) => {
  const { t } = useTranslation()
  const { createContractEndorsement, getContractAutoRenewalEndorsements } = useFetcher()
  const [autoRenewalState, setAutoRenewalState] = useState<boolean | null>(null)
  const [autoRenewalData, setAutoRenewalData] = useState<EndorsementContract>()
  const dialogRef = useRef<DialogRef>(null)
  const [canCreate, setCanCreate] = useState<boolean>(false)
  const [date, setDate] = useState<Dayjs | null>(dayjs())
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()

  useEffect(() => {
    setCanCreate(
      contract.agreement !== null && (Number(contract.state) === 2 || Number(contract.state) === 3)
    )
    setDate(dayjs(contract.end ?? contract.deadline).add(1, 'day'))

    getContractAutoRenewalEndorsements
      .mutateAsync(contract.id)
      .then((data) => {
        setAutoRenewalState(true)
        setAutoRenewalData(data)
      })
      .catch(() => {
        setAutoRenewalState(false)
      })
  }, [contract])

  const handleSubmit = useCallback(async () => {
    if (!contract || !date) return
    await handleMutation({
      confirm: {
        content: t('confirm_create_endorsement')
      },
      mutation: createContractEndorsement,
      data: { id: contract.id, date: date.format('YYYY-MM-DD') },
      toastSuccess: t('update_success'),
      toastError: t('error'),
      onSuccess: () => navigate(0)
    })
  }, [contract, date])

  const handleChangeDate = useCallback((date: Dayjs | null) => {
    setDate(date)
  }, [])

  const handleOpenModalAutoRenewal = useCallback(() => {
    dialogRef.current?.open()
  }, [])

  if (isLoading) return <CardSkeleton />
  return canCreate ? (
    <>
      <Dialog ref={dialogRef} maxWidth={'md'} actions={<></>} title={''}>
        <ReadonlyEndorsementComponent
          endorsement={contract ?? null}
          endorsementContract={autoRenewalData ?? null}
          isLoading={autoRenewalState === null}
        />
      </Dialog>
      <AlertBox
        isVisible={autoRenewalState}
        isLoading={autoRenewalState === null}
        severity={'info'}
      >
        <Typography>
          {t('endorsement_auto_wording_detail')}
          <Button onClick={handleOpenModalAutoRenewal} variant={'text'}>
            {t('endorsement_auto_wording_detail_button')}
          </Button>
        </Typography>
      </AlertBox>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '10px',
          p: 15
        }}
      >
        <Typography>{t('endorsement_begin')}</Typography>
        <DatePicker
          minDate={dayjs()}
          maxDate={dayjs(contract.end ?? contract.deadline).add(1, 'day')}
          value={date}
          onChange={handleChangeDate}
          slotProps={{
            textField: {
              size: 'small'
            }
          }}
        />
        <Button variant="contained" color="primary" size={'small'} onClick={handleSubmit}>
          {t('endorsement_create')}
        </Button>
      </Box>
    </>
  ) : (
    <Typography sx={{ p: 5 }}>{t('endorsement_unavailable')}</Typography>
  )
}
