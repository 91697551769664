import { ZodError } from 'zod'
import { formatZodError } from '../utils/format'

export const zodErrorHelper = <T>(parseMethod: () => T): T => {
  try {
    return parseMethod()
  } catch (error: unknown) {
    const zodError = error as ZodError
    const errorMessage = 'Parsing error: ' + formatZodError(zodError)
    const newError = new Error(errorMessage)
    newError.cause = zodError
    throw newError
  }
}
