import styled from '@emotion/styled'
import { Paper as MuiPaper, PaperProps } from '@mui/material'
import { darken } from 'polished'

const Paper = styled(MuiPaper)`
  border-radius: 2px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0);
  background-color: ${(props) =>
    props.theme.palette.mode === 'dark'
      ? darken(0.03, props.theme.palette.background.paper)
      : props.theme.palette.grey[100]};
  margin-top: ${(props) => props.theme.spacing(6)};
  margin-bottom: ${(props) => props.theme.spacing(6)};
`

export const PaperGreyComponent = ({ children, ...otherProps }: PaperProps): React.JSX.Element => {
  return <Paper {...otherProps}>{children}</Paper>
}
