import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import React, { RefObject } from 'react'
import { FormItems, QuotationInformation } from 'api/models'
import dayjs from 'dayjs'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import FormEditQuotation from 'app/forms/quotations/FormEditQuotation'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useNavigate } from 'react-router-dom'
import { UpdateQuotationFormData } from 'api/models/forms/quotations'

import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IQuotationDetailsProps {
  dialogRef: RefObject<DialogRef>
  options: FormItems
  quotation: QuotationInformation
}

export const QuotationEditDialog = ({ dialogRef, options, quotation }: IQuotationDetailsProps) => {
  const { t } = useTranslation()
  const { updateQuotation } = useFetcher()
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()
  const methods = useForm<UpdateQuotationFormData>({
    defaultValues: { language: quotation.languageId, dueDate: dayjs.utc(quotation.dueDate) }
  })

  const handleSubmit = async (data: UpdateQuotationFormData) => {
    await handleMutation({
      confirm: {
        content: t('update_quotation_confirm')
      },
      mutation: updateQuotation,
      data: { id: quotation.id, data: data },
      toastSuccess: t('update_quotation_success'),
      toastError: t('an_error_occurred'),
      onSuccess: () => {
        dialogRef.current?.close()
        navigate(0)
      }
    })
  }

  return (
    <Dialog
      ref={dialogRef}
      actions={
        <>
          <Button onClick={() => dialogRef.current?.close()}>{t('cancel')}</Button>
          <Button
            disabled={!methods.formState.isValid || methods.formState.isSubmitting}
            onClick={methods.handleSubmit(handleSubmit)}
          >
            {t('update')}
          </Button>
        </>
      }
      title={t('quotation_update')}
    >
      <FormEditQuotation methods={methods} options={options} />
    </Dialog>
  )
}
