import merge from 'deepmerge'
import { ReactNode } from 'react'
import { defaultVariant } from './default.theme'
import variantConfigs from './variants/index'

const variants: Array<VariantType> = variantConfigs.map((variant: any) =>
  merge(defaultVariant, variant)
)
export default variants

export type VariantType = {
  name: string
  mainBackground: ReactNode
  logo: { header: ReactNode; login: ReactNode }
  palette: {
    primary: MainContrastTextType
    secondary: MainContrastTextType
  }
  header: ColorBgType & {
    search: {
      color: string
    }
    indicator: {
      background: string
    }
  }
  footer: ColorBgType
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string
      }
    }
    footer: ColorBgType & {
      online: {
        background: string
      }
    }
    badge: ColorBgType
  }
  chip: ColorBgType
}

type MainContrastTextType = {
  main: string
  contrastText: string
}
type ColorBgType = {
  color: string
  background: string
}
