import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { DiscountGridAdd, DiscountGridRead, DiscountGridsList } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const discountGridRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<DiscountGridsList>({
      url: `${BASE_URL}${API.GET_DISCOUNT_GRIDS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  read: async ({ token }: Ctx, id: string) =>
    fetcher<DiscountGridRead>({
      url: `${BASE_URL}${urlHelper(API.GET_DISCOUNT_GRID, { id })}`,
      method: 'GET',
      token: token
    }),
  create: async ({ token }: Ctx, data: DiscountGridAdd) =>
    fetcher<void>({
      url: `${BASE_URL}${API.POST_DISCOUNT_GRID}`,
      method: 'POST',
      token: token,
      body: data
    })
}
