import { Card, CardContent, Button, Box } from '@mui/material'

import { TitleComponent } from 'app/components/titles/title.component'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { ContractMainList } from 'api/models'
import { Group } from '@mui/icons-material'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { formatCurrency, formatSurface } from 'app/utils/format'
import NumberInput from 'app/components/form/input-number.component'

interface IServicesProps {
  isLoading: boolean
  mainValues: ContractMainList
}

interface IOccupants {
  [key: string]: number
}

export const ServicesComponent = ({ isLoading, mainValues }: IServicesProps) => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { updateContractOccupant, searchParams } = useFetcher()
  const [occupants, setOccupants] = useState<IOccupants>()
  const [isEditable, setIsEditable] = useState<boolean>(true)
  const [initMap, setInitMap] = useState<Map<string, any>>(new Map())
  const onOccupantsChange = (event: any, value: number | undefined) => {
    if (event.target.tagName === 'INPUT' && value !== undefined) {
      setOccupants((prevState) => ({ ...prevState, [event.target.id]: value }))
    }
    setIsEditable(false)
  }

  const handleButtonClick = useCallback(async () => {
    await handleMutation({
      confirm: {
        content: t('confirm_update_occupants')
      },
      mutation: updateContractOccupant,
      data: { id: Number(searchParams.get('id')), occupants: occupants as IOccupants },
      toastSuccess: t('updated_occupants'),
      toastError: t('error')
    })
  }, [updateContractOccupant, searchParams, occupants])

  useEffect(() => {
    setInitMap(() => {
      const map = new Map()
      map.set('items', [
        { label: t('label').toUpperCase(), value: 'label' },
        { label: t('type').toUpperCase(), value: 'serviceType' },
        { label: t('surface').toUpperCase(), format: formatSurface, value: 'surface' },
        {
          label: <Group />,
          component: (row: any) => (
            <NumberInput
              id={`occupant_${row.id}`}
              defaultValue={row.occupants}
              onChangeInput={onOccupantsChange}
            />
          )
        },
        { label: t('monthly_price').toUpperCase(), format: formatCurrency, value: 'price' }
      ])
      map.set('data', mainValues)
      return map
    })
  }, [mainValues])

  return (
    <Card>
      <CardContent>
        <TitleComponent text={t('services')} variant={'h3'} paddingLeft={12} />
        <ListSheetComponent isLoading={isLoading} data={initMap} />
        <Box style={{ textAlign: 'right', marginTop: '16px' }}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={handleButtonClick}
            disabled={isEditable}
          >
            Mettre à jour
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}
