import React, { useCallback, useState } from 'react'
import {
  Box,
  Chip as MuiChip,
  TableBody,
  TableCell,
  TableHead,
  TableRow as MuiTableRow
} from '@mui/material'
import styled from '@emotion/styled'
import { Boy, KeyboardArrowDown, KeyboardArrowUp, SquareFoot } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { convertHexToRgbA } from 'app/helpers/color.helper'
import { usePricer } from 'app/providers/pricer.provider'
import { ServiceCheckboxes } from 'modules/pricers/components/services-checkboxes.component'
import { ServiceOccupants } from 'modules/pricers/components/service-occupants.component'
import { ServicePriceComponent } from 'modules/pricers/components/service-price.component'
import { formatSurface } from 'app/utils/format'

const Div = styled('div')(() => ({
  textTransform: 'uppercase'
}))

const TableRow = styled(MuiTableRow)`
  &.notAvailableLine {
    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? convertHexToRgbA(props.theme.palette.grey[900], 50)
        : convertHexToRgbA(props.theme.palette.grey[100], 100)};
    &:hover {
      background: ${(props) =>
        props.theme.palette.mode === 'dark'
          ? convertHexToRgbA(props.theme.palette.grey[900], 50)
          : convertHexToRgbA(props.theme.palette.grey[100], 100)};
    }
  }
`
const StyledChipInternal = styled(MuiChip)`
  height: 15px;
  font-size: 68%;
  font-weight: bold;
  background-color: ${(props) => props.theme.palette.primary.light};
  color: ${(props) => props.theme.palette.primary.contrastText};

  span {
    padding-left: ${(props) => props.theme.spacing(1)};
    padding-right: ${(props) => props.theme.spacing(1)};
  }
`

type IServiceOfficeQuotationComponentProps = {}

const ServiceOfficeQuotationComponent: React.FC<IServiceOfficeQuotationComponentProps> = ({}) => {
  const { t } = useTranslation()
  const { services } = usePricer()
  const [open, setOpen] = useState(true)

  const handleToggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <>
      <TableHead sx={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={handleToggle}>
        <TableRow>
          <TableCell align="left" sx={{ width: '190px!important' }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              {open ? (
                <KeyboardArrowUp fontSize={'small'} />
              ) : (
                <KeyboardArrowDown fontSize={'small'} />
              )}
              <Div>{t('full_office')}</Div>
            </Stack>
          </TableCell>
          <TableCell sx={{ width: '100px' }}>&nbsp;</TableCell>
          <TableCell sx={{ width: '100px' }}>&nbsp;</TableCell>
          <TableCell sx={{ width: '100px' }}>&nbsp;</TableCell>
          <TableCell align="left" sx={{ width: '200px' }}>
            <Div>{t('price')}</Div>
          </TableCell>
          <TableCell align="center" sx={{ width: '100px' }}>
            <Div>{t('occupants')}</Div>
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S1
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S2
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S3
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S4
          </TableCell>
        </TableRow>
      </TableHead>
      {open && (
        <TableBody>
          {undefined !== services &&
            undefined !== services.offices &&
            services.offices.map((office, index) => (
              <TableRow key={index} hover className={!office.available ? 'notAvailableLine' : ''}>
                <TableCell align="left" size="small">
                  {office.label}
                </TableCell>
                <TableCell align="left" size="small">
                  <StyledChipInternal label={office.typologyLabel} size="small" />
                </TableCell>
                <TableCell align="left" size="small">
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Boy fontSize={'small'} />
                    <Box component={'span'}>{office.capacity}</Box>
                  </Stack>
                </TableCell>
                <TableCell align="left" size="small">
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <SquareFoot fontSize={'small'} />{' '}
                    <Box component={'span'}>{formatSurface(office.surface)}</Box>
                  </Stack>
                </TableCell>
                <ServicePriceComponent prices={office.prices} showAllPrices={true} />
                <ServiceOccupants service={office} type="office" index={index} />
                <ServiceCheckboxes type={'office'} service={office} />
              </TableRow>
            ))}
        </TableBody>
      )}
    </>
  )
}

export default ServiceOfficeQuotationComponent
