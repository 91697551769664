import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import type { ListFloors, ServiceShapes } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Box, Container } from '@mui/system'
import dayjs, { Dayjs } from 'dayjs'
import KonvaComponent from 'modules/floorplans/components/konva.component'
import SearchbarComponent from 'modules/floorplans/components/searchbar.component'
import InfosbarComponent from 'modules/floorplans/components/infosbar.component'
import { ContractFloorPlans, FilterContracts } from 'api/models'
import { useApp } from 'app/providers/app.provider'

export const FloorPlansView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    getListFloors,
    getFloorPlans,
    setSearchParams,
    searchParams,
    getFilterContractsFloorPlans
  } = useFetcher()
  const { user } = useApp()
  const [floors, setFloors] = useState<ListFloors>([])
  const [floor, setFloor] = useState<string | null>(null)
  const [date, setDate] = useState<Dayjs>(dayjs())
  const [image, setImage] = useState<undefined | HTMLImageElement>()
  const [shapes, setShapes] = useState<ServiceShapes>([])
  const [displaySurface, setDisplaySurface] = useState(false)
  const [displayPrice, setDisplayPrice] = useState(false)
  const [displayName, setDisplayName] = useState(false)
  const [filterContracts, setFilterContracts] = useState<FilterContracts>([])
  const [selectedActiveContract, setSelectedActiveContract] = useState<string>('0')
  const [displayContract, setDisplayContract] = useState<string>('-500px')
  const [selectedContract, setSelectedContract] = useState<ContractFloorPlans>(
    {} as ContractFloorPlans
  )

  useEffect(() => {
    if (searchParams.get('center') === null) {
      searchParams.set('center', String(user?.mainCenter))
      setSearchParams(searchParams)
    }
    if (searchParams.get('begin')) {
      setDate(dayjs(searchParams.get('begin')))
      searchParams.delete('begin')
      setSearchParams(searchParams)
    }
    handleCenter().then()
  }, [])

  const handleCenter = async () => {
    setImage(undefined)
    setShapes([])
    setFloors([])
    setFloor(null)
    setFilterContracts([])
    setDisplayContract('-500px')

    const centerId = searchParams.get('center')
    if (centerId === null) {
      return
    }

    const floorsValue = await getListFloors.mutateAsync()
    const floor = floorsValue.length > 0 ? floorsValue[0].floor : null
    setFloors(floorsValue)
    setFloor(floor)
  }

  useEffect(() => {
    if (floor === null) {
      return
    }
    const centerId = searchParams.get('center')
    const currentDate = date.format('YYYY-MM-DD')

    const fetchData = async () => {
      try {
        const planValue = await getFloorPlans.mutateAsync({
          center: Number(centerId),
          floor: Number(floor),
          date: currentDate
        })
        if (planValue !== null) {
          const img = new window.Image()
          img.src = planValue.internalImage
          setImage(img)
          setShapes(planValue.serviceShapes)

          const filterContractsData = await getFilterContractsFloorPlans.mutateAsync({
            floorplan: planValue.id,
            date: currentDate
          })
          setFilterContracts(filterContractsData)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData().then()
  }, [floor, date])

  const handleFloor = (e: any) => {
    setDisplayContract('-500px')
    const value = e.target.value
    setFloor(value)
  }

  const handleDate = (newDate: any) => {
    setDisplayContract('-500px')
    setDate(newDate)
  }

  return (
    <Container>
      <Grid item xs={12}>
        <Box marginBottom="2rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('floor_plan')}
          </Typography>
        </Box>
        <Grid>
          <Grid marginY="1rem">
            <SearchbarComponent
              floors={floors}
              floor={floor}
              handleFloor={handleFloor}
              handleDate={handleDate}
              handleCenter={handleCenter}
              date={date}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              filterContracts={filterContracts}
              selectedActiveContract={selectedActiveContract}
              setSelectedActiveContract={setSelectedActiveContract}
              setDisplayContract={setDisplayContract}
              setSelectedContract={setSelectedContract}
              shapes={shapes}
            />
            <InfosbarComponent
              displayPrice={displayPrice}
              displayName={displayName}
              displaySurface={displaySurface}
              setDisplaySurface={setDisplaySurface}
              setDisplayPrice={setDisplayPrice}
              setDisplayName={setDisplayName}
              shapes={shapes}
              image={image}
            />
          </Grid>
          {shapes && shapes.length > 0 && image ? (
            <KonvaComponent
              displaySurface={displaySurface}
              displayPrice={displayPrice}
              displayName={displayName}
              shapes={shapes}
              image={image}
              setSelectedActiveContract={setSelectedActiveContract}
              selectedActiveContract={selectedActiveContract}
              displayContract={displayContract}
              setDisplayContract={setDisplayContract}
              selectedContract={selectedContract}
              setSelectedContract={setSelectedContract}
            />
          ) : (
            searchParams.get('center') !== null && <Typography>{t('no_floor_plan')}</Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
