import {
  Typography,
  useTheme,
  Chip as MuiChip,
  List as MuiList,
  ListItemIcon,
  Skeleton,
  ListItem,
  ListItemText,
  IconButton,
  Grid
} from '@mui/material'
import React from 'react'
import { Box } from '@mui/system'
import styled from '@emotion/styled'
import { ReactComponent as OriginalBookingIcon } from 'app/assets/icon-svg/calendar-event.svg'
import { ReactComponent as OriginalServiceClientIcon } from 'app/assets/icon-svg/service-client.svg'
import { ReactComponent as OriginalInvoiceIcon } from 'app/assets/icon-svg/invoice.svg'
import { ReactComponent as OriginalRenewIcon } from 'app/assets/icon-svg/renouvellement.svg'
import { ReactComponent as OriginalOpportunityIcon } from 'app/assets/icon-svg/opportunite.svg'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'app/utils/format'
import { Link } from 'react-router-dom'
import { DashboardOtherInformation } from 'api/models/dashboard'
import { convertHexToRgbA } from 'app/helpers/color.helper'
import { useApp } from 'app/providers/app.provider'
import dayjs from 'dayjs'

const List = styled(MuiList)`
  padding-top: 0;

  & .MuiListItem-root {
    background: ${(props) => props.theme.palette.background.paper};
    margin-bottom: ${(props) => props.theme.spacing(6)};
    padding: ${(props) => props.theme.spacing(3)};
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    &:hover {
      background: ${(props) => convertHexToRgbA(props.theme.palette.secondary.dark, 15)};
    }
  }

  & .MuiButtonBase-root:hover {
    background: none;
  }
`

const StyledChipHighlight = styled(MuiChip)`
  height: 20px;
  font-size: 85%;
  font-weight: bold;
  background-color: ${(props) => props.theme.chip.background};
  color: ${(props) => props.theme.chip.color};

  & .MuiChip-icon {
    color: ${(props) => props.theme.chip.color};
  }

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`
const StyledBookingIcon = styled(OriginalBookingIcon)`
  width: 35px;
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(2)};
  fill: white;
`

const StyledOpportunityIcon = styled(OriginalOpportunityIcon)`
  width: 35px;
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(2)};
  fill: white;
`

const StyledRenewIcon = styled(OriginalRenewIcon)`
  width: 35px;
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(2)};
  fill: white;
`

const StyledServiceClientIconIcon = styled(OriginalServiceClientIcon)`
  width: 35px;
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(2)};
  fill: white;
`
const StyledInvoiceIcon = styled(OriginalInvoiceIcon)`
  width: 35px;
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(2)};
  fill: white;
`

interface IDashboardListItem {
  label: string | number
  icon: any
  text: any
  link?: string
}
const DashboardListItem = ({ label, icon, text, link }: IDashboardListItem) => {
  return (
    <Link to={link ?? ''} style={{ textDecoration: 'none' }}>
      <ListItem>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="h4"
                color="text.primary"
              >
                {text}
              </Typography>
            </React.Fragment>
          }
        />
        <IconButton edge="end" size={'small'}>
          <StyledChipHighlight label={label} />
        </IconButton>
      </ListItem>
    </Link>
  )
}

interface DashboardListOtherInformation {
  otherInformations: DashboardOtherInformation
  center: string
  isLoading: boolean
}

export function DashboardListOtherInformation({
  otherInformations,
  center,
  isLoading
}: DashboardListOtherInformation) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { user } = useApp()
  const skeleton = (
    <Skeleton height={65} sx={{ transform: 'scale(1,1)', marginBottom: theme.spacing(6) }} />
  )
  return (
    <Grid container>
      <Grid item xs={6} sm={6} md={12}>
        {isLoading ? (
          <>
            {skeleton}
            {skeleton}
            {skeleton}
          </>
        ) : (
          <List sx={{ paddingBottom: 0 }}>
            <DashboardListItem
              label={otherInformations.nbClientRequests}
              icon={<StyledServiceClientIconIcon />}
              text={t('client_service')}
              link={`/clientrequests?status=[${otherInformations.clientRequestsStatus}]&center=${center}`}
            />
            <DashboardListItem
              label={otherInformations.nbCustomerReservations}
              icon={<StyledBookingIcon />}
              text={t('waiting_reservations')}
              link={`/customer_reservations?status=[${otherInformations.customerReservationsStatus}]&center=${center}`}
            />
            <DashboardListItem
              label={otherInformations.nbInvoices}
              icon={<StyledInvoiceIcon />}
              link={`/invoices?status=[${otherInformations.invoicesStatus}]&center=${center}`}
              text={
                <>
                  {t('late_invoices')} |{' '}
                  <Box component={'span'} style={{ color: 'red' }}>
                    {formatCurrency(otherInformations.lateInvoiceAmount)}
                  </Box>
                </>
              }
            />
          </List>
        )}
      </Grid>
      <Grid item xs={6} sm={6} md={12}>
        {isLoading ? (
          <>
            {skeleton}
            {skeleton}
          </>
        ) : (
          <List>
            <DashboardListItem
              label={otherInformations.nbRenewal}
              icon={<StyledRenewIcon />}
              text={t('renewal')}
              link={`/endorsements?states=[${otherInformations.renewalStatus}]&center=${center}`}
            />
            <DashboardListItem
              label={otherInformations.nbOpportunity}
              icon={<StyledOpportunityIcon />}
              text={t('waiting_opportunity')}
              link={`/opportunities?status=${otherInformations.opportunityStatus}&step=${otherInformations.opportunityStep}&center=${center}`}
            />
            <DashboardListItem
              label={otherInformations.nbEvents}
              icon={<StyledBookingIcon />}
              text={t('waiting_events')}
              link={`/events?status=1&staff=${user?.id}&center=${center}&begin=${dayjs().format(
                'YYYY-MM-DD'
              )}`}
            />
          </List>
        )}
      </Grid>
    </Grid>
  )
}
