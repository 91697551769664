import styled from '@emotion/styled'
import { Paper } from '@mui/material'
import { Navigate } from 'react-router-dom'

import { useApp } from 'app/providers/app.provider'

interface IAppProviderProps {
  children: React.ReactNode
}

const MainContent = styled(Paper)`
  min-height: 100vh;
  display: flex;
`

function LoggedLayout({ children }: IAppProviderProps) {
  const { user } = useApp()

  if (!user) {
    return <Navigate to="/sign-in" />
  }

  return <MainContent>{children}</MainContent>
}

export default LoggedLayout
