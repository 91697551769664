import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { EndorsementCommissionList, EndorsementList, SelectCommitments } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const endorsementsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<EndorsementList>({
      url: `${BASE_URL}${API.GET_ENDORSEMENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  addService: async ({ token }: Ctx, id: string, service: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_ENDORSEMENT_SERVICE, { id })}`,
      method: 'PATCH',
      token: token,
      body: {
        service
      }
    }),
  sendToDirection: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENDORSEMENT_SEND_TO_DIRECTION, { id })}`,
      method: 'PATCH',
      token: token
    }),
  validate: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENDORSEMENT_VALIDATE, { id })}`,
      method: 'PATCH',
      token: token
    }),
  getSelectCommitments: async ({ token }: Ctx, id: number) =>
    fetcher<SelectCommitments>({
      url: `${BASE_URL}${urlHelper(API.ENDORSEMENT_SELECT_COMMITMENTS, { id })}`,
      method: 'GET',
      token: token
    })
}
