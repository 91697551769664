import React, { createContext, useContext, ReactNode, useCallback } from 'react'
import {
  UseMutationResult,
  useMutation,
  useQuery,
  UseQueryResult,
  DefaultError,
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery
} from '@tanstack/react-query'
import {
  useSearchParams,
  SetURLSearchParams,
  URLSearchParamsInit,
  NavigateOptions
} from 'react-router-dom'
import { appRouter } from 'api'
import {
  ContractSum,
  EnterprisesListType,
  ContractList,
  Filters,
  FiltersInput,
  FilterClientInput,
  FilterClient,
  FilterCenterInput,
  FilterCenter,
  InvoiceList,
  ContractsOptionsSum,
  ContractsOptionsList,
  ContractUsers,
  ContractServiceLinkUser,
  OptionList,
  InvoiceSum,
  CreditList,
  CreditSum,
  OccupancySum,
  EnterpriseInformation,
  OccupancyList,
  RightList,
  Right,
  LitigationList,
  OpportunityList,
  FilterStaff,
  FilterStaffInput,
  IndividualList,
  CheckoutList,
  EnterpriseMembersList,
  EnterpriseOpportunitiesList,
  EnterpriseContractsList,
  FormItemsInput,
  FormItems,
  EventFormData,
  EventsList,
  ClientContractList,
  ClientContractParams,
  EnterpriseDocumentsList,
  ConsumptionList,
  ConsumptionsList,
  ConsumptionsListParams,
  UpdateClientLinkProps,
  CheckoutSum,
  MandatesList,
  MainList,
  MainSum,
  PrescriberList,
  Payment,
  PaymentsList,
  PaymentsSum,
  AccountingDocumentsList,
  QuotationsList,
  ContractsConsumptionsList,
  AccountingClientsList,
  ContractsConsumptionsSum,
  DuplicateEnterprisesList,
  IndexingList,
  EndorsementList,
  ClientCenter,
  ClientRequestList,
  ClientRequestSum,
  CustomerReservationList,
  CustomerReservationSum,
  EnterpriseDocumentsListParams,
  FilterMembers,
  FilterMemberInput,
  AccountingPaymentsList,
  IndividualDetails,
  InvoiceInformation,
  ClientLinkList,
  IndividualConsumptions,
  IndividualInvoices,
  IndividualDevices,
  InvoicePdf,
  Activities,
  CreditInformation,
  CreditPdf,
  CreditInvoiceSelect,
  CreditInvoice,
  CreditPayment,
  Contract,
  ContractMainList,
  ContractOptionList,
  ContractConsumptionList,
  ContractInvoiceList,
  CreditDeclarePayment,
  ContractIndexingAnniversaryList,
  QuotationInformation,
  QuotationSolutions,
  LinkIndividualProps,
  Opportunity,
  OpportunityQuotations,
  OpportunityContracts,
  CustomerReservationInformations,
  CustomerReservationServices,
  FormItemsInputWithFilters,
  IdReturn,
  IndividualFormDetails,
  ListFloors,
  FloorPlans,
  FilterContracts,
  FilterContractsParams,
  FloorPlansParams,
  OpportunityEvents,
  OpportunityUsers,
  SelectCenters,
  TermsOfSalesList,
  IndividualWifi,
  WifiPasswordUpdate,
  IndividualPartners,
  IndividualAccessList,
  FormVisorAccessData,
  AccessGroup,
  MessageList,
  Visors,
  PaymentMethod,
  BankAccount,
  Payments,
  EndorsementContract,
  AvailableDesktopsSearch,
  Litigation,
  SelectCommitments,
  QuotationSolutionPrice,
  FilterSelectSearch,
  FilterSelectInput,
  DiscountGridsList,
  DiscountGridsCenterList,
  DiscountGridsCenterBody,
  DiscountGridsCenterResponse,
  DiscountGridRead,
  StaffsList,
  OptionService,
  PrescriberInformation,
  PrescriberMembers,
  PrescriberOpportunities,
  CenterPricesList,
  CreateCenterPriceInputs,
  CenterBlockedPrices,
  CentersList,
  MainService,
  MainServiceBody,
  TertiariesList,
  CreateUpdateStaffForm,
  StaffDetails,
  OptionServiceBody,
  ParkingServiceAddBody,
  ParkingServiceBody,
  TermOfSalesItem,
  CompaniesListSchema,
  CompanyCenters,
  Company,
  CompanyBankAccounts,
  CompanyBankAccountBody,
  CompanyBankAccount,
  CompanyConfiguration,
  MainServiceAddBody,
  MainServiceComputePriceBody,
  TertiaryCreate,
  TertiaryBody,
  TypologiesList,
  UploadTermOfSalesInput,
  UpdateTermOfSalesInput,
  ConsumptionService,
  CenterClustersList,
  CenterDetails,
  AccessRoom,
  AddClusterProps,
  CommitmentsList,
  CenterDiscountsList,
  CenterDiscountsNotActive,
  CreateCenterDiscount,
  CreateCenterDataDiscount,
  EmailTemplatesListType,
  EmailTemplateSheetType,
  AuthentificationRadiusList,
  HappeningsList,
  CreateHappeningForm,
  HappeningsListPartner,
  HappeningsDetails,
  UpdateHappening,
  CreateHappeningPartnerForm,
  ContractUpdate,
  CryptResType,
  StaffsListAttribution,
  VariousOperationListType,
  MatchingTransfersExceptionsList,
  CenterBp,
  ActualPipeline,
  NotificationList,
  NotificationUpdate,
  PresenceLogsList,
  PresenceConsumptionList,
  PresenceConsumptionSum,
  PresenceScheduleList,
  ConsumptionsServiceBody,
  CenterAddProps,
  StaffDefault,
  EditClusterProps,
  ServicesTypesList,
  AssetsList,
  DiscountGridAdd,
  AttributionList,
  PostCompany
} from 'api/models'
import { useApp } from 'app/providers/app.provider'
import {
  OpportunityCreateFormData,
  OpportunityUpdateFormProps,
  QuotationCreateFormData
} from 'api/models/forms/opportunities'
import { EmailTemplateForm } from 'api/models/forms/email_template'
import { UpdateQuotationFormItems } from 'api/models/forms/quotations'
import { ConsumptionPricing, PricingConsumptionInputs } from 'api/models/pricing'
import { AddReservationConsumptionItems } from 'api/models/forms/consumptions'
import { CreateEnterpriseForm } from 'api/models/forms/enterprises'
import { ClientRequestFormProps } from 'api/models/forms/client_requests'
import { PlanningDesktops } from 'api/models/plannings'
import {
  DashboardContract,
  DashboardMeetings,
  DashboardNextEntries,
  DashboardOccupations,
  DashboardOpenDesktops,
  DashboardOtherInformation,
  DashboardVisits
} from 'api/models/dashboard'
import { CreateCustomerReservation, LinkToAccess } from 'api/models/forms/customer_reservations'
import { ServicePrice } from 'api/models'
import {
  ConsumptionContract,
  ConsumptionContractConsumptions
} from 'api/models/consumption-contracts'
import { Document } from 'api/models'
import { CancelContractProps, UpdateAdminContractProps } from 'api/models/forms/contract'
import { EndorsementUpdateData } from 'api/models/forms/endorsement'
import { AcquisitionCostList, CreateSourceFormData, SourcesList } from 'api/models/sources'
import {
  AccountingStats,
  AcquisitionsStats,
  ClientRequest,
  Inventories,
  MonthlyStats,
  Occupations,
  PerformanceStats,
  StatsCA,
  Surface
} from 'api/models/stats'
import { WifiLogsList, WifiClientLogsList } from 'api/models/wifi'
import { UpdateAcquisitionCostProps } from 'api/models/forms/sources'
import { ClientCenterForm } from 'api/models/forms/client_center'
import { ClientCenterMailForm } from 'api/models/forms/client_center'
import { ContactsByContractList } from 'api/models/contacts'
import { IdLabelItem, IdLabelItems, infiniteQueryDefaultParams } from 'api/models/commons'

type QueryInfiniteOptions<T> = Omit<
  UseInfiniteQueryOptions<T, DefaultError, InfiniteData<T>, T, QueryKey, number>,
  'queryKey' | 'queryFn' | 'getPreviousPageParam' | 'getNextPageParam' | 'initialPageParam'
> & {
  additionalQueryKeys?: Array<unknown>
}
type QueryInfiniteHook<T> = (
  options?: QueryInfiniteOptions<T>
) => UseInfiniteQueryResult<InfiniteData<T, unknown>, Error>

interface IFetcherContext {
  getDocument: UseMutationResult<Document, unknown, { id: string }, unknown>
  invalidateDocument: UseMutationResult<void, unknown, { id: string }, unknown>
  validateDocument: UseMutationResult<void, unknown, { id: string }, unknown>
  activateAccount: UseMutationResult<void, unknown, { id: string }, unknown>
  searchParams: URLSearchParams
  setSearchParams: SetURLSearchParams
  getActivities: UseMutationResult<
    Activities,
    unknown,
    { type: string; id?: string; page: number; activityType: string },
    unknown
  >
  getEmailTemplates: UseMutationResult<EmailTemplatesListType, unknown, void, unknown>
  getOneEmailTemplate: UseMutationResult<EmailTemplateSheetType, unknown, string, unknown>
  updateEmailTemplate: UseMutationResult<void, unknown, EmailTemplateForm, unknown>
  getCompanies: UseMutationResult<CompaniesListSchema, unknown, void, unknown>
  getCompany: UseMutationResult<Company, unknown, number, unknown>
  getCompanyCenters: UseMutationResult<CompanyCenters, unknown, number, unknown>
  getCompanyConfigurations: UseMutationResult<CompanyConfiguration, unknown, number, unknown>
  createCompanyBankAccount: UseMutationResult<IdReturn, unknown, CompanyBankAccountBody, unknown>
  updateCompanyBankAccount: UseMutationResult<
    void,
    unknown,
    { id: number; data: CompanyBankAccountBody },
    unknown
  >
  getCompanyBankAccounts: UseMutationResult<CompanyBankAccounts, unknown, number, unknown>
  getCompanyBankAccount: UseMutationResult<CompanyBankAccount, unknown, number, unknown>
  availableCentersCompany: UseMutationResult<IdLabelItems, unknown, void, unknown>
  postCompany: UseMutationResult<void, unknown, PostCompany, unknown>
  getEnterprises: UseMutationResult<EnterprisesListType, unknown, void, unknown>
  getEnterprise: UseMutationResult<EnterpriseInformation, unknown, string, unknown>
  getCenterDiscounts: UseMutationResult<CenterDiscountsList, unknown, void, unknown>
  getCenterDiscountsNotActive: UseMutationResult<CenterDiscountsNotActive, unknown, void, unknown>
  createCenterDiscount: UseMutationResult<
    CreateCenterDiscount,
    unknown,
    CreateCenterDataDiscount,
    unknown
  >
  getEnterpriseDocuments: UseMutationResult<
    EnterpriseDocumentsList,
    unknown,
    EnterpriseDocumentsListParams,
    unknown
  >
  getEnterpriseMembers: UseMutationResult<EnterpriseMembersList, unknown, string, unknown>
  getEnterpriseOpportunities: UseMutationResult<
    EnterpriseOpportunitiesList,
    unknown,
    string,
    unknown
  >
  getEnterpriseContracts: UseMutationResult<EnterpriseContractsList, unknown, string, unknown>
  getEnterpriseSsids: UseMutationResult<any, unknown, string, unknown>
  getEnterpriseSsidsUsers: UseMutationResult<any, unknown, { id: string; ssid: string }, unknown>
  createEnterpriseSsid: UseMutationResult<void, unknown, { id: string; data: any }, unknown>
  createEnterpriseSsidUser: UseMutationResult<
    void,
    unknown,
    { id: string; ssid: string; data: any },
    unknown
  >
  updateEnterpriseSsidUser: UseMutationResult<
    void,
    unknown,
    { id: string; ssid: string; ssidUser: string; data: any },
    unknown
  >
  linkEnterpriseMember: UseMutationResult<
    void,
    unknown,
    { id: string; data: LinkIndividualProps },
    unknown
  >
  createEnterprise: UseMutationResult<IdReturn, unknown, CreateEnterpriseForm, unknown>
  createEnterpriseMember: UseMutationResult<void, unknown, IndividualFormDetails, unknown>
  createEnterpriseOpportunity: UseMutationResult<
    IdReturn,
    unknown,
    { id: string; data: OpportunityCreateFormData },
    unknown
  >
  createEnterprisePrescriber: UseMutationResult<
    void,
    unknown,
    { id: number; type: string },
    unknown
  >
  updateEnterprise: UseMutationResult<void, unknown, EnterpriseInformation>
  getEvents: UseMutationResult<EventsList, unknown, string, unknown>
  getEventsList: UseMutationResult<EventsList, unknown, void, unknown>
  getHappeningsList: UseMutationResult<HappeningsList, unknown, void, unknown>
  createHappening: UseMutationResult<IdReturn, unknown, CreateHappeningForm, unknown>
  updateHappening: UseMutationResult<void, unknown, { id: number; data: UpdateHappening }>
  getHappeningsPartners: UseMutationResult<HappeningsListPartner, unknown, void, unknown>
  createPartners: UseMutationResult<IdReturn, unknown, CreateHappeningPartnerForm, unknown>
  getHappeningsDetails: UseMutationResult<HappeningsDetails, unknown, number>
  agreeContract: UseMutationResult<void, unknown, number, unknown>
  cancelContract: UseMutationResult<
    void,
    unknown,
    { id: number; data: CancelContractProps },
    unknown
  >
  downloadContract: UseMutationResult<void, unknown, { id: number; reference: string }, unknown>
  validateContract: UseMutationResult<void, unknown, number, unknown>
  changeContractService: UseMutationResult<
    void,
    unknown,
    {
      id: string
      contractServiceId: number
      data: {
        contractServiceId: number
        serviceId: number
      }
    }
  >
  getContracts: UseMutationResult<ContractList, unknown, void, unknown>
  getContractSum: UseMutationResult<ContractSum, unknown, void, unknown>
  getContractExport: UseMutationResult<void, unknown, void, unknown>
  createContractEndorsement: UseMutationResult<any, unknown, { id: number; date: string }, unknown>
  updateContractEndorsement: UseMutationResult<
    any,
    unknown,
    { id: number; data: EndorsementUpdateData },
    unknown
  >
  getContract: UseMutationResult<Contract, unknown, number, unknown>
  getContractEndorsements: UseMutationResult<EndorsementContract, unknown, number, unknown>
  getContractAutoRenewalEndorsements: UseMutationResult<
    EndorsementContract,
    unknown,
    number,
    unknown
  >
  getContractSelectOptions: UseMutationResult<
    any,
    unknown,
    { id: number; serviceTypeReference: string },
    unknown
  >
  stopContractService: UseMutationResult<
    void,
    unknown,
    { id: number; serviceId: number; end: string },
    unknown
  >
  getContractMain: UseMutationResult<ContractMainList, unknown, void, unknown>
  getContractOption: UseMutationResult<ContractOptionList, unknown, void, unknown>
  getContractConsumption: UseMutationResult<ContractConsumptionList, unknown, void, unknown>
  getContractInvoice: UseMutationResult<ContractInvoiceList, unknown, void, unknown>
  getContractLitigations: UseMutationResult<any, unknown, number, unknown>
  getContractPayments: UseMutationResult<any, unknown, number, unknown>
  getContractUsers: UseMutationResult<ContractUsers, unknown, number, unknown>
  getContractIndexingAnniversary: UseMutationResult<
    ContractIndexingAnniversaryList,
    unknown,
    void,
    unknown
  >
  linkContractServiceUser: UseMutationResult<
    IdReturn,
    unknown,
    { id: number; data: ContractServiceLinkUser },
    unknown
  >
  updateContractOccupant: UseMutationResult<
    void,
    unknown,
    { id: number; occupants: { [key: string]: number } }
  >
  updateContractAdmin: UseMutationResult<
    void,
    unknown,
    { id: number; data: UpdateAdminContractProps },
    unknown
  >
  getDashboardContracts: UseMutationResult<DashboardContract, unknown, void, unknown>
  getDashboardOccupations: UseMutationResult<DashboardOccupations, unknown, void, unknown>
  getWifiLogs: UseMutationResult<WifiLogsList, unknown, void, unknown>
  getDashboardVisits: UseMutationResult<DashboardVisits, unknown, void, unknown>
  getDashboardNextEntries: UseMutationResult<DashboardNextEntries, unknown, void, unknown>
  getDashboardMeetings: UseMutationResult<DashboardMeetings, unknown, void, unknown>
  getDashboardOpenDesktops: UseMutationResult<DashboardOpenDesktops, unknown, void, unknown>
  getDashboardOtherInformation: UseMutationResult<DashboardOtherInformation, unknown, void, unknown>
  getInvoices: UseMutationResult<InvoiceList, unknown, void, unknown>
  getInvoiceSum: UseMutationResult<InvoiceSum, unknown, void, unknown>
  getInvoice: UseMutationResult<InvoiceInformation, unknown, number, unknown>
  getInvoicePdf: UseMutationResult<InvoicePdf, unknown, number, unknown>
  getInvoiceActivities: UseMutationResult<
    Activities,
    unknown,
    { id: number; type: string },
    unknown
  >
  getCreditActivities: UseMutationResult<Activities, unknown, string, unknown>
  getFilters: UseMutationResult<Filters, unknown, { variables: FiltersInput; params: any }, unknown>
  getFormItems: UseMutationResult<FormItems, unknown, FormItemsInput, unknown>
  useGetFormItems: (variables: FormItemsInput) => UseQueryResult<FormItems, unknown>
  getFormItemsWithFilters: UseMutationResult<FormItems, unknown, FormItemsInputWithFilters, unknown>
  getClientFilters: UseMutationResult<FilterClient, unknown, FilterClientInput, unknown>
  getCenter: UseMutationResult<CenterDetails, unknown, string, unknown>
  useGetCenter: (id: string) => UseQueryResult<CenterDetails, unknown>
  getCenterAccessGroups: UseMutationResult<AccessGroup[], unknown, string, unknown>
  getCenterAccessRooms: UseMutationResult<
    AccessRoom[],
    unknown,
    { id: string; status: string | null; type: string | null },
    unknown
  >
  getCenterFilters: UseMutationResult<FilterCenter, unknown, FilterCenterInput, unknown>
  getCentersSelect: UseMutationResult<SelectCenters, unknown, FilterCenterInput, unknown>
  getCenterBlockedPrices: UseMutationResult<CenterBlockedPrices, unknown, number, unknown>
  getCenterPrices: UseMutationResult<CenterPricesList, unknown, void, unknown>
  getBusinessPlan: UseMutationResult<CenterBp[], unknown, void, unknown>
  uploadBusinessPlanCSV: UseMutationResult<void, unknown, File | FileList>
  updateCenter: UseMutationResult<void, unknown, { id: number; data: CenterDetails }, unknown>
  getTertiaries: UseMutationResult<TertiariesList, unknown, void, unknown>
  getTypologies: UseMutationResult<TypologiesList, unknown, void, unknown>
  getCommitments: UseMutationResult<CommitmentsList, unknown, void, unknown>
  createTertiary: UseMutationResult<TertiaryCreate, unknown, TertiaryBody, unknown>
  createCenterPrice: UseMutationResult<IdReturn, unknown, CreateCenterPriceInputs, unknown>
  getCentersClusters: UseMutationResult<CenterClustersList, unknown, void, unknown>
  createCenterCluster: UseMutationResult<IdReturn, unknown, AddClusterProps, unknown>
  updateCenterCluster: UseMutationResult<
    void,
    unknown,
    { id: number; data: EditClusterProps },
    unknown
  >
  availableCentersCluster: UseMutationResult<IdLabelItem[], unknown, number, unknown>
  getCenterVisors: UseMutationResult<Visors, unknown, void, unknown>
  getEnterprisePrescriber: UseMutationResult<
    PrescriberInformation,
    unknown,
    { prescriberId: number; enterpriseId: number },
    unknown
  >
  getPrescriberMembers: UseMutationResult<
    PrescriberMembers,
    unknown,
    { prescriberId: number; enterpriseId: number },
    unknown
  >
  getPrescriberOpportunities: UseMutationResult<
    PrescriberOpportunities,
    unknown,
    { prescriberId: number; enterpriseId: number },
    unknown
  >
  createPrescriberMemberSource: UseMutationResult<
    void,
    unknown,
    { prescriberId: number; enterpriseId: number; individualId: number }
  >
  getEnterpriseMembersFilters: UseMutationResult<FilterMembers, unknown, FilterMemberInput, unknown>
  getWifiSsidsFilters: UseMutationResult<FilterSelectSearch, unknown, FilterSelectInput, unknown>
  getWifiStationsFilters: UseMutationResult<FilterSelectSearch, unknown, FilterSelectInput, unknown>
  getPayment: UseMutationResult<Payment, unknown, number, unknown>
  updatePayment: UseMutationResult<void, unknown, { id: number; state: number }, unknown>
  deletePayment: UseMutationResult<void, unknown, { id: number }, unknown>
  useGetPayment: (id: number) => UseQueryResult<Payment, unknown>
  getPayments: UseMutationResult<PaymentsList, unknown, void, unknown>
  getPaymentsSum: UseMutationResult<PaymentsSum, unknown, void, unknown>
  getContractsConsumptions: UseMutationResult<ContractsConsumptionsList, unknown, void, unknown>
  getContractsConsumptionsSum: UseMutationResult<ContractsConsumptionsSum, unknown, void, unknown>
  getLitigations: UseMutationResult<LitigationList, unknown, void, unknown>
  getLitigation: UseMutationResult<Litigation, unknown, number, unknown>
  getLitigationContracts: UseMutationResult<any, unknown, number, unknown>
  getLitigationInvoices: UseMutationResult<any, unknown, number, unknown>
  getContactByContract: UseMutationResult<ContactsByContractList, unknown, void, unknown>
  getContactExport: UseMutationResult<void, unknown, void, unknown>
  getOpportunities: UseMutationResult<OpportunityList, unknown, void, unknown>
  getOpportunity: UseMutationResult<Opportunity, unknown, string, unknown>
  getOpportunityContracts: UseMutationResult<OpportunityContracts, unknown, string, unknown>
  getOpportunityEvents: UseMutationResult<OpportunityEvents, unknown, string, unknown>
  getOpportunityUsers: UseMutationResult<OpportunityUsers, unknown, string, unknown>
  getOpportunitySolutions: UseMutationResult<OpportunityQuotations, unknown, string, unknown>
  updateOpportunity: UseMutationResult<
    void,
    unknown,
    { id: string; data: OpportunityUpdateFormProps },
    unknown
  >
  getActualPipeline: UseMutationResult<ActualPipeline, unknown, void, unknown>
  getStaffFilters: UseMutationResult<FilterStaff, unknown, FilterStaffInput, unknown>
  getContractsOptions: UseMutationResult<ContractsOptionsList, unknown, void, unknown>
  getContractsOptionsSum: UseMutationResult<ContractsOptionsSum, unknown, void, unknown>
  updateContract: UseMutationResult<
    void,
    unknown,
    {
      id: number
      data: ContractUpdate
    }
  >
  getCredits: UseMutationResult<CreditList, unknown, void, unknown>
  getCreditSum: UseMutationResult<CreditSum, unknown, void, unknown>
  getCreditExport: UseMutationResult<void, unknown, void, unknown>
  getCredit: UseMutationResult<CreditInformation, unknown, void, unknown>
  getCreditPdf: UseMutationResult<CreditPdf, unknown, void, unknown>
  getCreditInvoiceSelect: UseMutationResult<CreditInvoiceSelect[], unknown, void, unknown>
  updateCreditRegeneratePdf: UseMutationResult<void, unknown, void, unknown>
  updateCreditApplyInvoice: UseMutationResult<void, unknown, { id: string; invoiceId: string }>
  addCreditDeclarePayment: UseMutationResult<void, unknown, CreditDeclarePayment & { id: string }>
  getCreditInvoice: UseMutationResult<CreditInvoice[], unknown, void, unknown>
  getCreditPayment: UseMutationResult<CreditPayment[], unknown, void, unknown>
  getOptions: UseMutationResult<OptionList, unknown, void, unknown>
  getDiscountGrids: UseMutationResult<DiscountGridsList, unknown, void, unknown>
  getDiscountGrid: UseMutationResult<DiscountGridRead, unknown, { id: string }, unknown>
  createDiscountGrid: UseMutationResult<void, unknown, DiscountGridAdd>
  getDiscountGridsCenter: UseMutationResult<DiscountGridsCenterList, unknown, void, unknown>
  getDiscountGridsCenterAdd: UseMutationResult<
    DiscountGridsCenterResponse,
    unknown,
    DiscountGridsCenterBody,
    unknown
  >
  getServiceCommitmentPrice: UseMutationResult<
    any,
    unknown,
    { id: string; commitment: number },
    unknown
  >
  getServiceAvailability: UseMutationResult<
    { message: string },
    unknown,
    { id: string; begin?: string },
    unknown
  >
  getServiceAvailableDesktops: UseMutationResult<any, unknown, AvailableDesktopsSearch, unknown>
  getServicePricing: UseMutationResult<ServicePrice, unknown, any, unknown>
  getServicesAvailable: UseMutationResult<any, unknown, any, unknown>
  getServiceConsumptionsPricing: UseMutationResult<
    ConsumptionPricing,
    unknown,
    PricingConsumptionInputs,
    unknown
  >
  getServicesOccupancy: UseMutationResult<OccupancyList, unknown, void, unknown>
  getServicesOccupancySum: UseMutationResult<OccupancySum, unknown, void, unknown>
  getServicesOccupancyExport: UseMutationResult<void, unknown, void, unknown>
  getConsumptionContract: UseMutationResult<ConsumptionContract, unknown, string, unknown>
  getConsumptionContractConsumptions: UseMutationResult<
    ConsumptionContractConsumptions,
    unknown,
    string,
    unknown
  >
  getConsumptions: UseMutationResult<ConsumptionList, unknown, void, unknown>
  getIndividual: UseMutationResult<IndividualDetails, unknown, string, unknown>
  getIndividualAccess: UseMutationResult<IndividualAccessList, unknown, string, unknown>
  getIndividualEnterprises: UseMutationResult<ClientLinkList, unknown, string, unknown>
  getIndividualConsumptions: UseMutationResult<IndividualConsumptions, unknown, string, unknown>
  getIndividualDevices: UseMutationResult<IndividualDevices, unknown, string, unknown>
  getIndividualEvents: UseMutationResult<EventsList, unknown, string, unknown>
  getIndividualInvoices: UseMutationResult<IndividualInvoices, unknown, string, unknown>
  getIndividuals: UseMutationResult<IndividualList, unknown, void, unknown>
  getIndividualPartners: UseMutationResult<IndividualPartners, unknown, string, unknown>
  updateActivCorner: UseMutationResult<void, unknown, string, unknown>
  getIndividualWifi: UseMutationResult<IndividualWifi, unknown, string, unknown>
  createIndividualAccess: UseMutationResult<
    void,
    unknown,
    { id: number; data: FormVisorAccessData },
    unknown
  >
  createWifiCredentials: UseMutationResult<
    void,
    unknown,
    { id: string; data: WifiPasswordUpdate },
    unknown
  >
  updateWifiPassword: UseMutationResult<
    void,
    unknown,
    { id: string; data: WifiPasswordUpdate },
    unknown
  >
  sendWifiPassword: UseMutationResult<void, unknown, string, unknown>
  updateIndividual: UseMutationResult<void, unknown, { id: number; data: IndividualDetails }>
  updateIndividualAccess: UseMutationResult<
    void,
    unknown,
    { id: number; data: FormVisorAccessData },
    unknown
  >
  getRights: UseMutationResult<RightList, unknown, void, unknown>
  getRight: UseMutationResult<Right, unknown, string, unknown>
  editRight: UseMutationResult<unknown, unknown, Right>
  getCheckouts: UseMutationResult<CheckoutList, unknown, void, unknown>
  getCheckoutSum: UseMutationResult<CheckoutSum, unknown, void, unknown>
  getMandates: UseMutationResult<MandatesList, unknown, void, unknown>
  getPaymentMethod: UseMutationResult<PaymentMethod, unknown, number, unknown>
  getBankAccount: UseMutationResult<BankAccount, unknown, number, unknown>
  getPaymentMethodPayments: UseMutationResult<Payments, unknown, number, unknown>
  paymentMethodCancelled: UseMutationResult<any, unknown, number, unknown>
  getMainServices: UseMutationResult<MainList, unknown, void, unknown>
  getMainService: UseMutationResult<MainService, unknown, number, unknown>
  createMainService: UseMutationResult<IdReturn, unknown, MainServiceAddBody, unknown>
  updateMainService: UseMutationResult<void, unknown, { id: number; data: MainServiceBody }>
  computePrice: UseMutationResult<{ computedPrice: number }, unknown, MainServiceComputePriceBody>
  getOptionService: UseMutationResult<OptionService, unknown, number, unknown>
  updateOptionService: UseMutationResult<void, unknown, { id: number; data: OptionServiceBody }>
  createOptionService: UseMutationResult<void, unknown, { data: OptionServiceBody }>
  getMainServiceSum: UseMutationResult<MainSum, unknown, void, unknown>
  getParkingServices: UseMutationResult<any, unknown, void, unknown>
  getParkingService: UseMutationResult<MainService, unknown, number, unknown>
  getConsumptionService: UseMutationResult<ConsumptionService, unknown, number, unknown>
  updateConsumptionService: UseMutationResult<void, unknown, any, unknown>
  createConsumptionService: UseMutationResult<void, unknown, ConsumptionsServiceBody, unknown>
  createParkingService: UseMutationResult<IdReturn, unknown, ParkingServiceAddBody, unknown>
  updateParkingService: UseMutationResult<void, unknown, { id: number; data: ParkingServiceBody }>
  getPlanningsDesktops: UseMutationResult<PlanningDesktops, unknown, void, unknown>
  getPlanningsDesktopsFloors: UseMutationResult<Filters, unknown, void, unknown>
  getPrescribers: UseMutationResult<PrescriberList, unknown, void, unknown>
  getAccountingDocuments: UseMutationResult<AccountingDocumentsList, unknown, void, unknown>
  getAccountingVariousOperations: UseMutationResult<
    VariousOperationListType,
    unknown,
    void,
    unknown
  >
  getStaffs: UseMutationResult<StaffsList, unknown, void, unknown>
  useGetAttributableStaffs: QueryInfiniteHook<StaffsListAttribution>
  useGetDefaultStaffQuery: () => UseQueryResult<StaffDefault, unknown>
  useUpdateDefaultStaffMutation: () => UseMutationResult<void, unknown, number, unknown>
  getStaff: UseMutationResult<StaffDetails, unknown, number, unknown>
  createStaff: UseMutationResult<IdReturn, unknown, CreateUpdateStaffForm, unknown>
  updateStaff: UseMutationResult<void, unknown, CreateUpdateStaffForm, unknown>
  updateStaffLanguage: UseMutationResult<void, unknown, { language: number; id: number }, unknown>
  updateStaffIsAttributable: UseMutationResult<
    void,
    unknown,
    { isAttributable: boolean; id: number },
    unknown
  >
  createOrUpdateStaffPhoneSystems: UseMutationResult<void, unknown, CreateUpdateStaffForm, unknown>
  getStatsAcquisitions: UseMutationResult<AcquisitionsStats, unknown, void, unknown>
  getStatsAccounting: UseMutationResult<AccountingStats, unknown, void, unknown>
  getStatsOccupations: UseMutationResult<Occupations, unknown, void, unknown>
  getStatsInventories: UseMutationResult<Inventories, unknown, void, unknown>
  getStatsCA: UseMutationResult<StatsCA, unknown, void, unknown>
  getMonthlyStatsCaVsBp: UseMutationResult<MonthlyStats, unknown, void, unknown>
  getStatsCaVsBp: UseMutationResult<StatsCA, unknown, void, unknown>
  getAcquisitionsCost: UseMutationResult<AcquisitionCostList, unknown, void, unknown>
  getSources: UseMutationResult<SourcesList, unknown, void, unknown>
  createSource: UseMutationResult<IdReturn, unknown, CreateSourceFormData, unknown>
  uploadAcquisitionCostsCSV: UseMutationResult<void, unknown, any, unknown>
  updateAcquisitionCost: UseMutationResult<
    void,
    unknown,
    { id: number; data: UpdateAcquisitionCostProps },
    unknown
  >
  getStatsSurface: UseMutationResult<Surface, unknown, void, unknown>
  getStatsClientRequests: UseMutationResult<ClientRequest, unknown, void, unknown>
  getStatsPerformance: UseMutationResult<PerformanceStats, unknown, void, unknown>
  getInternalDocuments: UseMutationResult<TermsOfSalesList, unknown, string | undefined>
  getInternalDocument: UseMutationResult<
    TermOfSalesItem,
    unknown,
    { type: string; id: number },
    unknown
  >
  getInternalDocumentPdf: UseMutationResult<any, unknown, { type: string; id: number }, unknown>
  updateInternalDocument: UseMutationResult<
    void,
    unknown,
    { type: string; id: number; data: UpdateTermOfSalesInput },
    unknown
  >
  uploadTermOfSales: UseMutationResult<
    void,
    unknown,
    { type: string; data: UploadTermOfSalesInput },
    unknown
  >
  getQuotations: UseMutationResult<QuotationsList, unknown, void, unknown>
  getQuotation: UseMutationResult<QuotationInformation, unknown, string, unknown>
  getQuotationDownload: UseMutationResult<void, unknown, string, unknown>
  getQuotationSolutions: UseMutationResult<QuotationSolutions, unknown, string, unknown>
  cancelQuotation: UseMutationResult<void, unknown, string, unknown>
  sendQuotation: UseMutationResult<void, unknown, string, unknown>
  updateQuotation: UseMutationResult<void, unknown, UpdateQuotationFormItems, unknown>
  transformQuotationSolution: UseMutationResult<
    void,
    unknown,
    { id: number; solutionId: number },
    unknown
  >
  updateQuotationSolution: UseMutationResult<
    void,
    unknown,
    { id: number; solutionId: number; data: any },
    unknown
  >
  getQuotationSolutionPrice: UseMutationResult<
    QuotationSolutionPrice[],
    unknown,
    { id: number; solutionId: number; data: any },
    unknown
  >
  getQuotationSolutionCommitments: UseMutationResult<
    SelectCommitments,
    unknown,
    { id: number; solutionId: number },
    unknown
  >
  getDuplicateEnterprises: UseMutationResult<DuplicateEnterprisesList, unknown, void, unknown>
  getIndexing: UseMutationResult<IndexingList, unknown, void, unknown>
  addEndorsementService: UseMutationResult<void, unknown, { id: string; service: string }, unknown>
  getEndorsements: UseMutationResult<EndorsementList, unknown, void, unknown>
  getSelectCommitments: UseMutationResult<any, unknown, number, unknown>
  sendEndorsementToDirection: UseMutationResult<void, unknown, string, unknown>
  validateEndorsement: UseMutationResult<void, unknown, string, unknown>
  getClientCenter: UseMutationResult<
    ClientCenter,
    unknown,
    { id: string; centerId: string },
    unknown
  >
  updateClientCenter: UseMutationResult<
    void,
    unknown,
    { id: string; clientCenterId: string; data: ClientCenterForm },
    unknown
  >
  updateClientCenterMail: UseMutationResult<
    void,
    unknown,
    { id: string; clientCenterId: string; data: ClientCenterMailForm },
    unknown
  >
  getClientRequest: UseMutationResult<any, unknown, string, unknown>
  getClientRequests: UseMutationResult<ClientRequestList, unknown, void, unknown>
  getClientRequestSum: UseMutationResult<ClientRequestSum, unknown, void, unknown>
  updateClientRequest: UseMutationResult<void, unknown, ClientRequestFormProps, unknown>
  getAccountingClients: UseMutationResult<AccountingClientsList, unknown, void, unknown>
  getAccountingClientsExport: UseMutationResult<void, unknown, void, unknown>
  getAccountingClientsExportSage: UseMutationResult<void, unknown, void, unknown>
  getAccountingPayments: UseMutationResult<AccountingPaymentsList, unknown, void, unknown>
  getAccountingPaymentsExport: UseMutationResult<void, unknown, void, unknown>
  getAccountingVariousOperationsExport: UseMutationResult<void, unknown, void, unknown>
  getMatchingTransfersExceptions: UseMutationResult<
    MatchingTransfersExceptionsList,
    unknown,
    void,
    unknown
  >
  createCustomerReservation: UseMutationResult<
    IdReturn,
    unknown,
    CreateCustomerReservation,
    unknown
  >
  linkToAccessCustomerReservation: UseMutationResult<void, unknown, LinkToAccess, unknown>
  getCustomerReservations: UseMutationResult<CustomerReservationList, unknown, void, unknown>
  getCustomerReservationSum: UseMutationResult<CustomerReservationSum, unknown, void, unknown>
  getCustomerReservation: UseMutationResult<
    CustomerReservationInformations,
    unknown,
    string,
    unknown
  >
  getCustomerReservationServices: UseMutationResult<
    CustomerReservationServices,
    unknown,
    string,
    unknown
  >
  getCustomerReservationContracts: UseMutationResult<any, unknown, string, unknown>
  getCustomerReservationIndividuals: UseMutationResult<any, unknown, string, unknown>
  cancelCustomerReservation: UseMutationResult<void, unknown, string, unknown>
  confirmCustomerReservation: UseMutationResult<void, unknown, string, unknown>
  updateCustomerReservation: UseMutationResult<void, unknown, any, unknown>
  addCustomerReservationService: UseMutationResult<
    void,
    unknown,
    AddReservationConsumptionItems,
    unknown
  >
  updateClientLink: UseMutationResult<void, unknown, UpdateClientLinkProps, unknown>
  uploadDocument: UseMutationResult<void, unknown, { id: string; data: any }, unknown>
  getClientConsumptions: UseMutationResult<
    ConsumptionsList,
    unknown,
    ConsumptionsListParams,
    unknown
  >
  getClientContracts: UseMutationResult<ClientContractList, unknown, ClientContractParams, unknown>
  createContract: UseMutationResult<IdReturn, unknown, any, unknown>
  createContractServices: UseMutationResult<IdReturn, unknown, { id: number; params: any }, unknown>
  generatePartialContractInvoice: UseMutationResult<
    void,
    unknown,
    { id: number; contractServices: number[] },
    unknown
  >
  deleteContractService: UseMutationResult<
    void,
    unknown,
    { contractId: number; serviceId: number },
    unknown
  >
  createEvent: UseMutationResult<IdReturn, unknown, EventFormData, unknown>
  editEvent: UseMutationResult<void, unknown, { id: number; data: EventFormData }, unknown>
  getListFloors: UseMutationResult<ListFloors, unknown, void>
  getFloorPlans: UseMutationResult<FloorPlans, unknown, FloorPlansParams>
  getFilterContractsFloorPlans: UseMutationResult<FilterContracts, unknown, FilterContractsParams>
  getServicesPricer: UseMutationResult<any, unknown, { id: string }, unknown>
  getInformationsPricer: UseMutationResult<any, unknown, { id: string }, unknown>
  getDiscountGridsPricer: UseMutationResult<
    any,
    unknown,
    { center: string; begin: string },
    unknown
  >
  createQuotation: UseMutationResult<
    IdReturn,
    unknown,
    { opportunityId: string; data: QuotationCreateFormData },
    unknown
  >
  patchRegeneratePdfInvoice: UseMutationResult<any, unknown, number, unknown>
  patchAccountInvoice: UseMutationResult<any, unknown, number, unknown>
  patchUnAccountInvoice: UseMutationResult<any, unknown, number, unknown>
  patchBlockInvoice: UseMutationResult<any, unknown, number, unknown>
  patchUnBlockInvoice: UseMutationResult<any, unknown, number, unknown>
  patchLitigationInvoice: UseMutationResult<
    any,
    unknown,
    { id: number; data: { reference: string } },
    unknown
  >
  postLitigationContract: UseMutationResult<
    any,
    unknown,
    { id: number; reference: string },
    unknown
  >
  postUnCollectibleInvoice: UseMutationResult<any, unknown, number, unknown>
  postManualReminderInvoice: UseMutationResult<any, unknown, number, unknown>
  getRowsInvoice: UseMutationResult<any, unknown, number, unknown>
  patchDiscountInvoice: UseMutationResult<any, unknown, { id: number; data: any }>
  patchLanguageInvoice: UseMutationResult<any, unknown, { id: number; data: { language: number } }>
  postManualBankDebitInvoice: UseMutationResult<any, unknown, { id: number; data: string }>
  postAddPaymentInvoice: UseMutationResult<any, unknown, { id: number; data: any }>
  patchLinkPaymentInvoice: UseMutationResult<any, unknown, { id: number; payment: number }>
  patchUnLinkPaymentInvoice: UseMutationResult<any, unknown, { id: number; payment: number }>
  patchCancelledPaymentInvoice: UseMutationResult<any, unknown, { id: number; payment: number }>
  resetPassword: UseMutationResult<void, unknown, { id: string }, unknown>
  getPaymentsInvoice: UseMutationResult<any, unknown, number>
  getCreditsInvoice: UseMutationResult<any, unknown, number>
  patchUnLinkCreditInvoice: UseMutationResult<any, unknown, { id: number; credit: number }>
  patchDueDateInvoice: UseMutationResult<any, unknown, { id: number; data: { dueDate: string } }>
  createActivity: UseMutationResult<any, unknown, any>
  patchLanguageCredit: UseMutationResult<any, unknown, any>
  getConsumptionsClient: UseMutationResult<any, unknown, any>
  getMessages: UseMutationResult<MessageList, unknown, void, unknown>
  getAuthentificationRadius: UseMutationResult<AuthentificationRadiusList, unknown, void, unknown>
  patchLitigation: UseMutationResult<void, unknown, { id: number; reference: string }, unknown>
  closeLitigation: UseMutationResult<void, unknown, number, unknown>
  individualDevicesUpdate: UseMutationResult<any, unknown, { id: number; data: any }>
  getWifiClientsLogs: UseMutationResult<WifiClientLogsList, unknown, void>
  getCenters: UseMutationResult<CentersList, unknown, void>
  createCenters: UseMutationResult<void, unknown, CenterAddProps>
  encrypt: UseMutationResult<CryptResType, unknown, { text: string }>
  decrypt: UseMutationResult<CryptResType, unknown, { text: string }>
  getNotifications: UseMutationResult<NotificationList, unknown, void>
  updateNotification: UseMutationResult<void, unknown, { id: number; data: NotificationUpdate }>
  useGetPresenceSchedules: QueryInfiniteHook<PresenceScheduleList>
  getAttributionLogs: UseMutationResult<AttributionList, unknown, void>
  getPresenceLogs: UseMutationResult<PresenceLogsList, unknown, void>
  getPresenceConsumption: UseMutationResult<PresenceConsumptionList, unknown, void>
  getPresenceConsumptionSum: UseMutationResult<PresenceConsumptionSum, unknown, void>
  getServicesTypes: UseMutationResult<ServicesTypesList, unknown, void>
  getAssets: UseMutationResult<AssetsList, unknown, { type: string; id: string }>
  useGetAssets: (params: {
    type: string
    id: string
    enabled?: boolean
  }) => UseQueryResult<AssetsList, unknown>
  useDeleteAsset: () => UseMutationResult<
    void,
    unknown,
    { type: string; id: string; imageId: string }
  >
  useAddAsset: () => UseMutationResult<
    void,
    unknown,
    { id: string; type: string; formData: FormData },
    unknown
  >
}

const FetcherContext = createContext<IFetcherContext>({} as IFetcherContext)

interface IFetcherProviderProps {
  children: ReactNode
}

export const FetcherProvider = ({ children }: IFetcherProviderProps): React.JSX.Element => {
  const { user } = useApp()
  const [searchParams, setParams] = useSearchParams()

  const setSearchParams = useCallback(
    (
      nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOpts?: NavigateOptions
    ) => {
      setParams(nextInit, { ...navigateOpts, replace: true })
    },
    [searchParams, setParams]
  )

  const activateAccount = useMutation<void, unknown, { id: string }>({
    mutationKey: ['clients', 'activate'],
    mutationFn: ({ id }) => appRouter.individuals.activate({ token: user?.token }, id)
  })

  const getActivities = useMutation<
    Activities,
    unknown,
    { type: string; id?: string; page: number; activityType: string }
  >({
    mutationKey: ['activities', 'list'],
    mutationFn: ({ type, page, id, activityType }) =>
      appRouter.activities.list({ token: user?.token }, type, page, id, activityType)
  })

  const getCompanies = useMutation<CompaniesListSchema>({
    mutationKey: ['companies', 'list'],
    mutationFn: () => appRouter.companies.list({ token: user?.token }, searchParams.toString())
  })

  const getCompany = useMutation<Company, unknown, number>({
    mutationKey: ['companies', 'get'],
    mutationFn: (id) => appRouter.companies.get({ token: user?.token }, id)
  })

  const getCompanyCenters = useMutation<any, unknown, number>({
    mutationKey: ['companies', 'centers'],
    mutationFn: (id) => appRouter.companies.getCenters({ token: user?.token }, id)
  })

  const getCompanyConfigurations = useMutation<CompanyConfiguration, unknown, number>({
    mutationKey: ['companies', 'config'],
    mutationFn: (id) => appRouter.companies.getConfigurations({ token: user?.token }, id)
  })

  const getCompanyBankAccounts = useMutation<CompanyBankAccounts, unknown, number>({
    mutationKey: ['companies', 'bank-account'],
    mutationFn: (id) => appRouter.companies.getBankAccounts({ token: user?.token }, id)
  })

  const createCompanyBankAccount = useMutation<IdReturn, unknown, CompanyBankAccountBody>({
    mutationKey: ['companies', 'bank-account', 'create'],
    mutationFn: (data) => {
      return appRouter.companies.createBankAccount(
        { token: user?.token },
        data.companyId || 0,
        data
      )
    }
  })

  const updateCompanyBankAccount = useMutation<
    void,
    unknown,
    { id: number; data: CompanyBankAccountBody }
  >({
    mutationKey: ['companies', 'bank-account', 'update'],
    mutationFn: ({ id, data }) =>
      appRouter.companies.updateBankAccount({ token: user?.token }, id, data)
  })

  const getCompanyBankAccount = useMutation<CompanyBankAccount, unknown, number>({
    mutationKey: ['companies', 'bank-account', 'get'],
    mutationFn: (id) => appRouter.companies.getBankAccount({ token: user?.token }, id)
  })

  const availableCentersCompany = useMutation<IdLabelItems>({
    mutationKey: ['available', 'centers'],
    mutationFn: () => appRouter.companies.getAvailableCenters({ token: user?.token })
  })

  const postCompany = useMutation<void, unknown, PostCompany, unknown>({
    mutationKey: ['companies', 'create'],
    mutationFn: (data) => appRouter.companies.postCompany({ token: user?.token }, data)
  })

  const getEnterprises = useMutation<EnterprisesListType>({
    mutationKey: ['enterprises', 'list'],
    mutationFn: () => appRouter.enterprises.list({ token: user?.token }, searchParams.toString())
  })

  const createEnterprise = useMutation<IdReturn, unknown, CreateEnterpriseForm>({
    mutationKey: ['clients', 'create'],
    mutationFn: (data) => appRouter.enterprises.create({ token: user?.token }, data)
  })

  const createEnterprisePrescriber = useMutation<void, unknown, { id: number; type: string }>({
    mutationKey: ['clients', 'prescriber', 'create'],
    mutationFn: ({ id, type }) =>
      appRouter.enterprises.createPrescriber({ token: user?.token }, id, type)
  })

  const agreeContract = useMutation<void, unknown, number>({
    mutationKey: ['contracts', 'cancel'],
    mutationFn: (id) => appRouter.contracts.agree({ token: user?.token }, id)
  })

  const cancelContract = useMutation<void, unknown, { id: number; data: CancelContractProps }>({
    mutationKey: ['contracts', 'cancel'],
    mutationFn: ({ id, data }) => appRouter.contracts.cancel({ token: user?.token }, id, data)
  })

  const downloadContract = useMutation<void, unknown, { id: number; reference: string }>({
    mutationKey: ['contracts', 'download'],
    mutationFn: ({ id, reference }) =>
      appRouter.contracts.download({ token: user?.token }, id, reference)
  })

  const validateContract = useMutation<void, unknown, number>({
    mutationKey: ['contracts', 'validate'],
    mutationFn: (id) => appRouter.contracts.validate({ token: user?.token }, id)
  })

  const createContractEndorsement = useMutation<any, unknown, { id: number; date: string }>({
    mutationKey: ['contracts', 'endorsements', 'create'],
    mutationFn: ({ id, date }) =>
      appRouter.contracts.createEndorsement({ token: user?.token }, id, date)
  })

  const updateContractEndorsement = useMutation<
    any,
    unknown,
    { id: number; data: EndorsementUpdateData }
  >({
    mutationKey: ['contracts', 'endorsements', 'update'],
    mutationFn: ({ id, data }) =>
      appRouter.contracts.updateEndorsement({ token: user?.token }, id, data)
  })
  const getContracts = useMutation<ContractList>({
    mutationKey: ['contracts', 'list'],
    mutationFn: () => appRouter.contracts.list({ token: user?.token }, searchParams.toString())
  })

  const getContractEndorsements = useMutation<EndorsementContract, unknown, number>({
    mutationKey: ['contracts', 'endorsements'],
    mutationFn: (id) => appRouter.contracts.getEndorsements({ token: user?.token }, id)
  })

  const getContractAutoRenewalEndorsements = useMutation<EndorsementContract, unknown, number>({
    mutationKey: ['contracts', 'endorsements'],
    mutationFn: (id) => appRouter.contracts.getAutoRenewalEndorsements({ token: user?.token }, id)
  })

  const getContractSelectOptions = useMutation<
    any,
    unknown,
    { id: number; serviceTypeReference: string }
  >({
    mutationKey: ['contracts', 'select-options'],
    mutationFn: ({ id, serviceTypeReference }) =>
      appRouter.contracts.getSelectOptions({ token: user?.token }, id, serviceTypeReference)
  })

  const stopContractService = useMutation<
    void,
    unknown,
    { id: number; serviceId: number; end: string }
  >({
    mutationKey: ['contracts', 'stop-service'],
    mutationFn: ({ id, serviceId, end }) =>
      appRouter.contracts.stopService({ token: user?.token }, id, serviceId, end)
  })

  const getContractSum = useMutation<ContractSum>({
    mutationKey: ['contracts', 'sum'],
    mutationFn: () => appRouter.contracts.getSum({ token: user?.token }, searchParams.toString())
  })

  const getContractExport = useMutation<void>({
    mutationKey: ['contracts', 'export'],
    mutationFn: () => appRouter.contracts.getExport({ token: user?.token }, searchParams.toString())
  })

  const getContract = useMutation<Contract, void, number>({
    mutationKey: ['contract', 'read'],
    mutationFn: (id) => appRouter.contracts.getRead({ token: user?.token }, id)
  })

  const updateContractAdmin = useMutation<
    void,
    unknown,
    { id: number; data: UpdateAdminContractProps }
  >({
    mutationKey: ['contract', 'admin-update'],
    mutationFn: ({ id, data }) => appRouter.contracts.adminUpdate({ token: user?.token }, id, data)
  })

  const getContractMain = useMutation<ContractMainList>({
    mutationKey: ['contract main', 'main'],
    mutationFn: () =>
      appRouter.contracts.getMain({ token: user?.token }, Number(searchParams.get('id')))
  })

  const getContractOption = useMutation<ContractOptionList>({
    mutationKey: ['contract option', 'option'],
    mutationFn: () =>
      appRouter.contracts.getOption({ token: user?.token }, Number(searchParams.get('id')))
  })

  const getContractConsumption = useMutation<ContractConsumptionList>({
    mutationKey: ['contract consumption', 'consumption'],
    mutationFn: () =>
      appRouter.contracts.getConsumption({ token: user?.token }, Number(searchParams.get('id')))
  })

  const getContractInvoice = useMutation<ContractInvoiceList>({
    mutationKey: ['contract invoice', 'invoice'],
    mutationFn: () =>
      appRouter.contracts.getInvoice({ token: user?.token }, Number(searchParams.get('id')))
  })

  const getContractPayments = useMutation<any, unknown, number>({
    mutationKey: ['contract', 'payments'],
    mutationFn: (id) => appRouter.contracts.getPayments({ token: user?.token }, id)
  })

  const getContractUsers = useMutation<ContractUsers, unknown, number>({
    mutationKey: ['contract', 'payments'],
    mutationFn: (id) => appRouter.contracts.getUsers({ token: user?.token }, id)
  })

  const getContractLitigations = useMutation<any, unknown, number>({
    mutationKey: ['contract', 'litigations'],
    mutationFn: (id) => appRouter.contracts.getLitigations({ token: user?.token }, id)
  })

  const getContractIndexingAnniversary = useMutation<ContractIndexingAnniversaryList>({
    mutationKey: ['contract indexing anniversary', 'indexing'],
    mutationFn: () =>
      appRouter.contracts.getIndexingAnniversary(
        { token: user?.token },
        Number(searchParams.get('id'))
      )
  })

  const linkContractServiceUser = useMutation<
    IdReturn,
    unknown,
    { id: number; data: ContractServiceLinkUser }
  >({
    mutationKey: ['contract indexing anniversary', 'indexing'],
    mutationFn: ({ id, data }) =>
      appRouter.contracts.linkServiceUser({ token: user?.token }, id, data)
  })

  const updateContractOccupant = useMutation<
    void,
    unknown,
    { id: number; occupants: { [key: string]: number } }
  >({
    mutationKey: ['contract occupant', 'occupant'],
    mutationFn: (params) => appRouter.contracts.updateOccupant({ token: user?.token }, params)
  })

  const getDashboardContracts = useMutation<DashboardContract>({
    mutationKey: ['dashboard', 'contracts'],
    mutationFn: () =>
      appRouter.dashboard.getContracts({ token: user?.token }, searchParams.toString())
  })

  const getDashboardOccupations = useMutation<DashboardOccupations>({
    mutationKey: ['dashboard', 'occupations'],
    mutationFn: () =>
      appRouter.dashboard.getOccupations({ token: user?.token }, searchParams.toString())
  })

  const getWifiLogs = useMutation<WifiLogsList, unknown, void>({
    mutationKey: ['wifi', 'logs'],
    mutationFn: () => appRouter.wifi.getLogs({ token: user?.token }, searchParams.toString())
  })

  const getDashboardVisits = useMutation<DashboardVisits>({
    mutationKey: ['dashboard', 'visits'],
    mutationFn: () => appRouter.dashboard.getVisits({ token: user?.token }, searchParams.toString())
  })

  const getDashboardNextEntries = useMutation<DashboardNextEntries>({
    mutationKey: ['dashboard', 'next_entries'],
    mutationFn: () =>
      appRouter.dashboard.getNexEntries({ token: user?.token }, searchParams.toString())
  })

  const getDashboardMeetings = useMutation<DashboardMeetings>({
    mutationKey: ['dashboard', 'meetings'],
    mutationFn: () =>
      appRouter.dashboard.getMeetings({ token: user?.token }, searchParams.toString())
  })

  const getDashboardOpenDesktops = useMutation<DashboardOpenDesktops>({
    mutationKey: ['dashboard', 'open_desktops'],
    mutationFn: () =>
      appRouter.dashboard.getOpenDesktops({ token: user?.token }, searchParams.toString())
  })

  const getDashboardOtherInformation = useMutation<DashboardOtherInformation>({
    mutationKey: ['dashboard', 'other_information'],
    mutationFn: () =>
      appRouter.dashboard.getOtherInformation({ token: user?.token }, searchParams.toString())
  })

  const getInvoices = useMutation<InvoiceList>({
    mutationKey: ['invoices', 'list'],
    mutationFn: () => appRouter.invoices.list({ token: user?.token }, searchParams.toString())
  })

  const getInvoice = useMutation<InvoiceInformation, unknown, number>({
    mutationKey: ['invoice', 'information'],
    mutationFn: (id) => appRouter.invoices.getInformation({ token: user?.token }, id)
  })

  const getInvoicePdf = useMutation<InvoicePdf, unknown, number>({
    mutationKey: ['invoice pdf', 'pdf'],
    mutationFn: (id) => appRouter.invoices.getPdf({ token: user?.token }, id)
  })

  const postManualBankDebitInvoice = useMutation<InvoicePdf, unknown, { id: number; data: string }>(
    {
      mutationKey: ['invoice manual bank debit', 'manual bank debit'],
      mutationFn: ({ id, data }) =>
        appRouter.invoices.postManualBankDebit({ token: user?.token }, id, data)
    }
  )

  const postAddPaymentInvoice = useMutation<any, unknown, { id: number; data: any }>({
    mutationKey: ['invoice add payment', 'add payment'],
    mutationFn: ({ id, data }) => appRouter.invoices.addPayment({ token: user?.token }, id, data)
  })

  const patchLinkPaymentInvoice = useMutation<any, unknown, { id: number; payment: number }>({
    mutationKey: ['invoice link payment', 'link payment'],
    mutationFn: ({ id, payment }) =>
      appRouter.invoices.linkPayment({ token: user?.token }, id, payment)
  })

  const patchUnLinkPaymentInvoice = useMutation<any, unknown, { id: number; payment: number }>({
    mutationKey: ['invoice unlink payment', 'unlink payment'],
    mutationFn: ({ id, payment }) =>
      appRouter.invoices.unLinkPayment({ token: user?.token }, id, payment)
  })

  const patchUnLinkCreditInvoice = useMutation<any, unknown, { id: number; credit: number }>({
    mutationKey: ['invoice unlink credit', 'unlink credit'],
    mutationFn: ({ id, credit }) =>
      appRouter.invoices.unLinkCredit({ token: user?.token }, id, credit)
  })

  const patchCancelledPaymentInvoice = useMutation<any, unknown, { id: number; payment: number }>({
    mutationKey: ['invoice cancelled payment', 'cancelled payment'],
    mutationFn: ({ id, payment }) =>
      appRouter.invoices.cancelledGc({ token: user?.token }, id, payment)
  })

  const getPaymentsInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice payments', 'payments'],
    mutationFn: (id) => appRouter.invoices.getPayments({ token: user?.token }, id)
  })

  const getCreditsInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice credits', 'credits'],
    mutationFn: (id) => appRouter.invoices.getCredits({ token: user?.token }, id)
  })

  const getInvoiceActivities = useMutation<Activities, unknown, { id: number; type: string }>({
    mutationKey: ['invoice activities', 'activities'],
    mutationFn: ({ id, type }) => appRouter.invoices.getActivities({ token: user?.token }, id, type)
  })

  const patchRegeneratePdfInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice regenerate pdf', 'regenerate pdf'],
    mutationFn: (id) => appRouter.invoices.patchRegeneratePdf({ token: user?.token }, id)
  })

  const patchAccountInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice account', 'account'],
    mutationFn: (id) => appRouter.invoices.patchAccount({ token: user?.token }, id)
  })

  const patchUnAccountInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice unaccount', 'unaccount'],
    mutationFn: (id) => appRouter.invoices.patchUnAccount({ token: user?.token }, id)
  })

  const patchBlockInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice block', 'block'],
    mutationFn: (id) => appRouter.invoices.patchBlock({ token: user?.token }, id)
  })

  const patchUnBlockInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice unblock', 'unblock'],
    mutationFn: (id) => appRouter.invoices.patchUnBlock({ token: user?.token }, id)
  })

  const postUnCollectibleInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice uncollectible', 'uncollectible'],
    mutationFn: (id) => appRouter.invoices.postUnCollectible({ token: user?.token }, id)
  })

  const postManualReminderInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice manual reminder', 'manual reminder'],
    mutationFn: (id) => appRouter.invoices.postManualReminder({ token: user?.token }, id)
  })

  const getRowsInvoice = useMutation<any, unknown, number>({
    mutationKey: ['invoice rows', 'rows'],
    mutationFn: (id) => appRouter.invoices.getRows({ token: user?.token }, id)
  })

  const patchLitigationInvoice = useMutation<
    any,
    unknown,
    { id: number; data: { reference: string } }
  >({
    mutationKey: ['invoice litigation', 'litigation'],
    mutationFn: ({ id, data }) =>
      appRouter.invoices.patchLitigation({ token: user?.token }, id, data)
  })

  const postLitigationContract = useMutation<any, unknown, { id: number; reference: string }>({
    mutationKey: ['invoice litigation', 'litigation'],
    mutationFn: ({ id, reference }) =>
      appRouter.contracts.postLitigation({ token: user?.token }, id, reference)
  })

  const patchDiscountInvoice = useMutation<void, unknown, { id: number; data: any }>({
    mutationKey: ['invoice discount', 'discount'],
    mutationFn: ({ id, data }) => appRouter.invoices.patchDiscount({ token: user?.token }, id, data)
  })

  const patchLanguageInvoice = useMutation<
    void,
    unknown,
    { id: number; data: { language: number } }
  >({
    mutationKey: ['invoice language', 'language'],
    mutationFn: ({ id, data }) => appRouter.invoices.patchLanguage({ token: user?.token }, id, data)
  })

  const patchDueDateInvoice = useMutation<void, unknown, { id: number; data: { dueDate: string } }>(
    {
      mutationKey: ['invoice due date', 'due date'],
      mutationFn: ({ id, data }) =>
        appRouter.invoices.patchDueDate({ token: user?.token }, id, data)
    }
  )

  const getCredits = useMutation<CreditList>({
    mutationKey: ['credits', 'list'],
    mutationFn: () => appRouter.credits.list({ token: user?.token }, searchParams.toString())
  })

  const getCreditSum = useMutation<CreditSum>({
    mutationKey: ['credits', 'sum'],
    mutationFn: () => appRouter.credits.getSum({ token: user?.token }, searchParams.toString())
  })

  const getCreditExport = useMutation<void>({
    mutationKey: ['credits', 'export'],
    mutationFn: () => appRouter.credits.getExport({ token: user?.token }, searchParams.toString())
  })

  const getCredit = useMutation<CreditInformation>({
    mutationKey: ['credit', 'information'],
    mutationFn: () =>
      appRouter.credits.getInformation({ token: user?.token }, Number(searchParams.get('id')))
  })

  const getCreditInvoiceSelect = useMutation<CreditInvoiceSelect[]>({
    mutationKey: ['credit invoice select', 'invoice select'],
    mutationFn: () =>
      appRouter.credits.getInvoiceSelect({ token: user?.token }, Number(searchParams.get('id')))
  })

  const getCreditPdf = useMutation<CreditPdf>({
    mutationKey: ['credit pdf', 'pdf'],
    mutationFn: () =>
      appRouter.credits.getPdf({ token: user?.token }, Number(searchParams.get('id')))
  })

  const updateCreditRegeneratePdf = useMutation<void>({
    mutationKey: ['credit update pdf', 'update'],
    mutationFn: () =>
      appRouter.credits.updatePdf({ token: user?.token }, Number(searchParams.get('id')))
  })

  const updateCreditApplyInvoice = useMutation<void, unknown, { id: string; invoiceId: string }>({
    mutationKey: ['credit apply on invoice', 'apply'],
    mutationFn: (params) => appRouter.credits.updateApplyInvoice({ token: user?.token }, params)
  })

  const addCreditDeclarePayment = useMutation<void, unknown, CreditDeclarePayment & { id: string }>(
    {
      mutationKey: ['credit declare payment', 'declare'],
      mutationFn: (params) => appRouter.credits.addDeclarePayment({ token: user?.token }, params)
    }
  )

  const getCreditInvoice = useMutation<CreditInvoice[]>({
    mutationKey: ['credit invoice', 'invoice'],
    mutationFn: () =>
      appRouter.credits.getInvoice({ token: user?.token }, Number(searchParams.get('id')))
  })

  const getCreditPayment = useMutation<CreditPayment[]>({
    mutationKey: ['credit payment', 'payment'],
    mutationFn: () =>
      appRouter.credits.getPayment({ token: user?.token }, Number(searchParams.get('id')))
  })

  const patchLanguageCredit = useMutation<void, unknown, any>({
    mutationKey: ['credit language', 'language'],
    mutationFn: (data) =>
      appRouter.credits.patchLanguage({ token: user?.token }, Number(searchParams.get('id')), data)
  })

  const getCreditActivities = useMutation<Activities, unknown, string>({
    mutationKey: ['credit activities', 'activities'],
    mutationFn: (type) =>
      appRouter.credits.getActivities({ token: user?.token }, Number(searchParams.get('id')), type)
  })

  const getInvoiceSum = useMutation<InvoiceSum>({
    mutationKey: ['invoices', 'sum'],
    mutationFn: () => appRouter.invoices.getSum({ token: user?.token }, searchParams.toString())
  })

  const getFilters = useMutation<Filters, unknown, { variables: FiltersInput; params: any }>({
    mutationKey: ['filters', 'list'],
    mutationFn: ({ variables, params }) =>
      appRouter.filters.list({ token: user?.token }, variables, params)
  })

  const getFormItems = useMutation<FormItems, unknown, FormItemsInput>({
    mutationKey: ['formItems', 'list'],
    mutationFn: (variables) => appRouter.formItems.list({ token: user?.token }, variables)
  })

  const getFormItemsWithFilters = useMutation<FormItems, unknown, FormItemsInputWithFilters>({
    mutationKey: ['formItems', 'listWithFilter'],
    mutationFn: (variables) =>
      appRouter.formItems.listWithFilters({ token: user?.token }, variables)
  })

  const useGetFormItems = (variables: FormItemsInput) =>
    useQuery<FormItems>({
      queryKey: ['query', 'formItems', 'list', ...variables],
      queryFn: () => appRouter.formItems.list({ token: user?.token }, variables)
    })

  const getClientFilters = useMutation<FilterClient, unknown, FilterClientInput>({
    mutationKey: ['filters', 'client'],
    mutationFn: (variables) => appRouter.filters.getClient({ token: user?.token }, variables)
  })

  const getCenter = useMutation<CenterDetails, unknown, string>({
    mutationKey: ['centers', 'access groups'],
    mutationFn: (id) => appRouter.centers.getOne({ token: user?.token }, id)
  })

  const useGetCenter = (id: string) =>
    useQuery({
      queryKey: ['centers', id],
      queryFn: () => appRouter.centers.getOne({ token: user?.token }, id)
    })

  const getCenterAccessGroups = useMutation<AccessGroup[], unknown, string>({
    mutationKey: ['centers', 'access groups'],
    mutationFn: (id) => appRouter.centers.listAccessGroups({ token: user?.token }, id)
  })

  const getCenterAccessRooms = useMutation<
    AccessRoom[],
    unknown,
    { id: string; status: string | null; type: string | null }
  >({
    mutationKey: ['centers', 'access groups'],
    mutationFn: ({ id, status, type }) =>
      appRouter.centers.listAccessRooms({ token: user?.token }, id, type, status)
  })

  const getCenterPrices = useMutation<CenterPricesList>({
    mutationKey: ['center_prices', 'list'],
    mutationFn: () => appRouter.centers.listPrices({ token: user?.token }, searchParams.toString())
  })

  const uploadBusinessPlanCSV = useMutation<void, unknown, File | FileList>({
    mutationKey: ['centers', 'business plan', 'upload'],
    mutationFn: (file) => appRouter.centers.uploadCSV({ token: user?.token }, file)
  })

  const getBusinessPlan = useMutation<CenterBp[]>({
    mutationKey: ['centers', 'business plan', 'get'],
    mutationFn: () => appRouter.centers.businessPlan({ token: user?.token })
  })

  const updateCenter = useMutation<void, unknown, { id: number; data: CenterDetails }>({
    mutationKey: ['center', 'update'],
    mutationFn: ({ id, data }) => appRouter.centers.update({ token: user?.token }, id, data)
  })

  const getTertiaries = useMutation<TertiariesList>({
    mutationKey: ['tertiaries', 'list'],
    mutationFn: () => appRouter.tertiaries.list({ token: user?.token })
  })

  const getTypologies = useMutation<TypologiesList>({
    mutationKey: ['typologies', 'list'],
    mutationFn: () => appRouter.typologies.list({ token: user?.token }, searchParams.toString())
  })

  const getCommitments = useMutation<CommitmentsList>({
    mutationKey: ['commitments', 'list'],
    mutationFn: () => appRouter.commitments.list({ token: user?.token }, searchParams.toString())
  })

  const createTertiary = useMutation<TertiaryCreate, unknown, TertiaryBody>({
    mutationKey: ['tertiary', 'create'],
    mutationFn: (data) => appRouter.tertiaries.create({ token: user?.token }, data)
  })

  const createCenterPrice = useMutation<IdReturn, unknown, CreateCenterPriceInputs>({
    mutationKey: ['center_prices', 'create'],
    mutationFn: (data) => appRouter.centers.createPrice({ token: user?.token }, data)
  })

  const getCenterBlockedPrices = useMutation<CenterBlockedPrices, unknown, number>({
    mutationKey: ['center_price', 'list', 'blocked_prices'],
    mutationFn: (id) => appRouter.centers.listBlockedServicePrices({ token: user?.token }, id)
  })

  const getCentersClusters = useMutation<CenterClustersList>({
    mutationKey: ['centers', 'clusters'],
    mutationFn: () =>
      appRouter.centers.listClusters({ token: user?.token }, searchParams.toString())
  })

  const createCenterCluster = useMutation<IdReturn, unknown, AddClusterProps>({
    mutationKey: ['centers', 'clusters', 'create'],
    mutationFn: (data) => appRouter.centers.createCluster({ token: user?.token }, data)
  })

  const updateCenterCluster = useMutation<void, unknown, { id: number; data: EditClusterProps }>({
    mutationKey: ['centers', 'clusters', 'update'],
    mutationFn: ({ id, data }) => appRouter.centers.updateCluster({ token: user?.token }, id, data)
  })

  const availableCentersCluster = useMutation<IdLabelItem[], unknown, number>({
    mutationKey: ['centers', 'clusters', 'available'],
    mutationFn: (id) => appRouter.centers.getAvailableCentersForCluster({ token: user?.token }, id)
  })

  const getCenterVisors = useMutation<Visors>({
    mutationKey: ['centers', 'visors'],
    mutationFn: () => appRouter.centers.listVisors({ token: user?.token })
  })

  const getCenterFilters = useMutation<FilterCenter, unknown, FilterCenterInput>({
    mutationKey: ['filters', 'center'],
    mutationFn: (variables) => appRouter.filters.getCenter({ token: user?.token }, variables)
  })

  const getCentersSelect = useMutation<SelectCenters, unknown, FilterCenterInput>({
    mutationKey: ['filters', 'select'],
    mutationFn: (variables) => appRouter.filters.getCenters({ token: user?.token }, variables)
  })

  const getEnterprisePrescriber = useMutation<
    PrescriberInformation,
    unknown,
    { prescriberId: number; enterpriseId: number }
  >({
    mutationKey: ['enteprises', 'prescriber'],
    mutationFn: ({ prescriberId, enterpriseId }) =>
      appRouter.prescribers.getEnterprisePrescriber(
        { token: user?.token },
        prescriberId,
        enterpriseId
      )
  })

  const getPrescriberMembers = useMutation<
    PrescriberMembers,
    unknown,
    { prescriberId: number; enterpriseId: number }
  >({
    mutationKey: ['prescribers', 'members'],
    mutationFn: ({ prescriberId, enterpriseId }) =>
      appRouter.prescribers.listMembers({ token: user?.token }, prescriberId, enterpriseId)
  })

  const createPrescriberMemberSource = useMutation<
    void,
    unknown,
    { prescriberId: number; enterpriseId: number; individualId: number }
  >({
    mutationKey: ['prescribers', 'members'],
    mutationFn: ({ prescriberId, enterpriseId, individualId }) =>
      appRouter.prescribers.createMemberSource(
        { token: user?.token },
        prescriberId,
        enterpriseId,
        individualId
      )
  })

  const getPrescriberOpportunities = useMutation<
    PrescriberOpportunities,
    unknown,
    { prescriberId: number; enterpriseId: number }
  >({
    mutationKey: ['prescribers', 'opportunities'],
    mutationFn: ({ prescriberId, enterpriseId }) =>
      appRouter.prescribers.listOpportunities({ token: user?.token }, prescriberId, enterpriseId)
  })

  const getCenters = useMutation<CentersList>({
    mutationKey: ['centers', 'list'],
    mutationFn: () => appRouter.centers.list({ token: user?.token })
  })

  const createCenters = useMutation<void, unknown, CenterAddProps>({
    mutationKey: ['centers', 'add'],
    mutationFn: (data) => appRouter.centers.createCenter({ token: user?.token }, data)
  })

  const getEnterpriseMembersFilters = useMutation<FilterMembers, unknown, FilterMemberInput>({
    mutationKey: ['filters', 'enterprise_members'],
    mutationFn: (variables) =>
      appRouter.filters.getEnterpriseMembers({ token: user?.token }, variables)
  })

  const getWifiSsidsFilters = useMutation<FilterSelectSearch, unknown, FilterSelectInput>({
    mutationKey: ['filters', 'enterprise_members'],
    mutationFn: (variables) => appRouter.filters.getWifiSsids({ token: user?.token }, variables)
  })

  const getWifiStationsFilters = useMutation<FilterSelectSearch, unknown, FilterSelectInput>({
    mutationKey: ['filters', 'enterprise_members'],
    mutationFn: (variables) => appRouter.filters.getWifiStations({ token: user?.token }, variables)
  })

  const getOptions = useMutation<OptionList>({
    mutationKey: ['options', 'list'],
    mutationFn: () => appRouter.options.list({ token: user?.token }, searchParams.toString())
  })

  const getServiceCommitmentPrice = useMutation<any, void, { id: string; commitment: number }>({
    mutationKey: ['service', 'commitment-price'],
    mutationFn: ({ id, commitment }) =>
      appRouter.services.getCommitmentPrice({ token: user?.token }, id, commitment)
  })

  const getServiceAvailability = useMutation<
    { message: string },
    void,
    { id: string; begin?: string }
  >({
    mutationKey: ['service', 'availability'],
    mutationFn: ({ id, begin }) =>
      appRouter.services.getServiceAvailability({ token: user?.token }, id, begin)
  })

  const getServiceAvailableDesktops = useMutation<any, void, AvailableDesktopsSearch>({
    mutationKey: ['service', 'available'],
    mutationFn: (data) => appRouter.services.getAvailableDesktops({ token: user?.token }, data)
  })

  const changeContractService = useMutation<
    void,
    unknown,
    {
      id: string
      contractServiceId: number
      data: {
        contractServiceId: number
        serviceId: number
      }
    }
  >({
    mutationKey: ['customer reservations', 'contract service', 'change'],
    mutationFn: ({ id, contractServiceId, data }) =>
      appRouter.contracts.changeService({ token: user?.token }, id, contractServiceId, data)
  })

  const getServicePricing = useMutation<ServicePrice, void, any>({
    mutationKey: ['service', 'pricing-consumptions'],
    mutationFn: ({ id, data }) => appRouter.services.getPricing({ token: user?.token }, id, data)
  })

  const getServicesAvailable = useMutation<any, void, any>({
    mutationKey: ['service', 'available'],
    mutationFn: (data) => appRouter.services.getAvailable({ token: user?.token }, data)
  })

  const getServiceConsumptionsPricing = useMutation<
    ConsumptionPricing,
    void,
    PricingConsumptionInputs
  >({
    mutationKey: ['service', 'pricing-consumptions'],
    mutationFn: (params) => appRouter.services.getConsumptionPricing({ token: user?.token }, params)
  })

  const getServicesOccupancy = useMutation<OccupancyList>({
    mutationKey: ['occupancy', 'list'],
    mutationFn: () => appRouter.occupancy.list({ token: user?.token }, searchParams.toString())
  })

  const getServicesOccupancySum = useMutation<OccupancySum>({
    mutationKey: ['occupancy', 'sum'],
    mutationFn: () => appRouter.occupancy.getSum({ token: user?.token }, searchParams.toString())
  })

  const getServicesOccupancyExport = useMutation<void>({
    mutationKey: ['occupancy', 'export'],
    mutationFn: () => appRouter.occupancy.getExport({ token: user?.token }, searchParams.toString())
  })

  const getRights = useMutation<RightList>({
    mutationKey: ['rights', 'list'],
    mutationFn: () => appRouter.rights.list({ token: user?.token }, searchParams.toString())
  })

  const getRight = useMutation<Right, unknown, string>({
    mutationKey: ['rights', 'get'],
    mutationFn: (id) => appRouter.rights.get({ token: user?.token }, id)
  })

  const editRight = useMutation<unknown, unknown, Right>({
    mutationKey: ['rights', 'edit'],
    mutationFn: (data) => appRouter.rights.edit({ token: user?.token }, data)
  })

  const getLitigations = useMutation<LitigationList>({
    mutationKey: ['litigations', 'list'],
    mutationFn: () => appRouter.litigations.list({ token: user?.token }, searchParams.toString())
  })
  const getLitigation = useMutation<Litigation, unknown, number>({
    mutationKey: ['litigation', 'get'],
    mutationFn: (id) => appRouter.litigations.getOne({ token: user?.token }, id)
  })
  const getLitigationContracts = useMutation<any, unknown, number>({
    mutationKey: ['litigation', 'contracts'],
    mutationFn: (id) => appRouter.litigations.getContracts({ token: user?.token }, id)
  })

  const getContactByContract = useMutation<ContactsByContractList, unknown, void, unknown>({
    mutationKey: ['contact', 'list'],
    mutationFn: () =>
      appRouter.contacts.getContacts({ token: user?.token }, searchParams.toString())
  })

  const getContactExport = useMutation<void>({
    mutationKey: ['contact', 'export'],
    mutationFn: () =>
      appRouter.contacts.getContactExport({ token: user?.token }, searchParams.toString())
  })

  const getLitigationInvoices = useMutation<any, unknown, number>({
    mutationKey: ['litigation', 'invoices'],
    mutationFn: (id) => appRouter.litigations.getInvoices({ token: user?.token }, id)
  })

  const getOpportunities = useMutation<OpportunityList>({
    mutationKey: ['opportunities', 'list'],
    mutationFn: () => appRouter.opportunities.list({ token: user?.token }, searchParams.toString())
  })

  const getOpportunity = useMutation<Opportunity, void, string>({
    mutationKey: ['opportunities', 'get'],
    mutationFn: (id) => appRouter.opportunities.getOne({ token: user?.token }, id)
  })

  const getOpportunityContracts = useMutation<any, void, string>({
    mutationKey: ['opportunities', 'contracts'],
    mutationFn: (id) => appRouter.opportunities.getContracts({ token: user?.token }, id)
  })

  const getOpportunityEvents = useMutation<OpportunityEvents, void, string>({
    mutationKey: ['opportunities', 'events'],
    mutationFn: (id) =>
      appRouter.opportunities.getEvents({ token: user?.token }, id, searchParams.toString())
  })

  const getOpportunityUsers = useMutation<any, void, string>({
    mutationKey: ['opportunities', 'users'],
    mutationFn: (id) => appRouter.opportunities.getUsers({ token: user?.token }, id)
  })

  const getOpportunitySolutions = useMutation<any, void, string>({
    mutationKey: ['opportunities', 'solutions'],
    mutationFn: (id) => appRouter.opportunities.getSolutions({ token: user?.token }, id)
  })

  const updateOpportunity = useMutation<
    void,
    void,
    { id: string; data: OpportunityUpdateFormProps }
  >({
    mutationKey: ['opportunities', 'id', 'update'],
    mutationFn: ({ id, data }) => appRouter.opportunities.update({ token: user?.token }, id, data)
  })

  const getActualPipeline = useMutation<ActualPipeline>({
    mutationKey: ['actual pipeline'],
    mutationFn: () =>
      appRouter.opportunities.actualPipeline({ token: user?.token }, searchParams.toString())
  })

  const getStaffFilters = useMutation<FilterStaff, unknown, FilterStaffInput>({
    mutationKey: ['filters', 'staff'],
    mutationFn: (variables) => appRouter.filters.getStaff({ token: user?.token }, variables)
  })

  const getIndividual = useMutation<IndividualDetails, unknown, string>({
    mutationKey: ['individuals', 'get'],
    mutationFn: (id) => appRouter.individuals.getOne({ token: user?.token }, id)
  })

  const getIndividualPartners = useMutation<IndividualPartners, unknown, string>({
    mutationKey: ['individuals', 'partners', 'get'],
    mutationFn: (id) => appRouter.individuals.getPartners({ token: user?.token }, id)
  })

  const updateActivCorner = useMutation<void, unknown, string>({
    mutationKey: ['individuals', 'partners', 'patch'],
    mutationFn: (id) => appRouter.individuals.updateActivCorner({ token: user?.token }, id)
  })

  const getIndividualAccess = useMutation<IndividualAccessList, unknown, string>({
    mutationKey: ['individuals', 'access', 'get'],
    mutationFn: (id) => appRouter.individuals.listAccess({ token: user?.token }, id)
  })

  const createIndividualAccess = useMutation<
    void,
    unknown,
    { id: number; data: FormVisorAccessData }
  >({
    mutationKey: ['individuals', 'access', 'create'],
    mutationFn: ({ id, data }) =>
      appRouter.individuals.createAccess({ token: user?.token }, id, data)
  })

  const updateIndividualAccess = useMutation<
    void,
    unknown,
    { id: number; data: FormVisorAccessData }
  >({
    mutationKey: ['individuals', 'access', 'update'],
    mutationFn: ({ id, data }) =>
      appRouter.individuals.updateAccess({ token: user?.token }, id, data)
  })

  const getIndividualWifi = useMutation<IndividualWifi, unknown, string>({
    mutationKey: ['individuals', 'wifi', 'get'],
    mutationFn: (id) => appRouter.individuals.getWifi({ token: user?.token }, id)
  })

  const createWifiCredentials = useMutation<
    void,
    unknown,
    { id: string; data: WifiPasswordUpdate }
  >({
    mutationKey: ['individuals', 'wifi', 'create'],
    mutationFn: ({ id, data }) =>
      appRouter.individuals.createWifiCredentials({ token: user?.token }, id, data)
  })

  const updateWifiPassword = useMutation<void, unknown, { id: string; data: WifiPasswordUpdate }>({
    mutationKey: ['individuals', 'wifi', 'update'],
    mutationFn: ({ id, data }) =>
      appRouter.individuals.updateWifiPassword({ token: user?.token }, id, data)
  })

  const sendWifiPassword = useMutation<void, unknown, string>({
    mutationKey: ['individuals', 'wifi', 'send'],
    mutationFn: (id) => appRouter.individuals.sendWifiPassword({ token: user?.token }, id)
  })

  const getIndividualEnterprises = useMutation<ClientLinkList, unknown, string>({
    mutationKey: ['individuals', 'enterprises'],
    mutationFn: (id) => appRouter.individuals.listEnterprises({ token: user?.token }, id)
  })

  const getIndividualConsumptions = useMutation<IndividualConsumptions, unknown, string>({
    mutationKey: ['individuals', 'consumptions'],
    mutationFn: (id) => appRouter.individuals.listConsumptions({ token: user?.token }, id)
  })

  const getIndividualDevices = useMutation<IndividualDevices, unknown, string>({
    mutationKey: ['individuals', 'devices'],
    mutationFn: (id) => appRouter.individuals.listDevices({ token: user?.token }, id)
  })

  const individualDevicesUpdate = useMutation<any, unknown, { id: number; data: any }>({
    mutationKey: ['individuals update', 'devices'],
    mutationFn: ({ id, data }) =>
      appRouter.individuals.updateDevices({ token: user?.token }, id, data)
  })

  const getIndividualEvents = useMutation<EventsList, unknown, string>({
    mutationKey: ['individuals', 'events'],
    mutationFn: (id) => appRouter.individuals.listEvents({ token: user?.token }, id)
  })

  const getIndividualInvoices = useMutation<IndividualInvoices, unknown, string>({
    mutationKey: ['individuals', 'invoices'],
    mutationFn: (id) => appRouter.individuals.listInvoices({ token: user?.token }, id)
  })

  const getIndividuals = useMutation<IndividualList>({
    mutationKey: ['individuals', 'list'],
    mutationFn: () => appRouter.individuals.list({ token: user?.token }, searchParams.toString())
  })

  const updateIndividual = useMutation<void, unknown, { id: number; data: IndividualDetails }>({
    mutationKey: ['individuals', 'update'],
    mutationFn: ({ id, data }) => appRouter.individuals.update({ token: user?.token }, id, data)
  })

  const getCheckouts = useMutation<CheckoutList>({
    mutationKey: ['checkouts', 'list'],
    mutationFn: () => appRouter.checkouts.list({ token: user?.token }, searchParams.toString())
  })

  const getCheckoutSum = useMutation<CheckoutSum>({
    mutationKey: ['checkouts', 'sum'],
    mutationFn: () => appRouter.checkouts.getSum({ token: user?.token }, searchParams.toString())
  })

  const getContractsOptions = useMutation<ContractsOptionsList>({
    mutationKey: ['contracts_options', 'list'],
    mutationFn: () =>
      appRouter.contracts.listOptions({ token: user?.token }, searchParams.toString())
  })

  const updateContract = useMutation<
    void,
    unknown,
    {
      id: number
      data: ContractUpdate
    }
  >({
    mutationKey: ['contract signatory', 'update'],
    mutationFn: ({ id, data }) =>
      appRouter.contracts.updateSignatoryAndLanguage({ token: user?.token }, id, data)
  })

  const getContractsOptionsSum = useMutation<ContractsOptionsSum>({
    mutationKey: ['contracts_options', 'sum'],
    mutationFn: () =>
      appRouter.contracts.getOptionsSum({ token: user?.token }, searchParams.toString())
  })

  const getMandates = useMutation<MandatesList>({
    mutationKey: ['mandates', 'list'],
    mutationFn: () => appRouter.mandates.list({ token: user?.token }, searchParams.toString())
  })

  const getPaymentMethod = useMutation<PaymentMethod, void, number>({
    mutationKey: ['payment method', 'sheet'],
    mutationFn: (id) => appRouter.mandates.paymentMethod({ token: user?.token }, id)
  })

  const getBankAccount = useMutation<BankAccount, void, number>({
    mutationKey: ['bank account', 'sheet'],
    mutationFn: (id) => appRouter.mandates.bankAccount({ token: user?.token }, id)
  })

  const getPaymentMethodPayments = useMutation<Payments, void, number>({
    mutationKey: ['payments', 'sheet'],
    mutationFn: (id) => appRouter.mandates.payments({ token: user?.token }, id)
  })

  const paymentMethodCancelled = useMutation<any, void, number>({
    mutationKey: ['cancelled', 'sheet'],
    mutationFn: (id) => appRouter.mandates.cancelled({ token: user?.token }, id)
  })

  const getMainServices = useMutation<MainList>({
    mutationKey: ['main services', 'list'],
    mutationFn: () => appRouter.services.mainList({ token: user?.token }, searchParams.toString())
  })

  const getCenterDiscounts = useMutation<CenterDiscountsList>({
    mutationKey: ['center discounts', 'list'],
    mutationFn: () => appRouter.centerDiscount.list({ token: user?.token }, searchParams.toString())
  })

  const getCenterDiscountsNotActive = useMutation<CenterDiscountsNotActive>({
    mutationKey: ['center discounts not active', 'not active'],
    mutationFn: () => appRouter.centerDiscount.notActive({ token: user?.token })
  })

  const createCenterDiscount = useMutation<CreateCenterDiscount, void, CreateCenterDataDiscount>({
    mutationKey: ['center discount', 'create'],
    mutationFn: (data) => appRouter.centerDiscount.create({ token: user?.token }, data)
  })

  const getMainService = useMutation<MainService, unknown, number>({
    mutationKey: ['main services', 'get'],
    mutationFn: (id) => appRouter.services.mainGet({ token: user?.token }, id)
  })

  const createMainService = useMutation<IdReturn, unknown, MainServiceAddBody>({
    mutationKey: ['main services', 'create'],
    mutationFn: (data) => appRouter.services.createMain({ token: user?.token }, data)
  })

  const createParkingService = useMutation<IdReturn, unknown, ParkingServiceAddBody>({
    mutationKey: ['parking services', 'create'],
    mutationFn: (data) => appRouter.services.createParking({ token: user?.token }, data)
  })

  const updateMainService = useMutation<void, unknown, { id: number; data: MainServiceBody }>({
    mutationKey: ['update main services', 'update'],
    mutationFn: ({ id, data }) => appRouter.services.updateMain({ token: user?.token }, id, data)
  })

  const computePrice = useMutation<{ computedPrice: number }, unknown, MainServiceComputePriceBody>(
    {
      mutationKey: ['main services', 'compute price'],
      mutationFn: (data) => appRouter.services.computePrice({ token: user?.token }, data)
    }
  )

  const getOptionService = useMutation<OptionService, unknown, number>({
    mutationKey: ['option services', 'get'],
    mutationFn: (id) => appRouter.services.optionGet({ token: user?.token }, id)
  })

  const updateOptionService = useMutation<void, unknown, { id: number; data: OptionServiceBody }>({
    mutationKey: ['option services', 'update'],
    mutationFn: ({ id, data }) => appRouter.services.updateOption({ token: user?.token }, id, data)
  })

  const createOptionService = useMutation<void, unknown, { data: OptionServiceBody }>({
    mutationKey: ['option services', 'create'],
    mutationFn: ({ data }) => appRouter.services.createOption({ token: user?.token }, data)
  })

  const getParkingService = useMutation<MainService, unknown, number>({
    mutationKey: ['service', 'parking', 'get'],
    mutationFn: (id) => appRouter.services.getParking({ token: user?.token }, id)
  })

  const getConsumptionService = useMutation<ConsumptionService, unknown, number>({
    mutationKey: ['service', 'consumption', 'get'],
    mutationFn: (id) => appRouter.services.getConsumption({ token: user?.token }, id)
  })

  const updateConsumptionService = useMutation<void, unknown, any>({
    mutationKey: ['service', 'consumption', 'update'],
    mutationFn: ({ id, data }) =>
      appRouter.services.updateConsumption({ token: user?.token }, id, data)
  })

  const createConsumptionService = useMutation<void, unknown, ConsumptionsServiceBody>({
    mutationKey: ['service', 'consumption', 'create'],
    mutationFn: (data) => appRouter.services.createConsumption({ token: user?.token }, data)
  })

  const updateParkingService = useMutation<void, unknown, { id: number; data: ParkingServiceBody }>(
    {
      mutationKey: ['update parking services', 'update'],
      mutationFn: ({ id, data }) =>
        appRouter.services.updateParking({ token: user?.token }, id, data)
    }
  )

  const getMainServiceSum = useMutation<MainSum>({
    mutationKey: ['main services', 'sum'],
    mutationFn: () => appRouter.services.getMainSum({ token: user?.token }, searchParams.toString())
  })

  const getParkingServices = useMutation<any>({
    mutationKey: ['parking services', 'list'],
    mutationFn: () =>
      appRouter.services.parkingList({ token: user?.token }, searchParams.toString())
  })

  const getMessages = useMutation<MessageList>({
    mutationKey: ['messages', 'list'],
    mutationFn: () => appRouter.monitoring.messages({ token: user?.token }, searchParams.toString())
  })

  const getAuthentificationRadius = useMutation<AuthentificationRadiusList>({
    mutationKey: ['authentifications radius', 'list'],
    mutationFn: () =>
      appRouter.monitoring.authentificationRadius({ token: user?.token }, searchParams.toString())
  })

  const patchLitigation = useMutation<void, unknown, { id: number; reference: string }>({
    mutationKey: ['litigations', 'update'],
    mutationFn: ({ id, reference }) =>
      appRouter.litigations.update({ token: user?.token }, id, reference)
  })

  const closeLitigation = useMutation<void, unknown, number>({
    mutationKey: ['litigations', 'close'],
    mutationFn: (id) => appRouter.litigations.close({ token: user?.token }, id)
  })

  const getPrescribers = useMutation<PrescriberList>({
    mutationKey: ['prescribers', 'list'],
    mutationFn: () => appRouter.prescribers.list({ token: user?.token }, searchParams.toString())
  })

  const getPlanningsDesktops = useMutation<PlanningDesktops>({
    mutationKey: ['plannings', 'desktops'],
    mutationFn: () => appRouter.plannings.list({ token: user?.token }, searchParams.toString())
  })

  const getPlanningsDesktopsFloors = useMutation<Filters>({
    mutationKey: ['plannings', 'desktops-floors'],
    mutationFn: () => appRouter.plannings.getFloors({ token: user?.token }, searchParams.toString())
  })

  const getPayment = useMutation<Payment, unknown, number>({
    mutationKey: ['payment', 'get'],
    mutationFn: (id) => appRouter.payments.read({ token: user?.token }, id)
  })

  const updatePayment = useMutation<void, unknown, { id: number; state: number }>({
    mutationKey: ['payment', 'update'],
    mutationFn: ({ id, state }) => appRouter.payments.update({ token: user?.token }, id, state)
  })

  const deletePayment = useMutation<void, unknown, { id: number }>({
    mutationKey: ['payment', 'delete'],
    mutationFn: ({ id }) => appRouter.payments.delete({ token: user?.token }, id)
  })

  const useGetPayment = (id: number) =>
    useQuery<Payment, unknown>({
      queryKey: ['query', 'payment', 'get', id],
      queryFn: () => appRouter.payments.read({ token: user?.token }, id)
    })

  const getPayments = useMutation<PaymentsList>({
    mutationKey: ['payment', 'list'],
    mutationFn: () => appRouter.payments.list({ token: user?.token }, searchParams.toString())
  })

  const getPaymentsSum = useMutation<PaymentsSum>({
    mutationKey: ['payment', 'sum'],
    mutationFn: () => appRouter.payments.getSum({ token: user?.token }, searchParams.toString())
  })

  const getAccountingDocuments = useMutation<AccountingDocumentsList>({
    mutationKey: ['accounting_documents', 'list'],
    mutationFn: () =>
      appRouter.accounting.documentsList({ token: user?.token }, searchParams.toString())
  })

  const getAccountingVariousOperations = useMutation<VariousOperationListType>({
    mutationKey: ['accounting_various_operations', 'list'],
    mutationFn: () =>
      appRouter.accounting.getVariousOperationsList({ token: user?.token }, searchParams.toString())
  })

  const getStaffs = useMutation<StaffsList>({
    mutationKey: ['staffs', 'list'],
    mutationFn: () => appRouter.staffs.list({ token: user?.token }, searchParams.toString())
  })

  const useGetDefaultStaffQuery = () =>
    useQuery({
      queryKey: ['query', 'attributions', 'staff', 'default'],
      queryFn: () => appRouter.staffs.getDefault({ token: user?.token })
    })

  const useUpdateDefaultStaffMutation = () =>
    useMutation({
      mutationKey: ['mutation', 'attributions', 'staff', 'default', 'update'],
      mutationFn: (staffId: number) =>
        appRouter.staffs.updateDefault({ token: user?.token }, staffId)
    })

  const useGetAttributableStaffs: QueryInfiniteHook<StaffsListAttribution> = (options) => {
    return useInfiniteQuery({
      ...infiniteQueryDefaultParams<StaffsListAttribution>(searchParams),
      queryKey: ['attribution', 'list', ...(options?.additionalQueryKeys ?? [])],
      queryFn: ({ pageParam, direction }) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('offset', `${pageParam}`)
        if (direction === 'forward') setSearchParams(newSearchParams)
        return appRouter.attribution.list({ token: user?.token }, newSearchParams.toString())
      },
      ...options
    })
  }

  const getStaff = useMutation<StaffDetails, unknown, number>({
    mutationKey: ['staffs', 'get'],
    mutationFn: (id) => appRouter.staffs.getOne({ token: user?.token }, id)
  })

  const createStaff = useMutation<IdReturn, unknown, CreateUpdateStaffForm>({
    mutationKey: ['staffs', 'create'],
    mutationFn: (data) => appRouter.staffs.create({ token: user?.token }, data)
  })

  const updateStaff = useMutation<void, unknown, CreateUpdateStaffForm>({
    mutationKey: ['staffs', 'update'],
    mutationFn: (data) => appRouter.staffs.update({ token: user?.token }, data)
  })

  const updateStaffLanguage = useMutation<void, unknown, { language: number; id: number }>({
    mutationKey: ['staff language', 'update'],
    mutationFn: ({ language, id }) =>
      appRouter.staffs.updateLanguage({ token: user?.token }, language, id)
  })

  const updateStaffIsAttributable = useMutation<
    void,
    unknown,
    { isAttributable: boolean; id: number }
  >({
    mutationKey: ['attributions', 'staff', 'update'],
    mutationFn: ({ isAttributable, id }) =>
      appRouter.staffs.updateIsAttributable({ token: user?.token }, isAttributable, id)
  })

  const createOrUpdateStaffPhoneSystems = useMutation<void, unknown, CreateUpdateStaffForm>({
    mutationKey: ['staffs', 'update', 'phone-systems'],
    mutationFn: (data) => appRouter.staffs.createOrUpdatePhoneSystem({ token: user?.token }, data)
  })

  const getStatsAcquisitions = useMutation<AcquisitionsStats>({
    mutationKey: ['stats', 'acquisitions'],
    mutationFn: () => appRouter.stats.acquisitions({ token: user?.token }, searchParams.toString())
  })

  const getStatsAccounting = useMutation<AccountingStats>({
    mutationKey: ['stats', 'accounting'],
    mutationFn: () => appRouter.stats.accounting({ token: user?.token }, searchParams.toString())
  })

  const getStatsCA = useMutation<StatsCA>({
    mutationKey: ['stats', 'CA'],
    mutationFn: () => appRouter.stats.CA({ token: user?.token }, searchParams.toString())
  })

  const getMonthlyStatsCaVsBp = useMutation<MonthlyStats>({
    mutationKey: ['stats', 'MonthlyStats'],
    mutationFn: () => appRouter.stats.monthlyCaVsBp({ token: user?.token }, searchParams.toString())
  })

  const getStatsCaVsBp = useMutation<StatsCA>({
    mutationKey: ['stats', 'CAvsBP'],
    mutationFn: () => appRouter.stats.caVsBp({ token: user?.token }, searchParams.toString())
  })

  const getStatsOccupations = useMutation<Occupations>({
    mutationKey: ['stats occupations', 'occupations'],
    mutationFn: () => appRouter.stats.occupations({ token: user?.token }, searchParams.toString())
  })

  const getStatsInventories = useMutation<Inventories>({
    mutationKey: ['stats inventories', 'inventories'],
    mutationFn: () => appRouter.stats.inventories({ token: user?.token }, searchParams.toString())
  })

  const getStatsSurface = useMutation<Surface>({
    mutationKey: ['stats surface', 'surface'],
    mutationFn: () => appRouter.stats.surface({ token: user?.token }, searchParams.toString())
  })

  const getStatsClientRequests = useMutation<ClientRequest>({
    mutationKey: ['stats client requests', 'requests'],
    mutationFn: () =>
      appRouter.stats.clientRequests({ token: user?.token }, searchParams.toString())
  })

  const getStatsPerformance = useMutation<PerformanceStats>({
    mutationKey: ['stats', 'performance'],
    mutationFn: () => appRouter.stats.performance({ token: user?.token }, searchParams.toString())
  })

  const getSources = useMutation<any>({
    mutationKey: ['sources', 'list'],
    mutationFn: () => appRouter.sources.list({ token: user?.token }, searchParams.toString())
  })

  const createSource = useMutation<IdReturn, unknown, CreateSourceFormData>({
    mutationKey: ['sources', 'create'],
    mutationFn: (data) => appRouter.sources.create({ token: user?.token }, data)
  })

  const getInternalDocuments = useMutation<TermsOfSalesList, unknown, string | undefined>({
    mutationKey: ['terms_of_sales', 'list'],
    mutationFn: (type) =>
      appRouter.termsOfSales.list({ token: user?.token }, type, searchParams.toString())
  })

  const getInternalDocument = useMutation<TermOfSalesItem, unknown, { type: string; id: number }>({
    mutationKey: ['terms_of_sales', 'list'],
    mutationFn: ({ type, id }) => appRouter.termsOfSales.get({ token: user?.token }, type, id)
  })

  const getInternalDocumentPdf = useMutation<any, unknown, { type: string; id: number }>({
    mutationKey: ['terms_of_sales', 'list'],
    mutationFn: ({ type, id }) => appRouter.termsOfSales.pdf({ token: user?.token }, type, id)
  })

  const updateInternalDocument = useMutation<
    void,
    unknown,
    { type: string; id: number; data: UpdateTermOfSalesInput }
  >({
    mutationKey: ['terms_of_sales', 'update'],
    mutationFn: ({ type, id, data }) =>
      appRouter.termsOfSales.update({ token: user?.token }, type, id, data)
  })

  const uploadTermOfSales = useMutation<
    void,
    unknown,
    { type: string; data: UploadTermOfSalesInput }
  >({
    mutationKey: ['terms_of_sales', 'upload'],
    mutationFn: ({ type, data }) =>
      appRouter.termsOfSales.upload({ token: user?.token }, type, data)
  })

  const getAcquisitionsCost = useMutation<AcquisitionCostList>({
    mutationKey: ['sources', 'acquisition-cost'],
    mutationFn: () =>
      appRouter.sources.getAcquisitionCosts({ token: user?.token }, searchParams.toString())
  })

  const uploadAcquisitionCostsCSV = useMutation<void, unknown, any>({
    mutationKey: ['sources', 'acquisition-cost', 'upload'],
    mutationFn: (file) => appRouter.sources.uploadCSV({ token: user?.token }, file)
  })

  const updateAcquisitionCost = useMutation<
    void,
    unknown,
    { id: number; data: UpdateAcquisitionCostProps }
  >({
    mutationKey: ['sources', 'acquisition-cost', 'update'],
    mutationFn: ({ id, data }) => appRouter.sources.update({ token: user?.token }, id, data)
  })

  const getQuotations = useMutation<QuotationsList>({
    mutationKey: ['quotations', 'list'],
    mutationFn: () => appRouter.quotations.list({ token: user?.token }, searchParams.toString())
  })

  const getQuotation = useMutation<QuotationInformation, void, string>({
    mutationKey: ['quotations', 'get'],
    mutationFn: (id) => appRouter.quotations.getOne({ token: user?.token }, id)
  })

  const getQuotationDownload = useMutation<void, unknown, string>({
    mutationKey: ['quotations', 'pdf'],
    mutationFn: (id) => appRouter.quotations.getPdf({ token: user?.token }, id)
  })

  const getQuotationSolutions = useMutation<QuotationSolutions, void, string>({
    mutationKey: ['quotations', 'solutions'],
    mutationFn: (id) => appRouter.quotations.getSolutions({ token: user?.token }, id)
  })

  const cancelQuotation = useMutation<void, void, string>({
    mutationKey: ['quotations', 'cancel'],
    mutationFn: (id) => appRouter.quotations.cancel({ token: user?.token }, id)
  })

  const resetPassword = useMutation<void, unknown, { id: string }>({
    mutationKey: ['clients', 'activate'],
    mutationFn: ({ id }) => appRouter.individuals.resetPassword({ token: user?.token }, id)
  })

  const sendQuotation = useMutation<void, void, string>({
    mutationKey: ['quotations', 'cancel'],
    mutationFn: (id) => appRouter.quotations.send({ token: user?.token }, id)
  })

  const updateQuotation = useMutation<void, void, UpdateQuotationFormItems>({
    mutationKey: ['quotations', 'update'],
    mutationFn: (data) => appRouter.quotations.update({ token: user?.token }, data)
  })

  const transformQuotationSolution = useMutation<void, void, { id: number; solutionId: number }>({
    mutationKey: ['quotations', 'transfers'],
    mutationFn: (data) => appRouter.quotations.transformSolution({ token: user?.token }, data)
  })

  const updateQuotationSolution = useMutation<
    void,
    void,
    { id: number; solutionId: number; data: any }
  >({
    mutationKey: ['quotations', 'solution', 'update'],
    mutationFn: (data) => appRouter.quotations.updateSolution({ token: user?.token }, data)
  })

  const getQuotationSolutionPrice = useMutation<
    QuotationSolutionPrice[],
    void,
    { id: number; solutionId: number; data: any }
  >({
    mutationKey: ['quotations', 'solution', 'price'],
    mutationFn: (data) => appRouter.quotations.priceSolution({ token: user?.token }, data)
  })
  const getQuotationSolutionCommitments = useMutation<
    any,
    void,
    { id: number; solutionId: number }
  >({
    mutationKey: ['quotations', 'solution', 'price'],
    mutationFn: ({ id, solutionId }) =>
      appRouter.quotations.getSelectCommitments({ token: user?.token }, id, solutionId)
  })

  const getContractsConsumptions = useMutation<ContractsConsumptionsList>({
    mutationKey: ['contractsConsumptions', 'list'],
    mutationFn: () =>
      appRouter.contracts.listConsumptions({ token: user?.token }, searchParams.toString())
  })

  const getContractsConsumptionsSum = useMutation<ContractsConsumptionsSum>({
    mutationKey: ['contractsConsumptions', 'sum'],
    mutationFn: () =>
      appRouter.contracts.getConsumptionsSum({ token: user?.token }, searchParams.toString())
  })

  const getConsumptionContract = useMutation<ConsumptionContract, unknown, string>({
    mutationKey: ['consumption-contract', 'get'],
    mutationFn: (id) => appRouter.consumptionContract.get({ token: user?.token }, id)
  })

  const getConsumptionContractConsumptions = useMutation<
    ConsumptionContractConsumptions,
    unknown,
    string
  >({
    mutationKey: ['consumptions-contract-consumptions', 'get'],
    mutationFn: (id) => appRouter.consumptionContract.getConsumptions({ token: user?.token }, id)
  })

  const getConsumptions = useMutation<ConsumptionList>({
    mutationKey: ['consumptions', 'list'],
    mutationFn: () => appRouter.consumptions.list({ token: user?.token }, searchParams.toString())
  })

  const getDuplicateEnterprises = useMutation<DuplicateEnterprisesList>({
    mutationKey: ['duplicate enterprises', 'list'],
    mutationFn: () =>
      appRouter.duplicateEnterprises.list({ token: user?.token }, searchParams.toString())
  })

  const getIndexing = useMutation<IndexingList>({
    mutationKey: ['indexing', 'list'],
    mutationFn: () => appRouter.indexing.list({ token: user?.token }, searchParams.toString())
  })

  const addEndorsementService = useMutation<void, unknown, { id: string; service: string }>({
    mutationKey: ['endorsements', 'add_service'],
    mutationFn: ({ id, service }) =>
      appRouter.endorsements.addService({ token: user?.token }, id, service)
  })

  const getEndorsements = useMutation<EndorsementList>({
    mutationKey: ['endorsements', 'list'],
    mutationFn: () => appRouter.endorsements.list({ token: user?.token }, searchParams.toString())
  })

  const getClientCenter = useMutation<ClientCenter, unknown, { id: string; centerId: string }>({
    mutationKey: ['client center', 'get'],
    mutationFn: ({ id, centerId }) =>
      appRouter.clientCenter.get({ token: user?.token }, id, centerId)
  })

  const updateClientCenter = useMutation<
    void,
    unknown,
    { id: string; clientCenterId: string; data: ClientCenterForm }
  >({
    mutationKey: ['client center', 'update'],
    mutationFn: ({ id, clientCenterId, data }) =>
      appRouter.clientCenter.update({ token: user?.token }, id, clientCenterId, data)
  })

  const updateClientCenterMail = useMutation<
    void,
    unknown,
    { id: string; clientCenterId: string; data: ClientCenterMailForm }
  >({
    mutationKey: ['client center mail', 'update'],
    mutationFn: ({ id, clientCenterId, data }) =>
      appRouter.clientCenter.updateMail({ token: user?.token }, id, clientCenterId, data)
  })

  const getClientRequest = useMutation<any, unknown, string>({
    mutationKey: ['client requests', 'get'],
    mutationFn: (id) => appRouter.clientRequests.getOne({ token: user?.token }, id)
  })

  const getClientRequests = useMutation<ClientRequestList>({
    mutationKey: ['client requests', 'list'],
    mutationFn: () => appRouter.clientRequests.list({ token: user?.token }, searchParams.toString())
  })

  const getClientRequestSum = useMutation<ClientRequestSum>({
    mutationKey: ['client requests', 'sum'],
    mutationFn: () =>
      appRouter.clientRequests.getSum({ token: user?.token }, searchParams.toString())
  })

  const updateClientRequest = useMutation<void, unknown, ClientRequestFormProps>({
    mutationKey: ['client requests', 'update'],
    mutationFn: (data) => appRouter.clientRequests.update({ token: user?.token }, data)
  })

  const getAccountingClients = useMutation<AccountingClientsList>({
    mutationKey: ['accountingClients', 'list'],
    mutationFn: () =>
      appRouter.accounting.clientsList({ token: user?.token }, searchParams.toString())
  })

  const getAccountingClientsExport = useMutation<void>({
    mutationKey: ['accountingclients', 'export'],
    mutationFn: () =>
      appRouter.accounting.getClientsExport({ token: user?.token }, searchParams.toString())
  })

  const getAccountingClientsExportSage = useMutation<void>({
    mutationKey: ['accountingclients', 'export-sage'],
    mutationFn: () =>
      appRouter.accounting.getClientsExportSage({ token: user?.token }, searchParams.toString())
  })
  const createCustomerReservation = useMutation<IdReturn, unknown, CreateCustomerReservation>({
    mutationKey: ['customer reservations', 'add'],
    mutationFn: (data) => appRouter.customerReservations.create({ token: user?.token }, data)
  })
  const linkToAccessCustomerReservation = useMutation<void, unknown, LinkToAccess, void>({
    mutationKey: ['customer reservations', 'link'],
    mutationFn: (data) => appRouter.customerReservations.linkToAccess({ token: user?.token }, data)
  })
  const getCustomerReservations = useMutation<CustomerReservationList>({
    mutationKey: ['customer reservations', 'list'],
    mutationFn: () =>
      appRouter.customerReservations.list({ token: user?.token }, searchParams.toString())
  })

  const getCustomerReservationSum = useMutation<CustomerReservationSum>({
    mutationKey: ['customer reservations', 'sum'],
    mutationFn: () =>
      appRouter.customerReservations.getSum({ token: user?.token }, searchParams.toString())
  })

  const getCustomerReservation = useMutation<CustomerReservationInformations, unknown, string>({
    mutationKey: ['customer reservations', 'information'],
    mutationFn: (id) => appRouter.customerReservations.getOne({ token: user?.token }, id)
  })

  const getCustomerReservationServices = useMutation<CustomerReservationServices, unknown, string>({
    mutationKey: ['customer reservations', 'services'],
    mutationFn: (id) => appRouter.customerReservations.getServices({ token: user?.token }, id)
  })

  const getCustomerReservationContracts = useMutation<any, unknown, string>({
    mutationKey: ['customer reservations', 'contracts'],
    mutationFn: (id) => appRouter.customerReservations.getContracts({ token: user?.token }, id)
  })

  const getCustomerReservationIndividuals = useMutation<any, unknown, string>({
    mutationKey: ['customer reservations', 'contracts'],
    mutationFn: (id) => appRouter.customerReservations.getIndividuals({ token: user?.token }, id)
  })

  const cancelCustomerReservation = useMutation<void, unknown, string>({
    mutationKey: ['customer reservations', 'cancel'],
    mutationFn: (id) => appRouter.customerReservations.cancel({ token: user?.token }, id)
  })

  const confirmCustomerReservation = useMutation<void, unknown, string>({
    mutationKey: ['customer reservations', 'confirm'],
    mutationFn: (id) => appRouter.customerReservations.confirm({ token: user?.token }, id)
  })

  const updateCustomerReservation = useMutation<void, unknown, any>({
    mutationKey: ['customer reservations', 'update'],
    mutationFn: (data) => appRouter.customerReservations.update({ token: user?.token }, data)
  })

  const addCustomerReservationService = useMutation<void, unknown, AddReservationConsumptionItems>({
    mutationKey: ['customer_reservations', 'add'],
    mutationFn: (data) => appRouter.customerReservations.addService({ token: user?.token }, data)
  })

  const getSelectCommitments = useMutation<any, unknown, number>({
    mutationKey: ['endorsements', 'select_commitments'],
    mutationFn: (id) => appRouter.endorsements.getSelectCommitments({ token: user?.token }, id)
  })

  const sendEndorsementToDirection = useMutation<void, unknown, string>({
    mutationKey: ['endorsements', 'send_to_direction'],
    mutationFn: (id) => appRouter.endorsements.sendToDirection({ token: user?.token }, id)
  })

  const validateEndorsement = useMutation<void, unknown, string>({
    mutationKey: ['endorsements', 'validate'],
    mutationFn: (id) => appRouter.endorsements.validate({ token: user?.token }, id)
  })

  const getAccountingPayments = useMutation<AccountingPaymentsList>({
    mutationKey: ['accounting payments', 'list'],
    mutationFn: () =>
      appRouter.accounting.paymentsList({ token: user?.token }, searchParams.toString())
  })

  const getAccountingPaymentsExport = useMutation<void>({
    mutationKey: ['accounting payments', 'export'],
    mutationFn: () =>
      appRouter.accounting.getPaymentsExport({ token: user?.token }, searchParams.toString())
  })

  const getAccountingVariousOperationsExport = useMutation<void>({
    mutationKey: ['accounting various operations', 'export'],
    mutationFn: () =>
      appRouter.accounting.getVariousOperationsExport(
        { token: user?.token },
        searchParams.toString()
      )
  })

  const getMatchingTransfersExceptions = useMutation<MatchingTransfersExceptionsList>({
    mutationKey: ['matching transfers exceptions', 'list'],
    mutationFn: () =>
      appRouter.accounting.getMatchingTransfersExceptions(
        { token: user?.token },
        searchParams.toString()
      )
  })

  const getEnterprise = useMutation<EnterpriseInformation, unknown, string>({
    mutationKey: ['enterprises', 'getone'],
    mutationFn: (id) => appRouter.enterprises.getOne({ token: user?.token }, id)
  })

  const getConsumptionsClient = useMutation<any, unknown, string>({
    mutationKey: ['client consumptions', 'consumptions'],
    mutationFn: (id) =>
      appRouter.consumptions.getClientContractsConsumptions({ token: user?.token }, id)
  })

  const getEvents = useMutation<EventsList, unknown, string>({
    mutationKey: ['enterprises', 'events'],
    mutationFn: (id) => appRouter.enterprises.listEvents({ token: user?.token }, id)
  })

  const getEventsList = useMutation<EventsList, unknown, void>({
    mutationKey: ['enterprises', 'events'],
    mutationFn: () => appRouter.events.list({ token: user?.token }, searchParams.toString())
  })

  const getHappeningsList = useMutation<HappeningsList, unknown, void>({
    mutationKey: ['happenings', 'list'],
    mutationFn: () => appRouter.happenings.list({ token: user?.token }, searchParams.toString())
  })

  const createHappening = useMutation<IdReturn, unknown, CreateHappeningForm>({
    mutationKey: ['happenings', 'create'],
    mutationFn: (data) => appRouter.happenings.create({ token: user?.token }, data)
  })

  const updateHappening = useMutation<void, unknown, { id: number; data: UpdateHappening }>({
    mutationKey: ['happenings', 'update'],
    mutationFn: ({ id, data }) => appRouter.happenings.update({ token: user?.token }, id, data)
  })

  const getHappeningsDetails = useMutation<HappeningsDetails, unknown, number>({
    mutationKey: ['happening', 'read'],
    mutationFn: (id) => appRouter.happenings.getRead({ token: user?.token }, id)
  })

  const getHappeningsPartners = useMutation<HappeningsListPartner, unknown, void>({
    mutationKey: ['happenings', 'partnersList'],
    mutationFn: () =>
      appRouter.happenings.getPartners({ token: user?.token }, searchParams.toString())
  })

  const createPartners = useMutation<IdReturn, unknown, CreateHappeningPartnerForm>({
    mutationKey: ['happenings', 'create'],
    mutationFn: (data) => appRouter.happenings.createPartners({ token: user?.token }, data)
  })

  const getEnterpriseDocuments = useMutation<
    EnterpriseDocumentsList,
    unknown,
    EnterpriseDocumentsListParams
  >({
    mutationKey: ['enterprises', 'documents'],
    mutationFn: (data) => appRouter.enterprises.listDocuments({ token: user?.token }, data)
  })

  const getEnterpriseMembers = useMutation<EnterpriseMembersList, unknown, string>({
    mutationKey: ['enterprises', 'members'],
    mutationFn: (id) => appRouter.enterprises.listMembers({ token: user?.token }, id)
  })

  const linkEnterpriseMember = useMutation<
    void,
    unknown,
    {
      id: string
      data: LinkIndividualProps
    }
  >({
    mutationKey: ['enterprises', 'link-members'],
    mutationFn: ({ id, data }) => appRouter.enterprises.linkMember({ token: user?.token }, id, data)
  })

  const createEnterpriseMember = useMutation<void, unknown, IndividualFormDetails>({
    mutationKey: ['enterprises', 'link-members'],
    mutationFn: (data) =>
      appRouter.enterprises.createMember({ token: user?.token }, String(data.enterpriseId), data)
  })

  const getEnterpriseOpportunities = useMutation<EnterpriseOpportunitiesList, unknown, string>({
    mutationKey: ['enterprises', 'opportunities'],
    mutationFn: (id) => appRouter.enterprises.listOpportunities({ token: user?.token }, id)
  })

  const getEnterpriseContracts = useMutation<EnterpriseContractsList, unknown, string>({
    mutationKey: ['enterprises', 'contracts'],
    mutationFn: (id) => appRouter.enterprises.listContracts({ token: user?.token }, id)
  })

  const getEnterpriseSsids = useMutation<any, unknown, string>({
    mutationKey: ['enterprises', 'ssids'],
    mutationFn: (id) => appRouter.enterprises.listSsids({ token: user?.token }, id)
  })

  const getEnterpriseSsidsUsers = useMutation<any, unknown, { id: string; ssid: string }>({
    mutationKey: ['enterprises', 'ssids'],
    mutationFn: ({ id, ssid }) =>
      appRouter.enterprises.listSsidsUsers({ token: user?.token }, id, ssid)
  })

  const updateEnterprise = useMutation<void, unknown, EnterpriseInformation>({
    mutationKey: ['enterprises', 'update'],
    mutationFn: (data) => appRouter.enterprises.update({ token: user?.token }, data)
  })

  const updateEnterpriseSsidUser = useMutation<
    void,
    unknown,
    { id: string; ssid: string; ssidUser: string; data: any }
  >({
    mutationKey: ['enterprises', 'ssid', 'user', 'update'],
    mutationFn: ({ id, ssid, ssidUser, data }) =>
      appRouter.enterprises.updateSsidUser({ token: user?.token }, id, ssid, ssidUser, data)
  })

  const createEnterpriseSsid = useMutation<void, unknown, { id: string; data: any }>({
    mutationKey: ['enterprises', 'ssid', 'create'],
    mutationFn: ({ id, data }) => appRouter.enterprises.createSsid({ token: user?.token }, id, data)
  })

  const createEnterpriseSsidUser = useMutation<
    void,
    unknown,
    { id: string; ssid: string; data: any }
  >({
    mutationKey: ['enterprises', 'ssid', 'user', 'create'],
    mutationFn: ({ id, ssid, data }) =>
      appRouter.enterprises.createSsidUser({ token: user?.token }, id, ssid, data)
  })

  const createEnterpriseOpportunity = useMutation<
    IdReturn,
    unknown,
    { id: string; data: OpportunityCreateFormData }
  >({
    mutationKey: ['opportunity', 'create'],
    mutationFn: ({ id, data }) =>
      appRouter.enterprises.createOpportunity({ token: user?.token }, id, data)
  })

  const updateClientLink = useMutation<void, unknown, UpdateClientLinkProps>({
    mutationKey: ['clientlink', 'update'],
    mutationFn: (params) => appRouter.individuals.updateClientLink({ token: user?.token }, params)
  })

  const uploadDocument = useMutation<void, unknown, { id: string; data: any }>({
    mutationKey: ['document', 'upload'],
    mutationFn: (params) => appRouter.enterprises.uploadDocument({ token: user?.token }, params)
  })

  const getClientConsumptions = useMutation<ConsumptionsList, unknown, ConsumptionsListParams>({
    mutationKey: ['client', 'consumptions'],
    mutationFn: (params) => appRouter.consumptions.listForClient({ token: user?.token }, params)
  })

  const getClientContracts = useMutation<ClientContractList, unknown, ClientContractParams>({
    mutationKey: ['contract', 'listClient'],
    mutationFn: (data) => appRouter.contracts.listForClient({ token: user?.token }, data)
  })

  const generatePartialContractInvoice = useMutation<
    void,
    unknown,
    { id: number; contractServices: number[] }
  >({
    mutationKey: ['invoices', 'partial_contract_invoice'],
    mutationFn: ({ id, contractServices }) =>
      appRouter.invoices.generatePartialContractInvoice(
        { token: user?.token },
        id,
        contractServices
      )
  })

  const createContract = useMutation<IdReturn, unknown, any>({
    mutationKey: ['contracts', 'create'],
    mutationFn: (params) => appRouter.contracts.create({ token: user?.token }, params)
  })

  const createContractServices = useMutation<IdReturn, unknown, { id: number; params: any }>({
    mutationKey: ['contracts', 'create_service'],
    mutationFn: ({ id, params }) =>
      appRouter.contracts.createService({ token: user?.token }, id, params)
  })

  const deleteContractService = useMutation<
    void,
    unknown,
    { contractId: number; serviceId: number }
  >({
    mutationKey: ['contracts', 'delete_service'],
    mutationFn: ({ contractId, serviceId }) =>
      appRouter.contracts.deleteService({ token: user?.token }, contractId, serviceId)
  })

  const createEvent = useMutation<IdReturn, unknown, EventFormData>({
    mutationKey: ['events', 'create'],
    mutationFn: (data) => appRouter.events.create({ token: user?.token }, data)
  })

  const editEvent = useMutation<void, unknown, { id: number; data: EventFormData }>({
    mutationKey: ['events', 'edit'],
    mutationFn: ({ id, data }) => appRouter.events.edit({ token: user?.token }, id, data)
  })

  const getServicesPricer = useMutation<any, unknown, { id: string }>({
    mutationKey: ['pricer', 'services'],
    mutationFn: ({ id }) => appRouter.pricer.services({ token: user?.token }, id)
  })

  const getInformationsPricer = useMutation<any, unknown, { id: string }>({
    mutationKey: ['pricer', 'informations'],
    mutationFn: ({ id }) => appRouter.pricer.informations({ token: user?.token }, id)
  })

  const getDiscountGridsPricer = useMutation<any, unknown, { center: string; begin: string }>({
    mutationKey: ['pricer', 'discount-grids'],
    mutationFn: ({ center, begin }) =>
      appRouter.pricer.discountGrids({ token: user?.token }, center, begin)
  })

  const getDiscountGrids = useMutation<DiscountGridsList>({
    mutationKey: ['discount grid', 'list'],
    mutationFn: () => appRouter.discountGrid.list({ token: user?.token }, searchParams.toString())
  })

  const getDiscountGrid = useMutation<DiscountGridRead, unknown, { id: string }>({
    mutationKey: ['discount grid', 'read'],
    mutationFn: ({ id }) => appRouter.discountGrid.read({ token: user?.token }, id)
  })

  const createDiscountGrid = useMutation<void, unknown, DiscountGridAdd>({
    mutationKey: ['discount grid', 'add'],
    mutationFn: (data) => appRouter.discountGrid.create({ token: user?.token }, data)
  })

  const getDiscountGridsCenter = useMutation<DiscountGridsCenterList>({
    mutationKey: ['discount grid center', 'list'],
    mutationFn: () =>
      appRouter.discountGridCenter.list({ token: user?.token }, searchParams.toString())
  })

  const getDiscountGridsCenterAdd = useMutation<
    DiscountGridsCenterResponse,
    unknown,
    DiscountGridsCenterBody
  >({
    mutationKey: ['discount grid center', 'add'],
    mutationFn: (body) => appRouter.discountGridCenter.add({ token: user?.token }, body)
  })

  const createQuotation = useMutation<
    IdReturn,
    unknown,
    { opportunityId: string; data: QuotationCreateFormData }
  >({
    mutationKey: ['quotation', 'create'],
    mutationFn: ({ opportunityId, data }) =>
      appRouter.opportunities.createQuotation({ token: user?.token }, opportunityId, data)
  })

  const getListFloors = useMutation<ListFloors, unknown, void>({
    mutationKey: ['list floors', 'floors'],
    mutationFn: () =>
      appRouter.floorPlans.listFloors({ token: user?.token }, String(searchParams.get('center')))
  })

  const getDocument = useMutation<Document, unknown, { id: string }>({
    mutationKey: ['document', 'get'],
    mutationFn: ({ id }) => appRouter.documents.getOne({ token: user?.token }, id)
  })

  const invalidateDocument = useMutation<void, unknown, { id: string }>({
    mutationKey: ['document', 'invalidate'],
    mutationFn: ({ id }) => appRouter.documents.invalidate({ token: user?.token }, id)
  })

  const validateDocument = useMutation<void, unknown, { id: string }>({
    mutationKey: ['document', 'validate'],
    mutationFn: ({ id }) => appRouter.documents.validate({ token: user?.token }, id)
  })

  const getFloorPlans = useMutation<FloorPlans, unknown, FloorPlansParams>({
    mutationKey: ['floor plans', 'floorPlans'],
    mutationFn: (data) => appRouter.floorPlans.informations({ token: user?.token }, data)
  })

  const getFilterContractsFloorPlans = useMutation<FilterContracts, unknown, FilterContractsParams>(
    {
      mutationKey: ['filter contracts floor', 'contracts floor'],
      mutationFn: (data) => appRouter.floorPlans.getFilterContracts({ token: user?.token }, data)
    }
  )

  const createActivity = useMutation<any, unknown, any>({
    mutationKey: ['activities', 'create'],
    mutationFn: (params) => appRouter.activities.create({ token: user?.token }, params)
  })

  const getWifiClientsLogs = useMutation<WifiClientLogsList>({
    mutationKey: ['wifi', 'clients'],
    mutationFn: () => appRouter.wifi.getClientsLogs({ token: user?.token }, searchParams.toString())
  })

  const getEmailTemplates = useMutation<EmailTemplatesListType>({
    mutationKey: ['emails', 'list'],
    mutationFn: () => appRouter.emailTemplate.list({ token: user?.token }, searchParams.toString())
  })

  const getOneEmailTemplate = useMutation<EmailTemplateSheetType, unknown, string>({
    mutationKey: ['emails', 'sheet'],
    mutationFn: (id) => appRouter.emailTemplate.getOne({ token: user?.token }, id)
  })

  const updateEmailTemplate = useMutation<void, unknown, EmailTemplateForm>({
    mutationKey: ['emails', 'update'],
    mutationFn: (data) => appRouter.emailTemplate.edit({ token: user?.token }, data)
  })

  const encrypt = useMutation<CryptResType, unknown, { text: string }>({
    mutationKey: ['crypt', 'encrypt'],
    mutationFn: (data) => appRouter.crypt.encrypt({ token: user?.token }, data)
  })

  const decrypt = useMutation<CryptResType, unknown, { text: string }>({
    mutationKey: ['crypt', 'decrypt'],
    mutationFn: (data) => appRouter.crypt.decrypt({ token: user?.token }, data)
  })

  const getNotifications = useMutation<NotificationList>({
    mutationKey: ['notifications', 'list'],
    mutationFn: () => appRouter.notifications.list({ token: user?.token }, searchParams.toString())
  })

  const updateNotification = useMutation<void, unknown, { id: number; data: NotificationUpdate }>({
    mutationKey: ['notifications', 'update'],
    mutationFn: ({ id, data }) => appRouter.notifications.update({ token: user?.token }, id, data)
  })

  const getAttributionLogs = useMutation<AttributionList>({
    mutationKey: ['attribution logs', 'list'],
    mutationFn: () =>
      appRouter.attributions.logsList({ token: user?.token }, searchParams.toString())
  })

  const getServicesTypes = useMutation<ServicesTypesList>({
    mutationKey: ['services', 'types'],
    mutationFn: () => appRouter.services.getServicesTypes({ token: user?.token })
  })

  const getAssets = useMutation<AssetsList, unknown, { type: string; id: string }>({
    mutationKey: ['assets', 'list'],
    mutationFn: ({ type, id }) => appRouter.assets.list({ token: user?.token }, type, id)
  })

  const useGetAssets = ({ type, id, enabled }: { type: string; id: string; enabled?: boolean }) =>
    useQuery({
      queryKey: ['query', 'assets', 'list', type, id],
      queryFn: () => appRouter.assets.list({ token: user?.token }, type, id),
      enabled: enabled
    })

  const useDeleteAsset = () =>
    useMutation<void, unknown, { type: string; id: string; imageId: string }>({
      mutationKey: ['assets', 'delete'],
      mutationFn: ({ type, id, imageId }) =>
        appRouter.assets.delete({ token: user?.token }, type, id, imageId)
    })

  const useAddAsset = () =>
    useMutation<void, unknown, { id: string; type: string; formData: FormData }>({
      mutationKey: ['assets', 'add'],
      mutationFn: ({ id, type, formData }) =>
        appRouter.assets.add({ token: user?.token }, id, type, formData)
    })

  const getPresenceLogs = useMutation<PresenceLogsList>({
    mutationKey: ['presence', 'logs'],
    mutationFn: () => appRouter.presences.logsList({ token: user?.token }, searchParams.toString())
  })

  const useGetPresenceSchedules: QueryInfiniteHook<PresenceScheduleList> = (options) =>
    useInfiniteQuery({
      ...infiniteQueryDefaultParams<PresenceScheduleList>(searchParams),
      queryKey: ['query', 'presences', 'schedule', 'list', ...(options?.additionalQueryKeys ?? [])],
      queryFn: ({ pageParam }) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('offset', `${pageParam}`)
        setSearchParams(newSearchParams)
        return appRouter.presences.schedulesList({ token: user?.token }, searchParams.toString())
      },
      ...options
    })

  const getPresenceConsumption = useMutation<PresenceConsumptionList>({
    mutationKey: ['presence consumption', 'list'],
    mutationFn: () =>
      appRouter.presencesConsumption.list({ token: user?.token }, searchParams.toString())
  })

  const getPresenceConsumptionSum = useMutation<PresenceConsumptionSum>({
    mutationKey: ['presence consumption', 'sum'],
    mutationFn: () =>
      appRouter.presencesConsumption.getSum({ token: user?.token }, searchParams.toString())
  })

  return (
    <FetcherContext.Provider
      value={{
        updateStaffIsAttributable,
        updateStaffLanguage,
        getAuthentificationRadius,
        getCenterDiscounts,
        getCenterDiscountsNotActive,
        createCenterDiscount,
        getStatsCA,
        getMonthlyStatsCaVsBp,
        getStatsCaVsBp,
        getCommitments,
        updateOptionService,
        createOptionService,
        getTertiaries,
        getTypologies,
        createTertiary,
        getCenters,
        createCenters,
        getDiscountGrid,
        createDiscountGrid,
        getDiscountGridsCenterAdd,
        getDiscountGridsCenter,
        getDiscountGrids,
        useGetDefaultStaffQuery,
        useUpdateDefaultStaffMutation,
        getStaffs,
        useGetAttributableStaffs,
        getStaff,
        createStaff,
        updateStaff,
        createOrUpdateStaffPhoneSystems,
        createMainService,
        updateMainService,
        computePrice,
        getStatsAcquisitions,
        getStatsAccounting,
        getStatsOccupations,
        getStatsInventories,
        getAcquisitionsCost,
        getSources,
        createSource,
        uploadAcquisitionCostsCSV,
        updateAcquisitionCost,
        getStatsSurface,
        getStatsClientRequests,
        getStatsPerformance,
        getInternalDocuments,
        getInternalDocument,
        getInternalDocumentPdf,
        updateInternalDocument,
        uploadTermOfSales,
        getDocument,
        invalidateDocument,
        validateDocument,
        activateAccount,
        getActivities,
        getCompanies,
        getCompany,
        getCompanyCenters,
        getCompanyConfigurations,
        getCompanyBankAccounts,
        createCompanyBankAccount,
        updateCompanyBankAccount,
        getCompanyBankAccount,
        availableCentersCompany,
        postCompany,
        getEnterprises,
        getEnterprise,
        getEvents,
        getEventsList,
        getHappeningsList,
        getHappeningsPartners,
        createPartners,
        getHappeningsDetails,
        createHappening,
        updateHappening,
        createEnterprise,
        createEnterprisePrescriber,
        agreeContract,
        cancelContract,
        downloadContract,
        createContractEndorsement,
        updateContractEndorsement,
        getContracts,
        getContractEndorsements,
        getContractAutoRenewalEndorsements,
        getContractSelectOptions,
        stopContractService,
        getContractSum,
        getContractExport,
        getContract,
        getContractMain,
        getContractOption,
        getContractConsumption,
        getContractInvoice,
        getContractIndexingAnniversary,
        linkContractServiceUser,
        updateContractAdmin,
        getContractLitigations,
        getContractPayments,
        getContractUsers,
        updateContractOccupant,
        validateContract,
        postLitigationContract,
        getDashboardContracts,
        getDashboardOccupations,
        getWifiLogs,
        getDashboardVisits,
        getDashboardNextEntries,
        getDashboardMeetings,
        getDashboardOpenDesktops,
        getDashboardOtherInformation,
        getInvoices,
        getInvoiceSum,
        getInvoice,
        getInvoicePdf,
        getInvoiceActivities,
        getCreditActivities,
        getFilters,
        getFormItems,
        useGetFormItems,
        getFormItemsWithFilters,
        getClientFilters,
        getCenter,
        useGetCenter,
        getCenterAccessGroups,
        getCenterAccessRooms,
        getCenterBlockedPrices,
        getCenterPrices,
        uploadBusinessPlanCSV,
        getBusinessPlan,
        updateCenter,
        createCenterPrice,
        getCentersClusters,
        createCenterCluster,
        updateCenterCluster,
        availableCentersCluster,
        getCenterVisors,
        getCenterFilters,
        getCentersSelect,
        getContractsOptions,
        getContractsOptionsSum,
        updateContract,
        getOptions,
        searchParams,
        setSearchParams,
        getCredits,
        getCreditSum,
        getCreditExport,
        getCredit,
        getCreditPdf,
        getCreditInvoiceSelect,
        updateCreditRegeneratePdf,
        updateCreditApplyInvoice,
        addCreditDeclarePayment,
        getCreditInvoice,
        getCreditPayment,
        getServiceCommitmentPrice,
        getServiceAvailability,
        getServiceAvailableDesktops,
        changeContractService,
        getServicePricing,
        getServicesAvailable,
        getServiceConsumptionsPricing,
        getServicesOccupancy,
        getServicesOccupancySum,
        getServicesOccupancyExport,
        getRights,
        getRight,
        editRight,
        getLitigations,
        getLitigation,
        getLitigationContracts,
        getContactByContract,
        getContactExport,
        getLitigationInvoices,
        getOpportunities,
        getOpportunity,
        getOpportunityContracts,
        getOpportunityEvents,
        getOpportunityUsers,
        getOpportunitySolutions,
        updateOpportunity,
        getActualPipeline,
        getStaffFilters,
        getIndividual,
        getIndividualAccess,
        getIndividualEnterprises,
        getIndividualConsumptions,
        getIndividualDevices,
        getIndividualEvents,
        getIndividualInvoices,
        getIndividuals,
        getIndividualPartners,
        updateActivCorner,
        getIndividualWifi,
        createIndividualAccess,
        createWifiCredentials,
        updateIndividualAccess,
        updateWifiPassword,
        sendWifiPassword,
        updateIndividual,
        getPayment,
        updatePayment,
        deletePayment,
        useGetPayment,
        getPayments,
        getPaymentsSum,
        getCheckouts,
        getCheckoutSum,
        getMandates,
        getPaymentMethod,
        getBankAccount,
        getPaymentMethodPayments,
        paymentMethodCancelled,
        getMainServices,
        getMainService,
        getMainServiceSum,
        getOptionService,
        getParkingServices,
        getParkingService,
        getConsumptionService,
        updateConsumptionService,
        createConsumptionService,
        createParkingService,
        updateParkingService,
        getPrescribers,
        getPlanningsDesktops,
        getPlanningsDesktopsFloors,
        getAccountingDocuments,
        getAccountingVariousOperations,
        getQuotations,
        getQuotation,
        getQuotationDownload,
        getQuotationSolutions,
        cancelQuotation,
        sendQuotation,
        updateQuotation,
        transformQuotationSolution,
        updateQuotationSolution,
        getQuotationSolutionPrice,
        getQuotationSolutionCommitments,
        getContractsConsumptions,
        getContractsConsumptionsSum,
        getConsumptionContract,
        getConsumptionContractConsumptions,
        getConsumptions,
        getDuplicateEnterprises: getDuplicateEnterprises,
        getIndexing,
        addEndorsementService,
        getEndorsements,
        getSelectCommitments,
        sendEndorsementToDirection,
        validateEndorsement,
        getClientCenter,
        updateClientCenter,
        updateClientCenterMail,
        getClientRequest,
        getClientRequests,
        getClientRequestSum,
        updateClientRequest,
        getAccountingClients,
        getAccountingClientsExport,
        getAccountingClientsExportSage,
        createCustomerReservation,
        linkToAccessCustomerReservation,
        getCustomerReservations,
        getCustomerReservationSum,
        getCustomerReservation,
        getCustomerReservationServices,
        getCustomerReservationContracts,
        getCustomerReservationIndividuals,
        cancelCustomerReservation,
        confirmCustomerReservation,
        updateCustomerReservation,
        addCustomerReservationService,
        getAccountingPayments,
        getAccountingPaymentsExport,
        getAccountingVariousOperationsExport,
        getMatchingTransfersExceptions,
        getEnterpriseDocuments,
        getEnterpriseMembers,
        getEnterpriseOpportunities,
        getEnterpriseContracts,
        getEnterpriseSsids,
        getEnterpriseSsidsUsers,
        linkEnterpriseMember,
        createEnterpriseMember,
        updateEnterprise,
        updateEnterpriseSsidUser,
        createEnterpriseSsid,
        createEnterpriseSsidUser,
        createEnterpriseOpportunity,
        updateClientLink,
        uploadDocument,
        getClientConsumptions,
        getClientContracts,
        generatePartialContractInvoice,
        createContract,
        createContractServices,
        deleteContractService,
        createEvent,
        editEvent,
        getEnterpriseMembersFilters,
        getEnterprisePrescriber,
        createPrescriberMemberSource,
        getPrescriberMembers,
        getPrescriberOpportunities,
        getWifiSsidsFilters,
        getWifiStationsFilters,
        patchRegeneratePdfInvoice,
        patchAccountInvoice,
        patchUnAccountInvoice,
        patchBlockInvoice,
        patchUnBlockInvoice,
        patchLitigationInvoice,
        postUnCollectibleInvoice,
        postManualReminderInvoice,
        getServicesPricer,
        getInformationsPricer,
        getDiscountGridsPricer,
        createQuotation,
        getListFloors,
        getFloorPlans,
        getFilterContractsFloorPlans,
        getRowsInvoice,
        patchLanguageInvoice,
        patchDiscountInvoice,
        postManualBankDebitInvoice,
        postAddPaymentInvoice,
        patchLinkPaymentInvoice,
        resetPassword,
        getPaymentsInvoice,
        patchUnLinkPaymentInvoice,
        patchCancelledPaymentInvoice,
        getCreditsInvoice,
        patchUnLinkCreditInvoice,
        patchDueDateInvoice,
        createActivity,
        patchLanguageCredit,
        getConsumptionsClient,
        getMessages,
        individualDevicesUpdate,
        patchLitigation,
        closeLitigation,
        getWifiClientsLogs,
        getEmailTemplates,
        getOneEmailTemplate,
        updateEmailTemplate,
        encrypt,
        decrypt,
        getNotifications,
        updateNotification,
        getAttributionLogs,
        getPresenceLogs,
        useGetPresenceSchedules,
        getPresenceConsumption,
        getPresenceConsumptionSum,
        getServicesTypes,
        getAssets,
        useGetAssets,
        useDeleteAsset,
        useAddAsset
      }}
    >
      {children}
    </FetcherContext.Provider>
  )
}

export const useFetcher = () => useContext(FetcherContext)
