import { Card, CardContent } from '@mui/material'

import { TitleComponent } from 'app/components/titles/title.component'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { Contract } from 'api/models'
import { formatCurrency, formatSurface } from 'app/utils/format'
interface ILitigationContracts {
  isLoading: boolean
  contracts: Contract[]
}

export const LitigationContracts = ({ isLoading, contracts }: ILitigationContracts) => {
  const { t } = useTranslation()

  const map = useMemo(() => {
    const _map = new Map<any, any>()
    _map.set('items', [
      {
        label: t('reference'),
        value: 'reference',
        link: '/contracts/:id'
      },
      {
        label: t('type'),
        value: 'invoicingTypeLabel'
      },
      {
        label: t('center'),
        value: 'centerName'
      },
      {
        label: t('surface'),
        value: 'surface',
        format: formatSurface
      },
      {
        label: t('main_services'),
        value: 'mainPrice',
        format: formatCurrency
      },
      {
        label: t('options'),
        value: 'optionsPrice',
        format: formatCurrency
      }
    ])

    _map.set('data', contracts ?? [])
    return _map
  }, [contracts])

  return (
    <Card>
      <CardContent>
        <TitleComponent text={t('contracts')} variant={'h3'} paddingLeft={12} />
        <ListSheetComponent isLoading={isLoading} data={map} />
      </CardContent>
    </Card>
  )
}
