import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { Euro } from '@mui/icons-material'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatClient, formatCurrency, formatDate } from 'app/utils/format'
import { FiltersBox } from 'app/components/filters/filter-box'
import { RangePicker } from 'app/components/filters/range-picker'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { SummaryComponent } from 'app/components/lists/summary.component'
import { ContractConsumption, ContractsConsumptionsSum } from 'api/models'
import { useApp } from 'app/providers/app.provider'
import { Link } from 'app/components/link.component'
import urlHelper from 'app/helpers/url.helper'
import { API } from 'api/constants'

export const ContractsConsumptionsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { user } = useApp()
  const { getContractsConsumptions, getContractsConsumptionsSum, searchParams, setSearchParams } =
    useFetcher()
  const [contractsConsumptions, setContractsConsumptions] = useState<ContractConsumption[]>([])
  const [summary, setSummary] = useState<ContractsConsumptionsSum>({} as ContractsConsumptionsSum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['contract_type', 'contractTypes'],
      ['consumption_services_type', 'type'],
      ['consumption_payment_status', 'paid']
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getContractsConsumptions
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setContractsConsumptions(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))

      setSummaryIsLoading(true)
      await getContractsConsumptionsSum
        .mutateAsync()
        .then((sum) => {
          setSummary(sum)
        })
        .finally(() => setSummaryIsLoading(false))
    },
    [getContractsConsumptionsSum, getContractsConsumptions, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    const newSummaryItems = new Map<string, string>()
    newSummaryItems.set('total_amount', formatCurrency(summary.totalAmount ?? 0))
    setSummaryItems(newSummaryItems)
  }, [summary])

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('consumptions_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slug="period"
            label={'period'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchClientInput
            slug="client"
            defaultIsIndividual={true}
            defaultIsEnterprise={true}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            initialValue={user?.mainCenter}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {contractsConsumptions.length} / {total}
          </Typography>
        </Grid>
        <List
          items={contractsConsumptions}
          columns={[
            {
              label: t('contract'),
              slug: 'contractType',
              condition: (row: ContractConsumption) => {
                const url =
                  row.contractType === 'Consommations'
                    ? urlHelper(API.GET_CONSUMPTION_CONTRACT, { id: row.contractId })
                    : urlHelper(API.GET_CONTRACT, { id: row.contractId })

                return <Link to={url}>{row.contractType}</Link>
              }
            },
            { label: t('type'), slug: 'type' },
            { label: t('label'), slug: 'reference' },
            {
              label: t('client'),
              slug: 'client',
              condition: (row: ContractConsumption) => (
                <Link to={`/${row.clientType}s/${row.clientId}`}>
                  {formatClient(row.client, row.clientType)}
                </Link>
              )
            },
            { label: t('center'), slug: 'center' },
            { label: t('quantity'), slug: 'quantity' },
            { label: Euro, slug: 'price', valueFormatter: formatCurrency },
            { label: t('begin_date'), slug: 'begin', valueFormatter: formatDate },
            { label: t('end_date'), slug: 'end', valueFormatter: formatDate },
            {
              label: t('invoice'),
              slug: 'invoice',
              link: { base: '/invoices', slug: 'invoiceId' }
            },
            { label: t('paid'), slug: 'paid' }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {contractsConsumptions.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
