import React from 'react'
import ReactDOM from 'react-dom/client'
import { LicenseInfo } from '@mui/x-license-pro'

import App from 'app/app'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { Msal2Provider } from '@microsoft/mgt-msal2-provider'
import { Providers } from '@microsoft/mgt-element'

if (process.env.REACT_APP_MUI_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY)
}

if (process.env.REACT_APP_AZURE_SSO_CLIENT_ID && process.env.REACT_APP_AZURE_SSO_URL) {
  Providers.globalProvider = new Msal2Provider({
    clientId: process.env.REACT_APP_AZURE_SSO_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AZURE_SSO_URL,
    scopes: [
      'calendars.read',
      'user.read',
      'openid',
      'profile',
      'people.read',
      'user.readbasic.all'
    ]
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
