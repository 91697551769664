import { type Ctx, fetcher, formatParams, downloader } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  CreditInformation,
  CreditList,
  CreditSum,
  CreditPdf,
  CreditInvoiceSelect,
  CreditDeclarePayment,
  CreditInvoice,
  CreditPayment,
  Activity,
  Activities
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const creditsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<CreditList>({
      url: `${BASE_URL}${API.GET_CREDITS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async ({ token }: Ctx, params: string) =>
    fetcher<CreditSum>({
      url: `${BASE_URL}${API.GET_CREDIT_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getExport: async ({ token }: Ctx, params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.GET_CREDIT_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      'credits.xlsx'
    ),
  getInformation: async ({ token }: Ctx, id: number) =>
    fetcher<CreditInformation>({
      url: `${BASE_URL}${urlHelper(API.GET_CREDIT_INFORMATION, { id: id })}`,
      method: 'GET',
      token: token
    }),
  getPdf: async ({ token }: Ctx, id: number) =>
    fetcher<CreditPdf>({
      url: `${BASE_URL}${urlHelper(API.GET_CREDIT_PDF, { id: id })}`,
      method: 'GET',
      token: token
    }),
  updatePdf: async ({ token }: Ctx, id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.GET_CREDIT_REGENERATE_PDF, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  getInvoiceSelect: async ({ token }: Ctx, id: number) =>
    fetcher<CreditInvoiceSelect[]>({
      url: `${BASE_URL}${urlHelper(API.GET_CREDIT_INVOICE_SELECT, { id: id })}`,
      method: 'GET',
      token: token
    }),
  updateApplyInvoice: async ({ token }: Ctx, params: { id: string; invoiceId: string }) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_CREDIT_APPLY_INVOICE, params)}`,
      method: 'PUT',
      token: token,
      body: { invoiceId: params.invoiceId }
    }),
  addDeclarePayment: async ({ token }: Ctx, params: CreditDeclarePayment & { id: string }) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_CREDIT_DECLARE_PAYMENT, params)}`,
      method: 'POST',
      token: token,
      body: (({ id, ...rest }) => rest)(params)
    }),
  getInvoice: async ({ token }: Ctx, id: number) =>
    fetcher<CreditInvoice[]>({
      url: `${BASE_URL}${urlHelper(API.GET_CREDIT_INVOICE, { id: id })}`,
      method: 'GET',
      token: token
    }),
  getPayment: async ({ token }: Ctx, id: number) =>
    fetcher<CreditPayment[]>({
      url: `${BASE_URL}${urlHelper(API.GET_CREDIT_PAYMENT, { id: id })}`,
      method: 'GET',
      token: token
    }),
  patchLanguage: async ({ token }: Ctx, id: number, data: any) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CREDIT_LANGUAGE, { id: id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  getActivities: async ({ token }: Ctx, id: number, type: string) =>
    fetcher<Activities>({
      url: `${BASE_URL}${urlHelper(API.GET_CREDIT_ACTIVITIES, { id: id })}${
        type ? `?type=${type}` : ''
      }`,
      method: 'GET',
      token: token
    })
}
