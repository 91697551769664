import React, { useCallback, useEffect } from 'react'
import {
  Box,
  FormControl as MuiFormControl,
  InputAdornment,
  OutlinedInput,
  Button,
  MenuItem,
  Select,
  Tooltip
} from '@mui/material'
import styled from '@emotion/styled'
import { People, SquareFoot, Today } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Save } from 'react-feather'
import { usePricer } from 'app/providers/pricer.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { OpportunityUpdateFormProps } from 'api/models/forms/opportunities'
import { CentersSelect } from 'app/components/filters/centers-select'
import { Center } from 'api/models'
import { useNavigate } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'

const FormControl = styled(MuiFormControl)`
  background: ${(props) => props.theme.palette.background.paper};
`

type IFormOpportunityDrawerComponentProps = {}

const FormOpportunityDrawerComponent: React.FC<IFormOpportunityDrawerComponentProps> = ({}) => {
  const { t } = useTranslation()
  const { pricerInformations, currentDiscountGrid } = usePricer()
  const { searchParams, updateOpportunity } = useFetcher()
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()
  const [opportunityForm, setOpportunityForm] = React.useState<OpportunityUpdateFormProps>(
    {} as OpportunityUpdateFormProps
  )

  const getCenter = useCallback(
    async (center: Center) => {
      if (center?.id) {
        setOpportunityForm({ ...opportunityForm, center: parseInt(center.id!) })
      }
    },
    [searchParams, opportunityForm]
  )

  const onSubmit = useCallback(async () => {
    if (opportunityForm && searchParams && searchParams.has('opportunity')) {
      await handleMutation({
        confirm: {
          content: t('confirm_update_opportunity')
        },
        mutation: updateOpportunity,
        data: {
          id: searchParams.get('opportunity')!,
          data: opportunityForm
        },
        toastSuccess: t('opportunity_update_success'),
        toastError: t('error'),
        onSuccess: () => navigate(0)
      })
    }
  }, [opportunityForm, searchParams])

  useEffect(() => {
    if (pricerInformations) {
      setOpportunityForm({
        ...opportunityForm,
        center: parseInt(pricerInformations.centerId!),
        capacity: pricerInformations.capacity!,
        surface: pricerInformations.surface!,
        begin: pricerInformations.begin,
        commitment: pricerInformations.commitmentId
      })
    }
  }, [pricerInformations])
  return (
    <Box component="form">
      <FormControl fullWidth sx={{ marginBottom: 4 }}>
        <CentersSelect
          slug="center"
          onChange={getCenter}
          defaultIsCluster={false}
          initialValue={opportunityForm.center}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 4 }}>
        <Tooltip placement="top" title={t('capacity')}>
          <OutlinedInput
            type={'number'}
            size={'small'}
            placeholder={t('capacity')}
            value={opportunityForm.capacity}
            onChange={(event) =>
              setOpportunityForm({ ...opportunityForm, capacity: parseInt(event.target.value) })
            }
            inputProps={{
              min: 1,
              step: 1
            }}
            startAdornment={
              <InputAdornment position="start">
                <People color={'primary'} />
              </InputAdornment>
            }
          />
        </Tooltip>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 4 }}>
        <Tooltip placement="top" title={t('surface')}>
          <OutlinedInput
            size={'small'}
            type={'number'}
            placeholder={t('surface')}
            value={opportunityForm?.surface}
            onChange={(event) =>
              setOpportunityForm({ ...opportunityForm, surface: parseFloat(event.target.value) })
            }
            inputProps={{
              min: 0,
              step: 0.1
            }}
            startAdornment={
              <InputAdornment position="start">
                <SquareFoot color={'primary'} />
              </InputAdornment>
            }
          />
        </Tooltip>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 4 }}>
        <Tooltip placement="top" title={t('begin_date')}>
          <OutlinedInput
            size={'small'}
            type={'date'}
            placeholder={t('begin')}
            value={opportunityForm?.begin}
            onChange={(event) =>
              setOpportunityForm({ ...opportunityForm, begin: event.target.value })
            }
            startAdornment={
              <InputAdornment position="start">
                <Today color={'primary'} />
              </InputAdornment>
            }
          />
        </Tooltip>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 4 }}>
        <Tooltip placement="top" title={t('commitment')}>
          <Select
            displayEmpty
            size={'small'}
            fullWidth={true}
            value={String(opportunityForm?.commitment)}
            onChange={(event) =>
              setOpportunityForm({ ...opportunityForm, commitment: event.target.value })
            }
          >
            {currentDiscountGrid.commitmentRates?.map((commitmentRate, index) => (
              <MenuItem key={index} value={commitmentRate.commitmentId}>
                {commitmentRate.label}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button size={'small'} title={t('save')} onClick={onSubmit}>
          <Save fontSize={'small'} />
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default FormOpportunityDrawerComponent
