import { Grid, SxProps } from '@mui/material'
import { FormItem } from 'api/models'
import * as React from 'react'
import { ControlledSelectField } from './controlled-select.component'
import { ControlledTextField } from './controlled-textfield.component'
import { ControlledDateTimePicker } from './controlled-datetimepicker.component'
import { ControlledCheckboxField } from './controlled-checkbox.component'
import { ControlledSelectClientsField } from './controlled-select-clients.component'
import { ControlledSelectCentersField } from './controlled-select-centers.component'
import { ControlledSelectStaffField } from './controlled-select-staff.component'
import { ControlledSelectMembersField } from './controlled-select-members.component'
import { UseFormReturn } from 'react-hook-form'
import { HTMLInputTypeAttribute } from 'react'
import { ControlledRadioField } from './controlled-radio.component'
import { ControlledNumberField } from './controlled-number.component'
import { ControlledDatePicker } from './controlled-datepicker.component'
import { ControlledAutocomplete } from 'app/components/form/controlled-autocomplete.component'
import { ControlledTimePicker } from 'app/components/form/controlled-timepicker.component'
import { ControlledSelectCheckboxField } from './controlled-select-checkbox.component'
import { ControlledSelectStaffCenterField } from './controlled-select-staff-center.component'
import { ControlledMultiSelectField } from 'app/components/form/controlled-multiselect.component'

export type FormInputProps =
  | {
      inputType?: HTMLInputTypeAttribute
      type:
        | 'select'
        | 'textfield'
        | 'checkbox'
        | 'datetimepicker'
        | 'datepicker'
        | 'clients'
        | 'centers'
        | 'staffs'
        | 'members'
        | 'number'
        | 'radio'
        | 'multiselect'
        | 'autocomplete'
        | 'timepicker'
        | 'selectCheckbox'
        | 'staffCenter'
      label: string
      name: string
      formItem?: FormItem
      icon?: React.FC<any>
      inputProps?: any
      required?: boolean
      tooltip?: string
      xs?: number
      info?: any
      sx?: SxProps
    }
  | {
      type: 'blank'
    }

export const FORM_ITEMS: { [key: string]: any } = {
  select: ControlledSelectField,
  textfield: ControlledTextField,
  datetimepicker: ControlledDateTimePicker,
  datepicker: ControlledDatePicker,
  checkbox: ControlledCheckboxField,
  clients: ControlledSelectClientsField,
  centers: ControlledSelectCentersField,
  staffs: ControlledSelectStaffField,
  members: ControlledSelectMembersField,
  number: ControlledNumberField,
  radio: ControlledRadioField,
  autocomplete: ControlledAutocomplete,
  timepicker: ControlledTimePicker,
  selectCheckbox: ControlledSelectCheckboxField,
  staffCenter: ControlledSelectStaffCenterField,
  multiselect: ControlledMultiSelectField
}

function ControlledForm({
  items,
  methods
}: {
  methods: UseFormReturn<any>
  items: FormInputProps[]
}) {
  return (
    <>
      {items.map((item) => {
        const Item = item.type === 'blank' ? null : FORM_ITEMS[item.type]
        if (item.type === 'blank') return <></>
        const formItem = item?.formItem ? { formItem: item.formItem || ({} as FormItem) } : null
        return (
          <Grid key={item.name} item xs={12} paddingY={4}>
            {Item ? (
              <Item
                control={methods.control}
                {...formItem}
                methods={methods}
                icon={item.icon}
                name={item.name}
                label={item.label}
                required={item.required}
                {...item.inputProps}
              />
            ) : null}
          </Grid>
        )
      })}
    </>
  )
}

export default ControlledForm
