import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ConsumptionService,
  FormItem,
  OptionServiceBody,
  optionServiceBodySchema
} from 'api/models'
import { Container, Grid, Typography, Paper as MuiPaper, Button, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import LanguageComponent from 'modules/services/components/languages.component'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import OptionInformationsComponent from 'modules/services/components/option-informations.component'

export const ConsumptionServiceEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getConsumptionService, updateConsumptionService, getFormItems } = useFetcher()
  const { id } = useParams()
  const [consumption, setConsumption] = useState<ConsumptionService>({} as ConsumptionService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const [formItems, setFormItems] = useState({} as FormItem)

  const methods = useForm<OptionServiceBody>({
    mode: 'onChange',
    resolver: zodResolver(
      optionServiceBodySchema.refine((data) => {
        if (!data.savedLabels) return false
        const fr = data.savedLabels.find((sl) => sl.language === 1)
        return (
          fr &&
          fr.label &&
          (data.isFreeFees || (data.commissioningFees && data.commissioningFees > 0)) &&
          (data.isFreePrice || (data.price && data.price > 0))
        )
      })
    )
  })

  const init = async () => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getConsumptionService,
      data: Number(id),
      onSuccess: (data) => setConsumption(data)
    })

    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getFormItems,
      data: ['consumption_services_types'],
      onSuccess: (data) => setFormItems(data.consumption_services_types as FormItem)
    })

    setListIsLoading(false)
  }

  useEffect(() => {
    init().then()
  }, [])

  const handleSubmit = useCallback(
    async (data: OptionServiceBody) => {
      await handleMutation({
        confirm: {
          content: t('confirm_edit_consumption')
        },
        mutation: updateConsumptionService,
        data: { id: Number(id), data: data },
        onSuccess: () => navigate(`/consumptions/${id}`)
      })
    },
    [updateConsumptionService, id]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/consumptions/${id}`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('consumption_edit')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <form onSubmit={methods.handleSubmit(handleSubmit)} autoComplete="off">
        <MuiPaper sx={{ marginTop: 4, padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <LanguageComponent control={methods.control} service={consumption} />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <OptionInformationsComponent
              type={'consumption'}
              methods={methods}
              optionService={consumption}
              formItems={formItems}
            />
          )}
        </MuiPaper>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
          {listIsLoading ? (
            <RectangularSkeleton />
          ) : (
            <>
              <Button variant={'outlined'} onClick={() => navigate(`/services/parking/${id}`)}>
                {t('cancel')}
              </Button>
              <Button
                type={'submit'}
                variant="contained"
                size="small"
                disabled={!methods.formState.isValid}
              >
                {t('edit')}
              </Button>
            </>
          )}
        </Box>
      </form>
    </Container>
  )
}
