import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

import LoggedWrapper from 'app/components/LoggedWrapper'
import Navbar from 'app/components/navbar/navbar.component'
import Sidebar from 'app/components/sidebar/sidebar.component'
import { menuItems } from 'app/constants/navbar'
import { FetcherProvider } from 'app/providers/fetcher.provider'
import { FetcherProvider as FetcherProviderV2 } from 'app/providers/fetcher_v2.provider'
import { ListProvider } from 'app/providers/list.provider'

const drawerWidth = 250

const Drawer = styled.div`
  box-shadow: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
  & .MuiPaper-root {
    box-shadow: none;
  }
`

const AppContent = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: calc(100% - ${drawerWidth}px);
  }
`

const MainContent = styled('div')`
  flex: 1 1 0;
  overflow: auto;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`
export const AppLayout = () => {
  const router = useLocation()
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  // Close mobile menu when navigation occurs
  useEffect(() => {
    setMobileOpen(false)
  }, [router.pathname])

  return (
    <LoggedWrapper>
      <FetcherProvider>
        <FetcherProviderV2>
          <ListProvider>
            <Drawer>
              <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
                <Sidebar
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  items={menuItems}
                />
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Sidebar PaperProps={{ style: { width: drawerWidth } }} items={menuItems} />
              </Box>
            </Drawer>
            <AppContent>
              <Navbar handleDrawerToggle={handleDrawerToggle} />
              <MainContent>
                <Outlet />
              </MainContent>
            </AppContent>
          </ListProvider>
        </FetcherProviderV2>
      </FetcherProvider>
    </LoggedWrapper>
  )
}
