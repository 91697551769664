import { z } from 'zod'

const litigationSchema = z.object({
  centerName: z.string(),
  clientId: z.number(),
  clientName: z.string(),
  clientType: z.string(),
  createdAt: z.string(),
  id: z.number(),
  reference: z.string(),
  status: z.number(),
  statusLabel: z.string()
})

export type LitigationItem = z.infer<typeof litigationSchema>

const litigationListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(litigationSchema)
})

export type LitigationList = z.infer<typeof litigationListSchema>

const litigationDetailsSchema = z.object({
  centerName: z.string(),
  clientId: z.number(),
  clientName: z.string(),
  clientType: z.string(),
  createdAt: z.string(),
  id: z.number(),
  reference: z.string(),
  stateId: z.number(),
  stateLabel: z.string()
})

export type Litigation = z.infer<typeof litigationDetailsSchema>
