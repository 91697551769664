import { z } from 'zod'

export const clientCenterSchema = z.object({
  id: z.number(),
  clientId: z.number(),
  isAccounted: z.boolean(),
  accountingName: z.string(),
  accountingMail: z.string(),
  accountingNumber: z.string()
})

export type ClientCenter = z.infer<typeof clientCenterSchema>
