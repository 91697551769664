import { Close, UploadFile } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'
interface IUploadButton {
  name: string
  onChange: (file: FileList | File | null) => void
  multiple?: boolean
  uploadLabel?: string
  accept?: string
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export const UploadButton = function ({
  name,
  onChange,
  multiple = undefined,
  uploadLabel = 'choose_file',
  accept
}: IUploadButton) {
  const [file, setFile] = useState<File | FileList | null>(null)
  const [value, setValue] = useState<string>('')
  const { t } = useTranslation()
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (multiple) {
      setFile(e.target.files)
    } else {
      setFile(e.target.files ? e.target.files[0] : null)
    }
    setValue(e.target.value)
  }
  const removeFile = (e: any) => {
    e.preventDefault()
    setFile(null)
    setValue('')
  }

  useEffect(() => {
    if (onChange) {
      onChange(file)
    }
  }, [file])

  return (
    <Button
      component="label"
      role={undefined}
      fullWidth
      variant="contained"
      startIcon={<UploadFile />}
      endIcon={file ? <Close onClick={removeFile} /> : undefined}
    >
      <Typography
        fontWeight={'500'}
        sx={{ lineHeight: '1.75em' }}
        textAlign="center"
        width={'100%'}
        noWrap={true}
        textOverflow={'ellipsis'}
      >
        {file
          ? 'name' in file
            ? file.name
            : t('files', { count: 'length' in file ? file.length : 0 })
          : t(uploadLabel)}
      </Typography>
      <VisuallyHiddenInput
        type="file"
        id={name}
        multiple={multiple}
        value={value}
        accept={accept ?? undefined}
        onChange={handleChange}
      />
    </Button>
  )
}
