import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RefObject, useEffect, useState } from 'react'
import { CustomerReservationInformations, FormItems } from 'api/models'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import Button from '@mui/material/Button'
import * as React from 'react'
import { Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { formatCurrency } from 'app/utils/format'
import FormAddService from 'app/forms/customerReservations/FormAddService'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ConsumptionPricing } from 'api/models/pricing'
import {
  addReservationConsumption,
  AddReservationConsumptionItems
} from 'api/models/forms/consumptions'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'

export function DialogAddService({
  customerReservation,
  options,
  dialogRef
}: {
  customerReservation: CustomerReservationInformations
  options: FormItems
  dialogRef: RefObject<DialogRef>
}) {
  const methods = useForm<AddReservationConsumptionItems>({
    defaultValues: {
      reservationId: customerReservation.id,
      quantity: 1,
      service: 0,
      price: 0,
      isFreePrice: false,
      isFreeFees: false,
      reduction: 0,
      fees: 0,
      begin: dayjs(customerReservation.begin),
      end: dayjs(customerReservation.end)
    },
    mode: 'onChange',
    resolver: zodResolver(addReservationConsumption)
  })
  const [service, price, fees, quantity, reduction] = useWatch({
    name: ['service', 'price', 'fees', 'quantity', 'reduction'],
    control: methods.control
  })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()
  const { getServiceConsumptionsPricing, addCustomerReservationService } = useFetcher()
  const [computedPrices, setComputedPrices] = useState<ConsumptionPricing | null>(null)

  const handleSubmit = async (data: AddReservationConsumptionItems) => {
    await handleMutation({
      mutation: addCustomerReservationService,
      data: data,
      toastSuccess: t('customer_reservation_added_success'),
      toastError: t('an_error_occurred'),
      onSuccess: () => navigate(0)
    })
  }

  const handleReset = () => {
    methods.reset()
    setComputedPrices(null)
  }
  const handleCompute = async () => {
    try {
      const data = await getServiceConsumptionsPricing.mutateAsync({
        serviceId: service,
        quantity: quantity,
        unitprice: price || 0,
        reduction: reduction || 0,
        fees: fees || 0
      })

      setComputedPrices(data)
    } catch {}
  }

  useEffect(() => {
    if (!options.consumptions_services_types) return
    const a: any | undefined = Object.entries(
      options.consumptions_services_types?.values as Record<string, any>
    )
      .flat(2)
      .find((s) => Number(s.id) === Number(service))
    methods.setValue('price', a?.price)
    methods.setValue('isFreePrice', a?.isFreePrice)
    methods.setValue('isFreeFees', a?.isFreeFees)
  }, [service])

  return (
    <Dialog
      ref={dialogRef}
      title={`${t('add_service')}`}
      actions={
        <>
          <Button color="secondary" variant="outlined" onClick={handleReset}>
            {t('reset')}
          </Button>
          <Button
            color="secondary"
            disabled={!methods.formState.isValid}
            variant="outlined"
            onClick={handleCompute}
          >
            {t('compute')}
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              dialogRef.current?.close()
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={
              !methods.formState.isValid || methods.formState.isSubmitting || !computedPrices
            }
            onClick={methods.handleSubmit(handleSubmit)}
          >
            {t('submit')}
          </Button>
        </>
      }
    >
      <Grid container columns={12}>
        <Grid item xs={6}>
          <FormAddService options={options} methods={methods} />
        </Grid>
        <Grid item xs={6}>
          {[
            { key: 'price', value: computedPrices?.priceService },
            { key: 'discount', value: computedPrices?.reductionPrice },
            { key: 'discount_price', value: computedPrices?.priceReduced },
            { key: 'fees', value: computedPrices?.priceFees },
            { key: 'total', value: computedPrices?.priceTotal }
          ].map((data) => {
            return (
              <Grid key={data.key} container columns={12} rowSpacing={6} paddingX={6} paddingY={3}>
                <Grid item xs={6}>
                  <Typography fontWeight="bold">{t(data.key)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  {
                    <Typography fontWeight="bold">
                      {data.value !== undefined ? formatCurrency(data.value) : ''}
                    </Typography>
                  }
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Dialog>
  )
}
