import { HourglassTop, SquareFoot } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Paper as MuiPaper } from '@mui/material'

import type { EnterpriseInformation } from 'api/models'
import {
  formatCommitment,
  formatContractState,
  formatCurrency,
  formatSurface
} from 'app/utils/format'
import React, { useCallback, useEffect, useState } from 'react'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { EnterpriseContract } from 'api/models'
import { TitleComponent } from 'app/components/titles/title.component'

interface IEnterpriseContract {
  enterprise: EnterpriseInformation
}

export function EnterpriseContractsComponent({ enterprise }: IEnterpriseContract) {
  const { t } = useTranslation()
  const [items, setItems] = useState([] as EnterpriseContract[])
  const [isLoading, setIsLoading] = useState(false)
  const { orderBy, handleSort, handleFilter } = useList()
  const { getEnterpriseContracts } = useFetcher()

  const refreshList = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await getEnterpriseContracts.mutateAsync(String(enterprise.id))
      setItems(data.items)
    } finally {
      setIsLoading(false)
    }
  }, [enterprise.id])

  useEffect(() => {
    refreshList()
  }, [refreshList])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('contracts')} variant={'h3'} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            items={items}
            selectable={false}
            columns={[
              {
                label: '',
                slug: 'state',
                text: 'stateLabel',
                valueFormatter: formatContractState
              },
              {
                label: t('reference'),
                slug: 'reference',
                link: { base: '/contracts', slug: 'id' }
              },
              {
                label: t('type'),
                slug: 'contractTypeLabel'
              },
              {
                label: t('center'),
                slug: 'centerName'
              },
              {
                label: SquareFoot,
                slug: 'surface',
                valueFormatter: formatSurface,
                tooltip: t('surface')
              },
              {
                label: t('main_services'),
                slug: 'mainPrice',
                valueFormatter: formatCurrency
              },
              {
                label: t('options'),
                slug: 'optionsPrice',
                valueFormatter: formatCurrency
              },
              {
                label: HourglassTop,
                slug: 'remainingMonths',
                valueFormatter: formatCommitment,
                tooltip: t('remaining_months')
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
