import { z } from 'zod'

export const paymentItemSchema = z.object({
  id: z.number({ coerce: true }),
  clientId: z.number({ coerce: true }),
  center: z.string(),
  reference: z.string(),
  clientType: z.string(),
  state: z.number({ coerce: true }),
  paymentType: z.number({ coerce: true }),
  paymentMode: z.number({ coerce: true }),
  stateLabel: z.string(),
  paymentModeLabel: z.string(),
  paymentTypeLabel: z.string(),
  client: z.string(),
  accountingDocument: z.string(),
  accountingDocumentType: z.string(),
  accountingDocumentId: z.number({ coerce: true }),
  contract: z.string(),
  contractId: z.number({ coerce: true }),
  created: z.string(),
  paid: z.string(),
  amount: z.number({ coerce: true }),
  invoiceId: z.string().nullable()
})

export type Payment = z.infer<typeof paymentItemSchema>

const paymentsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(paymentItemSchema)
})

export type PaymentsList = z.infer<typeof paymentsListSchema>

const paymentsSumSchema = z.object({
  totalAmount: z.number()
})

export type PaymentsSum = z.infer<typeof paymentsSumSchema>
