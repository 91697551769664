import { Grid, Paper, Typography, Box, Button } from '@mui/material'
import { Link } from 'app/components/link.component'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { formatCurrency, formatDate } from 'app/utils/format'
import { CreditInformation, FormItem, FormItems } from 'api/models'
import { Translation } from 'react-i18next'
import { SelectLanguages } from 'modules/invoices/components/languages_select.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useTranslation } from 'react-i18next'

interface IProps {
  data: CreditInformation['credit']
  dataFilters: FormItems
  isCreditEdit: boolean
  isCreditRegenerable: boolean
  setRefresh: Dispatch<SetStateAction<boolean>>
}

export const InformationsComponent = ({
  data,
  dataFilters,
  isCreditEdit,
  isCreditRegenerable,
  setRefresh
}: IProps) => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { patchLanguageCredit, updateCreditRegeneratePdf } = useFetcher()
  const [languageId, setLanguageId] = useState<number>(data.languageId)

  const onRegeneratePdf = useCallback(async () => {
    await handleMutation({
      confirm: {
        content: t('confirm_credit_regenerate')
      },
      mutation: updateCreditRegeneratePdf,
      toastSuccess: t('credit_regenerate_success'),
      onEnd: () => setRefresh((prevState: boolean) => !prevState)
    })
  }, [])

  const handleLanguageChange = async (e: any) => {
    const languageId = Number(e.target.value)
    setLanguageId(languageId)
    await handleMutation({
      confirm: {
        content: t('confirm_edit_language')
      },
      mutation: patchLanguageCredit,
      data: { language: languageId },
      toastSuccess: t('language_edited', { type: "l'avoir" }),
      onEnd: () => setRefresh((prevState: boolean) => !prevState)
    })
  }

  return (
    <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
      <Typography variant="h3" marginBottom="1rem" color="primary">
        <Translation>{(t) => t('informations')}</Translation>
      </Typography>
      <Grid container spacing={2}>
        <Grid item container spacing={9}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <Translation>{(t) => t('client')}</Translation>
            </Typography>
            <Typography variant="body2">
              <Link to={`/enterprises/${data.clientId}`}>{data.clientName}</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <Translation>{(t) => t('credit')}</Translation>
            </Typography>
            <Typography variant="body2">{data.reference}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <Translation>{(t) => t('remaining_sale')}</Translation>
            </Typography>
            <Typography variant="body2">{formatCurrency(data.dueAmount)}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <Translation>{(t) => t('spent_at')}</Translation>
            </Typography>
            <Typography variant="body2">{formatDate(data.spentAt)}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {dataFilters.languages_to_pdf && (
              <SelectLanguages
                filter={dataFilters.languages_to_pdf as FormItem}
                data={String(languageId)}
                onChange={handleLanguageChange}
                isEdit={isCreditEdit}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ '& button': { m: 1 } }}>
              <div>
                {data.isEditable && isCreditRegenerable && (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={onRegeneratePdf}
                  >
                    <Translation>{(t) => t('credit_regenerate')}</Translation>
                  </Button>
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
