import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  QuotationInformation,
  QuotationsList,
  QuotationSolutionPrice,
  QuotationSolutions,
  SelectCommitments
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'
import { UpdateQuotationFormItems } from 'api/models/forms/quotations'

export const quotationsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<QuotationsList>({
      url: `${BASE_URL}${API.GET_QUOTATIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async ({ token }: Ctx, id: string) =>
    fetcher<QuotationInformation>({
      url: `${BASE_URL}${API.GET_QUOTATIONS}/${id}`,
      method: 'GET',
      token: token
    }),
  getPdf: async ({ token }: Ctx, id: string) =>
    downloader(
      {
        url: `${BASE_URL}${urlHelper(API.GET_QUOTATION_DOWNLOAD, { id: id })}`,
        method: 'GET',
        token: token
      },
      'quotation_' + id + '.pdf'
    ),
  getSolutions: async ({ token }: Ctx, id: string) =>
    fetcher<QuotationSolutions>({
      url: `${BASE_URL}${urlHelper(API.GET_QUOTATION_SOLUTIONS, { id })}`,
      method: 'GET',
      token: token
    }),
  cancel: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_QUOTATION_CANCEL, { id })}`,
      method: 'PATCH',
      token: token
    }),
  send: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_QUOTATION_SEND, { id })}`,
      method: 'POST',
      token: token
    }),
  update: async ({ token }: Ctx, data: UpdateQuotationFormItems) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_QUOTATION, { id: data.id })}`,
      method: 'PATCH',
      token: token,
      body: data.data
    }),
  transformSolution: async ({ token }: Ctx, data: { id: number; solutionId: number }) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_QUOTATION_SOLUTION_TRANSFORM, {
        id: data.id,
        solutionId: data.solutionId
      })}`,
      method: 'POST',
      token: token
    }),
  updateSolution: async ({ token }: Ctx, data: { id: number; solutionId: number; data: any }) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_QUOTATION_SOLUTION, {
        id: data.id,
        solutionId: data.solutionId
      })}`,
      method: 'PATCH',
      token: token,
      body: data.data
    }),
  priceSolution: async ({ token }: Ctx, data: { id: number; solutionId: number; data: any }) =>
    fetcher<QuotationSolutionPrice[]>({
      url: `${BASE_URL}${urlHelper(API.PATCH_QUOTATION_SOLUTION_PRICE, {
        id: data.id,
        solutionId: data.solutionId
      })}?${new URLSearchParams(data.data)}`,
      method: 'GET',
      token: token
    }),
  getSelectCommitments: async ({ token }: Ctx, id: number, solutionId: number) =>
    fetcher<SelectCommitments>({
      url: `${BASE_URL}${urlHelper(API.QUOTATION_SOLUTION_SELECT_COMMITMENTS, { id, solutionId })}`,
      method: 'GET',
      token: token
    })
}
