import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  CreateHappeningForm,
  HappeningsList,
  HappeningsListPartner,
  HappeningsDetails,
  UpdateHappening,
  CreateHappeningPartnerForm
} from 'api/models/happenings'
import urlHelper from 'app/helpers/url.helper'
import { IdReturn } from 'api/models'

export const happeningsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<HappeningsList>({
      url: `${BASE_URL}${API.GET_HAPPENINGS}?${params}`,
      method: 'GET',
      token: token
    }),
  getPartners: async ({ token }: Ctx, params: string) =>
    fetcher<HappeningsListPartner>({
      url: `${BASE_URL}${API.GET_HAPPENINGS_PARTNERS}?${params}`,
      method: 'GET',
      token: token
    }),
  getRead: async ({ token }: Ctx, id: number) =>
    fetcher<HappeningsDetails>({
      url: `${BASE_URL}${urlHelper(API.GET_HAPPENINGS_DETAILS, { id: id })}`,
      method: 'GET',
      token: token
    }),
  create: async ({ token }: Ctx, data: CreateHappeningForm) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.POST_HAPPENING}`,
      method: 'POST',
      token: token,
      body: data
    }),
  update: async ({ token }: Ctx, id: number, data: UpdateHappening) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_HAPPENING, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  createPartners: async ({ token }: Ctx, data: CreateHappeningPartnerForm) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.POST_HAPPENINGS_PARTNERS}`,
      method: 'POST',
      token: token,
      body: data
    })
}
