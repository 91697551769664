import React, { useCallback, useEffect } from 'react'
import {
  Box,
  useTheme,
  FormControl as MuiFormControl,
  InputAdornment,
  OutlinedInput,
  Button
} from '@mui/material'
import styled from '@emotion/styled'
import { CalendarToday } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { PricerInformations } from 'api/models'
import { usePricer } from 'app/providers/pricer.provider'

const FormControl = styled(MuiFormControl)`
  background: ${(props) => props.theme.palette.background.paper};
  width: 200px;
`

type IDateQuotationComponentProps = {}

const DateQuotationComponent: React.FC<IDateQuotationComponentProps> = ({}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { pricerInformations, onCreateQuotation } = usePricer()
  const [currentDueDate, setCurrentDueDate] = React.useState<string>('')

  const onCurrentDueDate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentDueDate(event.target.value as string)
  }, [])

  useEffect(() => {
    if (pricerInformations.dueDate) {
      setCurrentDueDate(pricerInformations.dueDate)
    }
  }, [pricerInformations.dueDate])

  return (
    <Box component="form">
      {currentDueDate !== '' && pricerInformations && (
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <FormControl fullWidth sx={{ marginBottom: 4 }}>
            <OutlinedInput
              size={'small'}
              type={'date'}
              placeholder={t('date')}
              value={currentDueDate}
              onChange={onCurrentDueDate}
              inputProps={{ min: pricerInformations.minDueDate, max: pricerInformations.dueDate }}
              startAdornment={
                <InputAdornment position="start">
                  <CalendarToday color={'primary'} />
                </InputAdornment>
              }
            />
          </FormControl>
          <Button variant="contained" size={'small'} onClick={onCreateQuotation}>
            {t('submit')}
          </Button>
        </Stack>
      )}
    </Box>
  )
}

export default DateQuotationComponent
