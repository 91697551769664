import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link
} from '@mui/material'
import { ListSkeleton } from 'app/components/skeletons/list.skeleton'
import urlHelper from 'app/helpers/url.helper'
import styled from '@emotion/styled'

const TableGroupRow = styled(TableRow)`
  th {
    text-align: center;
    height: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    :after {
      content: ' ';
      border: 1px solid ${(props) => props.theme.palette.primary.main};
      position: absolute;
      inset: ${(props) => props.theme.spacing(2)};
      border-radius: ${(props) => props.theme.spacing(1)};
    }
  }
  + tr th {
    text-align: center;
    white-space: nowrap;
  }
`

type HeaderDef = {
  label: string
  colSpan?: number
}

interface IProps {
  isLoading: boolean
  data: Map<any, any>
  columnsGroups?: HeaderDef[]
}

const linkFormatter = (link: string, row: any, value: any): JSX.Element => (
  <Link href={urlHelper(link, row)}>{value}</Link>
)

export const ListSheetComponent = ({ isLoading, data, columnsGroups }: IProps) => {
  if (isLoading) return <ListSkeleton />

  const generateRow = (row: any) => {
    return Array.from(data.get('items'), (item: any, index: number): JSX.Element => {
      if (item.component) {
        return (
          <TableCell key={index} className={item.className}>
            {item.link ? linkFormatter(item.link, row, item.component(row)) : item.component(row)}
          </TableCell>
        )
      } else if (item.format) {
        return (
          <TableCell key={index} className={item.className}>
            {item.link
              ? linkFormatter(item.link, row, item.format(row[item.value]))
              : item.format(row[item.value])}
          </TableCell>
        )
      } else {
        return (
          <TableCell key={index} className={item.className}>
            {item.link ? linkFormatter(item.link, row, row[item.value]) : row[item.value]}
          </TableCell>
        )
      }
    })
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          {columnsGroups && (
            <TableGroupRow>
              {columnsGroups.map((h, index) => (
                <TableCell colSpan={h.colSpan ?? 1} key={index}>
                  {h.label ?? ''}
                </TableCell>
              ))}
            </TableGroupRow>
          )}
          <TableRow>
            {data.get('items').map((item: any, index: number) => (
              <TableCell className={item.className} key={index}>
                {item.label ?? ''}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.get('data').map((rowData: any, index: number) => (
            <TableRow
              key={index}
              sx={{ textWrap: 'nowrap', '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {generateRow(rowData)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
