import { useTranslation } from 'react-i18next'
import { Grid, Paper as MuiPaper } from '@mui/material'
import type {
  IndividualConsumptions as IndividualConsumptionProps,
  IndividualDetails
} from 'api/models'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { List } from 'app/components/lists/list'
import { formatContractState } from 'app/utils/format'
import { Box } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'

interface IIndividualEnterprises {
  individual: IndividualDetails
}

export function IndividualConsumptions({ individual }: IIndividualEnterprises) {
  const { t } = useTranslation()
  const [items, setItems] = useState([] as IndividualConsumptionProps)
  const [isLoading, setIsLoading] = useState(false)
  const { orderBy, handleSort, handleFilter } = useList()
  const { getIndividualConsumptions } = useFetcher()

  const refreshList = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await getIndividualConsumptions.mutateAsync(String(individual.id))
      setItems(data)
    } finally {
      setIsLoading(false)
    }
  }, [individual, setItems, setIsLoading])

  useEffect(() => {
    refreshList()
  }, [refreshList])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('consumptions')} variant={'h3'} paddingTop={0} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            items={items}
            selectable={false}
            columns={[
              {
                label: t('status'),
                slug: 'contractState',
                text: 'contractStateLabel',
                valueFormatter: formatContractState
              },
              {
                label: t('reference'),
                slug: 'contractReference',
                link: { base: '/consumption-contracts', slug: 'contractId' }
              },
              {
                label: t('center'),
                slug: 'centerName'
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
