import { fetcher } from 'api'
import { BASE_URL, API, FORGOTTEN_TOKEN } from 'api/constants'
import {
  ForgotPasswordInputs,
  ResetPasswordInputs,
  SignInAzureSsoInputs,
  SignInInputs,
  User
} from 'api/models'

export const authRouter = {
  signIn: async ({ email, password }: SignInInputs) =>
    fetcher<User>({
      url: `${BASE_URL}${API.SIGN_IN}`,
      headers: {
        Authorization: `Basic ${btoa(`${email}:${password}`)}`
      },
      method: 'GET'
    }),
  signInWithAzureSso: async ({ token }: SignInAzureSsoInputs) =>
    fetcher<User>({
      url: `${BASE_URL}${API.SIGN_IN}`,
      headers: {
        Authorization: `Bearer ${btoa(`${token}`)}`,
        'X-Token': 'AzureToken'
      },
      method: 'GET'
    }),
  signOut: async () => {},
  forgottenPassword: async ({ email }: ForgotPasswordInputs) =>
    fetcher<void>({
      url: `${BASE_URL}${API.FORGOTTEN_PASSWORD}`,
      headers: {
        Authorization: `Basic ${btoa(`${email}:${FORGOTTEN_TOKEN}`)}`,
        'X-Token': 'ForgottenPasswordToken'
      },
      method: 'GET'
    }),
  resetPassword: async ({ password, confirmPassword, token }: ResetPasswordInputs) =>
    fetcher<void>({
      url: `${BASE_URL}${API.FORGOTTEN_PASSWORD}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'PATCH',
      body: {
        password,
        confirmPassword
      }
    })
}
