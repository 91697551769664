import React, { createContext, Dispatch, useContext, useMemo, useState } from 'react'

interface SidebarContextProps {
  params: any | undefined
  setParams: Dispatch<React.SetStateAction<any | undefined>>
}

const SidebarContext = createContext<SidebarContextProps>({
  params: undefined,
  setParams: () => undefined
})

interface ISidebarProviderProps {
  children: React.ReactNode
}

const SidebarProvider = ({ children }: ISidebarProviderProps): React.JSX.Element => {
  const [params, setParams] = useState<any | undefined>()
  const values = useMemo(() => {
    return {
      params,
      setParams
    }
  }, [params, setParams])

  return <SidebarContext.Provider value={values}>{children}</SidebarContext.Provider>
}

const useSidebar = () => useContext(SidebarContext)
export { SidebarProvider, useSidebar }
