import { useTranslation } from 'react-i18next'
import type { PrescriberInformation } from 'api/models'
import React from 'react'
import InfoCard from 'app/components/card/info-card.component'

interface IIndividualDetailsProps {
  individual: PrescriberInformation
}

export const PrescriberInformationCard = ({ individual }: IIndividualDetailsProps) => {
  const { t } = useTranslation()
  return (
    <InfoCard
      title={t('informations')}
      columns={[
        {
          label: t('name'),
          value: individual.enterpriseName
        },
        { label: '', value: '' },
        {
          label: t('address'),
          value: individual.address
        },
        {
          label: t('zipcode'),
          value: individual.zipCode
        },
        {
          label: t('city'),
          value: individual.city
        },
        { label: '', value: '' },
        {
          label: t('source'),
          value: individual.source
        }
      ]}
    ></InfoCard>
  )
}
