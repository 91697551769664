import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { Activity } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'

interface IProps {
  initData?: Record<string, string>
  onChange: (data: Activity) => void
}

export const CreateActivityComponent = ({ initData, onChange }: IProps) => {
  const [messageType, setMessageType] = useState(0)
  const { createActivity } = useFetcher()
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    await handleMutation({
      mutation: createActivity,
      data: {
        type: messageType,
        message: e.target.message.value,
        ...initData
      },
      toastSuccess: t('activity_created'),
      toastError: t('error'),
      onSuccess: (data: Activity) => {
        onChange(data)
      }
    })
  }

  return (
    <form style={{ width: '100%', marginTop: '15px' }} onSubmit={handleSubmit}>
      <Grid container item rowSpacing={5}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="message-type-label">{t('message_type')}</InputLabel>
            <Select
              name={'type'}
              labelId="message-type-label"
              label={t('message_type')}
              value={messageType}
              onChange={(e: any) => setMessageType(e.target.value)}
              size={'small'}
            >
              <MenuItem value={0}>{t('select_message_type')}</MenuItem>
              <MenuItem value={1}>{t('message')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={'message'}
            size={'small'}
            fullWidth
            id="outlined-multiline-static"
            label={t('message')}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={messageType === 0}
            fullWidth
            size="small"
            type={'submit'}
            variant="contained"
            color="secondary"
          >
            {t('add_activity')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
