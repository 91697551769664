import React, { useCallback, useEffect, useState } from 'react'
import { Container, Stack } from '@mui/system'
import { Box, Grid, Typography, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { formatCurrency, formatPercentage } from 'app/utils/format'
import { Link } from 'app/components/link.component'
import { KeyboardBackspace } from '@mui/icons-material'
import { useFeedback } from 'app/providers/feedback.provider'
import { useParams } from 'react-router-dom'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import InfoCard from 'app/components/card/info-card.component'
import { DiscountDetails } from 'api/models/discounts'
import { CollapsableCardComponent } from 'app/components/card/collapsable-card.component'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'

export const DiscountView = (): React.JSX.Element => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [contractsData, setContractsData] = useState(new Map<string, any>())
  const [quotationsData, setQuotationsData] = useState(new Map<string, any>())
  const { handleFetcher } = useFeedback()
  const { Discount } = useFetcherV2()
  const [discount, setDiscount] = useState<DiscountDetails>({} as DiscountDetails)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getData().then()
  }, [])

  useEffect(() => {
    const _map = new Map()
    _map.set('items', [
      { value: 'reference' },
      { value: 'clientName' },
      { value: 'commitmentLabel' },
      { value: 'reductionValue', format: formatCurrency }
    ])
    _map.set('data', discount.contracts)
    setContractsData(_map)

    const _map2 = new Map()
    _map2.set('items', [
      { value: 'id' },
      { value: 'clientName' },
      { value: 'opportunityReference' },
      { value: 'maxReductionValue', format: formatCurrency }
    ])
    _map2.set('data', discount.quotations)
    setQuotationsData(_map2)
  }, [discount])

  const getData = useCallback(async () => {
    setIsLoading(true)
    await handleFetcher({
      func: Discount.get.bind(null, Number(id)),
      onSuccess: (data: any) => {
        setDiscount(data)
        setIsLoading(false)
      }
    })
  }, [id])

  return (
    <Container>
      <Box marginBottom="2rem">
        {isLoading ? (
          <RectangularSkeleton />
        ) : (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/discounts`}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h2" gutterBottom display="inline">
              {t('discount') + ' | ' + discount.label}
            </Typography>
          </Stack>
        )}
      </Box>
      {isLoading && <CardSkeleton />}
      <Grid container gap={4}>
        {!isLoading && (
          <Grid item xs={12}>
            <InfoCard
              title={t('informations')}
              columns={[
                {
                  label: t('label'),
                  value: discount.label
                },
                {
                  label: t('begin'),
                  value: discount.begin
                },
                {
                  label: t('end'),
                  value: discount.end
                }
              ]}
            >
              {discount?.envelop > 0 && (
                <>
                  <Divider />
                  <InfoCard
                    title={t('envelope') + ' : ' + formatCurrency(discount?.envelop)}
                    columns={[
                      {
                        label: t('consumed'),
                        value: formatCurrency(discount.totalReductionValue)
                      },
                      {
                        label: t('remaining'),
                        value: formatCurrency(discount.remainingReductionValue)
                      }
                    ]}
                  />
                </>
              )}
            </InfoCard>
          </Grid>
        )}
        <Grid item xs={12}>
          <CollapsableCardComponent title={t('linked_centers')} depth={0}>
            <Box paddingX={7}>
              {discount.centerDiscounts?.map((center, index) => {
                return (
                  <Box key={index} marginBottom={2}>
                    <Typography>{t(center.centerName)}</Typography>
                  </Box>
                )
              })}
            </Box>
          </CollapsableCardComponent>
        </Grid>
        <Grid item xs={12}>
          <CollapsableCardComponent title={t('discount_rates')} depth={0}>
            <Box paddingX={7}>
              {Object.entries(discount.discountRatesByRole ?? {}).map(([key, rates]) => {
                return (
                  <Box key={key} marginBottom={7}>
                    <Typography fontWeight={'bold'} variant={'subtitle2'} mb={3}>
                      {t(key)}
                    </Typography>
                    <Grid container columns={12} spacing={3}>
                      {rates.map((rate, index) => (
                        <Grid key={index} item xs={12} sm={6}>
                          <Grid container columns={12}>
                            <Grid item xs={6}>
                              <Typography>{rate.commitmentLabel}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{formatPercentage(rate.rate)}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )
              })}
            </Box>
          </CollapsableCardComponent>
        </Grid>
        <Grid item xs={12}>
          <CollapsableCardComponent
            title={t('contracts') + ' : ' + formatCurrency(discount.totalContracts)}
            depth={0}
          >
            <Box paddingX={7}>
              <Box marginBottom={2}>
                <ListSheetComponent isLoading={isLoading} data={contractsData} />
              </Box>
            </Box>
          </CollapsableCardComponent>
        </Grid>
        <Grid item xs={12}>
          <CollapsableCardComponent
            title={t('quotation') + ' : ' + formatCurrency(discount.totalQuotations)}
            depth={0}
          >
            <Box paddingX={7}>
              <Box marginBottom={2}>
                <ListSheetComponent isLoading={isLoading} data={quotationsData} />
              </Box>
            </Box>
          </CollapsableCardComponent>
        </Grid>
      </Grid>
    </Container>
  )
}
