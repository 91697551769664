import { Button, Divider, Grid } from '@mui/material'
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { CenterBlockedPrices, CreateCenterPriceInputs, createCenterPricesSchema } from 'api/models'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFeedback } from 'app/providers/feedback.provider'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import { formatCurrency } from 'app/utils/format'
import { TitleComponent } from 'app/components/titles/title.component'
import { ControlledSelectCentersField } from 'app/components/form/controlled-select-centers.component'
import dayjs from 'dayjs'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import styled from '@emotion/styled'

interface IAddCenterPriceProps {
  centerId: number
  dialogRef: RefObject<DialogRef>
  onSave: () => void
  onClose?: () => void
}

const StickyGrid = styled(Grid)`
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.palette.background.paper};
`

export const AddCenterPriceComponent = function ({
  centerId,
  dialogRef,
  onSave,
  onClose
}: IAddCenterPriceProps) {
  const { t } = useTranslation()
  const [items, setItems] = useState<CenterBlockedPrices>([])
  const { createCenterPrice, getCenterBlockedPrices } = useFetcher()
  const { confirm, handleMutation } = useFeedback()
  const methods = useForm<CreateCenterPriceInputs>({
    mode: 'onChange',
    resolver: zodResolver(createCenterPricesSchema)
  })
  const center = methods.watch('center')

  const fetchList = useCallback(async () => {
    const items = await getCenterBlockedPrices.mutateAsync(center)
    setItems(items)
  }, [center])

  useEffect(() => {
    if (center === undefined || center < 1) return
    fetchList().then()
  }, [center])

  useEffect(() => {
    methods.reset({
      center: centerId,
      begin: dayjs()
    })
  }, [centerId])

  const create = useCallback(
    (data: CreateCenterPriceInputs) => async () => {
      await handleMutation({
        mutation: createCenterPrice,
        data: data,
        toastSuccess: t('create_success'),
        onSuccess: () => {
          dialogRef.current?.close()
          onSave()
        }
      })
    },
    [handleMutation, createCenterPrice]
  )

  const handleSave = useCallback(
    async (data: CreateCenterPriceInputs) => {
      confirm({
        content: t('confirm_add_center_price'),
        onConfirm: create(data)
      })
    },
    [confirm, create]
  )

  const dataMap = useMemo(() => {
    const _map = new Map()
    _map.set('items', [
      { label: t('services'), value: 'label', link: 'services/main/:id' },
      { label: t('price'), value: 'price', format: formatCurrency }
    ])
    _map.set('data', items)
    return _map
  }, [items])

  return (
    <Dialog
      ref={dialogRef}
      onClose={onClose}
      maxWidth={'md'}
      title={`${t('new_price_assignment')}`}
      actions={
        <>
          <Button onClick={dialogRef.current?.close} variant={'outlined'}>
            {t('cancel')}
          </Button>
          <Button
            onClick={methods.handleSubmit(handleSave)}
            variant={'contained'}
            disabled={!methods.formState.isValid}
          >
            {t('save')}
          </Button>
        </>
      }
    >
      <StickyGrid container paddingY={4} spacing={2} columns={12}>
        <Grid item xs={4}>
          <ControlledSelectCentersField
            slug={'center'}
            label={t('center')}
            name={'center'}
            control={methods.control}
            disableClearable={true}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTextField
            label={t('price_by_month')}
            name={'price'}
            control={methods.control}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledDatePicker label={t('begin')} name={'begin'} control={methods.control} />
        </Grid>
        <Grid item xs={12}>
          <TitleComponent
            text={t('blocked_price_title')}
            variant={'h3'}
            paddingTop={7}
            paddingLeft={12}
          />
          <Divider sx={{ paddingBottom: 4 }} />
        </Grid>
      </StickyGrid>
      <ListSheetComponent isLoading={getCenterBlockedPrices.isPending} data={dataMap} />
    </Dialog>
  )
}
