import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'

const presenceConsumptionSchema = z.object({
  individualName: z.string(),
  individualId: z.string(),
  totalTime: z.string(),
  totalPercentage: z.number()
})

export type PresenceConsumption = z.infer<typeof presenceConsumptionSchema>
export const PresenceConsumptionListSchema = itemListSchema(presenceConsumptionSchema)
export type PresenceConsumptionList = z.infer<typeof PresenceConsumptionListSchema>

const presenceConsumptionSumSchema = z.object({
  sum: z.number()
})

export type PresenceConsumptionSum = z.infer<typeof presenceConsumptionSumSchema>
