import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { type PresenceConsumptionList } from 'api/models'
import { type PresenceConsumptionSum } from 'api/models'

export const presencesConsumptionRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<PresenceConsumptionList>({
      url: `${BASE_URL}${API.GET_PRESENCES_CONSUMPTION}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getSum: async ({ token }: Ctx, params: string) =>
    fetcher<PresenceConsumptionSum>({
      url: `${BASE_URL}${API.GET_PRESENCES_CONSUMPTION_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
}
