import React from 'react'
import { Notification } from 'api/models/notifications'
import { useTranslation } from 'react-i18next'

interface NotificationRecipientsProps {
  notification: Notification
}

export function NotificationRecipients({ notification }: NotificationRecipientsProps) {
  const { t } = useTranslation()

  return (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {notification.isCenterRecipient && (
        <li key="Center">
          <span style={{ fontWeight: 500 }}> {t('center_associated')}</span>
        </li>
      )}
      {notification?.notificationStaff?.names.length > 0 && (
        <li key="Names">
          <span style={{ fontWeight: 500 }}>
            {t('staffNames', { count: notification.notificationStaff.names.length })}
          </span>
          {notification.notificationStaff.names.join(', ')}
        </li>
      )}
      {notification?.notificationRole?.label.length > 0 && (
        <li key="Labels">
          <span style={{ fontWeight: 500 }}>
            {t('roleNames', { count: notification.notificationRole.label.length })}
          </span>
          {notification.notificationRole.label.join(', ')}
        </li>
      )}
      {notification?.additionalRecipients?.to.length > 0 && (
        <li key="Other">
          <span style={{ fontWeight: 500 }}>
            {t('additional_mails', { count: notification.additionalRecipients.to.length })}
          </span>
          {notification.additionalRecipients.to.join(', ')}
        </li>
      )}
    </ul>
  )
}
