import styled from '@emotion/styled'
import { ChevronRight, ChevronLeft } from '@mui/icons-material'
import { Box, Drawer, useTheme } from '@mui/material'
import { useCallback, useState } from 'react'
import { darken } from 'polished'
import { SidebarProvider } from 'app/providers/sidebar.provider'

interface IDetailsLayoutProps {
  children: React.ReactNode
  asideComponent?: React.ReactNode
}

const drawerWidth = 280

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flex: 1,
  width: drawerWidth,
  boxSizing: 'border-box',
  padding: theme.spacing(8),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }),
  position: 'relative'
}))

const DrawerToggle = styled('button')`
  position: absolute;
  right: 100%;
  top: 0;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 0;
  border: none;
  color: #fff;
  pointer-events: all;

  &[data-is-open='false'] {
    transform: translateX(${drawerWidth}px);
  }
`

const DrawerParent = styled('div')`
  position: relative;
  flex-shrink: 0;
  z-index: 0;
  &[data-is-open='false'] {
    pointer-events: none;
  }
`

export const DetailsLayout = ({ children, asideComponent }: IDetailsLayoutProps) => {
  const [open, setOpen] = useState<boolean>(asideComponent ? true : false)
  const theme = useTheme()
  const handleDrawerToggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <SidebarProvider>
      <Box sx={{ height: '100%', display: 'flex' }}>
        <Main open={open}>{children}</Main>

        <DrawerParent data-is-open={open}>
          <DrawerToggle onClick={handleDrawerToggle} data-is-open={open}>
            {open ? <ChevronRight /> : <ChevronLeft />}
          </DrawerToggle>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                height: 'calc(100vh - 64px)',
                width: drawerWidth,
                top: 64,
                background:
                  theme.palette.mode === 'dark'
                    ? darken(0.03, theme.palette.background.paper)
                    : theme.palette.grey[100]
              }
            }}
            open={open}
            variant="persistent"
            anchor="right"
          >
            {asideComponent}
          </Drawer>
        </DrawerParent>
      </Box>
    </SidebarProvider>
  )
}
