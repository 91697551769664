import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  ClientLinkList,
  FormVisorAccessData,
  EventsList,
  IndividualAccessList,
  IndividualConsumptions,
  IndividualDetails,
  IndividualDevices,
  IndividualInvoices,
  IndividualList,
  IndividualPartners,
  IndividualWifi,
  UpdateClientLinkProps,
  WifiPasswordUpdate
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const individualsRouter = {
  activate: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${API.POST_INDIVIDUAL}/${id}/activate`,
      method: 'POST',
      token: token
    }),
  list: async ({ token }: Ctx, params: string) =>
    fetcher<IndividualList>({
      url: `${BASE_URL}${API.GET_INDIVIDUALS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  updateClientLink: async ({ token }: Ctx, params: UpdateClientLinkProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_INDIVIDUAL_CLIENTLINK, {
        id: params.individualId,
        clientId: params.id
      })}`,
      method: 'PATCH',
      token: token,
      body: params.data
    }),
  getOne: async ({ token }: Ctx, id: string) =>
    fetcher<IndividualDetails>({
      url: `${BASE_URL}${API.GET_INDIVIDUALS}/${id}`,
      method: 'GET',
      token: token
    }),
  getWifi: async ({ token }: Ctx, id: string) =>
    fetcher<IndividualWifi>({
      url: `${BASE_URL}${API.GET_INDIVIDUALS}/${id}/wifi-informations`,
      method: 'GET',
      token: token
    }),
  getPartners: async ({ token }: Ctx, id: string) =>
    fetcher<IndividualPartners>({
      url: `${BASE_URL}${API.GET_INDIVIDUALS}/${id}/partners-informations`,
      method: 'GET',
      token: token
    }),
  listEvents: async ({ token }: Ctx, id: string) =>
    fetcher<EventsList>({
      url: `${BASE_URL}${API.GET_INDIVIDUAL_EVENTS.replace(':id', id)}`,
      method: 'GET',
      token: token
    }),
  listEnterprises: async ({ token }: Ctx, id: string) =>
    fetcher<ClientLinkList>({
      url: `${BASE_URL}${API.GET_INDIVIDUAL_ENTERPRISES.replace(':id', id)}`,
      method: 'GET',
      token: token
    }),
  listConsumptions: async ({ token }: Ctx, id: string) =>
    fetcher<IndividualConsumptions>({
      url: `${BASE_URL}${API.GET_INDIVIDUAL_CONSUMPTIONS.replace(':id', id)}`,
      method: 'GET',
      token: token
    }),
  listInvoices: async ({ token }: Ctx, id: string) =>
    fetcher<IndividualInvoices>({
      url: `${BASE_URL}${API.GET_INDIVIDUAL_INVOICES.replace(':id', id)}`,
      method: 'GET',
      token: token
    }),
  listDevices: async ({ token }: Ctx, id: string) =>
    fetcher<IndividualDevices>({
      url: `${BASE_URL}${API.GET_INDIVIDUAL_DEVICES.replace(':id', id)}`,
      method: 'GET',
      token: token
    }),
  updateDevices: async ({ token }: Ctx, id: number, data: any) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_INDIVIDUAL_DEVICES, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  resetPassword: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${API.POST_INDIVIDUAL}/${id}/send`,
      method: 'POST',
      token: token
    }),
  update: async ({ token }: Ctx, id: number, data: IndividualDetails) =>
    fetcher<void>({
      url: `${BASE_URL}${API.PUT_INDIVIDUAL}/${id}`,
      method: 'PUT',
      token: token,
      body: data
    }),
  createWifiCredentials: async ({ token }: Ctx, id: string, data: WifiPasswordUpdate) =>
    fetcher<void>({
      url: `${BASE_URL}${API.POST_INDIVIDUAL}/${id}/createradiususer`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateWifiPassword: async ({ token }: Ctx, id: string, data: WifiPasswordUpdate) =>
    fetcher<void>({
      url: `${BASE_URL}${API.PATCH_INDIVIDUAL}/${id}/updateradiuspassword`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  updateActivCorner: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${API.PATCH_INDIVIDUAL}/${id}/activcorner`,
      method: 'PATCH',
      token: token
    }),
  sendWifiPassword: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${API.POST_INDIVIDUAL}/${id}/sendradiuspassword`,
      method: 'POST',
      token: token
    }),
  listAccess: async ({ token }: Ctx, id: string) =>
    fetcher<IndividualAccessList>({
      url: `${BASE_URL}${urlHelper(API.GET_INDIVIDUAL_ACCESS, { id })}`,
      method: 'GET',
      token: token
    }),
  createAccess: async ({ token }: Ctx, id: number, data: FormVisorAccessData) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.POST_INDIVIDUAL_ACCESS, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateAccess: async ({ token }: Ctx, id: number, data: FormVisorAccessData) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_INDIVIDUAL_ACCESS, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    })
}
