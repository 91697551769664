import {
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Delete, KeyboardArrowDown, LinkOff } from '@mui/icons-material'
import { Activities, FormItem, InvoiceInformation } from 'api/models'
import dayjs, { Dayjs } from 'dayjs'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { DeclarePaymentComponent } from 'modules/invoices/components/declare_payment.component'
import { ExistingPaymentComponent } from 'modules/invoices/components/existing_payment.component'
import firstLetterUpper from 'app/helpers/first_letter_upper.helper'
import { formatCreditState, formatCurrency, formatDate } from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { ActivitiesContainer } from 'modules/activities/components/activities'
import { useTranslation } from 'react-i18next'
import { useApp } from 'app/providers/app.provider'
import { useParams } from 'react-router-dom'

interface IProps {
  data: InvoiceInformation
  translation: any
  expanded: string | false
  setExpanded: React.Dispatch<React.SetStateAction<string | false>>
  setRefresh: Dispatch<SetStateAction<boolean>>
  paymentMode: FormItem
  activityTypes: any
}

export const ActionsComponent = ({
  data,
  translation,
  expanded,
  setExpanded,
  setRefresh,
  paymentMode,
  activityTypes
}: IProps) => {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const [bankDebitDate, setBankDebitDate] = useState<Dayjs>(
    data.nextPossibleChargeDate ? dayjs(data.nextPossibleChargeDate) : dayjs()
  )
  const [payedDate, setPayedDate] = useState<Dayjs>(dayjs())
  const [isOnePayment, setIsOnePayment] = useState<boolean>(true)
  const [paymentType, setPaymentType] = useState<string>('declare')
  const [payments, setPayments] = useState<any>([])
  const [credits, setCredits] = useState<any>([])
  const {
    postManualBankDebitInvoice,
    postAddPaymentInvoice,
    patchLinkPaymentInvoice,
    getPaymentsInvoice,
    patchUnLinkPaymentInvoice,
    patchCancelledPaymentInvoice,
    getCreditsInvoice,
    patchUnLinkCreditInvoice,
    getInvoiceActivities
  } = useFetcher()
  const { handleMutation } = useFeedback()
  const [paymentsMap, setPaymentsMap] = useState<Map<string, any>>(new Map())
  const [creditsMap, setCreditsMap] = useState<Map<string, any>>(new Map())
  const [notificationsMap, setNotificationsMap] = useState<Map<string, any>>(new Map())
  const [dataActivities, setDataActivities] = useState<Activities>({} as Activities)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [activityType, setActivityType] = useState<string>('')
  const [hasPaymentRight, setHasPaymentRight] = useState<boolean>(false)
  const [hasPaymentTpeRight, setHasPaymentTpeRight] = useState<boolean>(false)
  const { id } = useParams()
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleActivitiesChange = (response: any) => {
    setDataActivities((prev: any) => {
      return {
        ...prev,
        activities: [
          {
            type: response.type,
            message: response.message,
            createdAt: response.createdAt,
            staff: response.staff
          },
          ...prev.activities
        ]
      }
    })
  }

  const handleManualBankDebit = async () => {
    const chargeDate = bankDebitDate.format('YYYY-MM-DD')
    await handleMutation({
      data: { data: chargeDate, id: Number(id) },
      mutation: postManualBankDebitInvoice,
      toastSuccess: t('program_success'),
      onEnd: () => setRefresh((prevState: boolean) => !prevState)
    })
  }

  const handleAddPayment = async (e: any) => {
    e.preventDefault()
    const form = new FormData(e.target)
    if (paymentType === 'declare') {
      form.set('payedDate', payedDate.format('YYYY-MM-DD'))
      const formEntries = Object.fromEntries(form.entries())
      await handleMutation({
        data: { data: formEntries, id: Number(id) },
        mutation: postAddPaymentInvoice,
        toastSuccess: t('declare_success'),
        onEnd: () => setRefresh((prevState: boolean) => !prevState)
      })
    } else {
      await handleMutation({
        data: { id: Number(id), payment: Number(form.get('existing_payment')) },
        mutation: patchLinkPaymentInvoice,
        toastSuccess: t('link_success'),
        onEnd: () => setRefresh((prevState: boolean) => !prevState)
      })
    }
  }

  useEffect(() => {
    if (getRight('payment', 'isAdd')) {
      setHasPaymentRight(true)
    }

    if (getRight('payment_tpe', 'isAdd')) {
      setHasPaymentTpeRight(true)
    }
    getPayments().then()
    getCredits().then()
  }, [])

  useEffect(() => {
    getDataActivities().then()
  }, [activityType])

  const getDataActivities = useCallback(async (): Promise<void> => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      data: { id: Number(id), type: activityType },
      mutation: getInvoiceActivities,
      onSuccess: (response) => setDataActivities(response),
      onEnd: () => setIsLoading(false)
    })
  }, [activityType, id])

  const getPayments = useCallback(async (): Promise<void> => {
    await handleMutation({
      mutation: getPaymentsInvoice,
      data: Number(id),
      onSuccess: (response) => setPayments(response)
    })
  }, [id])

  const getCredits = useCallback(async (): Promise<void> => {
    await handleMutation({
      mutation: getCreditsInvoice,
      data: Number(id),
      onSuccess: (response) => setCredits(response)
    })
  }, [id])

  const handleUnlinkPayment = useCallback(
    async (row: any): Promise<void> => {
      await handleMutation({
        data: { id: Number(id), payment: Number(row.id) },
        mutation: patchUnLinkPaymentInvoice,
        toastSuccess: t('unlink_success'),
        onEnd: () => setRefresh((prevState: boolean) => !prevState)
      })
    },
    [id]
  )

  useEffect(() => {
    if (!paymentMode?.values) {
      setPaymentType('link')
    }
  }, [paymentMode])

  const handleUnlinkCredit = useCallback(
    async (row: any): Promise<void> => {
      await handleMutation({
        data: { id: Number(id), credit: Number(row.creditId) },
        mutation: patchUnLinkCreditInvoice,
        toastSuccess: t('unlink_credit_success'),
        onEnd: () => setRefresh((prevState: boolean) => !prevState)
      })
    },
    [id]
  )

  const handleCancelledGc = useCallback(
    async (row: any): Promise<void> => {
      await handleMutation({
        data: { id: Number(id), payment: Number(row.id) },
        mutation: patchCancelledPaymentInvoice,
        toastSuccess: t('cancelled_success'),
        onEnd: () => setRefresh((prevState: boolean) => !prevState)
      })
    },
    [id]
  )

  useEffect(() => {
    if (payments.length > 0) {
      setPaymentsMap(() => {
        const map = new Map()
        map.set('items', [
          {
            label: translation('payment_mode_label').toUpperCase(),
            value: 'paymentModeLabel',
            format: firstLetterUpper
          },
          { label: translation('payment_id').toUpperCase(), value: 'partnerPaymentId' },
          { label: translation('amount').toUpperCase(), value: 'amount', format: formatCurrency },
          {
            label: translation('predicted_date').toUpperCase(),
            value: 'dueDate',
            format: formatDate
          },
          { label: translation('payment_state').toUpperCase(), value: 'paymentStatusLabel' },
          {
            component: (row: any) =>
              data.payments.isDebitDirect &&
              data.payments.isPaymentMethod &&
              data.payments.isGocardless &&
              data.isPaymentDirectDebit &&
              data.payments.isPendingSubmission && (
                <Delete
                  sx={{ cursor: 'pointer' }}
                  fontSize={'small'}
                  color="primary"
                  onClick={() => handleCancelledGc(row)}
                />
              )
          },
          {
            component: (row: any) =>
              data.isPaymentEdit &&
              data.payments.activePaymentsState[row.id] && (
                <LinkOff
                  sx={{ cursor: 'pointer' }}
                  fontSize={'small'}
                  color="primary"
                  onClick={() => handleUnlinkPayment(row)}
                />
              )
          }
        ])
        map.set('data', payments)
        return map
      })
    }
  }, [payments])

  useEffect(() => {
    if (credits.length > 0) {
      setCreditsMap(() => {
        const map = new Map()
        map.set('items', [
          {
            label: translation('state').toUpperCase(),
            component: (row: any) =>
              formatCreditState(row['creditStatus'], row['creditStatusLabel'])
          },
          {
            label: translation('reference').toUpperCase(),
            value: 'creditReference',
            link: '/credits/:creditId'
          },
          { label: translation('dated').toUpperCase(), value: 'createdAt', format: formatDate },
          {
            label: translation('accounting_document_due_amount').toUpperCase(),
            value: 'creditAmountTotal',
            format: formatCurrency
          },
          {
            label: translation('accounting_document_apply_amount_ttc').toUpperCase(),
            value: 'amount',
            format: formatCurrency
          },
          {
            label: translation('remaining_sale').toUpperCase(),
            value: 'creditAmountDue',
            format: formatCurrency
          },
          {
            component: (row: any) =>
              data.isCreditEdit && (
                <LinkOff
                  sx={{ cursor: 'pointer' }}
                  fontSize={'small'}
                  color="primary"
                  onClick={() => handleUnlinkCredit(row)}
                />
              )
          }
        ])
        map.set('data', credits)
        return map
      })
    }
  }, [credits])

  useEffect(() => {
    if (data !== undefined) {
      if (data.unlinkPayments.length > 0) {
        setIsOnePayment(false)
      }

      if (data.notifications.length > 0) {
        setNotificationsMap(() => {
          const map = new Map()
          map.set('items', [
            { label: translation('type').toUpperCase(), value: 'type' },
            {
              label: translation('sending_date').toUpperCase(),
              value: 'created_at',
              format: formatDate
            }
          ])
          map.set('data', data.notifications)
          return map
        })
      }
    }
  }, [data])

  return (
    <>
      <Accordion sx={{ paddingX: '1rem' }}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{translation('manual_bank_debit')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          {data.isPayable && data.partnerPaymentMethodId !== null && (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={data.partnerPaymentMethodId}
                  id="outlined-basic"
                  label={translation('mandate_client')}
                  variant="outlined"
                  size={'small'}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label={translation('debit_date')}
                  value={bankDebitDate}
                  onChange={(e: any) => setBankDebitDate(e)}
                  slotProps={{
                    textField: { size: 'small', fullWidth: true }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={handleManualBankDebit}
                >
                  {translation('debit_program')}
                </Button>
              </Grid>
            </Grid>
          )}
          {!data.isPayable && data.partnerPaymentMethodId !== null && (
            <Typography>{translation('payment_already_recorded')}</Typography>
          )}
          {data.partnerPaymentMethodId === null && (
            <Typography>{translation('no_mandate_associated')}</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ paddingX: '1rem' }}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{translation('payment_add')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          {data.isPayable && (hasPaymentRight || hasPaymentTpeRight) ? (
            <form autoComplete="off" onSubmit={handleAddPayment}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {translation('type_addition')}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={translation('type_addition')}
                      value={paymentType}
                      onChange={(e: any) => setPaymentType(e.target.value)}
                      size={'small'}
                      readOnly={isOnePayment}
                    >
                      {(hasPaymentRight || hasPaymentTpeRight) && (
                        <MenuItem value={'declare'}>Déclarer un paiement</MenuItem>
                      )}
                      {hasPaymentRight && <MenuItem value={'link'}>Paiement existant</MenuItem>}
                    </Select>
                  </FormControl>
                </Grid>
                {paymentType === 'declare' && (hasPaymentRight || hasPaymentTpeRight) && (
                  <DeclarePaymentComponent
                    payedDate={payedDate}
                    setPayedDate={setPayedDate}
                    t={translation}
                    paymentMode={paymentMode}
                    data={data}
                  />
                )}
                {paymentType === 'link' && hasPaymentRight && (
                  <ExistingPaymentComponent t={translation} data={data} />
                )}
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    type={'submit'}
                    size="small"
                    variant="contained"
                    color="secondary"
                  >
                    {translation('save')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : (
            <Typography>{translation('payment_transfer_no_more')}</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ paddingX: '1rem' }}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{translation('payments')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          {paymentsMap.size === 0 ? (
            <Typography>{translation('no_data_found')}</Typography>
          ) : (
            <ListSheetComponent isLoading={false} data={paymentsMap} />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ paddingX: '1rem' }}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{translation('credits')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          {creditsMap.size === 0 ? (
            <Typography>{translation('no_data_found')}</Typography>
          ) : (
            <ListSheetComponent isLoading={creditsMap.size === 0} data={creditsMap} />
          )}
        </AccordionDetails>
      </Accordion>
      {data.notifications.length > 0 && (
        <Accordion sx={{ paddingX: '1rem' }}>
          <AccordionSummary
            expandIcon={<KeyboardArrowDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{translation('Notifications client')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginBottom: '20px' }}>
            <ListSheetComponent isLoading={notificationsMap.size === 0} data={notificationsMap} />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        sx={{ paddingX: '1rem' }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{translation('internal_notes')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          <ActivitiesContainer
            isLoading={isLoading}
            activityTypes={activityTypes}
            activities={dataActivities.activities}
            data={{ invoice: String(id) }}
            onChange={handleActivitiesChange}
            onTypeChange={(type: any) => setActivityType(type)}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
