import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RefObject } from 'react'
import { FormItems } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { EventFormData, eventFormDataSchema } from 'api/models/forms/events'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import Button from '@mui/material/Button'
import * as React from 'react'
import FormAddEvent from 'app/forms/event/FormAddEvent'
import dayjs from 'dayjs'

export function AddEventDialog(props: {
  defaultValues: EventFormData
  options: FormItems
  dialogRef: RefObject<DialogRef>
  onSuccess: Function
}) {
  const methods = useForm<EventFormData>({
    defaultValues: props.defaultValues,
    resolver: zodResolver(eventFormDataSchema),
    mode: 'onChange'
  })
  const { t } = useTranslation()
  const { createEvent } = useFetcher()
  const { handleMutation } = useFeedback()

  const handleSubmit = async (data: EventFormData) => {
    await handleMutation({
      confirm: {
        content: t('confirm_create_event')
      },
      mutation: createEvent,
      data: {
        ...data,
        begin: dayjs(data.begin).format('YYYY-MM-DDTHH:mm:ss'),
        end: data.end ? dayjs(data.end).format('YYYY-MM-DDTHH:mm:ss') : null
      },
      toastSuccess: t('success_created_event'),
      toastError: t('error'),
      onSuccess: () => {
        if (props.onSuccess) props.onSuccess()
      },
      onEnd: () => {
        props.dialogRef.current?.close()
      }
    })
  }

  return (
    <Dialog
      ref={props.dialogRef}
      title={t('add_event')}
      actions={
        <>
          <Button
            id="pricer"
            color="secondary"
            variant="outlined"
            onClick={() => {
              props.dialogRef.current?.close()
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(handleSubmit)}
          >
            {t('submit')}
          </Button>
        </>
      }
    >
      <FormAddEvent methods={methods} defaultValue={props.defaultValues} options={props.options} />
    </Dialog>
  )
}
