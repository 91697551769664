import React, { useCallback, useEffect, useState } from 'react'
import { Button, Grid, Paper as MuiPaper, Typography } from '@mui/material'
import { Container, Stack } from '@mui/system'
import { CryptResType } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { FormCard } from 'app/components/form/form-card.component'
import { FormProvider, useForm } from 'react-hook-form'
import { CopyButton } from 'app/components/buttons/copy-button.component'
import { useApp } from 'app/providers/app.provider'
import { RolesEnum } from 'app/constants/roles'

export const CryptView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { encrypt, decrypt } = useFetcher()
  const { handleMutation } = useFeedback()
  const [isLoading] = useState<boolean>(false)
  const { isRole } = useApp()

  const methods = useForm<CryptResType>({
    mode: 'onChange'
  })

  const { isValid } = methods.formState

  useEffect(() => {
    methods.reset({
      text: '',
      result: ''
    })
  }, [methods])

  const encryptHandle = useCallback(
    async (data: { text: string }) => {
      await handleMutation({
        mutation: encrypt,
        data: data,
        onSuccess: (data) => {
          methods.setValue('result', data.result)
        }
      })
    },
    [methods, encrypt, handleMutation]
  )

  const decryptHandle = useCallback(
    async (data: { text: string }) => {
      await handleMutation({
        mutation: decrypt,
        data: data,
        onSuccess: (data) => {
          methods.setValue('result', data.result)
        }
      })
    },
    [methods, decrypt, handleMutation]
  )

  return !isRole(RolesEnum.ADMIN) ? (
    <></>
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h1">{t('cryptography')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid>
        <MuiPaper elevation={0} sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
          <FormProvider {...methods}>
            <Grid container padding={0}>
              <Grid item xs={12}>
                <FormCard
                  isLoading={isLoading}
                  control={methods.control}
                  items={[
                    {
                      type: 'textfield',
                      label: `${t('input')}`,
                      name: 'text',
                      xs: 6,
                      inputProps: { multiline: true, maxRows: 20, width: '100%' }
                    },
                    {
                      type: 'textfield',
                      label: `${t('result')}`,
                      name: 'result',
                      xs: 6,
                      icon: (): React.JSX.Element => (
                        <CopyButton text={methods.getValues('result')} />
                      ),
                      inputProps: { multiline: true, maxRows: 20, width: '100%', disabled: true }
                    }
                  ]}
                >
                  <Grid container columns={12} columnSpacing={4} marginTop={4}>
                    <Grid item xs={6} textAlign={'right'}>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={methods.control.handleSubmit(encryptHandle)}
                      >
                        {t('encrypt')}
                      </Button>
                    </Grid>
                    <Grid item xs={6} textAlign={'left'}>
                      <Button
                        disabled={!isValid}
                        variant={'contained'}
                        color={'primary'}
                        onClick={methods.control.handleSubmit(decryptHandle)}
                      >
                        {t('decrypt')}
                      </Button>
                    </Grid>
                  </Grid>
                </FormCard>
              </Grid>
            </Grid>
          </FormProvider>
        </MuiPaper>
      </Grid>
    </Container>
  )
}
