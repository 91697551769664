import React, { useCallback, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { StaffItem } from 'api/models/staffs'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { SearchInput } from 'app/components/filters/search-input'
import { AddCircle, Edit } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { useNavigate } from 'react-router-dom'
import { RolesEnum } from 'app/constants/roles'
import { FiltersContainer } from 'app/components/filters/filters-container'

export const StaffsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getRight, isRole } = useApp()
  const navigate = useNavigate()
  const { getStaffs, searchParams, setSearchParams } = useFetcher()
  const [staffs, setStaffs] = useState<StaffItem[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['roles_staff', 'role'],
      ['active_status', 'state']
    ])
  )
  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      const data = await getStaffs.mutateAsync()
      setOffset(data.actual)
      setIsLast(data.last)
      setTotal(data.total)
      setStaffs(reset ? data.items : (prev) => [...prev, ...data.items])
      setListIsLoading(false)
    },
    [getStaffs, setIsLast, setOffset, setTotal, searchParams]
  )

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('staffs_list')}
          {getRight('staff', 'isAdd') && (
            <IconButton
              title={t('add_staff')}
              aria-label={t('add_staff')}
              color="primary"
              onClick={() => navigate('/staffs/add')}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          )}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          initialOrder={[
            { property: 'firstname', order: 'asc' },
            { property: 'lastname', order: 'asc' }
          ]}
          onChange={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
          />
          <SearchInput
            slug={'name'}
            label={'name'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersContainer>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {staffs.length} / {total}
          </Typography>
        </Grid>
        <List
          items={staffs}
          columns={[
            {
              label: t('lastname'),
              slug: 'lastname'
            },
            {
              label: t('firstname'),
              slug: 'firstname'
            },
            {
              label: t('email'),
              slug: 'email'
            },
            {
              label: t('phone'),
              slug: 'phone'
            },
            {
              label: t('main_center'),
              slug: 'center'
            },
            {
              label: t('role'),
              slug: 'role'
            },
            {
              label: t('job'),
              slug: 'job'
            },
            {
              label: '',
              slug: 'id',
              condition: (staff: StaffItem) => {
                if (
                  !getRight('staff', 'isEdit') ||
                  (!isRole(RolesEnum.ADMIN) && staff.roleReference === RolesEnum.ADMIN)
                )
                  return
                return (
                  <IconButton
                    color={'primary'}
                    onClick={() => navigate(`/staffs/${staff.id}/edit`)}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                )
              }
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {staffs.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
