import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'

const prescriberSchema = z.object({
  id: z.string(),
  city: z.string(),
  email: z.string(),
  enterpriseId: z.string(),
  enterpriseName: z.string(),
  individualId: z.string(),
  individualName: z.string(),
  mobile: z.string(),
  typeLabel: z.string()
})

export type Prescriber = z.infer<typeof prescriberSchema>

const prescriberListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(prescriberSchema)
})

export type PrescriberList = z.infer<typeof prescriberListSchema>
const prescriberInformationSchema = z.object({
  id: z.number(),
  type: z.string(),
  opportunities: z.number(),
  quotations: z.number(),
  contracts: z.number(),
  enterpriseName: z.number(),
  address: z.string().nullable(),
  city: z.string().nullable(),
  zipCode: z.string().nullable(),
  source: z.string().optional()
})

export type PrescriberInformation = z.infer<typeof prescriberInformationSchema>

const prescriberOpportunitySchema = z.object({
  id: z.number(),
  reference: z.string(),
  enterpriseId: z.number(),
  enterpriseName: z.string(),
  centerName: z.string(),
  typeLabel: z.string(),
  hasContract: z.string(),
  createdAt: z.string()
})

const prescriberMemberSchema = z.object({
  id: z.number(),
  reference: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  city: z.string(),
  email: z.string(),
  mobile: z.string(),
  source: z.string().optional()
})

export type PrescriberOpportunity = z.infer<typeof prescriberOpportunitySchema>
export type PrescriberMember = z.infer<typeof prescriberMemberSchema>

const prescriberOpportunitiesSchema = itemListSchema(prescriberOpportunitySchema)
export type PrescriberOpportunities = z.infer<typeof prescriberOpportunitiesSchema>

const prescriberMembersSchema = itemListSchema(prescriberMemberSchema)
export type PrescriberMembers = z.infer<typeof prescriberMembersSchema>
