import { TitleComponent } from 'app/components/titles/title.component'
import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { Euro, Lock } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MainService, MainServiceBody, MainServiceAddBody } from 'api/models'
import { UseFormReturn } from 'react-hook-form'

interface IProps {
  methods: UseFormReturn<any>
  mainService: MainService
  calculatedPrice: string | undefined
}

export default function PriceCalculatorComponent({
  methods,
  mainService,
  calculatedPrice
}: IProps) {
  const { t } = useTranslation()

  return (
    <>
      <TitleComponent
        text={t('price_calculator')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={4}
      />
      <Grid container spacing={5} marginBottom={5}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Box sx={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
              <Typography>{t('monthly_price_locked')}</Typography>
              <Lock fontSize={'small'} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01
                }
              }}
              type="number"
              variant="outlined"
              defaultValue={mainService.price}
              {...methods.register('price', {
                setValueAs: (value) => (value && value > 0 ? parseFloat(value) : undefined)
              })}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('daily_price')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01
                }
              }}
              type="number"
              variant="outlined"
              defaultValue={mainService.dailyPrice}
              {...methods.register('dailyPrice')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('half_day_price')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01
                }
              }}
              type="number"
              variant="outlined"
              defaultValue={mainService.halfDayPrice}
              {...methods.register('halfDayPrice')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('hourly_price')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01
                }
              }}
              type="number"
              variant="outlined"
              defaultValue={mainService.hourlyPrice}
              {...methods.register('hourlyPrice')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={4} margin={'auto'}>
            <Typography>{t('calculated_monthly_price')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              disabled
              fullWidth
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro fontSize={'small'} />
                  </InputAdornment>
                ),
                inputProps: {
                  step: 0.01
                },
                readOnly: true
              }}
              type="number"
              variant="outlined"
              value={calculatedPrice ?? ''}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
