import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { PricerDiscountGrids, PricerInformations, ServicesPricer } from 'api/models'

export const pricerRouter = {
  services: async ({ token }: Ctx, id: string) =>
    fetcher<ServicesPricer>({
      url: `${BASE_URL}${API.GET_PRICER_SERVICES.replace(':id', id)}`,
      method: 'GET',
      token: token
    }),

  informations: async ({ token }: Ctx, id: string) =>
    fetcher<PricerInformations>({
      url: `${BASE_URL}${API.GET_PRICER_INFORMATIONS.replace(':id', id)}`,
      method: 'GET',
      token: token
    }),

  discountGrids: async ({ token }: Ctx, center: string, begin: string) =>
    fetcher<PricerDiscountGrids>({
      url: `${BASE_URL}${API.GET_CONFIGURATION_DISCOUNT_GRIDS}?center=${center}&begin=${begin}`,
      method: 'GET',
      token: token
    })
}
