import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import { Activities } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const activitiesRouter = {
  list: async ({ token }: Ctx, type: string, page: number, id?: string, activityType?: string) =>
    fetcher<Activities>({
      url: `${BASE_URL}${urlHelper(API.GET_ACTIVITIES, { type, id })}?page=${page}${
        activityType ? '&type=' + activityType : ''
      }`,
      method: 'GET',
      token: token
    }),
  create: async ({ token }: Ctx, params: any) =>
    fetcher<any>({
      url: `${BASE_URL}${API.POST_ACTIVITIES}`,
      method: 'POST',
      token: token,
      body: params
    })
}
