import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { Container, Typography } from '@mui/material'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import BaseModal from 'app/components/modals/base.modal'
import InformationsComponent from 'modules/contracts/components/mandate/informations.component'
import BankAccountComponent from 'modules/contracts/components/mandate/bankaccount.component'
import PaymentsComponent from 'modules/contracts/components/mandate/payments.component'
import { useTranslation } from 'react-i18next'
import { useFeedback } from 'app/providers/feedback.provider'

export const MandateView = (): React.JSX.Element => {
  const { getPaymentMethod, paymentMethodCancelled, getBankAccount, getPaymentMethodPayments } =
    useFetcher()
  const { id } = useParams()
  const [paymentMethod, setPaymentMethod] = useState<any>({})
  const [bankAccount, setBankAccount] = useState<any>({})
  const [payments, setPayments] = useState<any>({})
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [openActive, setOpenActive] = useState(false)
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()

  const refreshList = useCallback(async () => {
    setListIsLoading(true)
    try {
      const [paymentMethod, bankAccount, payments] = await Promise.all([
        getPaymentMethod.mutateAsync(Number(id)),
        getBankAccount.mutateAsync(Number(id)),
        getPaymentMethodPayments.mutateAsync(Number(id))
      ])
      setPaymentMethod(paymentMethod)
      setBankAccount(bankAccount)
      setPayments(payments)
    } finally {
      setListIsLoading(false)
    }
  }, [id])

  const handleActive = useCallback(async (e: any) => {
    e.preventDefault()
    await handleMutation({
      confirm: {
        content: t('confirm_cancel_mandate')
      },
      mutation: paymentMethodCancelled,
      data: Number(id),
      toastSuccess: t('mandate_cancelled'),
      toastError: t('error'),
      onEnd: () => {
        setOpenActive(false)
        refreshList().then()
      }
    })
  }, [])

  useEffect(() => {
    refreshList().then()
  }, [])

  return listIsLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <BaseModal
        open={openActive}
        setOpen={setOpenActive}
        title={'mandat'}
        handleUpdate={handleActive}
      >
        <Typography>{t('cancel_mandat')}</Typography>
      </BaseModal>
      <InformationsComponent paymentMethod={paymentMethod} setOpenActive={setOpenActive} />
      <BankAccountComponent bankAccount={bankAccount} />
      <PaymentsComponent payments={payments} />
    </Container>
  )
}
