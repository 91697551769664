import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { type ConsumptionList, ConsumptionsList, ConsumptionsListParams } from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const consumptionsRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<ConsumptionList>({
      url: `${BASE_URL}${API.GET_CONSUMPTIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  listForClient: async ({ token }: Ctx, params: ConsumptionsListParams) =>
    fetcher<ConsumptionsList>({
      url: `${BASE_URL}${urlHelper(API.GET_CLIENT_CONSUMPTIONS, { id: params.clientId })}?center=${
        params.center
      }&serviceTypeReference=${params.serviceTypeReference}`,
      method: 'GET',
      token: token
    }),
  getClientContractsConsumptions: async ({ token }: Ctx, id: string) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_CLIENT_CONTRACT_CONSUMPTIONS, { id })}`,
      method: 'GET',
      token: token
    })
}
