import { Autocomplete, FormControl, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type IAutocompleteSelect<T> = {
  label: string
  name: string
  labelSlug: keyof T
  valueSlug: keyof T
  items: Array<T>
  onChange: (e: any) => void
  value?: Array<any>
}

export const AutocompleteSelect = function <T>({
  label,
  valueSlug,
  labelSlug,
  items,
  onChange,
  value
}: IAutocompleteSelect<T>) {
  const [selectedItem, setSelectedItem] = useState<T[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    if (value) {
      setSelectedItem(items.filter((i) => value.indexOf(i[valueSlug]) > -1))
    }
  }, [items, value])

  return (
    <FormControl fullWidth size={'small'}>
      <Stack>
        <Autocomplete
          multiple
          autoComplete
          size={'small'}
          onChange={(_: any, newValue: any) => {
            setSelectedItem(newValue)
            if (onChange) {
              onChange(newValue)
            }
          }}
          value={selectedItem}
          loadingText={t('loading')}
          noOptionsText={t('no_options')}
          isOptionEqualToValue={(option, value) => option[valueSlug] === value[valueSlug]}
          getOptionLabel={(option) => option[labelSlug]}
          getOptionKey={(option) => option[valueSlug]}
          selectOnFocus
          options={items}
          renderInput={(params) => (
            <TextField
              {...(params as any)}
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>
              }}
              label={label}
            />
          )}
        />
      </Stack>
    </FormControl>
  )
}
