import React, { useEffect, useState } from 'react'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import StatsComponent from './stats.component'
import { extendSxProp } from '@mui/system'
import { grey } from '@mui/material/colors'

interface StatsAmountProps {
  value: number
  sx: any
}

interface StatsCircularProps extends CircularProgressProps {
  value: number
  sx: any
}

const CircularProgressWithLabel = (props: StatsCircularProps): React.JSX.Element => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" size={70} thickness={4} {...props} />
      <CircularProgress
        variant="determinate"
        size={70}
        thickness={4}
        value={100}
        sx={{ color: 'rgba(0, 0, 0, 0.15)', position: 'absolute', zIndex: 1 }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="h3" component="div" style={{ color: '#FFF' }}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

const StatsCircularProgressDeterminateComponent = ({
  value,
  sx
}: StatsAmountProps): React.JSX.Element => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1
        if (newProgress >= value) {
          clearInterval(timer)
          return value
        } else {
          return newProgress
        }
      })
    }, 10)

    return () => {
      clearInterval(timer)
    }
  }, [value])

  return (
    <Box sx={{ width: '100%' }}>
      <CircularProgressWithLabel
        value={progress}
        sx={{ color: sx, position: 'relative', zIndex: 4 }}
      />
    </Box>
  )
}
export default StatsCircularProgressDeterminateComponent
