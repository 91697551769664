import { Box, Card, CardContent, Link as ActionLink } from '@mui/material'
import { TitleComponent } from 'app/components/titles/title.component'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useList } from 'app/providers/list.provider'
import { List } from 'app/components/lists/list'
import { Event, FormItems, Opportunity, OpportunityEvent } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { formatDate, formatEventStatus } from 'app/utils/format'
import { AccountCircle } from '@mui/icons-material'
import { EditEventDialog } from 'modules/events/views/edit-event-dialog.component'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { useFeedback } from 'app/providers/feedback.provider'

interface IServicesProps {
  data: Opportunity
  options: FormItems
}

export const EventsComponent = ({ data, options }: IServicesProps) => {
  const [events, setEvents] = useState<OpportunityEvent[]>([])
  const { getOpportunityEvents } = useFetcher()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const dialogRef = useRef<DialogRef>(null)
  const [selectedEvent, setSelectedEvent] = useState<Event>({} as Event)
  const { handleMutation } = useFeedback()
  const { handleSort, handleFilter, orderBy, setOffset, setTotal, setIsLast } = useList()

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      await handleMutation({
        onStart: () => setIsLoading(true),
        mutation: getOpportunityEvents,
        data: String(data.id),
        onSuccess: (data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setEvents(reset ? data.items : (prev) => [...prev, ...data.items])
        },
        onEnd: () => setIsLoading(false)
      })
    },
    [getOpportunityEvents, setIsLast, setOffset, setTotal, data]
  )

  useEffect(() => {
    refreshList().then()
  }, [fetch])

  return (
    <>
      <Card>
        <CardContent>
          <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
            <TitleComponent text={t('events')} variant={'h3'} paddingLeft={12} />
          </Box>
          <List
            selectable={false}
            columns={[
              {
                label: '',
                slug: 'statusId',
                text: 'statusLabel',
                valueFormatter: formatEventStatus
              },
              {
                label: t('type'),
                slug: 'typeLabel',
                condition: (item) => (
                  <ActionLink
                    onClick={() => {
                      setSelectedEvent(item as unknown as Event)
                      dialogRef.current?.open()
                    }}
                  >
                    {item.typeLabel}
                  </ActionLink>
                )
              },
              {
                label: t('center'),
                slug: 'centerName'
              },
              {
                label: t('begin'),
                slug: 'beginLabel',
                valueFormatter: formatDate
              },
              {
                label: t('end'),
                slug: 'endLabel',
                valueFormatter: formatDate
              },
              {
                label: AccountCircle,
                tooltip: t('staff'),
                slug: 'staffName'
              }
            ]}
            items={events}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={isLoading}
          />
        </CardContent>
      </Card>
      <EditEventDialog
        options={options}
        dialogRef={dialogRef}
        selectedEvent={selectedEvent}
        onSuccess={() => {
          setSelectedEvent({} as Event)
          refreshList().then()
          dialogRef.current?.close()
        }}
      />
    </>
  )
}
