import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Grid, Link, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import {
  formatBeginWithLate,
  formatDate,
  formatDateWithTime,
  formatEventStatus,
  formatStaffBadge
} from 'app/utils/format'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { Event, FormItems } from 'api/models'
import { SearchStaffInput } from 'app/components/filters/search-staff-input'
import { EditEventDialog } from 'modules/events/views/edit-event-dialog.component'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { DayPicker } from 'app/components/filters/day-picker'
import { useApp } from 'app/providers/app.provider'

export const EventsView = (): React.JSX.Element => {
  const { user } = useApp()
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getFormItems, getEventsList, setSearchParams, searchParams } = useFetcher()
  const [events, setEvents] = useState<Event[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['event_status', 'status'],
      ['event_types', 'type'],
      ['opportunities', 'opportunity']
    ])
  )
  const dialogRef = useRef<DialogRef>(null)
  const [selectedEvent, setSelectedEvent] = useState<Event>({} as Event)
  const [options, setOptions] = useState<FormItems>({})
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['individual_roles', 'role'],
      ['centers', 'center'],
      ['event_types', 'type'],
      ['event_status', 'status'],
      ['prescriber_types', 'type'],
      ['opportunity_ground_refusals', 'opp_ground_refusal'],
      ['ground_refusal', 'ground_refusal'],
      ['opportunities', 'opportunity']
    ])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const initOptions = useCallback(
    async (commonOptions: Map<string, string>) => {
      await getFormItems
        .mutateAsync(Array.from(commonOptions.keys() as any))
        .then((optionsData) => {
          setOptions(optionsData as FormItems)
        })
    },
    [getFormItems, setOptions]
  )

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      if (reset) setListIsLoading(true)
      try {
        const events = await getEventsList.mutateAsync()
        setEvents((prevState) => (reset ? events.items : [...prevState, ...events.items]))
        setTotal(events.total)
        setIsLast(events.last)
        setOffset(events.actual)
      } finally {
        setListIsLoading(false)
      }
    },
    [getEventsList, setIsLast, setOffset, setTotal]
  )

  const handleEditEvent = useCallback(
    (row: Event) => {
      setSelectedEvent(row)
      dialogRef.current?.open()
    },
    [dialogRef, setSelectedEvent]
  )

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
    initOptions(commonOptions).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('events_list')}
        </Typography>
      </Box>
      <Grid>
        <EditEventDialog
          dialogRef={dialogRef}
          selectedEvent={selectedEvent}
          options={options}
          onSuccess={refreshList}
        />
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchStaffInput
            slug="staff"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            defaultValue={user?.id}
          />
          <SearchClientInput
            slug="enterprise"
            defaultIsIndividual={false}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchClientInput
            slug="owner"
            label={t('contact')}
            defaultIsEnterprise={false}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
          />
          <DayPicker
            slug={'begin'}
            views={['year', 'month', 'day']}
            label={t('begin')}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {events.length} / {total}
          </Typography>
        </Grid>
        <List
          items={events}
          columns={[
            {
              label: '',
              slug: 'statusId',
              text: 'statusLabel',
              valueFormatter: formatEventStatus
            },
            {
              label: t('type'),
              slug: 'typeLabel',
              condition: (row: Event) => (
                <Link onClick={() => handleEditEvent(row)}>{row.typeLabel}</Link>
              )
            },
            {
              label: t('center'),
              slug: 'centerName'
            },
            {
              label: t('begin'),
              slug: 'late',
              condition: (row: Event) => formatBeginWithLate(row.late, row.beginLabel, row.statusId)
            },
            {
              label: t('end'),
              slug: 'endLabel',
              valueFormatter: formatDateWithTime
            },
            {
              label: t('staff'),
              slug: 'staffInitial',
              text: 'staffName',
              valueFormatter: formatStaffBadge
            },
            {
              label: t('enterprise'),
              slug: 'enterpriseName',
              link: { base: '/enterprises', slug: 'enterpriseId' }
            },
            {
              label: t('contact'),
              slug: 'ownerName',
              link: { base: '/individuals', slug: 'ownerId' }
            },
            {
              label: t('opportunity'),
              slug: 'opportunityReference',
              link: { base: '/opportunities', slug: 'opportunityId' }
            },
            {
              label: t('created_at'),
              slug: 'createdAt',
              valueFormatter: formatDate
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {events.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
