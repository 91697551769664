import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback } from 'react'
import { StyledChip } from 'app/components/chips/multi-select-chip'
import { Cancel } from '@mui/icons-material'

export interface IMultiSelect<T> {
  label: string
  name: string
  labelSlug: keyof T
  valueSlug: keyof T
  items: Array<T>
  value: any[]
  onChange: (e: any) => void
}

export const MultiSelect = function <T>({
  label,
  name,
  valueSlug,
  labelSlug,
  items,
  value,
  onChange
}: IMultiSelect<T>) {
  const handleChange = useCallback(
    (event: SelectChangeEvent<typeof value>) => {
      onChange(event.target.value)
    },
    [onChange]
  )

  const handleDelete = useCallback(
    (valueToDelete: number) => () => {
      const newSelected = value.filter((item) => Number(item) !== Number(valueToDelete))
      onChange(newSelected)
    },
    [value, onChange]
  )

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        fullWidth
        label={label}
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected: any) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value: any) => {
              const group = items.find((g: any) => String(g[valueSlug]) === String(value))
              if (!group) return null
              return (
                <StyledChip
                  label={String(group[labelSlug])}
                  key={String(group[valueSlug])}
                  clickable
                  onDelete={handleDelete(Number(group[valueSlug]))}
                  deleteIcon={<Cancel onMouseDown={(e) => e.stopPropagation()} />}
                />
              )
            })}
          </Box>
        )}
      >
        {items.map((value: any) => (
          <MenuItem key={value[valueSlug]} value={value[valueSlug]}>
            {value[labelSlug]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
