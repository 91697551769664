import { FormControl } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { MainCenterSelect } from 'app/components/filters/main-center-select'
import { MultipleCentersSelect } from 'app/components/filters/multiple-centers-select'
import type { Center, StaffCenter, StaffCenters } from 'api/models'
import { useCallback } from 'react'

type IControlledTextFieldProps = {
  label: string
  name: string
  control: Control<any>
  required?: boolean
  selectMainCenter?: boolean
}

export type CenterWithIsMainAttribute = Center & {
  isMain: boolean
}

export function ControlledSelectStaffCenterField({
  name,
  label,
  control,
  required,
  selectMainCenter,
  ...otherProps
}: IControlledTextFieldProps) {
  const handleChangeMainCenter = useCallback(
    (staffCenters: StaffCenters | null, newValue: Center) => {
      if (!staffCenters) {
        return [
          {
            center: {
              id: Number(newValue.id)
            },
            isMain: true
          }
        ]
      }

      const newStaffCenters = staffCenters.map((center: StaffCenter) => {
        return {
          center: { id: center.center.id },
          isMain: center.center.id === Number(newValue.id)
        }
      })

      if (!newStaffCenters.some((center) => center.isMain)) {
        newStaffCenters.unshift({
          center: {
            id: Number(newValue.id)
          },
          isMain: true
        })
      }

      return newStaffCenters
    },
    []
  )

  const handleChangeAccessCenters = useCallback((newValue: CenterWithIsMainAttribute[]) => {
    return newValue.map((center: CenterWithIsMainAttribute) => {
      return {
        center: {
          id: Number(center.id)
        },
        isMain: center.isMain ?? false
      }
    })
  }, [])

  return (
    <Controller
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl fullWidth size={'small'} required={required}>
            {selectMainCenter ? (
              <MainCenterSelect
                {...otherProps}
                required={required}
                slug={label}
                onChange={(newValue: Center) => {
                  onChange(handleChangeMainCenter(value, newValue))
                }}
                initialValue={value}
              />
            ) : value ? (
              <MultipleCentersSelect
                {...otherProps}
                required={required}
                slug={label}
                onChange={(newValue: CenterWithIsMainAttribute[]) => {
                  onChange(handleChangeAccessCenters(newValue))
                }}
                initialValue={value}
              />
            ) : null}
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
