import { Chip } from '@mui/material'
import styled from '@emotion/styled'

export const StyledChip = styled(Chip)`
  && {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.contrastText};
    padding: 0.01rem 0.35rem;
    border-radius: 0.25rem;
    font-size: 10px;
    height: fit-content;
    & svg {
      color: ${(props) => props.theme.palette.primary.contrastText};
      font-size: 12px;
      margin: 0 0 0 0.175rem;
    }
    & span {
      padding: 0;
    }
  }
`
