import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  AvailableDesktopsSearch,
  MainList,
  MainService,
  MainSum,
  OptionService,
  ServicePrice,
  MainServiceBody,
  OptionServiceBody,
  ParkingServiceAddBody,
  ParkingServiceBody,
  IdReturn,
  MainServiceAddBody,
  MainServiceComputePriceBody,
  ConsumptionService,
  ConsumptionsServiceBody,
  ServicesTypesList
} from 'api/models'
import { ConsumptionPricing, PricingConsumptionInputs } from 'api/models/pricing'
import urlHelper from 'app/helpers/url.helper'

export const servicesRouter = {
  mainList: async ({ token }: Ctx, params: string) =>
    fetcher<MainList>({
      url: `${BASE_URL}${API.MAIN_SERVICES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  mainGet: async ({ token }: Ctx, id: number) =>
    fetcher<MainService>({
      url: `${BASE_URL}${urlHelper(API.GET_MAIN_SERVICE, { id })}`,
      method: 'GET',
      token: token
    }),
  optionGet: async ({ token }: Ctx, id: number) =>
    fetcher<OptionService>({
      url: `${BASE_URL}${urlHelper(API.GET_OPTION_SERVICE, { id })}`,
      method: 'GET',
      token: token
    }),
  updateOption: async ({ token }: Ctx, id: number, data: OptionServiceBody) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_OPTION_SERVICE, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  createOption: async ({ token }: Ctx, data: OptionServiceBody) =>
    fetcher<void>({
      url: `${BASE_URL}${API.POST_OPTION_SERVICE}`,
      method: 'POST',
      token: token,
      body: data
    }),
  createMain: async ({ token }: Ctx, data: MainServiceAddBody) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.MAIN_SERVICES}`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateMain: async ({ token }: Ctx, id: number, data: MainServiceBody) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_MAIN_SERVICE, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  computePrice: async ({ token }: Ctx, data: MainServiceComputePriceBody) =>
    fetcher<{ computedPrice: number }>({
      url: `${BASE_URL}${API.GET_COMPUTED_PRICE}?center=${data.center}&serviceType=${
        data.serviceType
      }${data.typology ? '&typology=' + data.typology : ''}${
        data.surface ? '&surface=' + data.surface : ''
      }`,
      method: 'GET',
      token: token
    }),
  parkingList: async ({ token }: Ctx, params: string) =>
    fetcher<any>({
      url: `${BASE_URL}${API.PARKING_SERVICES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getParking: async ({ token }: Ctx, id: number) =>
    fetcher<MainService>({
      url: `${BASE_URL}${urlHelper(API.GET_PARKING_SERVICE, { id })}`,
      method: 'GET',
      token: token
    }),
  createParking: async ({ token }: Ctx, data: ParkingServiceAddBody) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.PARKING_SERVICES}`,
      method: 'POST',
      token: token,
      body: data
    }),
  getConsumption: async ({ token }: Ctx, id: number) =>
    fetcher<ConsumptionService>({
      url: `${BASE_URL}${urlHelper(API.GET_CONSUMPTION, { id })}`,
      method: 'GET',
      token: token
    }),
  updateConsumption: async ({ token }: Ctx, id: number, data: any) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.GET_CONSUMPTION, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  createConsumption: async ({ token }: Ctx, data: ConsumptionsServiceBody) =>
    fetcher<void>({
      url: `${BASE_URL}${API.POST_CONSUMPTIONS}`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateParking: async ({ token }: Ctx, id: number, data: ParkingServiceBody) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.UPDATE_PARKING_SERVICE, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),

  getMainSum: async ({ token }: Ctx, params: string) =>
    fetcher<MainSum>({
      url: `${BASE_URL}${API.GET_MAIN_SERVICES_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getConsumptionPricing: async (
    { token }: Ctx,
    { serviceId, unitprice, quantity, fees, reduction }: PricingConsumptionInputs
  ) =>
    fetcher<ConsumptionPricing>({
      url: `${BASE_URL}${urlHelper(API.GET_SERVICE_CONSUMPTIONS_PRICING, {
        id: serviceId
      })}?${new URLSearchParams({
        unitprice: String(unitprice),
        fees: String(fees),
        quantity: String(quantity),
        reduction: String(reduction)
      })}`,
      method: 'GET',
      token: token
    }),
  getPricing: async (
    { token }: Ctx,
    id: string,
    { center, contract, client, begin, end, owner, reduction }: any
  ) =>
    fetcher<ServicePrice>({
      url: `${BASE_URL}${urlHelper(API.GET_SERVICE_PRICING, {
        id: id
      })}?${new URLSearchParams({
        center: String(center),
        contract: String(contract),
        client: String(client),
        begin: String(begin),
        end: String(end),
        owner: String(owner),
        reduction: String(reduction)
      })}`,
      method: 'GET',
      token: token
    }),
  getAvailableDesktops: async ({ token }: Ctx, data: AvailableDesktopsSearch) =>
    fetcher<void>({
      url: `${BASE_URL}${API.GET_SERVICES_AVAILABLE_DESKTOPS}?${new URLSearchParams(data)}`,
      method: 'GET',
      token: token
    }),
  getAvailable: async ({ token }: Ctx, data: any) =>
    fetcher<ServicePrice>({
      url:
        `${BASE_URL}${API.GET_SERVICES_AVAILABLE}?center=${data.center}&begin=${data.begin}&end=${data.end}` +
        `&commitment=${data.commitment}&serviceIds=[${data.serviceIds.join(',')}]` +
        `&originalServiceIds=[${data.originalServiceIds}]&contractType=${data.contractType}`,
      method: 'GET',
      token: token
    }),
  getCommitmentPrice: async ({ token }: Ctx, id: string, commitment: number) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.GET_SERVICE_COMMITMENT_PRICE, {
        id
      })}?commitment=${commitment}`,
      method: 'GET',
      token: token
    }),
  getServiceAvailability: async ({ token }: Ctx, id: string, begin?: string) => {
    let url = `${BASE_URL}${urlHelper(API.GET_SERVICE_AVAILABILITY, { id })}`
    if (begin !== undefined) {
      url += `?begin=${begin}`
    }
    return fetcher<{ message: string }>({
      url: url,
      method: 'GET',
      token: token
    })
  },
  getServicesTypes: async ({ token }: Ctx) =>
    fetcher<ServicesTypesList>({
      url: `${BASE_URL}${API.SERVICES_TYPES}`,
      method: 'GET',
      token: token
    })
}
