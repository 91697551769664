import { Button } from '@mui/material'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import React, { RefObject, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { ControlledSelectField } from 'app/components/form/controlled-select.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Contract, ContractServiceLinkUser, contractServiceLinkUserForm } from 'api/models'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'

interface ILinkUserModalProps {
  contract: Contract
  selectedRow: any
  dialogRef: RefObject<DialogRef>
}

export const LinkUserModal = function ({ contract, selectedRow, dialogRef }: ILinkUserModalProps) {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const { getContractUsers, linkContractServiceUser } = useFetcher()
  const method = useForm<ContractServiceLinkUser>({
    mode: 'onChange',
    resolver: zodResolver(contractServiceLinkUserForm)
  })
  const [users, setUsers] = useState<any>({})
  const isValid = method.formState.isValid

  const handleSave = useCallback(
    async (data: ContractServiceLinkUser) => {
      if (!contract) return
      await handleMutation({
        confirm: {
          content: t('confirm_link_client')
        },
        mutation: linkContractServiceUser,
        data: { id: contract.id, data },
        onSuccess: () => {
          navigate(0)
        }
      })
    },
    [t, contract]
  )

  useEffect(() => {
    if (!contract) return
    handleMutation({
      mutation: getContractUsers,
      data: contract.id,
      onSuccess: (data) => {
        setUsers({
          values: data.map((u) => ({
            label: u.client,
            id: u.id
          }))
        })
      }
    }).then()
  }, [contract])

  useEffect(() => {
    if (!selectedRow) return
    method.reset({
      contractService: selectedRow.id,
      individual: -1
    })
  }, [selectedRow])

  return (
    <Dialog
      ref={dialogRef}
      title={t('link_an_user')}
      actions={
        <>
          <Button onClick={() => dialogRef.current?.close()}>{t('cancel')}</Button>
          {users?.values?.length > 0 && (
            <Button
              variant="contained"
              disabled={!isValid}
              onClick={method.handleSubmit(handleSave)}
            >
              {t('link')}
            </Button>
          )}
        </>
      }
    >
      {users?.values?.length > 0 ? (
        <ControlledSelectField formItem={users} name={'individual'} control={method.control} />
      ) : (
        t('all_users_links')
      )}
    </Dialog>
  )
}
