import dayjs from 'dayjs'
import { ApexOptions } from 'apexcharts'

export const TimelineConfig = {
  theme: {
    palette: 'palette3'
  },
  chart: {
    animations: {
      enabled: false
    },
    type: 'rangeBar',
    toolbar: {
      tools: {
        zoom: true,
        zoomin: true,
        zoomout: false,
        pan: false,
        reset: true
      }
    }
  },
  grid: {
    padding: {
      top: 24
    }
  },
  legend: {
    float: true,
    show: true,
    showForSingleSeries: true,
    showForZeroSeries: true,
    position: 'top',
    fontSize: '14px',
    offsetY: 24,
    horizontalAlign: 'left',
    markers: {
      radius: 4,
      width: 32,
      height: 12
    }
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0
  },
  plotOptions: {
    bar: {
      barHeight: '100%',
      horizontal: true,
      rangeBarOverlap: true,
      rangeBarGroupRows: true,
      borderRadius: 4
    }
  },
  fill: {
    type: ['pattern', 'pattern', 'pattern', 'solid', 'solid'],
    opacity: [0.3, 0.3, 0.3, 1, 1],
    pattern: { style: 'slantedLines', width: 12, height: 32, strokeWidth: 2 }
  },
  xaxis: {
    type: 'datetime',
    position: 'top',
    tickPlacement: 'between',
    labels: {
      datetimeFormatter: {
        year: 'yyyy',
        month: 'dd/MM/yyyy',
        day: 'dd',
        hour: 'HH:mm'
      }
    }
  },
  yaxis: {
    forceNiceScale: true,
    showForNullSeries: true,
    showAlways: false,
    labels: {
      show: true,
      align: 'left',
      maxWidth: 240,
      style: {
        cssClass: 'red'
      }
    }
  },
  tooltip: {
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      return (
        '<div class="timeline-tooltip-container">' +
        '<p class="timeline-tooltip-title" style="background-color: ' +
        w.globals.colors[seriesIndex] +
        '; color : ' +
        (seriesIndex < 2 ? 'black' : 'white') +
        '">' +
        w.globals.initialSeries[seriesIndex].name +
        '</p>' +
        '<div class="timeline-tooltip-content"><p><span class="timeline-tooltip-x">' +
        w.globals.initialSeries[seriesIndex].data[dataPointIndex].name +
        ' : </span>' +
        dayjs(w.globals.initialSeries[seriesIndex].data[dataPointIndex].y[0]).format('DD/MM/YYYY') +
        ' - ' +
        dayjs(w.globals.initialSeries[seriesIndex].data[dataPointIndex].y[1]).format('DD/MM/YYYY') +
        '</p></div>' +
        '</div>'
      )
    },
    x: {
      formatter: (val: number, opts?: any) => {
        if (!opts || !opts.w.globals.initialSeries) return dayjs(val).format('DD/MM/YYYY')
        return opts?.w.globals.initialSeries[opts.seriesIndex].data[opts.dataPointIndex].name
      }
    }
  }
} as ApexOptions
