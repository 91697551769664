import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import urlHelper from 'app/helpers/url.helper'
import {
  CompaniesListSchema,
  Company,
  CompanyBankAccounts,
  CompanyBankAccount,
  CompanyCenters,
  CompanyConfiguration,
  IdReturn,
  CompanyBankAccountBody,
  PostCompany
} from 'api/models'
import { IdLabelItems } from 'api/models/commons'

export const companiesRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<CompaniesListSchema>({
      url: `${BASE_URL}${API.GET_COMPANIES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  get: async ({ token }: Ctx, id: number) =>
    fetcher<Company>({
      url: `${BASE_URL}${urlHelper(API.GET_COMPANY, { id })}`,
      method: 'GET',
      token: token
    }),
  getCenters: async ({ token }: Ctx, id: number) =>
    fetcher<CompanyCenters>({
      url: `${BASE_URL}${urlHelper(API.GET_COMPANY_CENTERS, { id })}`,
      method: 'GET',
      token: token
    }),
  getConfigurations: async ({ token }: Ctx, id: number) =>
    fetcher<CompanyConfiguration>({
      url: `${BASE_URL}${urlHelper(API.GET_COMPANY_CONFIG, { id })}`,
      method: 'GET',
      token: token
    }),
  getBankAccounts: async ({ token }: Ctx, id: number) =>
    fetcher<CompanyBankAccounts>({
      url: `${BASE_URL}${urlHelper(API.GET_COMPANY_BANK_ACCOUNTS, { id })}`,
      method: 'GET',
      token: token
    }),
  createBankAccount: async ({ token }: Ctx, id: number, data: CompanyBankAccountBody) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${urlHelper(API.POST_COMPANY_BANK_ACCOUNT, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateBankAccount: async ({ token }: Ctx, id: number, data: CompanyBankAccountBody) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_COMPANY_BANK_ACCOUNT, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  getBankAccount: async ({ token }: Ctx, id: number) =>
    fetcher<CompanyBankAccount>({
      url: `${BASE_URL}${urlHelper(API.GET_COMPANY_BANK_ACCOUNT, { id })}`,
      method: 'GET',
      token: token
    }),
  getAvailableCenters: async ({ token }: Ctx) =>
    fetcher<IdLabelItems>({
      url: `${BASE_URL}${API.GET_COMPANIES}/available_centers`,
      method: 'GET',
      token: token
    }),
  postCompany: async ({ token }: Ctx, data: PostCompany) =>
    fetcher<void>({
      url: `${BASE_URL}${API.POST_COMPANY}`,
      method: 'POST',
      token: token,
      body: data
    })
}
