import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CenterDetails, centerUpdateConfigurationInput, FormItem } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'
import { CenterUpdateContainer } from 'modules/centers/components/center-update-container.component'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
dayjs.extend(localeData)

export const CenterConfigurationEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { getCenter } = useFetcher()
  const { handleMutation } = useFeedback()
  const [center, setCenter] = useState<CenterDetails>({} as CenterDetails)

  useEffect(() => {
    handleMutation({
      mutation: getCenter,
      data: id,
      onSuccess: (data) => {
        setCenter(data)
      }
    }).then()
  }, [])

  return (
    <CenterUpdateContainer
      title={'configuration'}
      items={[
        {
          type: 'timepicker',
          label: t('opening_hour'),
          name: 'openingHour'
        },
        {
          type: 'timepicker',
          label: t('closing_hour'),
          name: 'closureHour'
        },
        {
          type: 'selectCheckbox',
          label: t('opening_day'),
          name: 'centerOpeningDays',
          formItem: {
            values: dayjs.weekdays().map((label, index) => ({ id: String(index), label }))
          } as FormItem
        },
        {
          type: 'textfield',
          label: t('phone'),
          name: 'phone'
        },
        {
          type: 'textfield',
          label: t('email'),
          name: 'email'
        },
        {
          type: 'textfield',
          label: t('equipped_stations'),
          name: 'nbDesktops'
        },
        {
          type: 'textfield',
          label: t('meeting_rooms'),
          name: 'nbMeetingRooms'
        },
        {
          type: 'select',
          label: t('community_room'),
          name: 'communityRoom.id',
          formItem: {
            values: center?.meetingsRooms?.map((room) => ({
              id: String(room.id),
              label: room.label
            }))
          } as FormItem
        },
        {
          type: 'centers',
          label: t('cluster'),
          name: 'centerCluster',
          inputProps: {
            defaultIsCenter: false,
            allCenters: false,
            defaultIsCluster: true,
            slug: 'cluster',
            initialValueIsCluster: true
          }
        },
        {
          type: 'checkbox',
          label: t('visible_back_office'),
          name: 'isActive',
          xs: 12
        },
        {
          type: 'checkbox',
          label: t('visible_customer_app'),
          name: 'isActiveFront',
          xs: 12
        }
      ]}
      resolver={centerUpdateConfigurationInput}
    />
  )
}
