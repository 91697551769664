import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { MainService } from 'api/models'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { MainServiceDetails } from 'modules/services/components/main-service-details.component'
import { KeyboardBackspace, Edit } from '@mui/icons-material'
import { Link } from 'app/components/link.component'

export const MainServiceView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getMainService } = useFetcher()
  const { id } = useParams()
  const [mainService, setMainService] = useState<MainService>({} as MainService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(async () => {
    if (!id) return
    setListIsLoading(true)

    try {
      const mainService = await getMainService.mutateAsync(Number(id))
      setMainService(mainService)
    } finally {
      setListIsLoading(false)
    }
  }, [id, getMainService])

  useEffect(() => {
    refreshList().then()
  }, [id])

  return listIsLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/services/main`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('main_services')}</Typography>
            <Link to={`/services/main/${id}/edit`}>
              <Edit fontSize={'small'} />
            </Link>
          </Stack>
        </Grid>
      </Grid>
      <MainServiceDetails mainService={mainService} />
    </Container>
  )
}
