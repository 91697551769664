import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, IconButton, Tooltip, useTheme } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { Stack } from '@mui/system'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { AccessRoom, CenterDetails, Filters } from 'api/models'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { Select } from 'app/components/filters/select'
import { FiltersSkeleton } from 'app/components/skeletons/filters.skeleton'

interface ICenterListAccesCardProps {
  center: CenterDetails
}

export const CenterListAccesCard = ({ center }: ICenterListAccesCardProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { getCenterAccessRooms, getFilters, searchParams, setSearchParams } = useFetcher()
  const { handleMutation } = useFeedback()
  const [items, setItems] = useState<AccessRoom[]>([])
  const [filters, setFilters] = useState<Filters>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getCenterAccessRooms,
      data: {
        id: String(center.id),
        type: searchParams.get('type'),
        status: searchParams.get('status')
      },
      onSuccess: (data) => setItems(data),
      onEnd: () => setIsLoading(false)
    })
  }, [center, searchParams])

  useEffect(() => {
    if (!center || !center.id) return
    refreshList().then()
    handleMutation({
      mutation: getFilters,
      data: { variables: ['rooms_services_type', 'access_status'], params: [] },
      onSuccess: (data) => setFilters(data)
    }).then()
  }, [center])

  const tableMap = useMemo(() => {
    const tableMap = new Map<string, any>()
    tableMap.set('items', [
      {
        value: 'service',
        component: (item: AccessRoom) => {
          return (
            <>
              <CircleIcon
                color={item.isConnected ? 'success' : 'error'}
                fontSize={'small'}
                sx={{ marginBottom: '-4px' }}
              />{' '}
              {item.service}
            </>
          )
        },
        label: t('services')
      },
      {
        value: 'room',
        label: t('rooms')
      }
    ])
    tableMap.set('data', items)
    return tableMap
  }, [items])

  return (
    <Grid container spacing={6} sx={{ marginBottom: theme.spacing(8) }} alignItems={'stretch'}>
      <Grid item xs={12} sm={8} md={7} marginBottom={4} marginTop={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
          {filters?.rooms_services_type ? (
            <Select
              filter={filters?.rooms_services_type}
              onChange={refreshList}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          ) : (
            <FiltersSkeleton size={1} />
          )}
          {filters?.access_status ? (
            <Select
              filter={filters?.access_status}
              onChange={refreshList}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          ) : (
            <FiltersSkeleton size={1} />
          )}
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={5}
        marginBottom={4}
        marginTop={4}
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Tooltip title={t('center_info_list_access')} arrow placement="left-start">
          <IconButton>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ListSheetComponent data={tableMap} isLoading={isLoading}></ListSheetComponent>
      </Grid>
    </Grid>
  )
}
