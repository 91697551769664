import { useTranslation } from 'react-i18next'
import { Box, Grid, Paper as MuiPaper } from '@mui/material'
import type { EnterpriseInformation, EnterpriseOpportunities } from 'api/models'
import { formatDate, formatOpportunityStatus } from 'app/utils/format'
import React, { useCallback, useEffect, useState } from 'react'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { TitleComponent } from 'app/components/titles/title.component'

interface IEnterpriseOpportunities {
  enterprise: EnterpriseInformation
}

export function EnterpriseOpportunitiesComponent({ enterprise }: IEnterpriseOpportunities) {
  const { t } = useTranslation()
  const [items, setItems] = useState([] as EnterpriseOpportunities[])
  const [isLoading, setIsLoading] = useState(false)
  const { orderBy, handleSort, handleFilter } = useList()
  const { getEnterpriseOpportunities } = useFetcher()

  const refreshList = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await getEnterpriseOpportunities.mutateAsync(String(enterprise.id))
      setItems(data.items)
    } finally {
      setIsLoading(false)
    }
  }, [enterprise.id])

  useEffect(() => {
    refreshList()
  }, [refreshList])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('opportunities')} variant={'h3'} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            items={items}
            selectable={false}
            columns={[
              {
                label: '',
                slug: 'status',
                text: 'statusLabel',
                valueFormatter: formatOpportunityStatus
              },
              {
                label: t('reference'),
                slug: 'reference',
                link: { base: '/opportunities', slug: 'id' }
              },
              {
                label: t('city'),
                slug: 'city'
              },
              {
                label: t('due_date'),
                slug: 'dueDate',
                valueFormatter: formatDate
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
