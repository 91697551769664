import { z } from 'zod'

const occupancySchema = z.object({
  id: z.number(),
  capacity: z.number(),
  center: z.string(),
  contractId: z.number().nullable(),
  contractReference: z.string().nullable(),
  enterpriseId: z.number().nullable(),
  enterpriseReference: z.string().nullable(),
  label: z.string(),
  occupants: z.number(),
  surface: z.string(),
  type: z.string()
})

export type Occupancy = z.infer<typeof occupancySchema>

const occupancyListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(occupancySchema)
})

export type OccupancyList = z.infer<typeof occupancyListSchema>

const occupancySumSchema = z.object({
  capacity: z.number(),
  occupants: z.number()
})

export type OccupancySum = z.infer<typeof occupancySumSchema>
