import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { OptionService } from 'api/models'
import { formatCurrency, formatDate, formatYesNo } from 'app/utils/format'
import { useApp } from 'app/providers/app.provider'
import InfoCard from 'app/components/card/info-card.component'
interface IOptionDetailsProps {
  optionService: OptionService
  children?: React.ReactNode
}

export const OptionServiceDetails = ({ optionService }: IOptionDetailsProps) => {
  const { t } = useTranslation()
  const { getLanguages } = useApp()

  const { savedLabels, info } = useMemo(() => {
    let savedLabels = getLanguages().map((language) => ({
      label: language.label,
      value:
        optionService.savedLabels.find((savedLabel) => savedLabel.language.id === language.id)
          ?.label ?? ''
    }))

    const info = [
      {
        label: t('type'),
        value: optionService.serviceTypeLabel
      },
      { label: '', value: '' },
      {
        label: t('installation_costs'),
        value: optionService.isFreeFees
          ? t('free')
          : formatCurrency(Number(optionService.commissioningFees))
      },
      {
        label: t('monthly_price'),
        value: optionService.isFreePrice
          ? t('free')
          : formatCurrency(Number(optionService.monthlyPrice))
      },
      {
        label: t('begin'),
        value: optionService.begin ? formatDate(optionService.begin) : ''
      },
      {
        label: t('end'),
        value: optionService.end ? formatDate(optionService.end) : ''
      },
      {
        label: t('available_online'),
        value: t(formatYesNo(optionService.isOnline))
      }
    ]

    return { savedLabels, info }
  }, [optionService, t])

  return (
    <>
      <InfoCard title={t('label')} columns={savedLabels} />
      <InfoCard title={t('informations')} columns={info} />
    </>
  )
}
