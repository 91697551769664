import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { Button, FormControl, TextField } from '@mui/material'
import React, { RefObject, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Contract } from 'api/models'
import { useNavigate } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'

interface IDialogLitigation {
  dialogRef: RefObject<DialogRef>
  contract: Contract
}

export const DialogLitigation = function ({ dialogRef, contract }: IDialogLitigation) {
  const { t } = useTranslation()
  const [reference, setReference] = useState<string>('')
  const { postLitigationContract } = useFetcher()
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()

  const handleSave = useCallback(async () => {
    if (!contract) return
    await handleMutation({
      confirm: {
        content: t('confirm_litigation_contract')
      },
      mutation: postLitigationContract,
      data: { id: contract.id, reference },
      toastSuccess: t('update_success'),
      toastError: t('error'),
      onSuccess: () => {
        dialogRef.current?.close()
        navigate(0)
      }
    })
  }, [contract, reference])

  if (!dialogRef) return

  return (
    <Dialog
      title={t('put_in_litigation')}
      ref={dialogRef}
      actions={
        <>
          <Button onClick={dialogRef.current?.close}>{t('cancel')}</Button>
          <Button onClick={handleSave} variant={'contained'}>
            {t('update')}
          </Button>
        </>
      }
    >
      <FormControl fullWidth sx={{ marginTop: 7 }}>
        <TextField
          size="small"
          label={t('litigation_reference')}
          onChange={(e) => setReference(e.currentTarget.value)}
        />
      </FormControl>
    </Dialog>
  )
}
