import { useTranslation } from 'react-i18next'
import { Paper as MuiPaper } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { EmailTemplateSheetType } from 'api/models'
import React from 'react'
import { TitleComponent } from 'app/components/titles/title.component'
import { Edit } from '@mui/icons-material'
import { Link } from 'app/components/link.component'

export const EmailTemplatePreview = ({
  emailTemplate
}: {
  emailTemplate: EmailTemplateSheetType
}): React.JSX.Element => {
  const { t } = useTranslation()

  let modifiedHtml = emailTemplate.html
    .replace(/<a\s+href="[^"]*"/g, '<span') // Replace <a> tags opening with <span>
    .replace(/<\/a>/g, '</span>') // Replace closing </a> tags with </span>
    .replace(/<button/g, '<span') // Replace <button with <span
    .replace(/<\/button>/g, '</span>') // Replace closing </button> with </span>
    .replace(/href/g, '') // Replace href only

  return (
    <MuiPaper elevation={0} sx={{ padding: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
          <TitleComponent text={t('preview')} variant={'h3'} paddingTop={0} paddingLeft={12} />
          <Link to={`/emails/${emailTemplate.id}/edit`} style={{ lineHeight: '1em' }}>
            <Edit fontSize={'small'} />
          </Link>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" margin="1rem">
          <div dangerouslySetInnerHTML={{ __html: modifiedHtml }} />
        </Stack>
      </Box>
    </MuiPaper>
  )
}
