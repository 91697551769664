import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  type IdReturn,
  Opportunity,
  OpportunityContracts,
  OpportunityEvents,
  type OpportunityList,
  OpportunityQuotations,
  OpportunityUsers
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'
import { OpportunityUpdateFormProps, QuotationCreateFormData } from 'api/models/forms/opportunities'

export const opportunitiesRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<OpportunityList>({
      url: `${BASE_URL}${API.GET_OPPORTUNITIES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async ({ token }: Ctx, id: string) =>
    fetcher<Opportunity>({
      url: `${BASE_URL}${urlHelper(API.GET_OPPORTUNITY, { id })}`,
      method: 'GET',
      token: token
    }),
  getSolutions: async ({ token }: Ctx, id: string) =>
    fetcher<OpportunityQuotations>({
      url: `${BASE_URL}${urlHelper(API.GET_OPPORTUNITY_SOLUTIONS, { id })}`,
      method: 'GET',
      token: token
    }),
  getUsers: async ({ token }: Ctx, id: string) =>
    fetcher<OpportunityUsers>({
      url: `${BASE_URL}${urlHelper(API.GET_OPPORTUNITY_USERS, { id })}`,
      method: 'GET',
      token: token
    }),
  getContracts: async ({ token }: Ctx, id: string) =>
    fetcher<OpportunityContracts>({
      url: `${BASE_URL}${urlHelper(API.GET_OPPORTUNITY_CONTRACTS, { id })}`,
      method: 'GET',
      token: token
    }),
  getEvents: async ({ token }: Ctx, id: string, params: string) =>
    fetcher<OpportunityEvents>({
      url: `${BASE_URL}${urlHelper(API.GET_OPPORTUNITY_EVENTS, { id })}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  update: async ({ token }: Ctx, id: string, data: OpportunityUpdateFormProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.PATCH_OPPORTUNITY, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  createQuotation: async ({ token }: Ctx, opportunityId: string, data: QuotationCreateFormData) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${urlHelper(API.POST_OPPORTUNITIES_QUOTATION, { id: opportunityId })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  actualPipeline: async ({ token }: Ctx, params: string) =>
    fetcher<any>({
      url: `${BASE_URL}${API.GET_ACTUAL_PIPELINE}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
}
