import { Filter, Filters } from 'api/models'
import { MultipleSelect } from 'app/components/filters/multiple-select'
import { SelectGrouped } from 'app/components/filters/select-grouped'
import { Select } from 'app/components/filters/select'
import { Grid, Skeleton, SxProps, Theme } from '@mui/material'
import { Children, ReactNode, useCallback, useEffect, useState } from 'react'
import { useList } from 'app/providers/list.provider'
import { SetURLSearchParams } from 'react-router-dom'
import { useDebouce } from 'app/hooks/use-debounce'
import { Box } from '@mui/system'
import { OrderByType } from 'app/components/lists/list'

const FilterContainerSkeleton = () => (
  <Skeleton
    sx={{
      height: '2.2rem',
      width: '200px',
      transform: 'scale(1)'
    }}
  />
)

interface IFiltersContainerProps {
  commonFilters?: Map<string, string>
  paramsFilters?: object
  initialOrder?: OrderByType[]
  onChange?: () => void
  children?: ReactNode
  searchParams: URLSearchParams
  setSearchParams: SetURLSearchParams
  groupBy?: string[]
  sx?: SxProps<Theme>
}

export const FiltersContainer = function ({
  commonFilters,
  paramsFilters,
  initialOrder,
  onChange,
  children,
  searchParams,
  setSearchParams,
  groupBy,
  sx
}: IFiltersContainerProps) {
  const { initFilters, filtersList, filtersIsLoading, setFiltersIsLoading, size } = useList()
  const [prevSearchParams, setPrevSearchParams] = useState<URLSearchParams>()

  useEffect(() => {
    setFiltersIsLoading(true)
    initFilters(commonFilters ?? new Map(), paramsFilters, initialOrder).then(() =>
      setFiltersIsLoading(false)
    )
  }, [])

  const fetch = useCallback(() => {
    if (filtersIsLoading) return
    if (
      onChange &&
      (!prevSearchParams ||
        (prevSearchParams?.get('offset') === searchParams.get('offset') &&
          prevSearchParams.toString() !== searchParams.toString()))
    ) {
      onChange()
    }
    setPrevSearchParams(new URLSearchParams(searchParams))
  }, [filtersIsLoading, onChange, searchParams, setSearchParams, prevSearchParams])

  useDebouce(fetch, [searchParams, filtersIsLoading], 150)

  return (
    <Grid container gap={4} marginY="1rem" sx={sx}>
      {filtersIsLoading
        ? Array.from(Array(size)).map((_, i) => <FilterContainerSkeleton key={i} />)
        : Object.keys(filtersList).map((key: any) => {
            const filter = filtersList[key as keyof Filters] as Filter

            if (!filter) return

            if (filter.multiple) {
              return (
                <MultipleSelect
                  key={filter.reference}
                  filter={filter}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                />
              )
            }

            if (groupBy && groupBy.includes(filter.reference)) {
              return (
                <SelectGrouped
                  key={filter.reference}
                  filter={filter}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                />
              )
            }

            return (
              <Select
                key={filter.reference}
                filter={filter}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
            )
          })}
      {filtersIsLoading
        ? Array.from(Array(Children.count(children))).map((_, i) => (
            <FilterContainerSkeleton key={'child_' + i} />
          ))
        : null}
      <Box sx={{ display: filtersIsLoading ? 'none' : 'contents' }}>{children}</Box>
    </Grid>
  )
}
