import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { Key, Visibility, VisibilityOff } from '@mui/icons-material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ResetPasswordInputs, resetPasswordInputsSchema } from 'api/models'
import { appRouter } from 'api'
import { useFeedback } from 'app/providers/feedback.provider'
import { useEffect, useState } from 'react'

export default function NewPasswordForm() {
  const { t } = useTranslation()
  const { toast } = useFeedback()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [searchParams] = useSearchParams()

  const handleClickShowPassword = () => setShowPassword((prev) => !prev)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev)

  const methods = useForm<ResetPasswordInputs>({
    defaultValues: {
      password: '',
      confirmPassword: ''
    },
    mode: 'onChange',
    resolver: zodResolver(resetPasswordInputsSchema)
  })

  useEffect(() => {
    const token = searchParams.get('token')
    if (!token) return navigate('/sign-up')
    methods.setValue('token', token)
  }, [searchParams.get('token')])

  const onSubmit = async ({ password, confirmPassword, token }: ResetPasswordInputs) => {
    if (!token) return
    try {
      await appRouter.auth.resetPassword({ password, confirmPassword, token })
      toast({
        message: t('reset_password_success'),
        variant: 'success'
      })
      return navigate('/sign-in')
    } catch (e: any) {
      toast({
        message: t(e.message ?? 'sign_in_error'),
        variant: 'error'
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField
          label={t('password')}
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          required
          fullWidth
          margin="normal"
          error={!!methods.formState.errors.password}
          helperText={
            methods.formState.errors.password
              ? t(`error_${methods.formState.errors.password?.type}`)
              : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Key />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          {...methods.register('password')}
        />
        <TextField
          label={t('confirm_password')}
          type={showConfirmPassword ? 'text' : 'password'}
          variant="outlined"
          required
          fullWidth
          margin="normal"
          error={!!methods.formState.errors.confirmPassword}
          helperText={
            methods.formState.errors.confirmPassword
              ? t(`error_${methods.formState.errors.confirmPassword?.type}`)
              : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Key />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          {...methods.register('confirmPassword')}
        />
        <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={methods.formState.isSubmitting || !methods.formState.isValid}
        >
          {t('reset_password')}
        </Button>
      </form>
    </FormProvider>
  )
}
