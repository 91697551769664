import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'

const accountingClientSchema = z.object({
  accountingName: z.string(),
  accountingNumber: z.string(),
  companyName: z.string(),
  centerId: z.number(),
  clientType: z.string(),
  createdAt: z.string(),
  id: z.number(),
  isAccounted: z.boolean(),
  isAccountedLabel: z.string(),
  reference: z.string()
})

export type AccountingClient = z.infer<typeof accountingClientSchema>

const accountingClientsList = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(accountingClientSchema)
})

export type AccountingClientsList = z.infer<typeof accountingClientsList>

const accountingDocumentSchema = z.object({
  centerCompanyName: z.string(),
  centerId: z.number(),
  createdAt: z.string(),
  documentTypeLabel: z.string(),
  documentType: z.string(),
  id: z.number(),
  isAccounted: z.boolean(),
  partNumber: z.string(),
  reference: z.string(),
  totalCost: z.number()
})

export type AccountingDocument = z.infer<typeof accountingDocumentSchema>

const accountingDocumentsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(accountingDocumentSchema)
})

export type AccountingDocumentsList = z.infer<typeof accountingDocumentsListSchema>

const accountingPaymentSchema = z.object({
  journalCode: z.string(),
  documentDate: z.string(),
  thirdParty: z.string(),
  accountingAccountNumber: z.string(),
  typeOfPiece: z.string(),
  label: z.string(),
  debitAmount: z.string(),
  creditAmount: z.string(),
  proofNumber: z.string(),
  settlementMethod: z.string(),
  company: z.string(),
  letteringCode: z.string()
})

export type AccountingPayment = z.infer<typeof accountingPaymentSchema>

const accountingPaymentsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(accountingPaymentSchema)
})

export type AccountingPaymentsList = z.infer<typeof accountingPaymentsListSchema>

const matchingTransfersExceptionSchema = z.object({
  id: z.number(),
  updatedAt: z.string(),
  createdAt: z.string(),
  caption: z.string()
})

export type MatchingTransfersException = z.infer<typeof matchingTransfersExceptionSchema>

const matchingTransfersExceptionsList = itemListSchema(matchingTransfersExceptionSchema)

export type MatchingTransfersExceptionsList = z.infer<typeof matchingTransfersExceptionsList>
