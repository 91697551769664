import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Form, useNavigate, useParams } from 'react-router-dom'
import { FormItem, OptionService, OptionServiceBody, optionServiceBodySchema } from 'api/models'
import { Box, Button, Container, Grid, Paper as MuiPaper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import LanguageComponent from 'modules/services/components/languages.component'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import OptionInformationsComponent from 'modules/services/components/option-informations.component'

export const ServiceOptionsAdd = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { createOptionService, getOptionService, getFormItems } = useFetcher()
  const { id } = useParams()
  const [optionService, setOptionService] = useState<OptionService>({} as OptionService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const methods = useForm<OptionServiceBody>({
    mode: 'onChange',
    resolver: zodResolver(
      optionServiceBodySchema.refine((data) => {
        return data.savedLabels.find((sl) => sl.language === 1 && sl.label.trim() !== '')
      })
    )
  })
  const navigate = useNavigate()
  const [formItems, setFormItems] = useState({} as FormItem)
  const { isValid } = methods.formState

  useEffect(() => {
    getData().then()
  }, [])

  const getData = useCallback(async () => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getFormItems,
      data: ['options_services_types'],
      onSuccess: (data) => setFormItems(data.options_services_types as FormItem),
      onEnd: () => setListIsLoading(false)
    })
  }, [getOptionService, id])

  const handleSubmit = useCallback(
    async (data: OptionServiceBody) => {
      await handleMutation({
        mutation: createOptionService,
        data: { data: data },
        confirm: {
          title: t('confirm'),
          content: t('confirm_add_options')
        },
        onSuccess: () => navigate(`/options`)
      })
    },
    [createOptionService, id]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/options`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('add_option')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Form onSubmit={methods.handleSubmit(handleSubmit)} autoComplete="off">
        <MuiPaper sx={{ marginTop: 4, padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <LanguageComponent control={methods.control} service={optionService} />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <OptionInformationsComponent
              type={'option'}
              methods={methods}
              optionService={optionService}
              formItems={formItems}
            />
          )}
        </MuiPaper>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
          {listIsLoading ? (
            <RectangularSkeleton />
          ) : (
            <>
              <Link to={`/options`}>
                <Button type={'button'} variant="outlined" size="small">
                  {t('cancel')}
                </Button>
              </Link>
              <Button
                type={'submit'}
                variant="contained"
                size="small"
                disabled={!isValid}
                onClick={methods.control.handleSubmit(handleSubmit)}
              >
                {t('save')}
              </Button>
            </>
          )}
        </Box>
      </Form>
    </Container>
  )
}
