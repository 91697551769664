import React, { useCallback, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { Box, Container } from '@mui/system'
import { AuthentificationRadius } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'
import { dates, RangePicker } from 'app/components/filters/range-picker'
import { List } from 'app/components/lists/list'
import { formatDateWithTime } from 'app/utils/format'
import { SearchInput } from 'app/components/filters/search-input'
import { FiltersContainer } from 'app/components/filters/filters-container'

export const AuthentificationRadiusViews = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getAuthentificationRadius, setSearchParams, searchParams } = useFetcher()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const [authentificationsRadius, setAuthentificationsRadius] = useState<AuthentificationRadius>([])
  const [commons] = useState<Map<string, string>>(
    new Map<string, string>([['authentification_radius', 'reply']])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      if (searchParams.get('reply') == '0') {
        searchParams.delete('reply')
        setSearchParams(searchParams)
      }
      await handleMutation({
        onStart: () => setListIsLoading(true),
        mutation: getAuthentificationRadius,
        onSuccess: (data) => {
          setIsLast(data.last)
          setOffset(data.actual)
          setTotal(data.total)
          setAuthentificationsRadius(reset ? data.items : (prev) => [...prev, ...data.items])
        },
        onEnd: () => setListIsLoading(false)
      })
    },
    [getAuthentificationRadius, searchParams]
  )

  return (
    <Container>
      <Grid item xs={12}>
        <Box marginBottom="2rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('authentifications_radius')}
          </Typography>
        </Box>
        <Grid>
          <FiltersContainer
            commonFilters={commons}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          >
            <RangePicker
              slug="date"
              label={'date'}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              valueLabel={dates.TODAY}
            />
            <SearchInput
              slug={'client'}
              label={'client'}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
          </FiltersContainer>
        </Grid>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom>
            {authentificationsRadius.length} / {total}
          </Typography>
        </Grid>
        <List
          items={authentificationsRadius}
          columns={[
            { label: t('id'), slug: 'id' },
            { label: t('username'), slug: 'username' },
            {
              label: t('reply'),
              slug: 'reply',
              valueFormatter: (e: string) => (e === 'Access-Accept' ? t('accepted') : t('rejected'))
            },
            { label: t('date'), slug: 'authdate', valueFormatter: formatDateWithTime }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {authentificationsRadius.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
