import { Paper as MuiPaper, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import ReactApexChart from 'react-apexcharts'
import styled from '@emotion/styled'
import { TimelineConfig } from 'app/components/charts/configs/timeline.config'

export const ThemedChart = styled(ReactApexChart)`
  & .apexcharts-svg {
    background: none !important;
  }

  & .apexcharts-legend-text,
  & .apexcharts-text {
    fill: ${({ theme }) => theme.palette.text.primary} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;
  }

  .apexcharts-tooltip {
    border: none !important;
    .timeline-tooltip-container {
      padding: 0;
      border: 0;

      .timeline-tooltip-title {
        margin: 0;
        padding: 10px 0;
        font-weight: bold;
      }
      .timeline-tooltip-x {
        font-weight: bold;
      }
      .timeline-tooltip-content {
        margin: 0;
        padding: 10px;
        p {
          margin: 0;
        }
      }
    }
  }

  & .apexcharts-gridlines-horizontal line,
  & .apexcharts-gridlines-vertical line,
  & .apexcharts-xaxis-tick,
  & .apexcharts-grid-borders line {
    stroke: ${({ theme }) => theme.palette.background.default} !important;
  }

  & .apexcharts-rangebar-area {
    cursor: pointer;
  }
`

export interface RangeProps {
  start: number
  end: number
}
interface TimelineProps {
  period?: 'year' | 'months' | 'days' | 'hours' | null
  ranges?: RangeProps
  series: any
  categories: string[]
  onItemClick?: (data: any) => void
}

const getChartHeight = (categories: string[]) => {
  const cellSize = 32
  return categories.length * cellSize + 80
}
const allEmpty = (series: any[]) => !series || series.every((s: any) => s.data.length == 0)

function Timeline({ series, categories, ranges, onItemClick }: TimelineProps) {
  const theme = useTheme()
  const ref = useRef<any>(null)
  const options = useMemo(() => TimelineConfig, [])

  const handleClick = useCallback(
    (e: any, { w }: any, { dataPointIndex, seriesIndex }: any) => {
      if (onItemClick) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
        onItemClick(data)
      }
      return e
    },
    [onItemClick]
  )

  useEffect(() => {
    if (!ref.current) return

    const isEmpty = allEmpty(series)

    ref.current?.chart.updateOptions({
      labels: categories ?? [],
      chart: {
        height: categories ? getChartHeight(categories) : 'auto',
        events: {
          dataPointSelection: handleClick
        }
      },
      series: isEmpty ? [] : series,
      theme: {
        palette: 'palette3',
        mode: theme.palette.mode
      },
      yaxis: {
        show: !!categories && categories.length > 0
      },
      xaxis: {
        min: ranges ? ranges.start : undefined,
        max: ranges ? ranges.end : undefined
      },
      colors: [
        theme.palette.grey['200'],
        theme.palette.secondary.light,
        theme.palette.secondary.dark,
        theme.palette.primary.dark,
        theme.palette.primary.light + 'b0'
      ]
    })
  }, [ref, ranges, theme, series, categories])

  const timeline = useMemo(() => {
    return <ThemedChart ref={ref} options={options} series={[]} type="rangeBar" />
  }, [])

  return <MuiPaper sx={{ marginTop: 4 }}>{timeline}</MuiPaper>
}

export default Timeline
