import React, { RefObject, useCallback, useEffect, useState } from 'react'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Litigation } from 'api/models'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'

export const ModalLitigationEdit = function ({
  litigation,
  dialogRef,
  onSuccess
}: {
  litigation: Litigation
  dialogRef: RefObject<DialogRef>
  onSuccess: any
}) {
  const [litigationReference, setLitigationReference] = useState<string>(litigation.reference ?? '')
  const { t } = useTranslation()
  const { patchLitigation } = useFetcher()
  const { handleMutation } = useFeedback()

  const handleLitigation = useCallback(async () => {
    if (!litigation) return
    await handleMutation({
      confirm: {
        content: t('confirm_litigation_update')
      },
      mutation: patchLitigation,
      data: { id: litigation.id, reference: litigationReference },
      toastSuccess: t('litigation_updated'),
      toastError: t('error'),
      onSuccess: () => {
        if (onSuccess) onSuccess()
      },
      onEnd: () => {
        dialogRef.current?.close()
      }
    })
  }, [litigationReference, litigation])

  useEffect(() => {
    setLitigationReference(litigation.reference)
  }, [litigation])

  return (
    <Dialog
      ref={dialogRef}
      title={t('litigation')}
      actions={
        <>
          <Button onClick={dialogRef.current?.close} variant={'outlined'}>
            {t('cancel')}
          </Button>
          <Button onClick={handleLitigation} variant={'contained'}>
            {t('update')}
          </Button>
        </>
      }
    >
      <Stack alignItems={'center'} marginTop={5}>
        <TextField
          label={t('litigation_reference')}
          variant="outlined"
          fullWidth
          value={litigationReference}
          onChange={(e) => setLitigationReference(e.target.value)}
        />
        <Typography style={{ opacity: 0.7, marginTop: 2 }}>{t('optional_reference')}</Typography>
      </Stack>
    </Dialog>
  )
}
