import React, { useCallback, useEffect, useState } from 'react'
import BaseModal from 'app/components/modals/base.modal'
import { Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { Percent } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'

interface IProps {
  openActive: boolean
  setOpenActive: (open: boolean) => void
  refreshList: (reset: boolean) => Promise<void>
}

export const AddDiscountView = ({
  openActive,
  setOpenActive,
  refreshList
}: IProps): React.JSX.Element => {
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState<boolean>(true)
  const { Discount } = useFetcherV2()
  const [found, setFound] = useState<boolean>()

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      const formData = getFormData(e.target)

      const roles = {
        admin: Number(formData.admin),
        siteDirector: Number(formData.siteDirector),
        rxp: Number(formData.rxp),
        direction: Number(formData.direction),
        commercial: Number(formData.commercial),
        tls: Number(formData.tls)
      }
      const formattedData = {
        label: String(formData.label),
        roles: [roles]
      }

      await Discount.create(formattedData)
      setOpenActive(false)
      await refreshList(true)
    },
    [setOpenActive, refreshList, Discount]
  )

  const getFormData = useCallback((form: HTMLFormElement) => {
    const formData = Object.fromEntries(new FormData(form).entries())
    for (const key in formData) {
      let value = formData[key]
      if (key !== 'label' && value !== '') {
        // @ts-ignore
        formData[key] = parseInt(value)
      }
    }
    return formData
  }, [])

  const handleExist = useCallback(async (e: any) => {
    const value = e.target.value

    if (value !== '') {
      const response = await Discount.exist(value.trim())
      setFound(response.found)
      handleValidation(e, response.found)
    }
  }, [])

  const handleValidation = useCallback((e: any, found?: boolean) => {
    if (found) {
      setDisabled(true)
      return
    }
    const form = e.target.form
    const formData = getFormData(form)

    const isValid = Object.values(formData).every((value, index) => {
      if (index === 0) {
        return typeof value === 'string' && value !== ''
      } else {
        return value !== '' && typeof value === 'number' && value >= 0 && value <= 100
      }
    })
    setDisabled(!isValid)
  }, [])

  useEffect(() => {
    if (!openActive) {
      setFound(undefined)
    }
  }, [openActive])

  return (
    <BaseModal
      open={openActive}
      setOpen={setOpenActive}
      title={'add_reduction'}
      handleUpdate={handleSubmit}
      labelButton={'save'}
      size={'sm'}
      disabled={disabled}
    >
      <Grid container columns={12}>
        <Grid container item sx={{ marginBottom: 5 }}>
          <Grid item xs={12} sm={2} marginY={'auto'}>
            <Typography fontWeight={'bold'}>{t('discount_name')}</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <TextField
              error={found}
              helperText={found ? t('discount_name_already_exist') : undefined}
              required={true}
              name={'label'}
              size={'small'}
              fullWidth
              onBlur={handleExist}
              onChange={() => setDisabled(true)}
            />
          </Grid>
        </Grid>
        <Grid container item marginTop={10} marginBottom={15}>
          <Grid item xs={12}>
            <Typography variant={'h3'}>{t('discount_rate')}</Typography>
          </Grid>
        </Grid>
        {['admin', 'siteDirector', 'rxp', 'direction', 'commercial', 'tls'].map((name, index) => (
          <Grid container item sx={{ marginBottom: 5 }} key={index}>
            <Grid item xs={12} sm={2} marginY={'auto'}>
              <Typography fontWeight={'bold'}>{t(name)}</Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                required={true}
                type={'number'}
                name={name}
                InputProps={{
                  inputProps: { min: 0, max: 100 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent fontSize={'small'} />
                    </InputAdornment>
                  )
                }}
                size={'small'}
                fullWidth
                onChange={(e) => handleValidation(e, found)}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </BaseModal>
  )
}
