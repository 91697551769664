import React from 'react'
import { Card, Chip } from '@mui/material'
import { CollapsableItem } from 'app/components/card/collapsable-item.component'
import { Stack } from '@mui/system'
import { ChipColorLinkActiveComponent } from 'app/components/chip/chip-color-link-active.component'
import { AccessGroup } from 'api/models'

interface ICenterGroupAccess {
  accessGroup: AccessGroup
}

export const CenterGroupAccesCardItem = ({
  accessGroup
}: ICenterGroupAccess): React.JSX.Element => {
  return (
    <>
      <Card sx={{ marginBottom: 6 }}>
        <CollapsableItem
          label={accessGroup.groupName}
          extra={
            <>
              <ChipColorLinkActiveComponent
                label={accessGroup.readers.length}
                size={'small'}
                sx={{ marginLeft: 3 }}
              />
            </>
          }
          depth={0}
        >
          <CollapsableItem
            label={''}
            depth={1}
            extra={
              <>
                <Stack direction="row" gap={2} flexWrap={'wrap'}>
                  {accessGroup.readers.map((reader) => (
                    <Chip
                      key={reader.readerIndex}
                      label={reader.readerName}
                      size={'small'}
                      sx={{ fontWeight: 'normal' }}
                    />
                  ))}
                </Stack>
              </>
            }
          />
        </CollapsableItem>
      </Card>
    </>
  )
}
