import styled from '@emotion/styled'
import { Box as MuiBox } from '@mui/material'

const Box = styled(MuiBox)`
  img {
    width: ${(props) => props.theme.sidebar.header.brand.size.width};
    height: ${(props) => props.theme.sidebar.header.brand.size.height};
    &.login,
    &.loginDarkMode {
      margin: 0 auto;
      margin-bottom: ${(props) => props.theme.spacing(20)};
      margin-top: ${(props) => props.theme.sidebar.header.brand.marginTop};
      display: block;
      scale: ${(props) => props.theme.sidebar.header.brand.scale};
    }
    &.loginDarkMode {
      color: ${(props) => props.theme.sidebar.header.brand.color};
      fill: ${(props) => props.theme.sidebar.header.brand.color};
    }
  }
`

interface ILogoProps {
  src: string
  alt?: string
  className?: string
}

export const LogoComponent = ({ src, alt, className }: ILogoProps): React.JSX.Element => {
  return (
    <Box>
      <img src={src} alt={alt} className={className} />
    </Box>
  )
}
