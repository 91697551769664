import { Box } from '@mui/system'
import { Stage, Layer, Line, Image, Tag, Label, Text } from 'react-konva'
import React, { useRef, useState, useCallback, useMemo, RefObject } from 'react'
import { ContractFloorPlans, ServiceShapes } from 'api/models'
import Konva from 'konva'
import InformationsComponent from 'modules/floorplans/components/informations.component'

interface IProps {
  shapes: ServiceShapes
  image: HTMLImageElement
  displaySurface: boolean
  displayPrice: boolean
  displayName: boolean
  displayContract: string
  selectedActiveContract: null | string
  setSelectedActiveContract: (e: any) => void
  selectedContract: ContractFloorPlans
  setDisplayContract: (e: any) => void
  setSelectedContract: (e: any) => void
}

const KonvaComponent = ({
  shapes,
  image,
  displaySurface,
  selectedActiveContract,
  setSelectedActiveContract,
  displayPrice,
  displayName,
  displayContract,
  selectedContract,
  setDisplayContract,
  setSelectedContract
}: IProps) => {
  const layerBackShapeRef = useRef(null)
  const layerLabelShapeRef = useRef(null)
  const layerSurfaceShapeRef = useRef(null)
  const layerPriceShapeRef = useRef(null)
  const layerNameShapeRef = useRef(null)
  const [stageScale, setStageScale] = useState(1)
  const [stageX, setStageX] = useState(0)
  const [stageY, setStageY] = useState(0)
  const init = useMemo(
    () => ({
      width: 1220,
      height: 1220
    }),
    []
  )
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
  const [isTooltipVisible, setIsTooltipVisble] = useState(false)
  const [tooltipText, setTooltipText] = useState('')

  const handleWheel = (e: any) => {
    e.evt.preventDefault()
    const scaleBy = 1.02
    const stage = e.target.getStage()
    const oldScale = stage.scaleX()

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    }

    const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy
    if (newScale <= 1) {
      return
    }

    let x = -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale
    let y = -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale

    const pos = boundFunc({ x, y }, newScale)

    setStageScale(newScale)
    setStageX(pos.x)
    setStageY(pos.y)
  }

  const boundFunc = useCallback((pos: any, scale: number) => {
    const x = Math.min(0, Math.max(pos.x, init.width * (1 - scale)))
    const y = Math.min(0, Math.max(pos.y, init.height * (1 - scale)))

    return {
      x,
      y
    }
  }, [])

  const getOffsetYForButton = useCallback(
    (type: string) => {
      switch (type) {
        case 'displaySurface':
          return -13
        case 'displaySquareMeterPrice':
          if (displaySurface) {
            return -26
          } else {
            return -13
          }
        case 'displayClientname':
          if (displaySurface && displayPrice) {
            return -39
          } else if (displaySurface || displayPrice) {
            return -26
          } else {
            return -13
          }
        default:
          return -13
      }
    },
    [displaySurface, displayPrice]
  )

  const handleShapeClick = (event: any) => {
    const contract = event.target.attrs.custom.shape.contract
    if (contract.id !== null) {
      setSelectedContract(contract)
      setSelectedActiveContract(contract.id)
      setDisplayContract('0')
      return
    }
    setSelectedActiveContract('0')
    setDisplayContract('-500px')
  }

  const updateFillColor = (layerRef: RefObject<Konva.Layer>, shape: any, isHover = true) => {
    if (layerRef.current) {
      const layer = layerRef.current as any
      layer.children.forEach((child: any) => {
        if (child.attrs.custom.shape.id === shape.id) {
          if (child.children && child.children[0]) {
            child.children[0].attrs.fill = colorShape(shape, isHover)
          } else {
            child.attrs.fill = colorShape(shape, isHover)
          }
        }
      })
    }
  }

  const handleShapeEnter = (evt: any) => {
    const shape = evt.target.attrs.custom.shape
    const pointsArray = shape.points.split(',')
    const yTmp = Number(pointsArray[1]) - 50
    const x = Number(pointsArray[0])
    let y = yTmp < 35 ? 35 : yTmp
    let text = `${shape.serviceLabel}\n${shape.serviceTypeLabel}\n${shape.serviceSurface} m²\n${shape.serviceCapacity} pers`

    if (shape.contract.id !== null) {
      text += `\n${shape.contract.reference}\n${shape.contract.socialReason}`
      y += -25
    }
    if (shape.contract.id !== null && shape.contract.type !== 5) {
      text += `\n${shape.contract.smPrice} €/m²`
      y += -10
    }

    updateFillColor(layerBackShapeRef, shape)
    updateFillColor(layerLabelShapeRef, shape)
    updateFillColor(layerSurfaceShapeRef, shape)
    updateFillColor(layerPriceShapeRef, shape)
    updateFillColor(layerNameShapeRef, shape)

    setTooltipText(text)
    setTooltipPosition({ x: x, y: y })
    setIsTooltipVisble(true)
  }

  const handleShapeLeave = (evt: any) => {
    const shape = evt.target.attrs.custom.shape
    updateFillColor(layerBackShapeRef, shape, false)
    updateFillColor(layerLabelShapeRef, shape, false)
    updateFillColor(layerSurfaceShapeRef, shape, false)
    updateFillColor(layerPriceShapeRef, shape, false)
    updateFillColor(layerNameShapeRef, shape, false)

    setTooltipText('')
    setTooltipPosition({ x: 0, y: 0 })
    setIsTooltipVisble(false)
  }

  const colorShape = (shape: any, isHover = false): string => {
    // contrat sélectionné
    if (selectedActiveContract && selectedActiveContract == shape.contract.id) {
      return '#40C140'
    }

    // contrat sur le bureau de type conso
    if (shape.contract.id !== null && shape.contract.type === 5) {
      return isHover ? '#992bd0' : '#b361dc'
    }

    if (shape.contract.id !== null) {
      return isHover ? '#D05E2B' : '#dc8661'
    }

    // bureau de type bureau
    if (shape.serviceType === 'FULL_DESKTOP') {
      return isHover ? '#16aac4' : '#6bbecc'
    }
    // bureau de type salle de réunion
    if (shape.serviceType === 'MEETING') {
      return isHover ? '#f3b10b' : '#ffc25d'
    }
    return isHover ? '#16aac4' : '#6bbecc'
  }

  return (
    <Box id="container" style={{ position: 'relative', overflow: 'hidden' }}>
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        container={'container'}
        draggable
        scale={{ x: stageScale, y: stageScale }}
        x={stageX}
        y={stageY}
        onWheel={handleWheel}
      >
        {/*couleur des bureaux*/}
        <Layer ref={layerBackShapeRef}>
          {shapes.map((shape, i) => {
            const points = shape.points.split(',').map((strNum) => parseInt(strNum.trim(), 10))
            const color = colorShape(shape)

            return <Line key={i} points={points} custom={{ shape }} closed={true} fill={color} />
          })}
        </Layer>
        {/*image de fond*/}
        <Layer>
          <Image
            x={0}
            y={0}
            image={image}
            width={init.width}
            height={init.height}
            index={0}
          ></Image>
        </Layer>
        {/*label des bureaux*/}
        <Layer ref={layerLabelShapeRef}>
          {shapes.map((shape, i) => {
            const pointsArray = shape.points.split(',')
            const serviceLabel = shape.serviceLabel.replace('CORPOWORKING', 'CORPO ')
            const color = colorShape(shape)

            return (
              <Label
                key={i}
                x={Number(pointsArray[0])}
                y={Number(pointsArray[1])}
                visible={true}
                listening={true}
                custom={{ shape }}
              >
                <Tag fill={color} cornerRadius={0} textBaseline={'bottom'}></Tag>
                <Text
                  text={serviceLabel}
                  fontFamily={'Arial'}
                  fontSize={11}
                  padding={2}
                  fill={'#FFF'}
                  align={'center'}
                  name={'konva-text'}
                ></Text>
              </Label>
            )
          })}
        </Layer>
        {/*surface des bureaux*/}
        <Layer ref={layerSurfaceShapeRef}>
          {shapes.map((shape, i) => {
            const pointsArray = shape.points.split(',')
            const color = colorShape(shape)

            return (
              <Label
                key={i}
                x={Number(pointsArray[0])}
                y={Number(pointsArray[1])}
                offsetX={0}
                offsetY={getOffsetYForButton('displaySurface')}
                visible={displaySurface}
                listening={true}
                custom={{ shape }}
              >
                <Tag fill={color} cornerRadius={0} textBaseline={'bottom'}></Tag>
                <Text
                  text={Math.round(shape.serviceSurface) + 'm² '}
                  fontFamily={'Arial'}
                  fontSize={9}
                  padding={2}
                  fill={'white'}
                  align={'left'}
                  name={'konva-text'}
                ></Text>
              </Label>
            )
          })}
        </Layer>
        {/*prix d'un bureau avec contrat*/}
        <Layer ref={layerPriceShapeRef}>
          {shapes.map((shape, i) => {
            const pointsArray = shape.points.split(',')
            const color = colorShape(shape)
            return (
              <Label
                key={i}
                x={Number(pointsArray[0])}
                y={Number(pointsArray[1])}
                offsetX={0}
                offsetY={getOffsetYForButton('displaySquareMeterPrice')}
                visible={shape.contract.id !== null && displayPrice && shape.contract?.type !== 5}
                listening={true}
                custom={{ shape }}
              >
                <Tag fill={color} cornerRadius={0} textBaseline={'bottom'}></Tag>
                <Text
                  text={Math.round(Number(shape.contract.smPrice)) + ' €/m² '}
                  fontFamily={'Arial'}
                  fontSize={9}
                  padding={2}
                  fill={'white'}
                  align={'left'}
                  name={'konva-text'}
                ></Text>
              </Label>
            )
          })}
        </Layer>
        {/*nom d'un bureau avec contrat*/}
        <Layer ref={layerNameShapeRef}>
          {shapes.map((shape, i) => {
            const pointsArray = shape.points.split(',')
            const color = colorShape(shape)

            let text = ''
            if (shape.contract.id !== null) {
              const clientname = shape.contract.socialReason

              if (clientname !== null) {
                if (clientname.length > 8) {
                  text = clientname.substring(0, 8)
                } else {
                  text = clientname
                }
              }
            }

            return (
              <Label
                key={i}
                x={Number(pointsArray[0])}
                y={Number(pointsArray[1])}
                offsetX={0}
                offsetY={getOffsetYForButton('displayClientname')}
                visible={shape.contract.id !== null && displayName}
                listening={true}
                custom={{ shape }}
              >
                <Tag fill={color} cornerRadius={0} textBaseline={'bottom'}></Tag>
                <Text
                  text={text}
                  fontFamily={'Arial'}
                  fontSize={9}
                  padding={2}
                  fill={'white'}
                  align={'left'}
                  name={'konva-text'}
                ></Text>
              </Label>
            )
          })}
        </Layer>
        {/*evenements des shapes*/}
        <Layer>
          {shapes.map((shape, i) => {
            const points = shape.points.split(',').map((strNum) => parseInt(strNum.trim(), 10))

            return (
              <Line
                key={i}
                points={points}
                stroke="black"
                strokeWidth={0.5}
                custom={{ shape }}
                closed={true}
                onClick={handleShapeClick}
                onMouseEnter={handleShapeEnter}
                onMouseLeave={handleShapeLeave}
              />
            )
          })}
        </Layer>
        {/*informations sur le bureau*/}
        <Layer>
          <Label
            visible={isTooltipVisible}
            listening={false}
            x={tooltipPosition.x}
            y={tooltipPosition.y}
          >
            <Tag
              fill={'white'}
              fontFamily={"'Roboto'"}
              stroke={'#888'}
              strokeWidth={0.5}
              padding={2}
              cornerRadius={2}
              lineJoin={'round'}
              shadowColor={'black'}
              shadowBlur={5}
              shadowOffsetX={3}
              shadowOffsetY={5}
              shadowOpacity={0.1}
            ></Tag>
            <Text
              text={tooltipText}
              fontFamily={'Roboto'}
              fontSize={12}
              padding={5}
              fill={'black'}
              opacity={1}
            ></Text>
          </Label>
        </Layer>
      </Stage>
      <InformationsComponent
        selectedContract={selectedContract}
        setDisplayContract={setDisplayContract}
        displayContract={displayContract}
      />
    </Box>
  )
}

export default KonvaComponent
