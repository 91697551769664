import { FormProvider, UseFormReturn } from 'react-hook-form'
import * as React from 'react'
import { FormItem, QuotationSolution, SolutionCommitment } from 'api/models'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { ControlledCheckboxField } from 'app/components/form/controlled-checkbox.component'
import { ControlledSelectField } from 'app/components/form/controlled-select.component'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import {
  formatCurrency,
  formatDate,
  formatDateWithFormat,
  formatPercentage
} from 'app/utils/format'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import styled from '@emotion/styled'
import { useMemo } from 'react'

const TypographyWithBorder = styled(Typography)`
  border-left: 3px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  padding: 12px;
`
const FormEditSolution = ({
  commitments,
  methods,
  solution,
  prices,
  selectedCommitment
}: {
  commitments: FormItem
  methods: UseFormReturn<any>
  solution: QuotationSolution
  prices: any
  selectedCommitment: SolutionCommitment | null
}) => {
  const { t } = useTranslation()

  const { min, max } = useMemo(() => {
    const { begin } = methods.getValues()
    return {
      min: begin
        ? begin
            .clone()
            .add(selectedCommitment?.min ?? 1, 'month')
            .startOf('month')
        : undefined,
      max: begin
        ? begin
            .clone()
            .add(selectedCommitment?.max ?? 1, 'month')
            .endOf('month')
        : undefined
    }
  }, [methods.watch('begin'), selectedCommitment])

  return (
    <FormProvider {...methods}>
      <Grid container columns={12} columnSpacing={4} padding={4}>
        <Grid container columns={12} columnSpacing={3} rowSpacing={6} alignItems={'center'}>
          <Grid item xs={12}>
            <ControlledCheckboxField
              label={t('pricing_without_degressivity')}
              name={'isPriceDegressive'}
              control={methods.control}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={'bold'}>{t('commitment')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <ControlledSelectField
              formItem={commitments}
              label={''}
              name={'commitment'}
              control={methods.control}
            />
          </Grid>
          <Grid item xs={4}>
            <TypographyWithBorder>{solution.commitmentLabel}</TypographyWithBorder>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={'bold'}>{t('begin')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <ControlledDatePicker control={methods.control} name={'begin'} />
          </Grid>
          <Grid item xs={4}>
            <TypographyWithBorder>{formatDate(solution.begin)}</TypographyWithBorder>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={'bold'}>{t('end')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <ControlledDatePicker
              control={methods.control}
              name={'end'}
              views={['month', 'year']}
              minDate={min}
              maxDate={max}
            />
          </Grid>
          <Grid item xs={4}>
            <TypographyWithBorder>
              {formatDateWithFormat(solution.deadline, 'MMMM YYYY')}
            </TypographyWithBorder>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={'bold'}>{t('reduction')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <ControlledTextField
              type={'number'}
              control={methods.control}
              inputProps={{
                min: 0,
                max: 100
              }}
              name={'discount'}
            />
          </Grid>
          <Grid item xs={4}>
            <TypographyWithBorder>{formatPercentage(solution.reduction)}</TypographyWithBorder>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={'bold'}>{t('price')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {formatCurrency(prices.find((p: any) => p.service === 'total')?.priceReduced ?? 0)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TypographyWithBorder>
              {solution.reduction > 0
                ? formatCurrency(solution.reductionPrice)
                : formatCurrency(solution.price)}
            </TypographyWithBorder>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default FormEditSolution
