import React from 'react'
import { Box, Skeleton, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PriceScalesDrawerComponent from 'modules/pricers/components/price-scales-drawer-component'
import FormOpportunityDrawerComponent from 'modules/pricers/components/form-opportunity-drawer.component'
import { usePricer } from 'app/providers/pricer.provider'

type IPricerDrawerComponentProps = {}

const PricerDrawerComponent: React.FC<IPricerDrawerComponentProps> = ({}) => {
  const { t } = useTranslation()
  const { isLoadingServices } = usePricer()
  const theme = useTheme()
  return (
    <Box sx={{ padding: theme.spacing(4) }}>
      {isLoadingServices ? (
        <Skeleton variant="rectangular" width="100%" height={400} />
      ) : (
        <>
          <PriceScalesDrawerComponent />
          <FormOpportunityDrawerComponent />
        </>
      )}
    </Box>
  )
}

export default PricerDrawerComponent
