import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { AddCircle, Euro } from '@mui/icons-material'

import type { ConsumptionItem } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatCurrency } from 'app/utils/format'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import { useApp } from 'app/providers/app.provider'
import { useNavigate } from 'react-router-dom'

export const ConsumptionsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const { getConsumptions, searchParams, setSearchParams } = useFetcher()
  const [consumptions, setConsumptions] = useState<ConsumptionItem[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['consumption_type', 'type'],
      ['consumption_status', 'status']
    ])
  )

  const { getRight } = useApp()
  const navigate = useNavigate()

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      try {
        const data = await getConsumptions.mutateAsync()
        setOffset(data.actual)
        setIsLast(data.last)
        setTotal(data.total)
        setConsumptions(reset ? data.items : (prev) => [...prev, ...data.items])
      } finally {
        setListIsLoading(false)
      }
    },
    [getConsumptions, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters).then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('consumptions_list')}
          {getRight('catalog_consumption', 'isAdd') && (
            <IconButton
              title={t('add_options')}
              aria-label={t('add_options')}
              color="primary"
              onClick={() => navigate('/services/consumptions/add')}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          )}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        ></FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {consumptions.length} / {total}
          </Typography>
        </Grid>
        <List
          items={consumptions}
          columns={[
            { label: t('label'), slug: 'label', link: { base: '/consumptions', slug: 'id' } },
            { label: t('type'), slug: 'type' },
            {
              label: Euro,
              tooltip: t('price'),
              slug: 'price',
              valueFormatter: formatCurrency
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {consumptions.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
