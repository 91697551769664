import {
  Business,
  Home,
  Circle,
  Balance,
  FactorySharp,
  BuildSharp,
  MonetizationOn,
  AccountBalance,
  Person,
  PersonalVideo,
  Groups,
  QueryStats,
  Source,
  Wifi,
  Settings,
  ContactPhone,
  CalendarMonth,
  Badge
} from '@mui/icons-material'
import { Permissions } from 'api/models'

export type MenuItemsTypes = {
  href: string
  title: string
  permissions: Permissions[]
  icon?: any
  badge?: string
  children?: MenuItemsTypes[]
}

export const firstSection: MenuItemsTypes[] = [
  {
    href: '/',
    icon: Home,
    title: 'dashboard',
    permissions: ['home.isDisplay']
  },
  {
    href: '/test-with-children',
    icon: FactorySharp,
    title: 'companies',
    permissions: ['client.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/enterprises',
        title: 'enterprises_list',
        permissions: ['client.isDisplay']
      },
      {
        icon: Circle,
        href: '/individuals',
        title: 'individuals_list',
        permissions: ['client.isDisplay']
      },
      {
        icon: Circle,
        href: '/opportunities',
        title: 'opportunities_list',
        permissions: ['opportunity.isDisplay']
      },
      {
        icon: Circle,
        href: '/quotations',
        title: 'quotations_list',
        permissions: ['quotation.isDisplay']
      },
      {
        icon: Circle,
        href: '/contracts',
        title: 'contracts_list',
        permissions: ['contract.isDisplay']
      },
      {
        icon: Circle,
        href: '/contracts/options',
        title: 'options_list',
        permissions: ['contract.isDisplay']
      },
      {
        icon: Circle,
        href: '/contract-consumptions',
        title: 'consumptions_list',
        permissions: ['contract.isDisplay']
      },
      {
        icon: Circle,
        href: '/checkouts',
        title: 'checkouts_list',
        permissions: ['checkout.isDisplay']
      },
      {
        icon: Circle,
        href: '/indexing',
        title: 'indexing_list',
        permissions: ['indexing.isDisplay']
      },
      {
        icon: Circle,
        href: '/endorsements',
        title: 'endorsements_list',
        permissions: ['contract.isDisplay']
      },
      {
        icon: Circle,
        href: '/enterprises/duplicate',
        title: 'duplicate_enterprises_list',
        permissions: ['enterprise_duplicate.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: BuildSharp,
    title: 'customer_service',
    permissions: ['clientservice.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/clientrequests',
        title: 'customer_service',
        permissions: ['clientservice.isDisplay']
      },
      {
        icon: Circle,
        href: '/customer_reservations',
        title: 'customer_reservations',
        permissions: ['customer_reservation.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: Business,
    title: 'services',
    permissions: ['service.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/plannings/services',
        title: 'services_planning',
        permissions: ['schedule.isDisplay']
      },
      {
        icon: Circle,
        href: '/services/main',
        title: 'main_services',
        permissions: ['catalog_service.isDisplay']
      },
      {
        icon: Circle,
        href: '/services/parking',
        title: 'parking_services',
        permissions: ['catalog_parking.isDisplay']
      },
      {
        icon: Circle,
        href: '/options',
        title: 'options_list',
        permissions: ['catalog_option.isDisplay']
      },
      {
        icon: Circle,
        href: '/consumptions',
        title: 'consumptions_list',
        permissions: ['catalog_consumption.isDisplay']
      },
      {
        icon: Circle,
        href: 'services/occupancy',
        title: 'services_occupancy_list',
        permissions: ['invoice.isDisplay']
      },
      {
        icon: Circle,
        href: 'floorplans',
        title: 'floor_plan',
        permissions: ['floor_plan.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: MonetizationOn,
    title: 'financial_services',
    permissions: ['invoice.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/invoices',
        title: 'invoices_list',
        permissions: ['invoice.isDisplay']
      },
      {
        icon: Circle,
        href: '/credits',
        title: 'credits_list',
        permissions: ['credit.isDisplay']
      },
      {
        icon: Circle,
        href: '/litigations',
        title: 'litigations_list',
        permissions: ['litigation.isDisplay']
      },
      {
        icon: Circle,
        href: '/mandates',
        title: 'mandates_list',
        permissions: ['paymentmethod.isDisplay']
      },
      {
        icon: Circle,
        href: '/payments',
        title: 'payments_list',
        permissions: ['payment.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: Balance,
    title: 'accounting',
    permissions: ['invoice.isDisplay', 'accounting_client.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/accounting_documents',
        title: 'accounting_documents_list',
        permissions: ['accounting_document.isDisplay']
      },
      {
        icon: Circle,
        href: '/accountingclients',
        title: 'accounting_clients_list',
        permissions: ['accounting_client.isDisplay']
      },
      {
        icon: Circle,
        href: '/accountingpayments',
        title: 'accounting_payments_list',
        permissions: ['accounting_payment.isDisplay']
      },
      {
        icon: Circle,
        href: '/accountingvariousoperations',
        title: 'accounting_various_operations_list',
        permissions: ['accounting_variousoperation.isDisplay']
      },
      {
        icon: Circle,
        href: '/matchingtransfersexception',
        title: 'transfers_exceptions_list',
        permissions: ['accounting_matchingtransfersexception.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: AccountBalance,
    title: 'rights',
    permissions: ['right.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/rights',
        title: 'rights_list',
        permissions: ['right.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: Groups,
    title: 'members',
    permissions: ['event.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/staffs',
        title: 'staffs_list',
        permissions: ['staff.isDisplay']
      },
      {
        icon: Circle,
        href: '/events',
        title: 'events_list',
        permissions: ['event.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: Person,
    title: 'prescribers',
    permissions: ['prescriber.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/prescribers',
        title: 'prescribers_list',
        permissions: ['prescriber.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: ContactPhone,
    title: 'contacts',
    permissions: ['contacts.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/contacts',
        title: 'contacts_list',
        permissions: ['contacts.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: Source,
    title: 'sources',
    permissions: ['source.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/sources',
        title: 'sources_list',
        permissions: ['source.isDisplay']
      },
      {
        icon: Circle,
        href: '/sources/acquisition-costs',
        title: 'acquisition_costs',
        permissions: ['acquisition_cost.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: Settings,
    title: 'global_settings',
    permissions: ['center.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/tertiaries',
        title: 'ilat_list_title',
        permissions: ['ilat.isDisplay']
      },
      {
        icon: Circle,
        href: '/centerprices',
        title: 'center_price_list',
        permissions: ['centerprice.isDisplay']
      },
      {
        icon: Circle,
        href: '/clusters',
        title: 'centers_clusters_list',
        permissions: ['center_cluster.isDisplay']
      },
      {
        icon: Circle,
        href: '/centers',
        title: 'centers_list',
        permissions: ['center.isDisplay']
      },
      {
        icon: Circle,
        href: '/discounts',
        title: 'discounts_list',
        permissions: ['discount.isDisplay']
      },
      {
        icon: Circle,
        href: '/discountgrids',
        title: 'discount_grids_list',
        permissions: ['discountgrid.isDisplay']
      },
      {
        icon: Circle,
        href: '/discountgrids-center',
        title: 'discount_grids_list_center',
        permissions: ['discountgrid.isDisplay']
      },
      {
        icon: Circle,
        href: '/companies',
        title: 'companies_list',
        permissions: ['company.isDisplay']
      },
      {
        icon: Circle,
        href: '/terms-of-sales',
        title: 'terms_of_sales_list',
        permissions: ['terms_of_sales.isDisplay']
      },
      {
        icon: Circle,
        href: '/rules-of-procedures',
        title: 'rules_of_procedures_list',
        permissions: ['terms_of_sales.isDisplay']
      },
      {
        icon: Circle,
        href: '/typologies',
        title: 'typologies_list',
        permissions: ['typology.isDisplay']
      },
      {
        icon: Circle,
        href: '/services-types',
        title: 'services_types',
        permissions: ['catalog_service.isDisplay']
      },
      {
        icon: Circle,
        href: '/commitments',
        title: 'commitments_list',
        permissions: ['commitment.isDisplay']
      },
      {
        icon: Circle,
        href: '/center-discounts',
        title: 'center_discounts_list',
        permissions: ['discount.isDisplay']
      },
      {
        icon: Circle,
        href: '/emails',
        title: 'emails_list',
        permissions: ['email_template.isDisplay']
      },
      {
        icon: Circle,
        href: '/cryptography',
        title: 'cryptography',
        permissions: ['monitoring.isEdit']
      },
      {
        icon: Circle,
        href: 'centers/bp',
        title: 'add_bp',
        permissions: ['bp.isAdd']
      },
      {
        icon: Circle,
        href: '/opportunity-attribution',
        title: 'opportunity_attribution',
        permissions: ['attribution_staff.isEdit']
      },
      {
        icon: Circle,
        href: '/opportunity-attribution-logs',
        title: 'opportunity_attribution_logs',
        permissions: ['attribution_staff.isDisplay']
      },
      {
        icon: Circle,
        href: '/notifications',
        title: 'notifications_list',
        permissions: ['notification.isEdit']
      }
    ]
  },
  {
    href: '',
    icon: PersonalVideo,
    title: 'monitoring',
    permissions: ['monitoring.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/messages',
        title: 'messages_list',
        permissions: ['client.isDisplay']
      },
      {
        icon: Circle,
        href: '/authentifications_client',
        title: 'authentifications_radius',
        permissions: ['radius_auth.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: QueryStats,
    title: 'Statistiques',
    permissions: ['stats_occupation.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/stats-health-center',
        title: 'dashboard_health_center',
        permissions: [
          'stats_occupation.isDisplay',
          'stats_revenue.isDisplay',
          'stats_price_per_surface.isDisplay'
        ]
      },
      {
        icon: Circle,
        href: '/stats-ca-vs-bp',
        title: 'dashboard_ca_vs_bp_navbar',
        permissions: ['stats_occupation.isDisplay', 'stats_revenue.isDisplay']
      },
      {
        icon: Circle,
        href: '/statsinventories',
        title: 'total_surface_center',
        permissions: ['stats_center.isDisplay']
      },
      {
        icon: Circle,
        href: '/statsrateoccupancy',
        title: 'occupation_rate',
        permissions: ['stats_occupation.isDisplay']
      },
      {
        icon: Circle,
        href: '/stats-acquisition',
        title: 'acquisition_stats',
        permissions: ['stats_acquisition.isDisplay']
      },
      {
        icon: Circle,
        href: '/stats-surface',
        title: 'surface_price',
        permissions: ['stats_price_per_surface.isDisplay']
      },
      {
        icon: Circle,
        href: '/stats-performance',
        title: 'performance',
        permissions: ['stats_performances.isDisplay']
      },
      {
        icon: Circle,
        href: '/stats-client-requests',
        title: 'requests_client',
        permissions: ['stats_client_request.isDisplay']
      },
      {
        icon: Circle,
        href: '/stats-ca',
        title: 'ca',
        permissions: ['stats_revenue.isDisplay']
      },
      {
        icon: Circle,
        href: '/accounting_dashboard',
        title: 'accounting_dashboard',
        permissions: ['stats_accounting.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: Badge,
    title: 'presences',
    permissions: ['presence_radius.isDisplay', 'presence_visor.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/presences/users',
        title: 'presence_users',
        permissions: ['presence_radius.isDisplay', 'presence_visor.isDisplay']
      },
      {
        icon: Circle,
        href: '/presences/logs',
        title: 'List_of_presence_logs',
        permissions: ['presence_radius.isDisplay', 'presence_visor.isDisplay']
      },
      {
        icon: Circle,
        href: '/presences/consumption',
        title: 'List_of_presence_consumption',
        permissions: ['presence_radius.isDisplay', 'presence_visor.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: Wifi,
    title: 'wi-fi',
    permissions: ['wifi_logs.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/wifi/clients',
        title: 'global_wifi_access_list',
        permissions: ['wifi_logs.isDisplay']
      },
      {
        icon: Circle,
        href: '/wifi/logs',
        title: 'wifi_logs_clients_list',
        permissions: ['wifi_logs.isDisplay']
      }
    ]
  },
  {
    href: '',
    icon: CalendarMonth,
    title: 'evenementiel',
    permissions: ['happening.isDisplay'],
    children: [
      {
        icon: Circle,
        href: '/happenings',
        title: 'happenings_list_events',
        permissions: ['happening.isDisplay']
      },
      {
        icon: Circle,
        href: '/happenings/partner',
        title: 'happenings_list_partners',
        permissions: ['happening.isDisplay']
      }
    ]
  }
]

export const menuItems = [
  {
    pages: firstSection,
    title: 'Pages'
  }
]
