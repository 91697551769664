import { FormControl, Grid, MenuItem, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import React from 'react'
import { FormItem, InvoiceInformation } from 'api/models'
import dayjs from 'dayjs'
import firstLetterUpper from 'app/helpers/first_letter_upper.helper'

interface IProps {
  t: any
  paymentMode: FormItem
  data: InvoiceInformation
  payedDate: dayjs.Dayjs
  setPayedDate: (e: any) => void
}

export const DeclarePaymentComponent = ({
  payedDate,
  setPayedDate,
  t,
  paymentMode,
  data
}: IProps) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            select
            required
            id="demo-simple-select2"
            label={t('payment_mode_label')}
            size={'small'}
            fullWidth
            name={'paymentMode'}
            defaultValue={''}
          >
            <MenuItem value={''}></MenuItem>
            {paymentMode.values.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {firstLetterUpper(item.label)}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          name={'amount'}
          id="outlined-number"
          label={t('amount')}
          type="number"
          size={'small'}
          fullWidth
          InputProps={{
            inputProps: {
              min: 0,
              max: data.invoice.dueAmount,
              step: 0.01
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="reference"
          id="outlined-number"
          label={t('reference')}
          size={'small'}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label={t('paid_at')}
          value={payedDate}
          onChange={(e: any) => setPayedDate(e)}
          slotProps={{
            textField: {
              size: 'small',
              fullWidth: true,
              required: true
            }
          }}
        />
      </Grid>
    </>
  )
}
