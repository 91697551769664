import styled from '@emotion/styled'
import { Typography as MuiTypography } from '@mui/material'
import { Stack } from '@mui/system'

const Typography = styled(MuiTypography)<{ 'data-indicator'?: boolean }>`
  position: relative;

  &:before {
    content: '';
    display: ${(props) => (props['data-indicator'] ? 'block' : 'none')};
    width: 6px;
    height: 6px;
    background: ${(props) => props.theme.palette.secondary.dark};
    position: absolute;
    bottom: 6px;
    left: 0;
    border-radius: 50%;
  }
`

interface ITitleProps {
  text?: any
  variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'body2'
    | 'body1'
    | 'subtitle1'
    | 'subtitle2'
    | 'overline'
    | undefined
  paddingTop?: number
  paddingLeft?: number
  marginBottom?: number
  icon?: React.ReactNode
}

export const TitleComponent = ({
  text,
  paddingTop,
  paddingLeft,
  variant,
  marginBottom,
  icon
}: ITitleProps): React.JSX.Element => {
  return (
    <Typography
      sx={{ paddingTop: { paddingTop }, paddingLeft: { paddingLeft } }}
      variant={variant}
      marginBottom={marginBottom}
      data-indicator={!icon}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        {icon}
        {text}
      </Stack>
    </Typography>
  )
}
