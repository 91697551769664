import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import { CryptResType } from 'api/models/crypt'

export const cryptRouter = {
  decrypt: async ({ token }: Ctx, data: { text: String }) =>
    fetcher<CryptResType>({
      url: `${BASE_URL}${API.POST_DECRYPT}`,
      method: 'POST',
      token: token,
      body: data
    }),
  encrypt: async ({ token }: Ctx, data: { text: String }) =>
    fetcher<CryptResType>({
      url: `${BASE_URL}${API.POST_ENCRYPT}`,
      method: 'POST',
      token: token,
      body: data
    })
}
