import { useTranslation } from 'react-i18next'
import type { PrescriberInformation } from 'api/models'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { Grid, IconButton, Paper as MuiPaper } from '@mui/material'
import { List } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import { PrescriberMember } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'
import { AddCircle } from '@mui/icons-material'

interface IIndividualDetailsProps {
  individual: PrescriberInformation
}

export const PrescriberMembersCard = ({ individual }: IIndividualDetailsProps) => {
  const { enterpriseId } = useParams()
  const { handleSort, orderBy, handleFilter } = useList()
  const [members, setMembers] = useState<PrescriberMember[]>([])
  const { getPrescriberMembers, createPrescriberMemberSource } = useFetcher()
  const { handleMutation } = useFeedback()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const fetch = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getPrescriberMembers,
      data: {
        prescriberId: individual.id,
        enterpriseId: Number(enterpriseId)
      },
      onSuccess: (data) => setMembers(data.items),
      onEnd: () => setIsLoading(false)
    })
  }, [individual])

  const handleClickSource = useCallback(
    (item: PrescriberMember) => async () => {
      await handleMutation({
        confirm: {
          content: t('confirm_add_member_source')
        },
        mutation: createPrescriberMemberSource,
        data: {
          prescriberId: individual.id,
          enterpriseId: Number(enterpriseId),
          individualId: Number(item.id)
        },
        onSuccess: () => navigate(0)
      })
    },
    []
  )

  useEffect(() => {
    fetch().then()
  }, [])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('users')} variant={'h3'} paddingTop={0} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            columns={[
              {
                slug: 'reference',
                label: t('reference'),
                link: { slug: 'id', base: `/individuals` }
              },
              {
                slug: 'firstname',
                label: t('client')
              },
              {
                slug: 'lastname',
                label: t('center')
              },
              {
                slug: 'city',
                label: t('city')
              },
              {
                slug: 'email',
                label: t('email')
              },
              {
                slug: 'mobile',
                label: t('mobile')
              },
              {
                slug: 'source',
                label: t('source'),
                condition: (item: PrescriberMember) => {
                  return item?.source != '' ? (
                    item.source
                  ) : (
                    <IconButton color="primary" size={'small'} onClick={handleClickSource(item)}>
                      <AddCircle fontSize={'small'} />
                    </IconButton>
                  )
                }
              }
            ]}
            items={members}
            handleReset={() => handleFilter(fetch, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(fetch, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
