import { Card, CardContent, Box, IconButton, Typography } from '@mui/material'

import { TitleComponent } from 'app/components/titles/title.component'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { Contract, ContractMainList } from 'api/models'
import { AddLink } from '@mui/icons-material'
import { formatCurrency } from 'app/utils/format'
import { Stack } from '@mui/system'
import { useApp } from 'app/providers/app.provider'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { LinkUserModal } from 'modules/contracts/components/users/link-user.modal'
import { Link } from 'app/components/link.component'

interface IServicesProps {
  isLoading: boolean
  mainValues: ContractMainList
  contract: Contract
}

export const RecurringServicesComponent = ({ isLoading, mainValues, contract }: IServicesProps) => {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const dialogRef = useRef<DialogRef>(null)
  const [selectedRow, setSelectedRow] = useState<any>(null)
  const [initMap, setInitMap] = useState<Map<string, any>>(
    new Map([
      ['items', []],
      ['data', []]
    ])
  )

  const openDialog = (row: any) => () => {
    setSelectedRow(row)
    dialogRef.current?.open()
  }

  useEffect(() => {
    setInitMap(() => {
      const map = new Map()
      map.set('items', [
        { label: t('label').toUpperCase(), value: 'label' },
        {
          label: t('client').toUpperCase(),
          component: (mainService: any) => {
            return mainService.clientId ? (
              <Link to={`/individuals/${mainService.clientId}`}>{mainService.client}</Link>
            ) : getRight('contract_service_link_user', 'isAdd') ? (
              <IconButton onClick={openDialog(mainService)} size={'small'}>
                <AddLink />
              </IconButton>
            ) : null
          }
        },
        { label: t('monthly_price').toUpperCase(), format: formatCurrency, value: 'price' },
        {
          label: t('counter').toUpperCase(),
          component: (mainService: any) => {
            return (
              <Stack direction={'row'} gap={1}>
                <Box paddingX={2} textAlign={'center'}>
                  <Typography variant={'caption'}>{mainService.counterPrevious}</Typography>
                </Box>
                <Box
                  paddingX={2}
                  textAlign={'center'}
                  sx={{
                    borderLeft: '1px solid',
                    borderRight: '1px solid',
                    borderColor: 'text.disabled'
                  }}
                >
                  <Typography variant={'caption'}>{mainService.counter}</Typography>
                </Box>
                <Box paddingX={2} textAlign={'center'}>
                  <Typography variant={'caption'}>{mainService.counterNext}</Typography>
                </Box>
              </Stack>
            )
          }
        }
      ])
      map.set('data', mainValues ?? [])
      return map
    })
  }, [mainValues, getRight])

  return (
    <>
      <LinkUserModal selectedRow={selectedRow} dialogRef={dialogRef} contract={contract} />
      <Card>
        <CardContent>
          <TitleComponent text={t('recurring_services')} variant={'h3'} paddingLeft={12} />
          <ListSheetComponent isLoading={isLoading} data={initMap} />
        </CardContent>
      </Card>
    </>
  )
}
