import { z } from 'zod'

export const mandateSchema = z.object({
  id: z.string(),
  isActive: z.string(),
  partnerPaymentMethodId: z.string(),
  clientId: z.string(),
  clientName: z.string(),
  centerId: z.string(),
  centerName: z.string(),
  createdAt: z.string(),
  partnerLabel: z.string(),
  nbPaymentsConfirmed: z.string(),
  nbPaymentsTotal: z.string()
})

export type Mandate = z.infer<typeof mandateSchema>

export const mandatesListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(mandateSchema)
})

export type MandatesList = z.infer<typeof mandatesListSchema>

export const paymentmethodSchema = z.object({
  id: z.number(),
  partnerPaymentMethodId: z.string(),
  centerName: z.string(),
  clientId: z.number(),
  clientName: z.string(),
  createdAt: z.string(),
  isActive: z.boolean(),
  activeLabel: z.string()
})

export type PaymentMethod = z.infer<typeof paymentmethodSchema>

export const bankAccountSchema = z.object({
  stateLabel: z.string(),
  accountHolderName: z.string(),
  accountNumberEnding: z.string(),
  countryCode: z.string(),
  currency: z.string(),
  bankName: z.string()
})

export type BankAccount = z.infer<typeof bankAccountSchema>

export const paymentSchema = z.object({
  accountingDocument: z.string(),
  accountingDocumentId: z.number(),
  client: z.string(),
  clientId: z.number(),
  state: z.number(),
  stateLabel: z.string(),
  contract: z.string(),
  contractId: z.number(),
  reference: z.string(),
  chargeDate: z.string(),
  payed: z.string(),
  amount: z.number()
})

export type PaymentSchema = z.infer<typeof paymentSchema>

const PaymentsListSchema = z.array(paymentSchema)

export type Payments = z.infer<typeof PaymentsListSchema>
