import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Typography, Paper as MuiPaper, Container, Stack, Grid, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Litigation } from 'api/models'
import { Link } from 'app/components/link.component'
import styled from '@emotion/styled'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { CardItem, CardItemName } from 'app/components/card/info-card.component'
import { Apartment, CalendarMonthRounded, Edit, FolderOpen } from '@mui/icons-material'
import { LitigationContracts } from 'modules/litigations/components/contracts.component'
import { LitigationInvoices } from 'modules/litigations/components/invoices.component'
import SplitButton from 'app/components/buttons/split-button.component'
import { formatDate } from 'app/utils/format'
import { ModalLitigationEdit } from 'modules/litigations/components/modal-litigation-edit'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { darken } from 'polished'
import { useFeedback } from 'app/providers/feedback.provider'

const Icon = styled('div')`
  background-color: #fff;
  color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
  padding: 8px;
`

const Paper = styled(MuiPaper)`
  background: ${(props) =>
    props.theme.palette.mode === 'dark'
      ? darken(0.03, props.theme.palette.background.paper)
      : props.theme.palette.grey[400]};
`

export const LitigationView = () => {
  const { id } = useParams()
  const dialogRef = useRef<DialogRef>(null)
  const { t } = useTranslation()
  const { closeLitigation, getLitigation, getLitigationContracts, getLitigationInvoices } =
    useFetcher()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [litigation, setLitigation] = useState<Litigation>({} as Litigation)
  const [contracts, setContracts] = useState<any>([])
  const [invoices, setInvoices] = useState<any>([])
  const { handleMutation } = useFeedback()

  const fetchData = useCallback(async () => {
    if (!id) return
    const [litigation, contracts, invoices] = await Promise.all([
      getLitigation.mutateAsync(Number(id)),
      getLitigationContracts.mutateAsync(Number(id)),
      getLitigationInvoices.mutateAsync(Number(id))
    ])

    setLitigation(litigation)
    setContracts(contracts)
    setInvoices(invoices)
  }, [])

  const refreshData = useCallback(() => {
    setIsLoading(true)
    fetchData().then(() => setIsLoading(false))
  }, [setIsLoading, fetchData])

  useEffect(refreshData, [id])
  const handleEdit = useCallback(() => {
    dialogRef.current?.open()
  }, [dialogRef])

  const handleClose = useCallback(async () => {
    if (!litigation) return
    await handleMutation({
      confirm: {
        content: t('confirm_close_litigation')
      },
      mutation: closeLitigation,
      data: litigation.id,
      toastSuccess: t('litigation_close_success'),
      onSuccess: () => refreshData(),
      toastError: t('litigation_close_error')
    })
  }, [litigation])

  const items = useMemo(() => {
    if (!litigation) return []

    return [
      {
        label: t('reference'),
        value: (
          <Stack direction={'row'} alignItems={'center'}>
            {litigation.reference}
            <IconButton size={'small'} onClick={handleEdit} color={'primary'}>
              <Edit sx={{ fontSize: '12px' }} />
            </IconButton>
          </Stack>
        ),
        icon: FolderOpen
      },
      {
        label: t('created_at'),
        value: formatDate(litigation.createdAt),
        icon: CalendarMonthRounded
      },
      {
        label: t('enterprise'),
        value: <Link to={`/enterprises/${litigation.clientId}`}>{litigation.clientName}</Link>,
        icon: Apartment
      },
      {
        label: t('status'),
        value: litigation.stateLabel,
        icon: null
      }
    ]
  }, [litigation])

  return (
    <Container>
      <ModalLitigationEdit litigation={litigation} dialogRef={dialogRef} onSuccess={refreshData} />
      <>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="h1">{t('litigation')}</Typography>
          <SplitButton
            items={[
              {
                key: 'close_litigation',
                label: t('close_litigation'),
                action: handleClose
              }
            ]}
          />
        </Stack>
      </>
      {
        <Paper sx={{ marginTop: 5 }}>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent={'space-between'}
            paddingX={12}
            paddingY={4}
          >
            {items.map((item) => (
              <CardItem>
                {item.icon && <Icon>{<item.icon />}</Icon>}
                <Stack>
                  <Typography variant="caption">{item.label}</Typography>
                  <CardItemName variant="body2">{item.value}</CardItemName>
                </Stack>
              </CardItem>
            ))}
          </Stack>
        </Paper>
      }
      <Grid container spacing={5} marginTop={5}>
        <Grid item xs={6}>
          <LitigationContracts isLoading={isLoading} contracts={contracts} />
        </Grid>
        <Grid item xs={6}>
          <LitigationInvoices isLoading={isLoading} invoices={invoices} />
        </Grid>
      </Grid>
    </Container>
  )
}
