import { Ctx, fetcher } from 'api'
import { Document } from 'api/models'
import { API, BASE_URL } from 'api/constants'
import urlHelper from 'app/helpers/url.helper'

export const documentsRouter = {
  getOne: async ({ token }: Ctx, id: string) =>
    fetcher<Document>({
      url: `${BASE_URL}${urlHelper(API.DOCUMENT, { id: id })}`,
      method: 'GET',
      token: token
    }),
  invalidate: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.DOCUMENT_INVALIDATE, { id: id })}`,
      method: 'PATCH',
      token: token
    }),
  validate: async ({ token }: Ctx, id: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.DOCUMENT_VALIDATE, { id: id })}`,
      method: 'PATCH',
      token: token
    })
}
