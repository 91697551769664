import styled from '@emotion/styled'
import { Drawer as MuiDrawer, ListItemButton, useTheme as useThemeMui } from '@mui/material'
import { NavLink } from 'react-router-dom'
import type { MenuItemsTypes } from 'app/constants/navbar'
import { SidebarNav } from 'app/components/sidebar/sidebar-nav.component'
import { LogoComponent } from 'app/components/logo/logo.component'

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: ${(props) => props.theme.sidebar.borderRight};
    border-right-color: ${(props) => props.theme.sidebar.borderColor};
  }
`

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode
  to?: string
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`

interface ISidebarProps {
  PaperProps: {
    style: {
      width: number
    }
  }
  variant?: 'permanent' | 'persistent' | 'temporary'
  open?: boolean
  onClose?: () => void
  items: {
    title: string
    pages: MenuItemsTypes[]
  }[]
}

export default function Sidebar({ items, ...rest }: ISidebarProps): JSX.Element {
  //@ts-ignore
  const { logo } = useThemeMui()
  return (
    <>
      <Drawer variant="permanent" {...rest}>
        <Brand component={NavLink as any} to="/">
          <LogoComponent src={logo.header} alt={'logo'} className={''} />
        </Brand>
        <SidebarNav items={items} />
      </Drawer>
    </>
  )
}
