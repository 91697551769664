import SplitButton, { ButtonItemProps } from 'app/components/buttons/split-button.component'
import React, { useCallback, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApp } from 'app/providers/app.provider'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { DialogCancelContract } from 'modules/contracts/components/dialog-cancel-contract'
import { DialogLitigation } from 'modules/contracts/components/dialog-litigation'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Contract } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useFeedback } from 'app/providers/feedback.provider'

export const ContractActions = function ({ contract }: { contract: Contract }) {
  const { downloadContract, validateContract, agreeContract } = useFetcher()
  const navigate = useNavigate()
  const { getRight } = useApp()
  const dialogRef = useRef<DialogRef>(null)
  const dialogLitigationRef = useRef<DialogRef>(null)
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()

  const handleDownloadContract = useCallback(async () => {
    await handleMutation({
      mutation: downloadContract,
      data: {
        id: Number(contract?.id),
        reference: String(contract?.reference)
      }
    })
  }, [contract])

  const handleValidateContract = useCallback(async () => {
    if (!contract.id) return
    await handleMutation({
      confirm: {
        content: t('confirm_validate_contract')
      },
      mutation: validateContract,
      data: contract.id,
      onEnd: () => navigate(0)
    })
  }, [contract])

  const handleAgreeContract = useCallback(async () => {
    if (!contract.id) return
    await handleMutation({
      confirm: {
        content: t('confirm_agree_contract')
      },
      mutation: agreeContract,
      data: contract.id,
      onEnd: () => navigate(0)
    })
  }, [contract])

  const handleCancelContractModal = useCallback(async () => {
    dialogRef.current?.open()
  }, [contract])

  const actions = useMemo(() => {
    let _items = [
      {
        label: t('download_contract'),
        key: 'download_contract',
        action: handleDownloadContract
      }
    ] as ButtonItemProps[]

    if (!contract) return _items

    const canEdit = getRight('contract', 'isEdit')

    if (canEdit && contract.state === 1 && !contract.contractBlocked) {
      _items.push({
        label: t('validate_agreement'),
        key: 'agreement_validate',
        action: handleAgreeContract
      })
    } else if (canEdit && contract.state === 2) {
      _items.push({
        label: t('validate_payment'),
        key: 'validate_payment',
        action: handleValidateContract
      })
    }

    if (canEdit && !contract.end) {
      _items.push({
        label: contract?.agreement ? t('terminate_contract') : t('cancel_contract'),
        key: 'cancel_contract',
        action: handleCancelContractModal
      })
    }

    if (getRight('litigation', 'isAdd') && (contract.state === 1 || contract.state === 2)) {
      _items.push({
        label: t('put_in_litigation'),
        key: 'put_in_litigation',
        action: () => {
          dialogLitigationRef.current?.open()
        }
      })
    }

    if (getRight('admin_contract', 'isEdit')) {
      _items.push({
        label: t('update_contract'),
        key: 'update_contract',
        action: () => {
          navigate(`/contracts/${contract?.id}/edit`)
        }
      })
    }

    return _items
  }, [contract, getRight, t])

  return (
    <>
      <DialogCancelContract contract={contract} dialogRef={dialogRef} />
      <DialogLitigation contract={contract} dialogRef={dialogLitigationRef} />
      <SplitButton items={actions} />
    </>
  )
}
