import * as React from 'react'
import { GridColDef } from '@mui/x-data-grid-premium'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material'
import { TitleComponent } from 'app/components/titles/title.component'
import { formatCurrency, formatFullName, formatPercentage } from 'app/utils/format'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import { dates, RangePicker } from 'app/components/filters/range-picker'
import { TablePerformanceComponent } from 'modules/stats/components/table-performance.component'
import { PerformanceStats } from 'api/models/stats'
import { Filters } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'

const initialState = {
  columns: { columnVisibilityModel: { staffFirstName: false, staffLastName: false } },
  rowGrouping: { model: ['staffFullName'] },
  aggregation: {
    model: {
      contractsCount: 'sum',
      surfaceSum: 'sum',
      reductionAverage: 'avgContracts',
      commitmentAverage: 'avgContracts',
      squareMeterPriceAverage: 'avgContracts',
      mainPriceSum: 'sum'
    }
  }
}

export const StatsPerformanceView = function () {
  const { t } = useTranslation()
  const { handleFilter } = useList()
  const { getStatsPerformance, setSearchParams, searchParams } = useFetcher()
  const [rows, setRows] = useState<PerformanceStats>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { handleMutation } = useFeedback()

  const fetchData = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getStatsPerformance,
      onSuccess: (data) =>
        setRows(
          data.map((d: any) => ({
            ...d,
            staffFullName: formatFullName(d.staffFirstName, d.staffLastName)
          }))
        ),
      onEnd: () => setIsLoading(false)
    })
  }, [handleMutation, getStatsPerformance])

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'staffFullName',
        headerName: t('staff'),
        width: 140
      },
      { field: 'contractTypeLabel', headerName: t('type'), groupable: false },
      { field: 'contractsCount', headerName: t('nb_contracts'), type: 'number' },
      {
        field: 'surfaceSum',
        headerName: t('sum_sold_surface'),
        groupable: false,
        type: 'number',
        width: 160
      },
      {
        field: 'reductionAverage',
        headerName: t('avg_reduction'),
        groupable: false,
        type: 'number',
        valueFormatter: (data) => data.value !== undefined && formatPercentage(data.value)
      },
      {
        field: 'commitmentAverage',
        headerName: t('avg_commitment'),
        groupable: false,
        type: 'number'
      },
      {
        field: 'squareMeterPriceAverage',
        headerName: t('avg_square_meter_price'),
        groupable: false,
        type: 'number',
        valueFormatter: (data) => data.value !== undefined && formatCurrency(data.value)
      },
      {
        field: 'mainPriceSum',
        headerName: t('sum_main_price'),
        groupable: false,
        type: 'number',
        width: 160,
        valueFormatter: (data) => data.value !== undefined && formatCurrency(data.value)
      }
    ],
    [t]
  )

  useEffect(() => {
    fetchData().then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('stats_performances')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={[] as Filters}
          handleFilters={() => handleFilter(fetchData, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            label={'period'}
            slug={'begin'}
            slugs={{ begin: 'begin', end: 'end' }}
            valueLabel={dates.CURRENT_MONTH}
            onChange={() => handleFilter(fetchData, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Stack direction={'row'} spacing={6}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <TitleComponent
              text={t('performance')}
              variant={'h3'}
              paddingTop={0}
              paddingLeft={12}
            />
            <TablePerformanceComponent
              initialState={initialState}
              rows={rows}
              isLoading={isLoading}
              columns={columns}
            />
          </CardContent>
        </Card>
      </Stack>
    </Container>
  )
}
