import { FormProvider, UseFormReturn } from 'react-hook-form'
import * as React from 'react'
import { FormItems } from 'api/models'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import ControlledForm from 'app/components/form/controlled-form.component'
import { Euro, Percent } from '@mui/icons-material'
const FormAddService = ({
  methods,
  options
}: {
  methods: UseFormReturn<any>
  options: FormItems
}) => {
  const { t } = useTranslation()

  return (
    <FormProvider {...methods}>
      <Grid container columns={12} columnSpacing={4}>
        <ControlledForm
          methods={methods}
          items={[
            {
              type: 'select',
              name: 'service',
              label: t('service'),
              formItem: options.consumptions_services_types,
              required: true
            },
            {
              type: 'number',
              name: 'quantity',
              label: t('quantity'),
              required: true
            },
            {
              type: 'datetimepicker',
              name: 'begin',
              label: t('begin'),
              required: true
            },
            {
              type: 'datetimepicker',
              name: 'end',
              label: t('end'),
              required: true
            },
            {
              type: 'number',
              name: 'price',
              label: t('pu_ht'),
              required: true,
              icon: Euro,
              inputProps: { disabled: !methods.getValues().isFreePrice }
            },
            {
              type: 'number',
              name: 'reduction',
              label: t('reduction'),
              required: true,
              icon: Percent
            },
            {
              type: 'number',
              name: 'fees',
              label: t('fees'),
              required: true,
              icon: Euro,
              inputProps: { disabled: !methods.getValues().isFreeFees }
            }
          ]}
        />
      </Grid>
    </FormProvider>
  )
}

export default FormAddService
