import { useFeedback } from 'app/providers/feedback.provider'
import { ContentCopy } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'

export const CopyButton = function ({ text }: { text: string }) {
  const { toast } = useFeedback()
  const { t } = useTranslation()
  const copy = () => {
    navigator.clipboard.writeText(text)
    toast({
      message: t('copied'),
      variant: 'success'
    })
  }
  return (
    <IconButton size={'small'} onClick={copy}>
      <ContentCopy fontSize={'small'} />
    </IconButton>
  )
}
