import { type DependencyList, useCallback, useEffect } from 'react'

export const useDebouce = (effect: Function, dependencies: DependencyList, delay: number) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, dependencies)

  useEffect(() => {
    const timeout = setTimeout(callback, delay)
    return () => clearTimeout(timeout)
  }, [callback, delay])
}
