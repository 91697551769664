import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  CenterDiscountsList,
  CenterDiscountsNotActive,
  CreateCenterDataDiscount,
  CreateCenterDiscount
} from 'api/models/center_discounts'

export const centerDiscountRouter = {
  list: async ({ token }: Ctx, params: string) =>
    fetcher<CenterDiscountsList>({
      url: `${BASE_URL}${API.GET_CENTER_DISCOUNTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  notActive: async ({ token }: Ctx) =>
    fetcher<CenterDiscountsNotActive>({
      url: `${BASE_URL}${API.GET_CENTER_DISCOUNTS_NOT_ACTIVE}`,
      method: 'GET',
      token: token
    }),
  create: async ({ token }: Ctx, data: CreateCenterDataDiscount) =>
    fetcher<CreateCenterDiscount>({
      url: `${BASE_URL}${API.GET_CENTER_DISCOUNTS}`,
      method: 'POST',
      token: token,
      body: data
    })
}
