import { useTranslation } from 'react-i18next'
import type { PrescriberInformation, PrescriberOpportunity } from 'api/models'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { Grid, Paper as MuiPaper } from '@mui/material'
import { List } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import { formatDate } from 'app/utils/format'
import { useFeedback } from 'app/providers/feedback.provider'

interface IIndividualDetailsProps {
  individual: PrescriberInformation
}

export const PrescriberOpportunitiesCard = ({ individual }: IIndividualDetailsProps) => {
  const { enterpriseId } = useParams()
  const { handleSort, orderBy, handleFilter } = useList()
  const [opportunities, setOpportunities] = useState<PrescriberOpportunity[]>([])
  const { getPrescriberOpportunities } = useFetcher()
  const { handleMutation } = useFeedback()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const fetch = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getPrescriberOpportunities,
      data: {
        prescriberId: individual.id,
        enterpriseId: Number(enterpriseId)
      },
      onSuccess: (data) => setOpportunities(data.items),
      onEnd: () => setIsLoading(false)
    })
  }, [individual])

  useEffect(() => {
    fetch().then()
  }, [])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <TitleComponent text={t('opportunities')} variant={'h3'} paddingTop={0} paddingLeft={12} />
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            columns={[
              {
                slug: 'reference',
                label: t('reference'),
                link: { base: '/opportunities', slug: 'id' }
              },
              {
                slug: 'enterpriseName',
                label: t('client'),
                link: { base: '/companies', slug: 'enterpriseId' } //TODO : Changer une fois renommage companies -> enterprise merged
              },
              {
                slug: 'centerName',
                label: t('center')
              },
              {
                slug: 'typeLabel',
                label: t('type')
              },
              {
                slug: 'hasContract',
                label: t('contract')
              },
              {
                slug: 'createdAt',
                valueFormatter: formatDate,
                label: t('date')
              }
            ]}
            items={opportunities}
            handleReset={() => handleFilter(fetch, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(fetch, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
