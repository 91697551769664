import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import { WifiClientLogsList, WifiLogsList } from 'api/models/wifi'

export const wifiRouter = {
  getLogs: async ({ token }: Ctx, params: string) =>
    fetcher<WifiLogsList>({
      url: `${BASE_URL}${API.GET_WIFI_LOGS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getClientsLogs: async ({ token }: Ctx, params: string) =>
    fetcher<WifiClientLogsList>({
      url: `${BASE_URL}${API.GET_WIFI_CLIENTS}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
}
