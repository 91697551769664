import React, { useCallback, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Inventories } from 'api/models/stats'
import dayjs from 'dayjs'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import TableInventoriesComponent from 'modules/stats/components/table-inventories.component'

export const StatsInventoriesView = () => {
  const { t } = useTranslation()
  const { getStatsInventories, setSearchParams, searchParams, getFormItems } = useFetcher()
  const [statsInventories, setStatsInventories] = useState<Inventories>([])
  const [centers, setCenters] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    setIsLoading(true)
    const now = dayjs()
    const begin = now.startOf('year')
    const end = now.endOf('year')

    searchParams.set('begin', begin.format('YYYY-MM-DD'))
    searchParams.set('end', end.format('YYYY-MM-DD'))

    setSearchParams(searchParams)
    getData().finally(() => setIsLoading(false))
  }, [])

  const getData = useCallback(async () => {
    const inventories = await getStatsInventories.mutateAsync()
    const centers = await getFormItems.mutateAsync(['centers'])
    setCenters(centers.centers?.values)
    setStatsInventories(inventories)
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('total_surface_center')}
        </Typography>
      </Box>
      {isLoading ? (
        <CardSkeleton />
      ) : (
        <TableInventoriesComponent data={statsInventories} centers={centers} />
      )}
    </Container>
  )
}
