import { Card, CardContent, Divider, Grid, Skeleton, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system'
import { formatCurrency, formatPercentage } from 'app/utils/format'
import React, { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ServicePrice } from 'api/models'

export default function CustomerReservationPricing({
  isLoading,
  pricing,
  children
}: {
  isLoading: boolean
  pricing: ServicePrice
  children?: React.ReactNode
}) {
  const { t } = useTranslation()
  const theme = useTheme()
  const pricingData = useMemo(
    () => [
      {
        label: 'price',
        value: formatCurrency(pricing.price ?? 0)
      },
      {
        label: 'reduction',
        value:
          formatPercentage(pricing.reductionRate ? pricing.reductionRate / 100 : 0) +
          ' ' +
          (pricing.reductionRate > 0 ? `(${formatCurrency(pricing.reduction)})` : '')
      },
      {
        label: 'total_net',
        value: formatCurrency(pricing.amount ?? 0)
      },
      {
        label: 'vat',
        value: formatCurrency(pricing.vat ?? 0)
      }
    ],
    [pricing]
  )

  return (
    <Card sx={{ height: '100%', display: 'flex' }}>
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography>{t('customer_reservation_summary')}</Typography>
        <Stack flex={1} flexDirection={'column'} justifyContent={'space-evenly'}>
          {pricingData.map(({ label, value }, index) => {
            return (
              <Fragment key={index}>
                {isLoading ? (
                  <Skeleton height={60} />
                ) : (
                  <Grid container columns={12} padding={3}>
                    <Grid item xs={6}>
                      <Typography>{t(label)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography textAlign={'right'}> {value}</Typography>
                    </Grid>
                  </Grid>
                )}
                <Divider />
              </Fragment>
            )
          })}
          {isLoading ? (
            <Skeleton style={{ backgroundColor: theme.palette.primary.main + 'b0' }} height={60} />
          ) : (
            <Grid
              container
              columns={12}
              sx={{ backgroundColor: theme.palette.primary.main }}
              padding={3}
            >
              <Grid item xs={6}>
                <Typography
                  fontWeight={'bold'}
                  style={{ color: theme.palette.getContrastText(theme.palette.primary.main) }}
                >
                  {t('total_amount_ttc')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontWeight={'bold'}
                  style={{ color: theme.palette.getContrastText(theme.palette.primary.main) }}
                  textAlign={'right'}
                >
                  {formatCurrency(pricing.total ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Stack>
        {children}
      </CardContent>
    </Card>
  )
}
