import { FormProvider, UseFormReturn } from 'react-hook-form'
import * as React from 'react'
import { FormItems } from 'api/models'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import ControlledForm from 'app/components/form/controlled-form.component'
const FormEditCustomerReservation = ({
  methods,
  options
}: {
  methods: UseFormReturn<any>
  options: FormItems
}) => {
  const { t } = useTranslation()

  return (
    <FormProvider {...methods}>
      <Grid container columns={12} columnSpacing={4}>
        <ControlledForm
          methods={methods}
          items={[
            {
              type: 'select',
              name: 'owner',
              label: t('owner'),
              formItem: options.individuals,
              required: true
            },
            {
              type: 'select',
              name: 'contract',
              label: t('contract'),
              formItem: options.contracts,
              required: true
            },
            {
              type: 'select',
              name: 'staff',
              label: t('staff'),
              formItem: options.staffs,
              required: false
            }
          ]}
        />
      </Grid>
    </FormProvider>
  )
}

export default FormEditCustomerReservation
