import React, { HTMLInputTypeAttribute } from 'react'
import { FormControl } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro'
import dayjs from 'dayjs'

type IControlledDateFieldProps<TDate> = {
  type?: HTMLInputTypeAttribute
  label?: string
  name: string
  control: Control<any>
  icon?: React.FC<any>
  required?: boolean
} & TimePickerProps<TDate>

export function ControlledTimePicker({
  name,
  label,
  control,
  required,
  ...otherProps
}: IControlledDateFieldProps<any>) {
  return (
    <Controller
      render={({ field: { ref, value, onChange } }) => {
        return (
          <FormControl fullWidth size={'small'} required={required}>
            <TimePicker
              label={`${label || ''}`}
              defaultValue={value ? dayjs(value) : null}
              value={value ? dayjs(value) : null}
              inputRef={ref}
              onChange={onChange}
              slotProps={{
                textField: { size: 'small' }
              }}
              {...otherProps}
            />
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
