import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import urlHelper from 'app/helpers/url.helper'
import {
  FilterContracts,
  FilterContractsParams,
  FloorPlans,
  FloorPlansParams,
  ListFloors
} from 'api/models/floor_plans'

export const floorPlansRouter = {
  informations: async ({ token }: Ctx, data: FloorPlansParams) =>
    fetcher<FloorPlans>({
      url: `${BASE_URL}${API.GET_FLOOR_PLANS}?center=${data.center}&floor=${data.floor}&date=${data.date}`,
      method: 'GET',
      token: token
    }),
  listFloors: async ({ token }: Ctx, center: string) =>
    fetcher<ListFloors>({
      url: `${BASE_URL}${urlHelper(API.GET_LIST_FLOORS, { center })}`,
      method: 'GET',
      token: token
    }),
  getFilterContracts: async ({ token }: Ctx, data: FilterContractsParams) =>
    fetcher<FilterContracts>({
      url: `${BASE_URL}${API.GET_FILTER_CONTRACTS}?floorplan=${data.floorplan}&date=${data.date}`,
      method: 'GET',
      token: token
    })
}
