import React, { ChangeEvent, useCallback, useEffect } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import BaseModal from 'app/components/modals/base.modal'
import { useTranslation } from 'react-i18next'
import { IndividualDetails, type IndividualWifi, WifiPasswordUpdate } from 'api/models'
import { CheckboxComponent } from 'app/components/checkboxes/checkbox.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useApp } from 'app/providers/app.provider'

export interface IProps {
  individual: IndividualDetails
  wifi: IndividualWifi
  isOpen: boolean
  setIsOpen: (value: boolean, isRefresh?: boolean) => void
  isUpdate: boolean
}
export const WifiModalComponent = ({
  individual,
  wifi,
  isOpen,
  setIsOpen,
  isUpdate
}: IProps): React.JSX.Element => {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const { handleMutation } = useFeedback()
  const { updateWifiPassword, createWifiCredentials } = useFetcher()

  const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false)
  const [title, setTitle] = React.useState<string>('')
  const [showFormAdmin] = React.useState<boolean>(getRight('radius_password', 'isEdit'))
  const [wifiPasswordUpdate, setWifiPasswordUpdate] = React.useState<WifiPasswordUpdate>({
    mode: 'email',
    individual_radiuspassword: ''
  } as WifiPasswordUpdate)

  const handleCredentialWifi = useCallback(
    async (e: any) => {
      e.preventDefault()
      await handleMutation({
        confirm: {
          content: isUpdate ? t('confirm_update_wifi') : t('confirm_create_wifi')
        },
        mutation: isUpdate ? updateWifiPassword : createWifiCredentials,
        data: {
          id: String(individual.id),
          data: wifiPasswordUpdate
        },
        onSuccess: () => setIsOpen(false, !isUpdate),
        toastSuccess: t('password_sent'),
        toastError: t('error')
      })
    },
    [wifiPasswordUpdate]
  )

  const handleChangePassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // TODO: check if the password is correct (regex: ^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,253}$)
      setWifiPasswordUpdate({
        ...wifiPasswordUpdate,
        individual_radiuspassword: String(e.target.value)
      })
    },
    [wifiPasswordUpdate]
  )

  const handleChangeMode = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setWifiPasswordUpdate({ ...wifiPasswordUpdate, mode: String(e.target.value) })
    },
    [wifiPasswordUpdate]
  )

  useEffect(() => {
    if (!isOpen) {
      setWifiPasswordUpdate({ ...wifiPasswordUpdate, individual_radiuspassword: '', mode: 'email' })
    }
  }, [isOpen])

  useEffect(() => {
    if (isUpdate) {
      setTitle(t('wifi_modal_title_update', { login: wifi.login, name: individual.labelledName }))
    } else {
      setTitle(t('wifi_modal_title_create', { name: individual.labelledName }))
    }
  }, [isUpdate, wifi])

  return (
    <BaseModal
      open={isOpen}
      setOpen={setIsOpen}
      title={title}
      handleUpdate={handleCredentialWifi}
      size={'sm'}
    >
      <Box component={'div'} display="flex" flexDirection="column" gap={'2rem'}>
        {showFormAdmin && (
          <>
            <Box component={'div'} display="flex" flexDirection="column">
              <Typography component={'div'}>{t('wifi_modal_summary')}</Typography>
              <Typography component={'ul'}>
                <li>{t('wifi_modal_rule_1')}</li>
                <li>{t('wifi_modal_rule_2')}</li>
                <li>{t('wifi_modal_rule_3')}</li>
                <li>{t('wifi_modal_rule_4')}</li>
              </Typography>
            </Box>
            <Box component={'div'} display="flex" flexDirection="row" flex={1}>
              <TextField
                id="outlined-basic"
                type={isShowPassword ? 'text' : 'password'}
                style={{ flex: 1 }}
                label={t('password')}
                value={wifiPasswordUpdate.individual_radiuspassword}
                onChange={handleChangePassword}
                variant="outlined"
              />
              <Box
                component={'div'}
                display="flex"
                flex={1}
                flexDirection="row"
                alignItems="center"
              >
                <CheckboxComponent
                  checked={isShowPassword}
                  onChange={() => setIsShowPassword(!isShowPassword)}
                />
                <Typography>{t('show_password')}</Typography>
              </Box>
            </Box>
          </>
        )}
        <Box component={'div'} display="flex" flexDirection="row">
          <FormControl>
            <FormLabel id="radio-send-by">{t('send_by')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="radio-send-by"
              defaultValue="email"
              onChange={handleChangeMode}
            >
              <FormControlLabel value="email" control={<Radio />} label={t('email')} />
              <FormControlLabel value="sms" control={<Radio />} label={t('sms')} />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </BaseModal>
  )
}
