import { z } from 'zod'

const indexingSchema = z.object({
  centerName: z.string(),
  clientId: z.string(),
  clientName: z.string(),
  contractId: z.string(),
  contractReference: z.string(),
  contractState: z.string(),
  contractStateLabel: z.string(),
  indexedAt: z.string(),
  indexingRate: z.string(),
  newPrice: z.string(),
  nextIndexation: z.string(),
  oldPrice: z.string()
})

export type Indexing = z.infer<typeof indexingSchema>

const indexingListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(indexingSchema)
})

export type IndexingList = z.infer<typeof indexingListSchema>
