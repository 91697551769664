import { useCallback, useState } from 'react'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useDebouce } from 'app/hooks/use-debounce'
import styled from '@emotion/styled'
import { useFeedback } from 'app/providers/feedback.provider'
import type { Center, StaffCenters } from 'api/models'

export type CentersSelectInputProps = {
  slug: string
  onChange?: (item: Center) => void
  initialValue?: StaffCenters
  disableClearable?: boolean
  required?: boolean
}

const GroupItems = styled('ul')({
  padding: 0
})

export function MainCenterSelect({
  slug,
  onChange,
  initialValue,
  disableClearable = true,
  required
}: CentersSelectInputProps) {
  const { getCentersSelect } = useFetcher()
  const { t } = useTranslation()
  const [selectedCenter, setSelectedCenter] = useState<Center | null>(null)
  const [centers, setCenters] = useState<Center[]>([])
  const [defaultValue, setDefaultValue] = useState<StaffCenters | null>(initialValue ?? null)
  const [loading, setLoading] = useState<boolean>(false)
  const { handleMutation } = useFeedback()

  const selectDefaultValue = useCallback((defaultValues: StaffCenters, centers: Center[]) => {
    defaultValues.forEach((defaultValue) => {
      if (!defaultValue.isMain) return
      const center = centers.find((obj) => {
        return obj.id === String(defaultValue.center.id) && defaultValue.isMain
      })

      if (center) {
        setDefaultValue(null)
        handleOnChange(null, center)
      }
    })
  }, [])

  useDebouce(
    async () => {
      await handleMutation({
        onStart: () => setLoading(true),
        mutation: getCentersSelect,
        data: {
          name: '',
          is_cluster: false,
          is_center: true,
          all_centers: false,
          bypass_all_centers: false
        },
        onSuccess: (data) => {
          const _centers = data.flatMap((obj: any) => obj.children ?? [])
          setCenters(_centers)
          if (defaultValue) selectDefaultValue(defaultValue, _centers)
        },
        onEnd: () => setLoading(false)
      })
    },
    [initialValue],
    150
  )

  const handleOnChange = useCallback(
    (event: any, newValue: Center | null) => {
      setSelectedCenter(newValue)
      if (onChange && newValue) {
        onChange(newValue)
      }
    },
    [onChange]
  )

  return (
    <Stack>
      <Autocomplete
        size={'small'}
        id={slug ?? 'center-select'}
        disableClearable={disableClearable}
        onChange={handleOnChange}
        value={selectedCenter}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        loadingText={t('loading')}
        noOptionsText={t('no_options')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => centers.find((center) => center.id === option.id)?.text ?? ''}
        options={centers}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={13} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
              required={required}
              label={slug}
            />
          )
        }}
      />
    </Stack>
  )
}
