import React, { useMemo } from 'react'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { TitleComponent } from 'app/components/titles/title.component'
import urlHelper from 'app/helpers/url.helper'
import { API } from 'api/constants'
import { formatCurrency, formatPercentage, formatSurface } from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { Contract, EndorsementContract, EndorsementContractMainService } from 'api/models'
import { t } from 'i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Help } from '@mui/icons-material'
import { SmallChip } from 'modules/contracts/components/endorsement/small-chip.component'
import { calculateServiceFinalPriceTotal, calculateServiceGuarantee } from 'app/utils/calculate'

type ReadonlyEndorsementProps = {
  endorsement: Contract | null
  endorsementContract: EndorsementContract | null
  isLoading: boolean
}

export const ReadonlyEndorsementComponent = ({
  endorsement,
  endorsementContract,
  isLoading,
  ...otherProps
}: ReadonlyEndorsementProps) => {
  const { servicesMap, optionsMap, services } = useMemo(() => {
    if (!endorsementContract || !endorsement) {
      return { servicesMap: new Map(), optionsMap: new Map(), services: [] }
    }

    const services = endorsementContract.mainContractServices.map(
      (mainContractService: EndorsementContractMainService) => ({
        ...mainContractService,
        sellPrice: mainContractService.previousPrice,
        finalPrice: mainContractService.price,
        priceDifference: -1 * mainContractService.reduction,
        id: mainContractService.id,
        workforce: mainContractService.occupants,
        serviceId: mainContractService.serviceId
      })
    )

    const servicesMap = new Map()
    servicesMap.set('items', [
      {
        label: t('label'),
        value: 'label'
      },
      {
        label: t('type'),
        value: 'serviceTypeLabel'
      },
      {
        label: t('typology'),
        value: 'typology',
        format: (value: string) => (value ? <SmallChip label={value} color="primary" /> : null)
      },
      {
        label: t('surface'),
        value: 'surface',
        format: formatSurface
      },
      {
        label: t('occupants'),
        value: 'occupants'
      },
      {
        label: t('price'),
        component: (row: any) => formatCurrency(row.sellPrice)
      },
      {
        label: t('price_diff'),
        component: (row: any) => formatPercentage(row.priceDifference / 100)
      },
      {
        label: t('final_price'),
        value: 'finalPrice',
        format: formatCurrency
      }
    ])
    servicesMap.set('data', services ?? [])

    const optionsMap = new Map()
    if (
      endorsementContract.optionContractServices &&
      endorsementContract.optionContractServices.length > 0
    ) {
      optionsMap.set('items', [
        {
          label: t('label'),
          value: 'label'
        },
        {
          label: t('type'),
          value: 'serviceTypeLabel'
        },
        {
          value: 'typology',
          format: (value: string) => (value ? <SmallChip label={value} color="primary" /> : null)
        },
        {
          label: t('quantity'),
          value: 'quantity'
        },
        {
          label: t('reduction'),
          component: (row: any) => formatPercentage(row.reduction / 100)
        },
        {
          label: t('price'),
          value: 'price',
          format: formatCurrency
        }
      ])
      optionsMap.set('data', endorsementContract.optionContractServices)
    }
    return { servicesMap, optionsMap, services }
  }, [endorsement, endorsementContract])

  if (isLoading || !endorsementContract || !endorsement) {
    return <CardSkeleton />
  }

  const newTotal = calculateServiceFinalPriceTotal(services)
  const newGuarantee = calculateServiceGuarantee(services)
  const priceRate =
    newTotal > 0 ? (1 - (endorsementContract.contractMainPrice ?? 0) / newTotal) * 100 : 100

  const priceDifference = newTotal - (endorsementContract.contractMainPrice ?? 0)
  const guaranteesDifference = newGuarantee * (priceRate / 100)

  return (
    <Card {...otherProps}>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 8 }}>
          <Grid item>
            <TitleComponent
              text={`${t('endorsement')} ${endorsement.reference}`}
              variant={'h3'}
              paddingTop={0}
              paddingLeft={12}
            />
          </Grid>
        </Grid>
        <Grid container columns={12} spacing={7} sx={{ mb: 5 }}>
          <Grid item xs={4}>
            <Typography>{t('discountgrid_label')} :</Typography>
            <Typography>
              <Link
                href={urlHelper(API.GET_DISCOUNT_GRID_READ, {
                  id: endorsementContract.discountGridId
                })}
                target="_blank"
              >
                {endorsementContract.discountGridLabel}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              {t('center_price', { price: formatCurrency(endorsementContract.centerPrice) })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 5 }}>
          <Grid item>
            <Stack direction={'row'} alignItems="flex-end" gap={1}>
              <Typography variant="h4">
                {t('price')} : {formatCurrency(newTotal)}/{t('month')}
              </Typography>
              <Typography variant="h5" color={'error'}>
                ({(priceDifference ?? 0) > 0 ? '+' : ''}
                {formatCurrency(priceDifference)})
              </Typography>
              <Typography variant="h5" color={'error'}>
                ({Number(priceRate ?? 0) > 0 ? '+' : ''}
                {formatPercentage(Number(priceRate) / 100)})
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction={'row'} alignItems="flex-end" gap={1}>
              <Typography variant="h4">DG : {formatCurrency(newGuarantee)}</Typography>
              <Typography variant="h5" color={'error'}>
                ({(guaranteesDifference ?? 0) > 0 ? '+' : ''} {formatCurrency(guaranteesDifference)}
                )
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Divider color={'black'} sx={{ marginBottom: 7 }} />
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>
            {t('main_services')}
          </Typography>
        </Box>
        <ListSheetComponent isLoading={isLoading} data={servicesMap} />
        {optionsMap.size > 0 && (
          <>
            <Box display={'flex'} alignItems={'center'} gap={2} marginTop={3}>
              <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>
                {t('options')}
              </Typography>
              <Tooltip title={t('endorsement_options')} placement="top">
                <Help fontSize={'small'} color="primary" />
              </Tooltip>
            </Box>
            <ListSheetComponent isLoading={isLoading} data={optionsMap} />
          </>
        )}
      </CardContent>
    </Card>
  )
}
