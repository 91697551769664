import { z } from 'zod'
import dayjs from 'dayjs'

const creditSchema = z.object({
  id: z.string(),
  typeLabel: z.string(),
  reference: z.string(),
  status: z.string(),
  type: z.string(),
  clientId: z.string(),
  amount: z.string(),
  vat: z.string(),
  total: z.string(),
  due: z.string(),
  clientName: z.string(),
  createdAt: z.string(),
  spentAt: z.string()
})

export type Credit = z.infer<typeof creditSchema>

const creditListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(creditSchema)
})

export type CreditList = z.infer<typeof creditListSchema>

const creditSumSchema = z.object({
  amount: z.number(),
  amountVat: z.number(),
  totalCost: z.number(),
  dueAmount: z.number()
})

export type CreditSum = z.infer<typeof creditSumSchema>

const informationSchema = z.object({
  id: z.number(),
  reference: z.string(),
  clientId: z.number(),
  clientName: z.string(),
  dueAmount: z.number(),
  languageId: z.number(),
  language: z.string(),
  spentAt: z.string(),
  isEditable: z.boolean(),
  isPayable: z.boolean()
})

const InformationSchema = z.object({
  credit: informationSchema,
  activities: z.record(z.string())
})

export type CreditInformation = z.infer<typeof InformationSchema>

export const DeclarePaymentSchema = z.object({
  amount: z.coerce.number(),
  payedDate: z.instanceof(dayjs as any),
  reference: z.string(),
  paymentMode: z.coerce.number()
})

export type CreditDeclarePayment = z.infer<typeof DeclarePaymentSchema>

const PdfSchema = z.object({
  file: z.string()
})

export type CreditPdf = z.infer<typeof PdfSchema>

const InvoiceSelectSchema = z.object({
  id: z.string(),
  reference: z.string(),
  dueAmount: z.string(),
  dueDate: z.string()
})

export type CreditInvoiceSelect = z.infer<typeof InvoiceSelectSchema>

const InvoiceSchema = z.object({
  id: z.string(),
  reference: z.string(),
  totalCost: z.string(),
  dueAmount: z.string(),
  applyAmount: z.string(),
  status: z.string(),
  statusLabel: z.string(),
  createdAt: z.string()
})

export type CreditInvoice = z.infer<typeof InvoiceSchema>

const PaymentSchema = z.object({
  id: z.string(),
  reference: z.string(),
  amount: z.string(),
  status: z.string(),
  statusLabel: z.string(),
  createdAt: z.string()
})

export type CreditPayment = z.infer<typeof PaymentSchema>
