import { z } from 'zod'

const activitySchema = z.object({
  type: z.number(),
  message: z.string(),
  createdAt: z.string(),
  staff: z.number().nullable()
})

const activitiesSchema = z.object({
  isLastPage: z.boolean(),
  page: z.number(),
  activities: z.array(activitySchema)
})

export type Activity = z.infer<typeof activitySchema>
export type Activities = z.infer<typeof activitiesSchema>
