import { Edit } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { HappeningsDetails } from 'api/models'
import { formatDateWithTime } from 'app/utils/format'
import React, { useMemo } from 'react'
import InfoCard, { InfoCardColumns } from 'app/components/card/info-card.component'
import { Box, Stack } from '@mui/system'
import { Paper as MuiPaper } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface IHappeningDetailsProps {
  happening: HappeningsDetails
  isLoading: boolean
}

export const HappeningDetailsComponent = ({ happening, isLoading }: IHappeningDetailsProps) => {
  const { t } = useTranslation()

  const items = useMemo<InfoCardColumns>(() => {
    return [
      {
        label: t('name'),
        value: happening.name
      },
      {
        label: t('center'),
        value: happening.centerName
      },
      {
        label: t('place'),
        value: happening.place
      },
      {
        label: t('description'),
        value: happening.description
      },
      {
        label: t('organized_by'),
        value: happening.partnerName
      },
      {
        label: t('active'),
        value: happening.isActive ? t('yes') : t('no')
      },
      {
        label: t('begin'),
        value: formatDateWithTime(happening.begin)
      },
      {
        label: t('end'),
        value: formatDateWithTime(happening.end)
      },
      {
        label: t('number_of_participants'),
        value: happening.nbUsers
      },
      {
        label: t('number_max_of_participants'),
        value: happening.maxUsers
      },
      {
        label: t('partner_link'),
        value: happening.partnerLink
      },
      {
        label: t('banner'),
        value: happening.banner ? (
          <img src={String(happening.banner)} alt="Banner" width="300" height="150" />
        ) : (
          ''
        )
      },
      {
        label: t('publicated_at'),
        value: formatDateWithTime(happening.publicatedAt)
      }
    ]
  }, [happening, t])
  const navigate = useNavigate()

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack justifyContent="flex-start" spacing={1}>
          <InfoCard
            isLoading={isLoading}
            title={t('informations')}
            titleAction={{
              icon: Edit,
              onClick: () => {
                navigate(`/happenings/${happening.id}/edit`)
              }
            }}
            columns={items}
          />
        </Stack>
      </Box>
    </MuiPaper>
  )
}
