import { CheckboxProps, FormControlLabel } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { CheckboxComponent } from '../checkboxes/checkbox.component'
import React from 'react'

type IControlledTextFieldProps = {
  label?: string
  name: string
  control: Control<any>
  isUncheckedFalse?: boolean //Variable pour supporter les 2 valeurs possibles attendues par l'api false ou undefined,
  values?: { true: string; false: string }
} & CheckboxProps

export function ControlledCheckboxField({
  name,
  label,
  control,
  isUncheckedFalse = true,
  values,
  ...others
}: IControlledTextFieldProps) {
  const uncheckedValue = isUncheckedFalse ? false : undefined
  return (
    <Controller
      render={({ field: { name, value, onChange } }) => {
        return (
          <FormControlLabel
            key={name}
            labelPlacement={'start'}
            label={label || ''}
            sx={{ margin: 0 }}
            control={
              <CheckboxComponent
                checked={values ? value == values.true : value == true}
                onChange={(e) => {
                  if (values) {
                    return onChange(e.target.checked ? values.true : values.false)
                  } else {
                    onChange(e.target.checked ? true : uncheckedValue)
                  }
                }}
                {...others}
              />
            }
          />
        )
      }}
      control={control}
      name={name}
    />
  )
}
