import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RefObject, useCallback } from 'react'
import { CustomerReservationInformations, FormItems } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import Button from '@mui/material/Button'
import * as React from 'react'
import FormEditCustomerReservation from 'app/forms/customerReservations/FormEditCustomerReservation'
import { z } from 'zod'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'

const formDataSchema = z.object({
  id: z.number(),
  owner: z.coerce.number().min(1),
  contract: z.coerce.number().min(1),
  staff: z.coerce.number().nullable()
})

type FormData = z.infer<typeof formDataSchema>

export function CustomerReservationEdit(props: {
  customerReservation: CustomerReservationInformations
  options: FormItems
  dialogRef: RefObject<DialogRef>
}) {
  const { updateCustomerReservation } = useFetcher()
  const methods = useForm<FormData>({
    defaultValues: {
      id: props.customerReservation.id,
      owner: props.customerReservation.ownerId,
      contract: props.customerReservation.contractId || 0,
      staff: props.customerReservation.staffId || 0
    },
    mode: 'onChange',
    resolver: zodResolver(formDataSchema)
  })
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()

  const handleSubmit = useCallback(
    async (data: FormData) => {
      await handleMutation({
        confirm: {
          content: t('confirm_update_reservation')
        },
        mutation: updateCustomerReservation,
        data: data,
        toastSuccess: t('update_reservation_success'),
        toastError: t('update_reservation_error'),
        onSuccess: () => navigate(0)
      })
    },
    [updateCustomerReservation, t]
  )

  return (
    <Dialog
      ref={props.dialogRef}
      title={`${t('customer_reservation')} | ${props.customerReservation.id} (${
        props.customerReservation.clientName
      })`}
      actions={
        <>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              props.dialogRef.current?.close()
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!methods.formState.isValid || methods.formState.isSubmitting}
            onClick={methods.handleSubmit(handleSubmit)}
          >
            {t('submit')}
          </Button>
        </>
      }
    >
      <FormEditCustomerReservation options={props.options} methods={methods} />
    </Dialog>
  )
}
