import { Stack } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { Card, Collapse, IconButton, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import React, { PropsWithChildren, useState } from 'react'
import { darken } from 'polished'
import styled from '@emotion/styled'

const CollapsableStack = styled(Stack)`
  .collapse-item-header {
    cursor: pointer;
  }
  &.collapse-item-0 {
    font-weight: bold;
  }
  &.collapse-item-1 {
    color: ${(props) => props.theme.palette.primary.light};
    .collapse-item-header {
      border-top: 1px solid ${(props) => darken(0.03, props.theme.palette.background.paper)};
    }
    &:first-of-type > .collapse-item-header {
      border-color: ${(props) => props.theme.palette.grey[900]};
    }
  }
  &.collapse-item-2 {
    font-weight: normal;
    &:nth-of-type(odd) {
      background-color: ${(props) =>
        props.theme.palette.mode === 'dark'
          ? darken(0.03, props.theme.palette.background.paper)
          : props.theme.palette.grey[100]};
      color: ${(props) =>
        props.theme.palette.getContrastText(
          props.theme.palette.mode === 'dark'
            ? darken(0.03, props.theme.palette.background.paper)
            : props.theme.palette.grey[100]
        )};
    }
    &:nth-of-type(even) {
      background: ${(props) => props.theme.palette.background.default};
      color: ${(props) =>
        props.theme.palette.getContrastText(props.theme.palette.background.default)};
    }
    .collapse-item-header {
      border-top: 1px solid ${(props) => darken(0.03, props.theme.palette.background.paper)};
    }
  }
`

export const CollapsableCardComponent = function ({
  title,
  children,
  depth
}: { title: string; depth: number } & PropsWithChildren) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Card>
      <CollapsableStack sx={{ width: '100%' }} className={'collapse-item-' + depth}>
        <Stack
          onClick={() => setOpen(!open)}
          className="collapse-item-header"
          paddingLeft={4 * (depth + 1)}
          paddingRight={5}
          paddingY={3}
          sx={{ width: '100%', boxSizing: 'border-box' }}
        >
          <Stack
            flexDirection={'row'}
            gap={3}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {depth === 0 ? (
              <TitleComponent text={title} variant={'h3'} paddingLeft={12} />
            ) : (
              <Typography fontWeight={depth < 2 ? 'bold' : undefined}>{title}</Typography>
            )}
            <IconButton size={'small'} onClick={() => setOpen(!open)}>
              {children && (open ? <ExpandLess /> : <ExpandMore />)}
            </IconButton>
          </Stack>
        </Stack>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </CollapsableStack>
    </Card>
  )
}
