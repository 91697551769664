import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { FormItem } from 'api/models'

interface ISelectProps {
  filter: FormItem
  data: string
  onChange: (e: any) => void
  isEdit: boolean
}

export const SelectLanguages = ({ filter, data, onChange, isEdit }: ISelectProps) => {
  return (
    <FormControl sx={{ width: '100%', height: '100%' }} size={'small'}>
      <InputLabel>{filter.label}</InputLabel>
      <Select value={data} label={filter.label} onChange={onChange} disabled={!isEdit}>
        {filter.values.map((value) => (
          <MenuItem key={value.id} value={value.id}>
            {value.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
