import React, { useMemo } from 'react'
import { Grid, useTheme } from '@mui/material'
import { CenterStaffCard } from 'modules/centers/components/center-staff-card.component'
import { CenterDetails } from 'api/models'

interface ICenterStaffsCardProps {
  center: CenterDetails
}

export const CenterStaffsCard = ({ center }: ICenterStaffsCardProps) => {
  const theme = useTheme()
  const centerStaffs = useMemo(() => center.staffs, [center])

  return (
    <Grid container spacing={6} sx={{ marginBottom: theme.spacing(8) }} alignItems={'stretch'}>
      {centerStaffs.map((staff) => {
        if (staff.isActive) {
          return <CenterStaffCard key={staff.id} staff={staff}></CenterStaffCard>
        }
      })}
    </Grid>
  )
}
