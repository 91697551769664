import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { TitleComponent } from 'app/components/titles/title.component'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import type { IndividualDetails, IndividualPartners } from 'api/models'
import styled from '@emotion/styled'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'

const CustomGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;
  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const ButtonRow = styled(Stack)`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 1rem;
  align-items: center;
`

const Item = styled(Stack)`
  gap: 8px;
  flex-direction: row;
  height: fit-content;
  align-items: center;
`

const ItemName = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
`

export interface IProps {
  individual: IndividualDetails
}

export const PartnerCardComponent = ({ individual }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { getIndividualPartners, updateActivCorner } = useFetcher()
  const { handleMutation } = useFeedback()

  const [isLoading, setIsLoading] = useState(true)
  const [partners, setPartners] = React.useState<IndividualPartners>({} as IndividualPartners)

  const getPartnersInformations = useCallback(async () => {
    if (individual.id) {
      const data = await getIndividualPartners.mutateAsync(String(individual.id))
      setPartners(data)
    }
  }, [individual.id])

  const handleUpdateActivCorner = useCallback(async () => {
    if (individual.id) {
      await handleMutation({
        confirm: {
          content: t('confirm_update_activ_corner')
        },
        onStart: () => setIsLoading(true),
        mutation: updateActivCorner,
        data: String(individual.id),
        onSuccess: await getPartnersInformations,
        toastSuccess: t('active_corner_updated'),
        toastError: t('error'),
        onEnd: () => setIsLoading(false)
      })
    }
  }, [individual.id])

  useEffect(() => {
    if (individual.id) {
      getPartnersInformations().then(() => setIsLoading(false))
    }
  }, [individual.id])

  return (
    <>
      {isLoading ? (
        <CardSkeleton height={500} />
      ) : (
        <>
          <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <TitleComponent text={t('Partners')} variant={'h3'} paddingTop={0} paddingLeft={12} />
            </Stack>
          </Box>
          <CustomGrid>
            <Item>
              <Stack>
                <Typography>activCorner</Typography>
                <ItemName variant="body2">{partners.activCorner ? t('yes') : t('no')}</ItemName>
              </Stack>
            </Item>
          </CustomGrid>
          <ButtonRow>
            <Button onClick={handleUpdateActivCorner}>
              {t(partners.activCorner ? 'activcorner_disable' : 'activcorner_enable')}
            </Button>
          </ButtonRow>
        </>
      )}
    </>
  )
}
