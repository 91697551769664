import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Container, Stack } from '@mui/system'
import { EmailTemplateSheetType } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { Link } from 'app/components/link.component'
import { TabPanel } from 'app/components/tab-panel.component'
import { EmailTemplateDetails } from 'modules/emails/components/email-template-details.components'
import { EmailTemplatePreview } from 'modules/emails/components/email-template-preview.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { KeyboardBackspace } from '@mui/icons-material'
import { useFeedback } from 'app/providers/feedback.provider'

export const EmailView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { getOneEmailTemplate } = useFetcher()
  const [emailTemplate, setEmail] = useState({} as EmailTemplateSheetType)

  const { handleMutation } = useFeedback()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const fetchEmail = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getOneEmailTemplate,
      data: id,
      onSuccess: (data) => {
        setEmail(data)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [setIsLoading, getOneEmailTemplate, id, setEmail, handleMutation])

  useEffect(() => {
    fetchEmail().then()
  }, [])

  return isLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/emails`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">
              {t('email_template')} |{' '}
              <Link to={`/emails/${emailTemplate.id}`}>{emailTemplate.name}</Link>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <TabPanel value={0} index={0}>
        <EmailTemplateDetails emailTemplate={emailTemplate} />
        <EmailTemplatePreview emailTemplate={emailTemplate} />
      </TabPanel>
    </Container>
  )
}
