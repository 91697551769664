import { useCallback, useState } from 'react'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useDebouce } from 'app/hooks/use-debounce'
import type { Staff } from 'api/models'
import { SetURLSearchParams } from 'react-router-dom'

interface ISearchStaffInputProps {
  slug: string
  onChange?: () => void
  setSearchParams?: SetURLSearchParams
  searchParams: URLSearchParams
  defaultValue?: Number
}

export function SearchStaffInput({
  slug,
  onChange,
  searchParams,
  setSearchParams,
  defaultValue
}: ISearchStaffInputProps) {
  const { getStaffFilters } = useFetcher()
  const { t } = useTranslation()
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null)
  const [staffs, setStaffs] = useState<Staff[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [initialValue, setInitialValue] = useState<Number | undefined>(defaultValue)

  const handleTyping = useCallback(async (event: any) => {
    if (!event || event.target.value === 0) return
    const value = event.target.value as string
    setInputValue(value)
  }, [])

  useDebouce(
    () => {
      setLoading(true)
      getStaffFilters
        .mutateAsync({
          name: inputValue
        })
        .then((data) => {
          const _staffs = data
            .map((obj) => {
              if (!obj.children) return null
              return obj.children
            })
            .filter((obj) => obj !== null)
            .flat() as unknown as Staff[]
          if (initialValue && !searchParams.get(slug)) {
            const staffObject = _staffs.find((obj) => obj.id === initialValue)
            if (staffObject) {
              handleOnChange(null, staffObject)
            }
            setInitialValue(undefined)
          }
          setStaffs(_staffs)
        })
        .finally(() => setLoading(false))
    },
    [inputValue, searchParams, setSearchParams],
    350
  )

  const handleOnChange = useCallback(
    (event: any, newValue: any) => {
      setSelectedStaff(newValue)

      if (setSearchParams) {
        setSearchParams((searchParams) => {
          if (newValue) {
            searchParams.set(slug, newValue.id)
          } else {
            searchParams.delete(slug)
          }
          return searchParams
        })
      } else {
        if (newValue) {
          searchParams.set(slug, newValue.id)
        } else {
          searchParams.delete(slug)
        }
      }

      if (onChange) {
        onChange()
      }
    },
    [onChange, setSearchParams, searchParams, slug]
  )

  return (
    <Stack>
      <Autocomplete
        disablePortal
        autoComplete
        id="staff-search"
        size={'small'}
        onChange={handleOnChange}
        value={selectedStaff}
        onInputChange={handleTyping}
        loadingText={t('loading')}
        noOptionsText={t('no_options')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.text}
        selectOnFocus
        options={staffs}
        sx={{ width: 200 }}
        renderInput={(params) => (
          <TextField
            {...(params as any)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={13} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            label={t('staff')}
          />
        )}
      />
    </Stack>
  )
}
