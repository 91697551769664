import {
  Card,
  CardContent,
  Typography,
  useTheme,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import dayjs from 'dayjs'
import { DashboardOpenDesktops } from 'api/models/dashboard'
import { Link } from 'app/components/link.component'
interface IDashboardCardOpenDesktops {
  openDesktops: DashboardOpenDesktops
  isLoading: boolean
}
export function DashboardCardOpenDesktops({ openDesktops, isLoading }: IDashboardCardOpenDesktops) {
  const theme = useTheme()
  if (isLoading) {
    return <CardSkeleton height={160}></CardSkeleton>
  }
  return (
    <Card>
      <CardContent>
        <TableContainer>
          <Table size="small" sx={{ minWidth: 650 }}>
            <TableBody>
              {openDesktops.map((openDesktop, index) => (
                <TableRow key={index} hover>
                  <TableCell style={{ whiteSpace: 'nowrap', color: theme.palette.primary.light }}>
                    {openDesktop.reservationId ? (
                      <Link to={`/customer_reservations/${openDesktop.reservationId}`}>
                        {dayjs(openDesktop.begin).format('L LT')}
                      </Link>
                    ) : (
                      dayjs(openDesktop.begin).format('L LT')
                    )}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap', color: theme.palette.primary.light }}>
                    {openDesktop.reservationId ? (
                      <Link to={`/customer_reservations/${openDesktop.reservationId}`}>
                        {dayjs(openDesktop.end).format('L LT')}
                      </Link>
                    ) : (
                      dayjs(openDesktop.end).format('L LT')
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography component={'span'} variant={'body1'} sx={{ opacity: 0.75 }}>
                      {openDesktop.reservationId ? (
                        <Link to={`/customer_reservations/${openDesktop.reservationId}`}>
                          {openDesktop.desktop}
                        </Link>
                      ) : (
                        openDesktop.desktop
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <RouterLink
                      to={
                        openDesktop.clientType == 'enterprise'
                          ? `/enterprises/${openDesktop.clientId}`
                          : `/individuals/${openDesktop.clientId}`
                      }
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {openDesktop.clientName}
                    </RouterLink>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}
