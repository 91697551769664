import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { Box, Container, Grid, Paper as MuiPaper, Typography } from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { MonthPicker } from 'app/components/filters/month-picker'
import { List } from 'app/components/lists/list'
import dayjs from 'dayjs'
import { useFeedback } from 'app/providers/feedback.provider'
import { MonthlyStats } from 'api/models/stats'
import { CaVsBpLineChartComponent } from 'modules/stats/components/ca-vs-bp-line-chart.component'

export const StatsCaVsBpView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { setSearchParams, searchParams, getMonthlyStatsCaVsBp } = useFetcher()
  const { filtersList, orderBy, handleSort, handleFilter, initFilters } = useList()
  const { handleMutation } = useFeedback()
  const commonFilters = new Map<string, string>([['', '']])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [originalData, setOriginalData] = useState<MonthlyStats>()

  const formatDateBeginEndForMonth = () => {
    const month = searchParams.get('month')
    const dateBegin = dayjs(month).subtract(12, 'months').startOf('month').format('YYYY-MM-DD')
    const dateEnd = dayjs(month).endOf('month').format('YYYY-MM-DD')
    searchParams.set('date_begin', dateBegin)
    searchParams.set('date_end', dateEnd)
  }

  const transformData = (data: any[]) => {
    return data.map((item) => ({
      center: item.centerName,
      to: item.to,
      toVsBP: item.toBp !== 0 ? (((item.to - item.toBp) / item.toBp) * 100).toFixed(0) : 'N/A',
      caVsBP: item.caBp !== 0 ? (((item.ca - item.caBp) / item.caBp) * 100).toFixed(0) : 'N/A',
      deltaCAYTDvsCaBpYTD:
        item.caBpYTD !== 0 ? (((item.caYTD - item.caBpYTD) / item.caBpYTD) * 100).toFixed(0) : 'N/A'
    }))
  }

  const dataPerCenter = useMemo(
    () => (originalData ? transformData(originalData) : []),
    [originalData]
  )

  useEffect(() => {
    initFilters(commonFilters).then()
    setIsLoading(true)
    if (!searchParams.get('month')) {
      const now = dayjs()
      searchParams.set('month', now.subtract(1, 'months').startOf('month').format('YYYY-MM'))
    }
    formatDateBeginEndForMonth()
    setIsLoading(false)
  }, [])

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getMonthlyStatsCaVsBp,
      onSuccess: (data: MonthlyStats) => {
        setOriginalData(data)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    refreshList().then()
  }, [refreshList])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('dashboard_ca_vs_bp')}
        </Typography>
      </Box>
      <Grid paddingBottom={6}>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <MonthPicker
            slug="month"
            label={t('date')}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            views={['month', 'year']}
            disableFuture={true}
            onChange={() => {
              formatDateBeginEndForMonth()
              handleFilter(refreshList, true).then()
            }}
          />
        </FiltersBox>
      </Grid>
      <Grid container spacing={6}>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('result_for_month')}</Typography>
              </Box>
              <Box padding={6} height="400px" sx={{ overflow: 'auto' }}>
                <List
                  items={dataPerCenter}
                  columns={[
                    { label: t('center'), slug: 'center' },
                    {
                      label: t('occupation_rate'),
                      slug: 'to',
                      valueFormatter: (value: string) => `${value}%`
                    },
                    {
                      label: t('Delta TO vs BP'),
                      slug: 'toVsBP',
                      valueFormatter: (value: string) => `${value}%`
                    },
                    {
                      label: t('ca_vs_bp'),
                      slug: 'deltaCAYTDvsCaBpYTD',
                      valueFormatter: (value: string) => `${value}%`
                    }
                  ]}
                  handleReset={() => handleFilter(refreshList, true)}
                  sort={orderBy}
                  handleSort={(property) => handleSort(refreshList, property)}
                  isLoading={isLoading}
                />
              </Box>
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">
                  {t('ca')} {t('vs')} {t('BP')}
                </Typography>
              </Box>
              <Box padding={6} height={400}>
                <CaVsBpLineChartComponent height={300} params={searchParams} />
              </Box>
            </MuiPaper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
