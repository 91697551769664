import React from 'react'

import {
  Box,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Typography
} from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { ArrowBack } from '@mui/icons-material'
import SolutionCardComponent from 'modules/pricers/components/solution-card.component'
import DateQuotationComponent from 'modules/pricers/components//date-quotation.component'
import ServiceDomiciliationQuotationComponent from 'modules/pricers/components/service-domiciliation-quotation.component'
import ServiceCoworkingQuotationComponent from 'modules/pricers/components/service-coworking-quotation.component'
import ServiceOfficeQuotationComponent from 'modules/pricers/components/service-office-quotation.component'
import ServiceNomadeQuotationComponent from 'modules/pricers/components/service-nomade-quotation.component'
import { usePricer } from 'app/providers/pricer.provider'
import { Link } from 'react-router-dom'

const PricerView = () => {
  const { t } = useTranslation()
  const { pricerInformations, isLoadingInformations, isLoadingServices, error } = usePricer()
  return (
    <Container>
      <Grid container marginBottom="2rem">
        <Grid item xs={12}>
          {isLoadingInformations ? (
            <Skeleton variant="rectangular" width="100%" height={50} />
          ) : (
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Link to={`/opportunities/${pricerInformations.id}`}>
                  <IconButton size={'small'}>
                    <ArrowBack fontSize={'small'} />
                  </IconButton>
                </Link>
                <Box>
                  <Typography variant="h1">
                    {t('opportunity_pricer')} | {pricerInformations.enterpriseName}
                  </Typography>
                  <Typography variant="body2">{pricerInformations.reference}</Typography>
                </Box>
              </Stack>
              {!error && <DateQuotationComponent />}
            </Stack>
          )}
        </Grid>
      </Grid>
      {error ? (
        <Typography variant="h1">{t('error_pricer')}</Typography>
      ) : (
        <>
          <Grid container columnSpacing={8} columns={12} marginBottom="1rem">
            <SolutionCardComponent solutionReference={'S1'} />
            <SolutionCardComponent solutionReference={'S2'} />
            <SolutionCardComponent solutionReference={'S3'} />
            <SolutionCardComponent solutionReference={'S4'} />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            marginBottom="2rem"
          ></Grid>
          <Grid container marginBottom="2rem">
            <Grid item xs={12}>
              <Paper>
                {isLoadingServices ? (
                  <Skeleton variant="rectangular" width="100%" height={400} />
                ) : (
                  <TableContainer>
                    <Table data-cy="opportunity-prices" size="small" sx={{ minWidth: 650 }}>
                      <ServiceOfficeQuotationComponent />
                      <ServiceCoworkingQuotationComponent />
                      <ServiceNomadeQuotationComponent />
                      <ServiceDomiciliationQuotationComponent />
                    </Table>
                  </TableContainer>
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  )
}
export default PricerView
