import { Card, CardContent } from '@mui/material'

import { TitleComponent } from 'app/components/titles/title.component'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatCurrency, formatDate } from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { ContractPaymentList } from 'api/models'

interface IPaymentsProps {
  paymentValues: ContractPaymentList
  isLoading: boolean
}

export const PaymentsComponent = ({ paymentValues, isLoading }: IPaymentsProps) => {
  const { t } = useTranslation()
  const [initMap, setInitMap] = useState<Map<string, any>>(new Map())

  useEffect(() => {
    setInitMap(() => {
      const map = new Map()
      map.set('items', [
        { label: t('invoice').toUpperCase(), value: 'invoiceReference' },
        { label: t('period').toUpperCase(), value: 'period', format: formatDate },
        { label: t('amount').toUpperCase(), value: 'amount', format: formatCurrency }
      ])
      map.set('data', paymentValues)
      return map
    })
  }, [paymentValues])

  return (
    <Card>
      <CardContent>
        <TitleComponent text={t('payments')} variant={'h3'} paddingLeft={12} />
        <ListSheetComponent isLoading={isLoading} data={initMap} />
      </CardContent>
    </Card>
  )
}
