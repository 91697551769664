import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Container } from '@mui/system'
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { List } from 'app/components/lists/list'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { DiscountGridsCenter } from 'api/models'
import { useTranslation } from 'react-i18next'
import { formatDate, formatDiscountGridType } from 'app/utils/format'
import { useFeedback } from 'app/providers/feedback.provider'
import { AddCircleOutline } from '@mui/icons-material'
import BaseModal from 'app/components/modals/base.modal'
import { DatePicker } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'

export const DiscountGridsCenterView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const {
    getDiscountGridsCenter,
    searchParams,
    setSearchParams,
    getFormItems,
    getDiscountGridsCenterAdd
  } = useFetcher()
  const [discountGridsCenter, setDiscountGridsCenter] = useState<DiscountGridsCenter[]>([])
  const [discountGrids, setDiscountGrids] = useState<any[]>()
  const [discountGridsSelect, setDiscountGridsSelect] = useState<any[]>()
  const [centerTitle, setCenterTitle] = useState<string>('')
  const [centerId, setCenterId] = useState<string>('')
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [selectedGrid, setSelectedGrid] = useState<string>('')
  const [oldDiscountGrid, setOldDiscountGrid] = useState<string>('')
  const [begin, setBegin] = useState<Dayjs | null>(dayjs().add(1, 'day'))
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['centers', 'center'],
      ['active_status', 'inprogress'],
      ['discount_grid_type', 'type'],
      ['discount_grids', 'discountgrid']
    ])
  )
  const paramsFilters = useMemo(() => {
    return {
      active_status: {
        all: true
      },
      centers: {
        all: true,
        multiple: false,
        default: -1
      },
      discount_grid_type: {
        default: 1
      }
    }
  }, [])
  const { handleMutation } = useFeedback()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)

      await handleMutation({
        mutation: getDiscountGridsCenter,
        onSuccess: (data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setDiscountGridsCenter(reset ? data.items : (prev) => [...prev, ...data.items])
        }
      })

      await handleMutation({
        mutation: getFormItems,
        data: ['discount_grids'],
        onSuccess: (response) => setDiscountGrids(response.discount_grids?.values)
      })

      setListIsLoading(false)
    },
    [discountGridsCenter, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters, paramsFilters).then(() => refreshList(true))
  }, [])

  const formatEnd = (item: DiscountGridsCenter) => {
    if (item.end !== null) {
      return formatDate(item.end)
    }

    return (
      <Button sx={{ display: 'flex', alignItems: 'center' }} onClick={() => handleModal(item)}>
        <AddCircleOutline fontSize={'small'} />
      </Button>
    )
  }

  const handleModal = useCallback(
    (item: DiscountGridsCenter) => {
      if (discountGrids !== undefined) {
        setDiscountGridsSelect(discountGrids.filter((grid) => grid.type === item.discountGridType))
        if (item.discountGridType === '1') {
          setSelectedGrid('2')
        } else {
          setSelectedGrid('6')
        }
        setOldDiscountGrid(item.discountGridId)
        setCenterTitle(item.centerName)
        setCenterId(item.centerId)
        setOpenModal(true)
      }
    },
    [discountGrids]
  )

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()

      await handleMutation({
        mutation: getDiscountGridsCenterAdd,
        data: {
          center: centerId,
          discountGrid: selectedGrid,
          oldDiscountGrid: oldDiscountGrid,
          begin: String(begin?.format('YYYY-MM-DD'))
        },
        onSuccess: () => refreshList(true).then(),
        onEnd: () => setOpenModal(false)
      })
    },
    [centerId, selectedGrid, begin, oldDiscountGrid]
  )

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('discount_grids_list_center')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        ></FiltersBox>
      </Grid>
      <Grid>
        <BaseModal
          open={openModal}
          setOpen={setOpenModal}
          title={centerTitle + ' | ' + t('new_assignment')}
          handleUpdate={handleSubmit}
        >
          <Grid container columnSpacing={5}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size={'small'}>
                <InputLabel id="demo-simple-select-label">{t('discountgrid')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedGrid}
                  onChange={(e) => setSelectedGrid(e.target.value)}
                  label={t('discountgrid')}
                >
                  {discountGridsSelect?.map((grid) => (
                    <MenuItem key={grid.id} value={grid.id}>
                      {grid.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size={'small'}>
                <DatePicker
                  label={t('begin')}
                  value={begin}
                  onChange={(date) => setBegin(date)}
                  slotProps={{
                    textField: { size: 'small' }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </BaseModal>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {discountGridsCenter.length} / {total}
          </Typography>
        </Grid>
        <List
          items={discountGridsCenter}
          columns={[
            { label: t('center'), slug: 'centerName' },
            { label: t('label'), slug: 'discountGridLabel' },
            { label: t('type'), slug: 'discountGridType', valueFormatter: formatDiscountGridType },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDate },
            { label: t('end'), slug: 'id', condition: formatEnd }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {discountGridsCenter.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
