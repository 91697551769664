import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { Container, Grid, Tab, Tabs, Typography } from '@mui/material'
import { Link } from 'app/components/link.component'
import { TabPanel } from 'app/components/tab-panel.component'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { PaperGreyComponent } from 'app/components/papers/paper-grey.component'
import { CompanyInformations } from 'modules/companies/components/company-information.component'
import { Company } from 'api/models/companies'
import { CompanyCentersComponent } from 'modules/companies/components/company-centers.component'
import { CompanyConfigurationsComponent } from 'modules/companies/components/company-configurations.component'
import { CompanyBankAccountsComponent } from 'modules/companies/components/company-bank-accounts.component'

const TAB_TYPE = {
  INFORMATIONS: 0,
  CENTERS: 1,
  CONFIGURATIONS: 2,
  BANK_ACCOUNTS: 3
}

export const CompanyView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [company, setCompany] = useState<Company>({} as Company)
  const [tab, setTab] = useState(0)
  const { getCompany } = useFetcher()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(async () => {
    if (!id) return
    setListIsLoading(true)
    getCompany
      .mutateAsync(Number(id))
      .then((data) => {
        setCompany(data)
      })
      .finally(() => {
        setListIsLoading(false)
      })
  }, [id])

  const handleChangeTab = async (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  useEffect(() => {
    refreshList().then()
  }, [refreshList])

  return listIsLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {t('company')} | <Link to={`/companies/${company.id}`}>{company.companyName}</Link>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="body2">{company.reference}</Typography>
      <PaperGreyComponent>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="company_tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ marginBottom: 6 }}
        >
          <Tab label={t('informations')} value={TAB_TYPE.INFORMATIONS} />
          <Tab label={t('centers')} iconPosition={'end'} value={TAB_TYPE.CENTERS} />
          <Tab label={t('configuration')} iconPosition={'end'} value={TAB_TYPE.CONFIGURATIONS} />
          <Tab label={t('bank_accounts')} iconPosition={'end'} value={TAB_TYPE.BANK_ACCOUNTS} />
        </Tabs>
      </PaperGreyComponent>
      <TabPanel value={tab} index={TAB_TYPE.INFORMATIONS}>
        <CompanyInformations company={company} />
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.CENTERS}>
        <CompanyCentersComponent company={company} />
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.CONFIGURATIONS}>
        <CompanyConfigurationsComponent company={company} />
      </TabPanel>
      <TabPanel value={tab} index={TAB_TYPE.BANK_ACCOUNTS}>
        <CompanyBankAccountsComponent company={company} />
      </TabPanel>
    </Container>
  )
}
