import * as React from 'react'
import DialogMUI, { DialogProps as MUIDialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react'
import { Chip, CircularProgress, IconButton, Paper, Slide, Stack, Typography } from '@mui/material'
import { AddPhotoAlternate, Close, HideImage } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import Carousel from 'app/components/carousel/carousel.component'
import styled from '@emotion/styled'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useQueryClient } from '@tanstack/react-query'
import { LoadingButton } from '@mui/lab'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export enum typeTags {
  services = 'services',
  serviceTypes = 'service-types',
  centers = 'centers'
}

export type DialogRef = {
  open: () => void
  close: () => void
}

type DialogProps = {
  entityLabel: string
  entityId: string
  type: string
  onClose?: () => void
  canDismiss?: boolean
} & Omit<MUIDialogProps, 'title' | 'open'>

export const EditImagesDialog = forwardRef<DialogRef, DialogProps>(
  ({ type, entityLabel, entityId, onClose, canDismiss = true, ...otherProps }, ref) => {
    const { handleMutation, toast } = useFeedback()
    const { useGetAssets, useAddAsset, useDeleteAsset } = useFetcher()
    const [open, setOpen] = React.useState(false)
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const typeLabel = useMemo(() => {
      switch (type) {
        case typeTags.services:
          return t('service')
        case typeTags.serviceTypes:
          return t('service_type')
        case typeTags.centers:
          return t('center')
        default:
          return ''
      }
    }, [type, t])

    const { data: images, isPending } = useGetAssets({ id: entityId, type, enabled: !!entityId })

    const refreshList = useCallback(async () => {
      await queryClient.invalidateQueries({
        queryKey: ['query', 'assets', 'list', type, entityId]
      })
    }, [queryClient, entityId, type])

    const addAssetMutation = useAddAsset()
    const onAdd = useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files == null) return

        const file = event.target.files[0]
        event.target.value = ''

        if (!file) return
        const formData = new FormData()
        formData.append('file', file)

        await handleMutation({
          mutation: addAssetMutation,
          data: { id: entityId, type: type, formData: formData },
          toastSuccess: t('image_added_successfully'),
          toastError: t('error_adding_image'),
          onError: (error) => {
            toast({ message: error.message ?? t('error_adding_image'), variant: 'error' })
          },
          onSuccess: refreshList
        })
      },
      [addAssetMutation, entityId, handleMutation, refreshList, t, type, toast]
    )

    const deleteAssetMutation = useDeleteAsset()
    const handleDelete = useCallback(
      async (id: number) => {
        await handleMutation({
          mutation: deleteAssetMutation,
          data: { id: entityId, type: type, imageId: String(id) },
          toastSuccess: t('image_deleted_successfully'),
          toastError: t('error_deleting_image'),
          onSuccess: refreshList
        })
      },
      [entityId, type, deleteAssetMutation, handleMutation, refreshList, t]
    )

    const handleClickOpen = useCallback(() => setOpen(true), [setOpen])
    const handleClose = useCallback(() => {
      setOpen(false)
      if (onClose) onClose()
    }, [onClose, setOpen])

    useImperativeHandle(ref, () => ({
      open: handleClickOpen,
      close: handleClose
    }))

    return (
      <DialogMUI
        maxWidth={'md'}
        fullWidth={true}
        {...otherProps}
        open={open}
        onClose={canDismiss ? handleClose : undefined}
        TransitionComponent={Slide}
        data-cy="edit-images-modal"
      >
        <DialogTitle variant="h2">
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
            <div>
              <Typography variant="h2">{t('update_service_images')}</Typography>
              <Typography variant="h3">
                {typeLabel} <Chip label={entityLabel} size="small" />
              </Typography>
            </div>
            {canDismiss && (
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            )}
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Paper variant="outlined">
            {(images ?? []).length > 0 ? (
              <Carousel
                entityId={entityId}
                entityType={type}
                onDelete={handleDelete}
                isDeleteLoading={deleteAssetMutation.isPending}
              />
            ) : (
              <Stack alignItems="center" justifyContent="center" height={400} gap={4}>
                {isPending ? (
                  <>
                    <CircularProgress />
                    <Typography fontWeight="500">{t('loading_images')}</Typography>
                  </>
                ) : (
                  <>
                    <HideImage color="action" fontSize="large" />
                    <Typography fontWeight="500">{t('no_image_associated')}</Typography>
                  </>
                )}
              </Stack>
            )}
          </Paper>
        </DialogContent>
        <DialogActions sx={{ padding: '0px 24px 16px 24px' }}>
          <LoadingButton
            component="label"
            variant="contained"
            startIcon={<AddPhotoAlternate />}
            loading={addAssetMutation.isPending}
            data-cy="add-image"
          >
            {t('add_image')}
            <VisuallyHiddenInput
              type="file"
              onChange={onAdd}
              id="file"
              multiple={false}
              accept="image/*"
            />
          </LoadingButton>
        </DialogActions>
      </DialogMUI>
    )
  }
)
