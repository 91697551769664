import { z } from 'zod'

const discountGridsSchema = z.object({
  id: z.string(),
  label: z.string(),
  type: z.string(),
  typeLabel: z.string()
})

export type DiscountGrids = z.infer<typeof discountGridsSchema>

const discountGridsListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(discountGridsSchema)
})

export type DiscountGridsList = z.infer<typeof discountGridsListSchema>

export const discountGridSchema = z.object({
  label: z.string(),
  type: z.number(),
  typeLabel: z.string(),
  isActive: z.boolean(),
  centers: z.array(z.number(), z.string()),
  typologyRates: z.array(z.string(), z.number()),
  commitmentRates: z.array(z.string(), z.number())
})

export type DiscountGridRead = z.infer<typeof discountGridSchema>
export const discountGridAddSchema = z.object({
  label: z.string(),
  type: z.string(),
  typeLabel: z.string().optional(),
  isActive: z.boolean().optional(),
  typologyRates: z
    .array(
      z.object({
        id: z.number(),
        rate: z.number()
      })
    )
    .optional(),
  commitmentRates: z
    .array(
      z.object({
        id: z.number(),
        rate: z.number()
      })
    )
    .optional()
})

export type DiscountGridAdd = z.infer<typeof discountGridAddSchema>
