import styled from '@emotion/styled'
import { AddCircle, RemoveCircle, UploadFile } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper as MuiPaper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { Container, Stack } from '@mui/system'

import type { EnterpriseDocuments, EnterpriseInformation, FormItems } from 'api/models'
import { formatDate, formatDocumentStatus } from 'app/utils/format'
import React, { useCallback, useEffect, useState } from 'react'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { FormProvider, useForm } from 'react-hook-form'
import { useFeedback } from 'app/providers/feedback.provider'
import { CheckboxComponent } from 'app/components/checkboxes/checkbox.component'
import { TitleComponent } from 'app/components/titles/title.component'

interface IEnterpriseDocumentProps {
  enterprise: EnterpriseInformation
  options: FormItems
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export function EnterpriseDocumentsComponent({ enterprise, options }: IEnterpriseDocumentProps) {
  const { t } = useTranslation()
  const [items, setItems] = useState([] as EnterpriseDocuments[])
  const [isLoading, setIsLoading] = useState(false)
  const { orderBy, handleSort, handleFilter } = useList()
  const { getEnterpriseDocuments, uploadDocument } = useFetcher()
  const [addIsVisible, setAddIsVisible] = useState(false)
  const [invalidated, setInvalidated] = useState(0)
  const { handleMutation } = useFeedback()
  const methods = useForm<{ file: any; name: string; type: number }>({
    defaultValues: {
      file: '',
      name: '',
      type: 1
    }
  })

  const refreshList = useCallback(async () => {
    if (!enterprise.id) return
    setIsLoading(true)
    try {
      const data = await getEnterpriseDocuments.mutateAsync({
        id: String(enterprise.id),
        invalidated
      })
      setItems(() => data.items as EnterpriseDocuments[])
    } finally {
      setIsLoading(false)
    }
  }, [invalidated, enterprise.id])

  const onInvalidatedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidated(event.target.checked ? 1 : 0)
  }

  const toggleAdd = useCallback(() => {
    setAddIsVisible(!addIsVisible)
  }, [addIsVisible, setAddIsVisible])

  useEffect(() => {
    refreshList()
  }, [invalidated, enterprise.id])

  const onSubmit = useCallback(
    async (data: any) => {
      await handleMutation({
        mutation: uploadDocument,
        data: {
          id: String(enterprise.id),
          data: {
            name: data.name,
            file: data.file[0],
            type: data.type
          }
        },
        toastSuccess: t('upload_success'),
        toastError: t('upload_error'),
        onEnd: () => {
          methods.reset()
          refreshList()
        }
      })
    },
    [enterprise]
  )

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent text={t('documents_list')} variant={'h3'} paddingLeft={12} />
          <IconButton color="primary" onClick={toggleAdd}>
            {addIsVisible ? <RemoveCircle fontSize="small" /> : <AddCircle fontSize="small" />}
          </IconButton>
        </Stack>
      </Box>
      {addIsVisible && (
        <Container>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container columns={12} columnSpacing={2} textAlign={'center'}>
                <Grid item xs={3} alignItems={'center'}>
                  <Button
                    component="label"
                    fullWidth
                    variant="contained"
                    startIcon={<UploadFile />}
                  >
                    {t('add_file')}
                    <VisuallyHiddenInput
                      {...methods.register('file')}
                      type="file"
                      id="file"
                      multiple={false}
                    />
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    size={'small'}
                    label={t('name')}
                    {...methods.register('name')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel size={'small'} id="demo-simple-select-label">
                      {t('type')}
                    </InputLabel>
                    <Select
                      label={t('type')}
                      size={'small'}
                      fullWidth={true}
                      {...methods.register('type')}
                    >
                      {options.document_types?.values.map((value) => (
                        <MenuItem value={value.id}>{value.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <Button color="secondary" fullWidth variant="contained" type={'submit'}>
                    {t('submit')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Container>
      )}
      <Grid container columns={4}>
        <Grid item xs={4} textAlign={'right'}>
          <Typography>
            <FormControlLabel
              labelPlacement={'end'}
              label={t('invalid_documents')}
              control={
                <CheckboxComponent checked={invalidated == 1} onChange={onInvalidatedChange} />
              }
            />
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <List
            items={items}
            selectable={false}
            columns={[
              {
                slug: 'name',
                label: t('name'),
                link: { base: `/enterprises/${enterprise.id}/document`, slug: 'id' }
              },
              {
                slug: 'status',
                text: 'statusLabel',
                label: '',
                valueFormatter: formatDocumentStatus,
                link: { base: `/enterprises/${enterprise.id}/document`, slug: 'id' }
              },
              {
                slug: 'type',
                label: t('type'),
                link: { base: `/enterprises/${enterprise.id}/document`, slug: 'id' }
              },
              {
                slug: 'createdAt',
                label: t('date'),
                valueFormatter: formatDate,
                link: { base: `/enterprises/${enterprise.id}/document`, slug: 'id' }
              }
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
