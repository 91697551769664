import { type Ctx, fetcher } from 'api'
import { BASE_URL, API } from 'api/constants'
import urlHelper from 'app/helpers/url.helper'
import {
  ConsumptionContract,
  ConsumptionContractConsumptions
} from 'api/models/consumption-contracts'

export const consumptionContractsRouter = {
  get: async ({ token }: Ctx, id: string) =>
    fetcher<ConsumptionContract>({
      url: `${BASE_URL}${urlHelper(API.GET_CONSUMPTION_CONTRACT, { id: id })}`,
      method: 'GET',
      token: token
    }),
  getConsumptions: async ({ token }: Ctx, id: string) =>
    fetcher<ConsumptionContractConsumptions>({
      url: `${BASE_URL}${urlHelper(API.GET_CONSUMPTION_CONTRACT_CONSUMPTIONS, { id: id })}`,
      method: 'GET',
      token: token
    })
}
