import { Grid, SxProps, Theme } from '@mui/material'
import type { Filter, Filters } from 'api/models'
import { MultipleSelect } from './multiple-select'
import { Select } from './select'
import { FiltersSkeleton } from '../skeletons/filters.skeleton'
import { useList } from 'app/providers/list.provider'
import { SelectGrouped } from './select-grouped'
import { Children, useEffect } from 'react'
import { SetURLSearchParams } from 'react-router-dom'

interface IFiltersBoxProps {
  filters: Filters
  handleFilters: () => void
  searchParams: URLSearchParams
  setSearchParams: SetURLSearchParams
  children?: React.ReactNode
  groupBy?: string[]
  sx?: SxProps<Theme>
}

export function FiltersBox({
  filters,
  children,
  handleFilters,
  setSearchParams,
  searchParams,
  groupBy,
  sx
}: IFiltersBoxProps) {
  const { filtersIsLoading, size, setFiltersIsLoading } = useList()

  useEffect(() => {
    setFiltersIsLoading(false)
  }, [])

  if (filtersIsLoading) return <FiltersSkeleton sx={sx} size={size + Children.count(children)} />

  return (
    <Grid container gap={4} marginY="1rem" sx={sx}>
      {Object.keys(filters).map((key) => {
        const filter = filters[key as keyof Filters] as Filter
        if (filter.multiple) {
          return (
            <MultipleSelect
              key={filter.reference}
              filter={filter}
              onChange={handleFilters}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          )
        }

        if (groupBy && groupBy.includes(filter.reference)) {
          return (
            <SelectGrouped
              key={filter.reference}
              filter={filter}
              onChange={handleFilters}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          )
        }

        return (
          <Select
            key={filter.reference}
            filter={filter}
            onChange={handleFilters}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        )
      })}

      {children}
    </Grid>
  )
}
