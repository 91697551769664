import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { Stack } from '@mui/system'
import { Link } from 'app/components/link.component'
import { KeyboardBackspace } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { CompanyBankAccountBody, companyBankAccountBodySchema, FormItems } from 'api/models'
import { useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { TitleComponent } from 'app/components/titles/title.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'

export const CompanyBankAccountAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const { createCompanyBankAccount, getFormItems } = useFetcher()
  const { handleMutation } = useFeedback()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [options, setOptions] = useState({} as FormItems)

  const methods = useForm<CompanyBankAccountBody>({
    mode: 'onChange',
    resolver: zodResolver(companyBankAccountBodySchema)
  })

  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([['credit_payment_mode_all', 'paymentModeId']])
  )

  methods.watch(['paymentModeId'])

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    setIsLoading(true)
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setOptions(optionsData as FormItems)
      methods.setValue(
        'paymentModeId',
        Number(optionsData?.credit_payment_mode_all?.values[0].id) ?? null
      )
    })
    setIsLoading(false)
  }, [])

  useEffect(() => {
    initOptions(commonOptions).then()
  }, [])

  const handleSubmit = useCallback(
    async (data: CompanyBankAccountBody) => {
      await handleMutation({
        confirm: {
          content: t('confirm_add_bank_account')
        },
        mutation: createCompanyBankAccount,
        data: data,
        onSuccess: () => navigate(`/companies/${data.companyId}`)
      })
    },
    [createCompanyBankAccount, id]
  )

  useEffect(() => {
    if (id !== undefined) {
      methods.setValue('companyId', parseInt(id))
    }
  }, [id])

  const fieldSkeleton = <CardSkeleton height={40} />

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/companies/${id}`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('add_bank_account')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Grid item xs={12}>
            <Paper sx={{ padding: 4, marginBottom: 4, marginTop: 10 }}>
              <TitleComponent
                text={t('informations')}
                variant={'h3'}
                paddingTop={0}
                paddingLeft={12}
                marginBottom={4}
              />
              <Grid container item xs={6} spacing={5} marginBottom={5}>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={4} margin={'auto'}>
                    <Typography>{t('journal_code')} *</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {isLoading ? (
                      fieldSkeleton
                    ) : (
                      <TextField
                        fullWidth
                        size={'small'}
                        variant="outlined"
                        defaultValue={''}
                        {...methods.register('journalCode')}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={4} margin={'auto'}>
                    <Typography>{t('accounting_number')} *</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {isLoading ? (
                      fieldSkeleton
                    ) : (
                      <TextField
                        fullWidth
                        size={'small'}
                        variant="outlined"
                        defaultValue={''}
                        {...methods.register('accountingNumber')}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={4} margin={'auto'}>
                    <Typography>{t('payment_mode_label')} *</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {!isLoading &&
                    options.credit_payment_mode_all &&
                    options.credit_payment_mode_all.values.length > 0 ? (
                      <Select
                        fullWidth
                        size={'small'}
                        {...methods.register('paymentModeId')}
                        value={methods.getValues().paymentModeId ?? ''}
                      >
                        {options.credit_payment_mode_all.values.map(
                          (paymentMode) =>
                            Number(paymentMode.id) !== 0 && (
                              <MenuItem key={paymentMode.id} value={Number(paymentMode.id)}>
                                {paymentMode.label}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    ) : (
                      fieldSkeleton
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={4} margin={'auto'}>
                    <Typography>{t('partner_reference')}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {isLoading ? (
                      fieldSkeleton
                    ) : (
                      <TextField
                        fullWidth
                        size={'small'}
                        variant="outlined"
                        defaultValue={''}
                        {...methods.register('partnerBankAccountId')}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={4} margin={'auto'}>
                    <Typography>{t('iban')} *</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {isLoading ? (
                      fieldSkeleton
                    ) : (
                      <TextField
                        fullWidth
                        size={'small'}
                        variant="outlined"
                        defaultValue={''}
                        {...methods.register('iban')}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={4} margin={'auto'}>
                    <Typography>{t('bic')} *</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {isLoading ? (
                      fieldSkeleton
                    ) : (
                      <TextField
                        fullWidth
                        size={'small'}
                        variant="outlined"
                        defaultValue={''}
                        {...methods.register('bic')}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
            <>
              <Button variant={'outlined'} onClick={() => navigate(`/companies/${id}`)}>
                {t('cancel')}
              </Button>
              <Button
                type={'submit'}
                variant="contained"
                size="small"
                disabled={!methods.formState.isValid}
              >
                {t('add')}
              </Button>
            </>
          </Box>
        </form>
      </FormProvider>
    </Container>
  )
}
