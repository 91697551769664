import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { CenterDetailsCard } from 'modules/centers/components/center-details.component'
import { CenterDisplayCardInfoConfiguration } from 'modules/centers/components/center-display-card-info-configuration.component'
import { CenterDetails } from 'api/models'

interface ICenterInformationsCardProps {
  center: CenterDetails | undefined
  isLoading?: boolean
}

export const CenterInformationsCard = ({ center, isLoading }: ICenterInformationsCardProps) => {
  const theme = useTheme()
  return (
    <Grid container spacing={6} sx={{ marginBottom: theme.spacing(8) }} alignItems={'stretch'}>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <CenterDisplayCardInfoConfiguration
          center={center}
          isLoading={isLoading}
        ></CenterDisplayCardInfoConfiguration>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={8}>
        <CenterDetailsCard center={center} isLoading={isLoading}></CenterDetailsCard>
      </Grid>
    </Grid>
  )
}
