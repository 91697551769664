import { z } from 'zod'
import { itemListSchema } from './commons'

const centerDiscountSchema = z.object({
  id: z.string(),
  discountId: z.string(),
  discountLabel: z.string(),
  centerId: z.string(),
  centerName: z.string(),
  begin: z.string(),
  end: z.string()
})

const centerSchema = z.object({
  id: z.number(),
  name: z.string(),
  reference: z.string()
})

const createCenterDiscountSchema = z.object({
  centerDiscount: z.number(),
  oldCenterDiscount: z.number().nullable()
})

const createCenterDiscountDataSchema = z.object({
  center: z.string(),
  discount: z.string(),
  begin: z.string()
})

const centerDiscountsSchema = itemListSchema(centerDiscountSchema)
export type CenterDiscountsList = z.infer<typeof centerDiscountsSchema>
export type CenterDiscounts = z.arrayOutputType<typeof centerDiscountSchema>
export type CenterDiscount = z.infer<typeof centerDiscountSchema>
export type CenterDiscountsNotActive = z.arrayOutputType<typeof centerSchema>
export type CreateCenterDiscount = z.infer<typeof createCenterDiscountSchema>
export type CreateCenterDataDiscount = z.infer<typeof createCenterDiscountDataSchema>
