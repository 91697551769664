import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'
import { InformationsComponent } from 'modules/credits/components/informations.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import {
  CreditInformation,
  CreditInvoiceSelect,
  FormItems,
  CreditInvoice,
  CreditPayment
} from 'api/models'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import '@react-pdf-viewer/core/lib/styles/index.css'
import base64ConverterHelper from 'app/helpers/base64_converter.helper'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useApp } from 'app/providers/app.provider'
import { ActionsComponent } from 'modules/credits/components/actions.component'
import { formatCurrency, formatDate } from 'app/utils/format'
import { PDFViewer } from 'app/components/pdf/PDFViewer'

export const CreditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    getCredit,
    getCreditPdf,
    getFormItems,
    getCreditInvoiceSelect,
    getCreditInvoice,
    getCreditPayment,
    searchParams
  } = useFetcher()
  const { id } = useParams()
  const { user, getRight } = useApp()
  const [information, setInformation] = useState<CreditInformation['credit']>()
  const [invoiceSelect, setInvoiceSelect] = useState<{ id: string; value: string }[]>([])
  const [pdf, setPdf] = useState<string>('')
  const [formItems, setFormItems] = useState({})
  const [invoices, setInvoices] = useState<CreditInvoice[]>([])
  const [payments, setPayments] = useState<CreditPayment[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [activityTypes, setActivityTypes] = useState<any>()

  useEffect(() => {
    searchParams.set('id', String(id))
    searchParams.set('activity_type', 'credit')
    ;(async () => {
      try {
        setIsLoading(true)
        const results = await Promise.all([
          getCredit.mutateAsync(),
          getCreditPdf.mutateAsync(),
          getCreditInvoiceSelect.mutateAsync(),
          getCreditInvoice.mutateAsync(),
          getCreditPayment.mutateAsync()
        ])
        setInformation(results[0].credit)
        setActivityTypes(results[0].activities)
        const blob = base64ConverterHelper().base64ToBlob(results[1].file, 'application/pdf')
        setPdf(URL.createObjectURL(blob))
        if (results[2].length === 0) {
          setInvoiceSelect([{ id: '', value: t('credit_sold_invoice_not_allowed') }])
        } else {
          setInvoiceSelect(
            results[2].map((item: CreditInvoiceSelect) => ({
              id: item.id,
              value: `${item.reference} - ${formatCurrency(item.dueAmount)} - ${formatDate(
                item.dueDate
              )}`
            }))
          )
        }
        setInvoices(results[3])
        setPayments(results[4])
      } finally {
        setIsLoading(false)
      }
    })()
  }, [id, searchParams, refresh])

  useEffect(() => {
    setIsLoading(true)
    let _commonOptions = new Map<string, string>([['languages_to_pdf', 'language']])

    if (getRight('payment', 'isAdd')) {
      if (getRight('transfer', 'isAdd')) {
        _commonOptions.set('credit_payment_mode', 'paymentMode')
      } else {
        _commonOptions.set('credit_payment_mode_without_transfer', 'paymentMode')
      }
    }

    getFormItems
      .mutateAsync(Array.from(_commonOptions.keys() as any))
      .then((optionsData) => setFormItems(optionsData as FormItems))
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        {information && (
          <Typography variant="h2" gutterBottom display="inline">
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Link to={`/credits`}>
                <KeyboardBackspace style={{ marginRight: 5 }} />
              </Link>
              <Typography variant="h2" style={{ marginRight: 5 }}>
                {t('credit')}
              </Typography>
              <Typography variant="h3">{information.reference}</Typography>
            </Box>
          </Typography>
        )}
        {!information && <RectangularSkeleton />}
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={12} md={5}>
          {isLoading && <CardSkeleton />}
          {!isLoading && user && information && (
            <InformationsComponent
              setRefresh={setRefresh}
              data={information}
              dataFilters={formItems}
              isCreditEdit={user.rights.credit.isEdit}
              isCreditRegenerable={user.rights.credit.isRegenerable}
            />
          )}
          {!isLoading && invoiceSelect.length !== 0 && user && information && (
            <ActionsComponent
              setRefresh={setRefresh}
              dataFilters={formItems}
              isLoading={isLoading}
              translation={t}
              invoiceSelectValues={invoiceSelect}
              isPaymentAdd={user.rights.payment.isAdd}
              isPayable={information.isPayable}
              isEditable={information.isEditable}
              invoicesValues={invoices}
              paymentsValues={payments}
              activityTypes={activityTypes}
            />
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          {!pdf && <CardSkeleton />}
          {pdf && <PDFViewer pdf={pdf} />}
        </Grid>
      </Grid>
    </Container>
  )
}
