import { Card, CardContent, IconButton } from '@mui/material'
import { Stack } from '@mui/system'
import { TitleComponent } from 'app/components/titles/title.component'
import { AddCircle, StopRounded } from '@mui/icons-material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'app/components/link.component'
import { Contract, ContractOptionList, ContractService } from 'api/models'
import { formatCurrency, formatDateWithTime } from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { Delete } from '@mui/icons-material'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useNavigate } from 'react-router-dom'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { DialogStopOption } from 'modules/options/components/dialog-stop-option'

interface IOptionsProps {
  optionValues: ContractOptionList
  isLoading: boolean
  contract: Contract | undefined
}

export const OptionComponent = ({ contract, optionValues, isLoading }: IOptionsProps) => {
  const { t } = useTranslation()
  const [initMap, setInitMap] = useState<Map<string, any>>(new Map())
  const { deleteContractService } = useFetcher()
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const dialogRef = useRef<DialogRef>(null)
  const [selectedService, setSelectedService] = useState<ContractService>({} as ContractService)

  const handleDelete = useCallback(
    async (row: ContractService) => {
      if (!contract) return
      await handleMutation({
        confirm: {
          content: t('confirm_delete_service')
        },
        mutation: deleteContractService,
        data: {
          contractId: contract.id,
          serviceId: row.id
        },
        onSuccess: () => navigate(0),
        toastSuccess: t('delete_success'),
        toastError: t('error')
      })
    },
    [contract, navigate]
  )

  const handleStop = useCallback(
    async (row: ContractService) => {
      if (!contract || !row.id) return
      setSelectedService(row)
      dialogRef.current?.open()
    },
    [contract, setSelectedService]
  )

  const formatActions = useCallback(
    (row: any) => {
      return (
        <Stack direction={'row'}>
          {row.isRemovable && (
            <IconButton size="small" onClick={() => handleDelete(row)}>
              <Delete fontSize={'small'} color={'primary'} />
            </IconButton>
          )}
          {row.end === null && (
            <IconButton size="small" onClick={() => handleStop(row)}>
              <StopRounded fontSize={'small'} color={'primary'} />
            </IconButton>
          )}
        </Stack>
      )
    },
    [handleDelete, handleStop]
  )

  useEffect(() => {
    setInitMap(() => {
      const map = new Map()
      map.set('items', [
        { label: t('label').toUpperCase(), value: 'label' },
        { label: t('type').toUpperCase(), value: 'serviceType' },
        { label: t('begin').toUpperCase(), format: formatDateWithTime, value: 'begin' },
        { label: t('end').toUpperCase(), format: formatDateWithTime, value: 'end' },
        { label: t('price').toUpperCase(), format: formatCurrency, value: 'price' },
        { label: '', component: formatActions }
      ])
      map.set('data', optionValues)
      return map
    })
  }, [optionValues])

  return (
    <>
      <DialogStopOption contract={contract} service={selectedService} dialogRef={dialogRef} />
      <Card>
        <CardContent>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <TitleComponent text={t('options')} variant={'h3'} paddingTop={0} paddingLeft={12} />
            <Link to={`/contracts/${contract?.id}/options/add`}>
              <IconButton size="small" color={'primary'}>
                <AddCircle fontSize="small" />
              </IconButton>
            </Link>
          </Stack>
          <ListSheetComponent isLoading={isLoading} data={initMap} />
        </CardContent>
      </Card>
    </>
  )
}
