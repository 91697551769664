import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useFetcher } from './fetcher.provider'
import {
  CommitmentRate,
  DiscountGrid,
  NomadeAndDomiciliationPricer,
  OfficePricer,
  PricerDiscountGrids,
  PricerInformations,
  ServicesPricer
} from 'api/models'
import { QuotationCreateFormData } from 'api/models/forms/opportunities'
import { useNavigate } from 'react-router-dom'

export interface Solution {
  mustRefresh: boolean
  label: string
  canOffice: boolean
  canCoworking: boolean
  canDomiciliation: boolean
  canNomade: boolean
  empty: boolean
  noDegressive: boolean
  standardPrice: number
  commitmentPrice: number
  reduction: number
  monthlyReduction: number
  serviceType?: string
  totalPrice: number
  deadline: string
  commitment: CommitmentRate
  offices: OfficePricer[]
  coworking: OfficePricer[]
  domiciliations: NomadeAndDomiciliationPricer[]
  nomades: NomadeAndDomiciliationPricer[]
}

interface PricerContextProps {
  solutions: Map<string, Solution>
  services: ServicesPricer
  pricerInformations: PricerInformations
  pricerDiscountGrids: PricerDiscountGrids
  currentDiscountGrid: DiscountGrid
  setServices: React.Dispatch<React.SetStateAction<ServicesPricer>>
  onSelectService: (
    isChecked: boolean,
    service: OfficePricer | NomadeAndDomiciliationPricer,
    type: string,
    solution: string
  ) => void
  updateOccupant: (
    occupants: number,
    service: OfficePricer | NomadeAndDomiciliationPricer,
    type: string,
    index: number
  ) => void
  refreshInformations: (reference: string, commitment: CommitmentRate) => void
  onDegressiveChange: (reference: string) => void
  onDeadlineChange: (deadline: string, reference: string) => void
  onCommitmentChange: (commitmentReference: string, reference: string) => void
  onReductionChange: (reduction: number, reference: string) => void
  onCreateQuotation: () => void
  isLoadingInformations: boolean
  isLoadingServices: boolean
  error: boolean
}

const PricerContext = createContext<PricerContextProps>({
  solutions: new Map<string, Solution>(),
  services: {} as ServicesPricer,
  pricerInformations: {} as PricerInformations,
  pricerDiscountGrids: {} as PricerDiscountGrids,
  currentDiscountGrid: {} as DiscountGrid,
  setServices: () => undefined,
  onSelectService: () => undefined,
  updateOccupant: () => undefined,
  refreshInformations: () => undefined,
  onDegressiveChange: () => undefined,
  onDeadlineChange: () => undefined,
  onCommitmentChange: () => undefined,
  onReductionChange: () => undefined,
  onCreateQuotation: () => undefined,
  isLoadingInformations: true,
  isLoadingServices: true,
  error: false
})

interface IPricerProviderProps {
  children: React.ReactNode
}

const PricerProvider = ({ children }: IPricerProviderProps): React.JSX.Element => {
  const {
    searchParams,
    getServicesPricer,
    getInformationsPricer,
    getDiscountGridsPricer,
    createQuotation
  } = useFetcher()

  const navigate = useNavigate()

  const [services, setServices] = useState<ServicesPricer>({} as ServicesPricer)
  const [pricerInformations, setPricerInformations] = useState<PricerInformations>(
    {} as PricerInformations
  )
  const [pricerDiscountGrids, setPricerDiscountGrids] = useState<PricerDiscountGrids>(
    {} as PricerDiscountGrids
  )
  const [currentDiscountGrid, setCurrentDiscountGrid] = useState<DiscountGrid>({} as DiscountGrid)
  const [solutions, setSolutions] = useState<Map<string, Solution>>(new Map<string, Solution>())
  const [isLoadingInformations, setIsLoadingInformations] = useState<boolean>(true)
  const [isLoadingServices, setIsLoadingServices] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)

  /* INITIALIZATION */
  const onInitSolutions = useCallback(() => {
    let _solutions = new Map<string, Solution>()
    for (let i = 1; i <= 4; i++) {
      let solution: Solution = {
        mustRefresh: false,
        label: 'S' + i,
        noDegressive: false,
        canOffice: true,
        canCoworking: true,
        canDomiciliation: true,
        canNomade: false,
        empty: true,
        standardPrice: 0,
        commitmentPrice: 0,
        totalPrice: 0,
        reduction: 0,
        monthlyReduction: 0,
        deadline: '',
        commitment: {} as CommitmentRate,
        offices: [],
        coworking: [],
        domiciliations: [],
        nomades: []
      }
      _solutions.set('S' + i, solution)
    }
    setSolutions(_solutions)
  }, [currentDiscountGrid.defaultCommitmentReference])

  const properties = useMemo(
    () => ({
      opportunityId: searchParams.get('opportunity')
    }),
    [searchParams]
  )

  useEffect(() => {
    onInitSolutions()
  }, [])

  /* GET DATA */
  const getInformationsForPricer = useCallback(async () => {
    if (properties.opportunityId) {
      const info = await getInformationsPricer.mutateAsync({ id: properties.opportunityId })
      setPricerInformations(info)
    }
  }, [properties.opportunityId])

  const getDiscountGrindsAndServices = useCallback(async () => {
    if (pricerInformations && properties.opportunityId && pricerInformations.centerId) {
      const grids = await getDiscountGridsPricer.mutateAsync({
        center: pricerInformations.centerId,
        begin: pricerInformations.begin
      })

      setPricerDiscountGrids(grids)
      try {
        const data = await getServicesPricer.mutateAsync({ id: properties.opportunityId })
        setServices(data)
      } catch (Exception) {
        setError(true)
      }
    }
  }, [pricerInformations, properties.opportunityId])

  useEffect(() => {
    if (properties.opportunityId) {
      getInformationsForPricer().then()
    }
  }, [properties.opportunityId])

  useEffect(() => {
    if (Object.keys(pricerInformations).length > 0 && properties.opportunityId) {
      setIsLoadingInformations(false)
      getDiscountGrindsAndServices().then()
    }
  }, [pricerInformations, properties.opportunityId])

  useEffect(() => {
    if (pricerDiscountGrids) {
      const grids = pricerDiscountGrids.discountGrids?.filter((grid) => grid.type === 1)
      if (grids && grids.length == 1) {
        setCurrentDiscountGrid(grids.shift()!)
      }
    }
  }, [pricerDiscountGrids])

  /* UPDATE */
  const onSelectService = useCallback(
    (
      isChecked: boolean,
      service: OfficePricer | NomadeAndDomiciliationPricer,
      type: string,
      solution: string
    ) => {
      const _solutions = new Map<string, Solution>(solutions)
      let _solution = _solutions.get(solution)
      _solution!.mustRefresh = true
      if (isChecked && _solution) {
        switch (type) {
          case 'office':
            _solution.offices!.push(service as OfficePricer)
            break
          case 'coworking':
            _solution.coworking!.push(service as OfficePricer)
            break
          case 'domiciliation':
            _solution.domiciliations!.push(service as NomadeAndDomiciliationPricer)
            break
          case 'nomade':
            _solution.nomades!.push(service as NomadeAndDomiciliationPricer)
            break
        }
      } else if (_solution) {
        switch (type) {
          case 'office':
            _solution.offices = _solution.offices!.filter((_service) => _service.id !== service.id)
            break
          case 'coworking':
            _solution.coworking = _solution.coworking!.filter(
              (_service) => _service.id !== service.id
            )
            break
          case 'domiciliation':
            _solution.domiciliations = _solution.domiciliations!.filter(
              (_service) => _service.id !== service.id
            )
            break
          case 'nomade':
            _solution.nomades = _solution.nomades!.filter((_service) => _service.id !== service.id)
            break
        }
      }

      if (_solution) {
        _solutions.set(solution, _solution)
        setSolutions(_solutions)
      }
    },
    [solutions]
  )

  const updateOccupant = useCallback(
    (
      occupants: number,
      service: OfficePricer | NomadeAndDomiciliationPricer,
      type: string,
      index: number
    ) => {
      const _solutions = new Map<string, Solution>(solutions)

      switch (type) {
        case 'office':
          let _offices = [...services!.offices]
          const _office = services!.offices.find((item) => item.id === service.id)
          if (_office) {
            _solutions.forEach((solution) => {
              let office = solution.offices!.find((_service) => {
                return _service.id === service.id
              })
              if (office) {
                solution.mustRefresh = true
                office.persons = _office.persons
              }
            })
            _offices[index] = { ..._office, persons: occupants }
            setServices({ ...services, offices: _offices })
          }
          break
        case 'coworking':
          let _coworking = [...services!.coworking]
          const _cowork = services!.coworking.find((item) => item.id === service.id)
          if (_cowork) {
            _solutions.forEach((solution) => {
              let cowork = solution.coworking!.find((_service) => {
                return _service.id === service.id
              })
              if (cowork) {
                solution.mustRefresh = true
                cowork.persons = _cowork.persons
              }
            })
            _coworking[index] = { ..._cowork, persons: occupants }
            setServices({ ...services, coworking: _coworking })
          }
          break
        case 'nomade':
          let _nomades = [...services!.nomades]
          const _nomade = services!.nomades.find((item) => item.id === service.id)
          if (_nomade) {
            _solutions.forEach((solution) => {
              let nomade = solution.nomades!.find((_service) => {
                return _service.id === service.id
              })
              if (nomade) {
                solution.mustRefresh = true
                nomade.persons = occupants
              }
            })
            _nomades[index] = { ..._nomade, persons: occupants }
            setServices({ ...services, nomades: _nomades })
          }
          break
      }
      setSolutions(_solutions)
    },
    [services]
  )

  useEffect(() => {
    if (Object.keys(services).length > 0) {
      setIsLoadingServices(false)
    }
  }, [services])

  const refreshInformations = useCallback(
    (reference: string, commitment: CommitmentRate) => {
      let _solutions = new Map<string, Solution>(solutions)
      let _solution = _solutions.get(reference)
      if (_solution) {
        _solution.mustRefresh = false
        _solution.canDomiciliation =
          commitment.reference !== 'COMMITMENT_WITHOUT' &&
          _solution.coworking.length === 0 &&
          _solution.nomades.length === 0 &&
          _solution.offices.length === 0
        _solution.canNomade =
          commitment.reference === 'COMMITMENT_WITHOUT' &&
          _solution.coworking.length === 0 &&
          _solution.domiciliations.length === 0 &&
          _solution.offices.length === 0
        _solution.canCoworking =
          _solution.nomades.length === 0 &&
          _solution.domiciliations.length === 0 &&
          _solution.offices.length === 0
        _solution.canOffice =
          _solution.nomades.length === 0 &&
          _solution.domiciliations.length === 0 &&
          _solution.coworking.length === 0

        let standardPrice = 0
        let commitmentPrice = 0
        let totalPrice = 0
        let monthlyReduction = 0
        if (_solution.offices.length > 0) {
          _solution.offices.map((service) => {
            service.prices.map((price) => {
              if (currentDiscountGrid.defaultCommitmentReference === price.commitmentReference) {
                standardPrice += price.price
              }

              if (commitment.commitmentId === price.commitmentId) {
                let _price = _solution?.noDegressive ? price.noDegressivePrice : price.price
                monthlyReduction += _solution?.reduction ? (_price * _solution.reduction) / 100 : 0
                _price *= _solution?.reduction ? (100 - _solution.reduction) / 100 : 1
                commitmentPrice += _price
                totalPrice += _price
              }
            })
          })
        }

        if (_solution.coworking.length > 0) {
          _solution.coworking.map((service) => {
            service.prices.map((price) => {
              if (currentDiscountGrid.defaultCommitmentReference === price.commitmentReference) {
                standardPrice += price.price
              }

              if (commitment.commitmentId === price.commitmentId) {
                let _price = _solution?.noDegressive ? price.noDegressivePrice : price.price
                monthlyReduction += _solution?.reduction ? (_price * _solution.reduction) / 100 : 0
                _price *= _solution?.reduction ? (100 - _solution.reduction) / 100 : 1
                commitmentPrice += _price
                totalPrice += _price
              }
            })
          })
        }

        if (_solution.domiciliations.length > 0) {
          _solution.domiciliations.map((service) => {
            service.prices.map((price) => {
              if (currentDiscountGrid.defaultCommitmentReference === price.commitmentReference) {
                standardPrice += price.price
              }

              if (commitment.commitmentId === price.commitmentId) {
                let _price = _solution?.noDegressive ? price.noDegressivePrice : price.price
                monthlyReduction += _solution?.reduction ? (_price * _solution.reduction) / 100 : 0
                _price *= _solution?.reduction ? (100 - _solution.reduction) / 100 : 1
                commitmentPrice += _price
                totalPrice += _price
              }
            })
          })
        }

        if (_solution.nomades.length > 0) {
          _solution.nomades.map((service) => {
            service.prices.map((price) => {
              if (commitment.commitmentId === price.commitmentId) {
                let _price = price.price * service.persons
                monthlyReduction += _solution?.reduction ? (_price * _solution.reduction) / 100 : 0
                _price *= _solution?.reduction ? (100 - _solution.reduction) / 100 : 1
                commitmentPrice += _price
                totalPrice += _price
              }
            })
          })
        }

        _solution.standardPrice = standardPrice
        _solution.commitmentPrice = commitmentPrice
        _solution.totalPrice = totalPrice
        _solution.monthlyReduction = monthlyReduction
        _solutions.set(reference, _solution)
        setSolutions(_solutions)
      }
    },
    [solutions]
  )

  const onDegressiveChange = useCallback(
    (reference: string) => {
      let _solutions = new Map<string, Solution>(solutions)
      let _solution = solutions.get(reference)
      if (_solution) {
        _solution.noDegressive = !_solution.noDegressive
        _solution.mustRefresh = true
        _solutions.set(reference, _solution)
        setSolutions(_solutions)
      }
    },
    [solutions]
  )

  const onDeadlineChange = useCallback(
    (deadline: string, reference: string) => {
      let _solutions = new Map<string, Solution>(solutions)
      let _solution = solutions.get(reference)
      if (_solution) {
        _solution.deadline = deadline
        _solution.mustRefresh = true
        _solutions.set(reference, _solution)
        setSolutions(_solutions)
      }
    },
    [solutions]
  )

  const onReductionChange = useCallback(
    (reduction: number, reference: string) => {
      let _solutions = new Map<string, Solution>(solutions)
      let _solution = solutions.get(reference)
      if (_solution) {
        let _reduction = Math.max(reduction, 0)
        if (pricerDiscountGrids.discount) {
          const _discount = pricerDiscountGrids.discount.rates.find(
            (discountRate) => discountRate.commitmentId === _solution?.commitment.commitmentId
          )
          if (_discount) {
            _reduction = Math.min(_reduction, _discount.rate)
          }
        }

        _solution.reduction = _reduction
        _solution.mustRefresh = true
        _solutions.set(reference, _solution)
        setSolutions(_solutions)
      }
    },
    [solutions]
  )

  const setCurrentCommitmentFromReference = useCallback(
    (commitmentReference: string, reference: string) => {
      let _solutions = new Map<string, Solution>(solutions)
      let _solution = solutions.get(reference)
      if (_solution) {
        _solution.mustRefresh = true
        if (undefined !== currentDiscountGrid.commitmentRates) {
          const _commitment = currentDiscountGrid.commitmentRates.find(
            (commitmentRate) => commitmentRate.reference === commitmentReference
          )

          if (_commitment) {
            _solution.commitment = _commitment
            _solution.deadline = _commitment.min
          }
        }

        if (_solution.commitment.reference === 'COMMITMENT_WITHOUT') {
          _solution.domiciliations = []
        } else {
          _solution.nomades = []
        }

        _solutions.set(reference, _solution)
        setSolutions(_solutions)
      }
    },
    [currentDiscountGrid.commitmentRates, solutions]
  )

  const onCommitmentChange = useCallback(
    (commitmentReference: string, reference: string) => {
      setCurrentCommitmentFromReference(commitmentReference, reference)
    },
    [currentDiscountGrid.commitmentRates, solutions]
  )

  const onCreateQuotation = useCallback(async () => {
    let quotation: QuotationCreateFormData = {
      s1Center: '',
      s2Center: '',
      s3Center: '',
      s4Center: '',
      s1Commitment: 0,
      s2Commitment: 0,
      s3Commitment: 0,
      s4Commitment: 0,
      s1Deadline: '',
      s2Deadline: '',
      s3Deadline: '',
      s4Deadline: '',
      s1IsPriceDegressive: false,
      s2IsPriceDegressive: false,
      s3IsPriceDegressive: false,
      s4IsPriceDegressive: false,
      s1Reduction: 0,
      s2Reduction: 0,
      s3Reduction: 0,
      s4Reduction: 0,
      s1ServiceType: '',
      s2ServiceType: '',
      s3ServiceType: '',
      s4ServiceType: '',
      dueDate: pricerInformations.dueDate
    }

    solutions.forEach((solution) => {
      let _servicesData = ''
      let _service_type = ''
      let _service: string[] = []
      if (solution.offices.length > 0) {
        solution.offices.map((service) => {
          _service.push([service.id, service.persons].join(':') + ':')
        })
        _service_type = 'FULL_DESKTOP'
      } else if (solution.coworking.length > 0) {
        solution.coworking.map((service) => {
          _service.push([service.id, service.persons].join(':') + ':')
        })
        _service_type = 'COWORKING'
      } else if (solution.domiciliations.length > 0) {
        solution.domiciliations.map((service) => {
          _service.push([service.id, service.persons].join(':') + ':')
        })
        _service_type = 'DOMICILIATION'
      } else if (solution.nomades.length > 0) {
        solution.nomades.map((service) => {
          _service.push([service.id, service.capacity, service.persons].join(':'))
        })
        _service_type = 'COWORKING'
      }
      _servicesData = _service.join(',')
      const _reduction = isNaN(solution.reduction / 100) ? 0 : solution.reduction / 100

      switch (solution.label) {
        case 'S1':
          quotation.s1Center = _servicesData
          quotation.s1Commitment = parseInt(solution.commitment.commitmentId)
          quotation.s1Deadline = solution.deadline
          quotation.s1IsPriceDegressive = !solution.noDegressive
          quotation.s1Reduction = _reduction
          quotation.s1ServiceType = _service_type
          break
        case 'S2':
          quotation.s2Center = _servicesData
          quotation.s2Commitment = parseInt(solution.commitment.commitmentId)
          quotation.s2Deadline = solution.deadline
          quotation.s2IsPriceDegressive = !solution.noDegressive
          quotation.s2Reduction = _reduction
          quotation.s2ServiceType = _service_type
          break
        case 'S3':
          quotation.s3Center = _servicesData
          quotation.s3Commitment = parseInt(solution.commitment.commitmentId)
          quotation.s3Deadline = solution.deadline
          quotation.s3IsPriceDegressive = !solution.noDegressive
          quotation.s3Reduction = _reduction
          quotation.s3ServiceType = _service_type
          break
        case 'S4':
          quotation.s4Center = _servicesData
          quotation.s4Commitment = parseInt(solution.commitment.commitmentId)
          quotation.s4Deadline = solution.deadline
          quotation.s4IsPriceDegressive = !solution.noDegressive
          quotation.s4Reduction = _reduction
          quotation.s4ServiceType = _service_type
          break
      }
    })

    const result = await createQuotation.mutateAsync({
      opportunityId: pricerInformations.id,
      data: quotation
    })
    if (result.id) {
      navigate('/quotations/' + result.id)
    }
  }, [solutions])

  useEffect(() => {
    if (undefined !== currentDiscountGrid.commitmentRates) {
      setCurrentCommitmentFromReference(currentDiscountGrid.defaultCommitmentReference, 'S1')
      setCurrentCommitmentFromReference(currentDiscountGrid.defaultCommitmentReference, 'S2')
      setCurrentCommitmentFromReference(currentDiscountGrid.defaultCommitmentReference, 'S3')
      setCurrentCommitmentFromReference(currentDiscountGrid.defaultCommitmentReference, 'S4')
    }
  }, [currentDiscountGrid.commitmentRates])

  useEffect(() => {
    for (let i = 1; i <= 4; i++) {
      if (solutions.get('S' + i)?.mustRefresh) {
        refreshInformations('S' + i, solutions.get('S' + i)!.commitment)
      }
    }
  }, [solutions])

  const values = useMemo(() => {
    return {
      solutions,
      services,
      pricerInformations,
      pricerDiscountGrids,
      currentDiscountGrid,
      setServices,
      onSelectService,
      updateOccupant,
      refreshInformations,
      onDegressiveChange,
      onDeadlineChange,
      onCommitmentChange,
      onReductionChange,
      onCreateQuotation,
      isLoadingInformations,
      isLoadingServices,
      error
    }
  }, [
    solutions,
    services,
    pricerInformations,
    pricerDiscountGrids,
    currentDiscountGrid,
    setServices,
    onSelectService,
    updateOccupant,
    refreshInformations,
    onDegressiveChange,
    onDeadlineChange,
    onCommitmentChange,
    onReductionChange,
    onCreateQuotation,
    isLoadingInformations,
    isLoadingServices,
    error
  ])
  return <PricerContext.Provider value={values}>{children}</PricerContext.Provider>
}

const usePricer = () => useContext(PricerContext)

export { PricerProvider, usePricer }
