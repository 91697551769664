import { Box, Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'app/components/link.component'
import { formatActive, formatDateWithTime } from 'app/utils/format'
import { NotInterested } from '@mui/icons-material'
import React from 'react'

const InformationsComponent = ({ paymentMethod, setOpenActive }: any) => {
  const { t } = useTranslation()

  return (
    <Paper style={{ padding: '1rem', marginBottom: '1rem' }}>
      <Typography variant="h3" marginBottom="1rem" color="primary">
        {t('informations')}
      </Typography>
      <Grid container rowSpacing={5}>
        <Grid item container spacing={9}>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('client')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>
              <Link to={`/enterprises/${paymentMethod.clientId}`}>{paymentMethod.clientName}</Link>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('center')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>{paymentMethod.centerName}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={9}>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('mandat')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>{paymentMethod.partnerPaymentMethodId}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('created_at')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography>{formatDateWithTime(paymentMethod.createdAt)}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={9}>
          <Grid item xs={6} sm={3}>
            <Typography color="textSecondary">{t('status')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {formatActive(paymentMethod.isActive)}
              <Typography>{paymentMethod.activeLabel}</Typography>
              <NotInterested
                fontSize={'small'}
                onClick={() => setOpenActive(true)}
                sx={{ cursor: 'pointer' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default InformationsComponent
