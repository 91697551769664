import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import {
  EnterpriseInformation,
  enterpriseInformationFormSchema,
  FormItem,
  FormItems
} from 'api/models'
import { Button, Grid, Typography, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'

export const EnterpriseEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getFormItems } = useFetcher()
  const { id } = useParams()
  const [enterprise, setEnterprise] = useState({} as EnterpriseInformation)
  const { getEnterprise, updateEnterprise } = useFetcher()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [formItems, setFormItems] = useState({} as FormItems)
  const { handleMutation } = useFeedback()
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['languages', 'language'],
      ['countries', 'country'],
      ['status', 'status']
    ])
  )

  const getDefaultValue = useCallback(async () => {
    setIsLoading(true)
    const data = await getEnterprise.mutateAsync(String(id))
    setEnterprise(data)
    setIsLoading(false)

    // TODO : Mettre les propriétés iso manager / Api pour get et update
    return {
      ...data,
      zipcode: data.zipCode,
      isKeyAccount: data.isKeyAccounting ? true : undefined
    }
  }, [id])

  const methods = useForm<EnterpriseInformation>({
    defaultValues: async () => await getDefaultValue(),
    mode: 'onChange',
    resolver: zodResolver(enterpriseInformationFormSchema)
  })

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems.mutateAsync(Array.from(commonOptions.keys() as any)).then((optionsData) => {
      setFormItems(optionsData as FormItems)
    })
  }, [])

  const handleSubmit = useCallback(
    async (data: EnterpriseInformation) => {
      await handleMutation({
        confirm: {
          title: 'save',
          content: t('confirm_edit_enterprise'),
          variant: 'primary'
        },
        onStart: () => setIsLoading(true),
        mutation: updateEnterprise,
        data: data,
        toastSuccess: t('update_enterprise_success'),
        toastError: t('update_error'),
        onSuccess: () => {
          navigate(`/enterprises/${enterprise.id}`)
        },
        onEnd: () => {
          setIsLoading(false)
        }
      })
    },
    [enterprise, navigate]
  )

  useEffect(() => {
    initOptions(commonOptions).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('edit_informations')} | {enterprise.name}
        </Typography>
        <Typography variant="body2">
          {enterprise.reference} | {enterprise.name}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading}
              title={t('main_informations')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('name'), name: 'name', required: true },
                {
                  type: 'centers',
                  label: t('center'),
                  name: 'mainCenter',
                  required: true
                },
                {
                  type: 'textfield',
                  label: t('workforce'),
                  name: 'workforce',
                  inputType: 'number'
                },
                { type: 'textfield', label: t('website'), name: 'website' },
                {
                  type: 'select',
                  label: t('language'),
                  name: 'languageId',
                  formItem: formItems.languages as FormItem,
                  required: true
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading}
              title={t('address')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('address'), name: 'address' },
                { type: 'textfield', label: t('zipcode'), name: 'zipcode' },
                { type: 'textfield', label: t('city'), name: 'city' },
                {
                  type: 'select',
                  label: t('country'),
                  name: 'country',
                  formItem: formItems.countries as FormItem
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading}
              title={t('contact')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('email'), name: 'email' },
                { type: 'textfield', label: t('phone'), name: 'phone' },
                { type: 'textfield', label: t('mobile'), name: 'mobile' },
                {
                  type: 'textfield',
                  label: t('email_accounting_contact'),
                  name: 'accountingContact'
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading}
              title={t('other_informations')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('type'), name: 'type' },
                { type: 'textfield', label: t('ca'), name: 'ca', inputType: 'number' },
                { type: 'textfield', label: t('siret'), name: 'siret' },
                { type: 'textfield', label: t('vat'), name: 'vat' },
                { type: 'textfield', label: t('ape_code'), name: 'ape' },
                { type: 'textfield', label: t('rcs'), name: 'rcs' },
                { type: 'textfield', label: t('rcs_city'), name: 'rcsCity' },
                {
                  type: 'select',
                  label: t('status'),
                  name: 'status',
                  formItem: formItems.status as FormItem
                },
                {
                  type: 'checkbox',
                  label: t('key_account'),
                  name: 'isKeyAccount',
                  inputProps: {
                    isUncheckedFalse: false
                  }
                },
                {
                  type: 'textfield',
                  tooltip: t('tooltip_due_offset'),
                  label: t('due_offset'),
                  name: 'dueOffset',
                  inputType: 'number'
                }
              ]}
            />
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: 4 }} container columns={12} columnSpacing={4}>
          <Grid item xs={6} textAlign={'right'}>
            <Button
              variant={'outlined'}
              color={'error'}
              onClick={() => navigate(`/enterprises/${enterprise.id}`)}
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item xs={6} textAlign={'left'}>
            <Button
              disabled={methods.formState.isSubmitting || !methods.formState.isValid}
              variant={'contained'}
              color={'primary'}
              onClick={methods.control.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  )
}
