import { Box, Card, CardContent } from '@mui/material'

import { TitleComponent } from 'app/components/titles/title.component'
import React, { useCallback, useEffect, useState } from 'react'
import { List } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import { Opportunity, OpportunityContracts } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'

interface IServicesProps {
  data: Opportunity
}

export const ContractsComponent = ({ data }: IServicesProps) => {
  const [contracts, setContracts] = useState<OpportunityContracts>([] as OpportunityContracts)
  const { getOpportunityContracts } = useFetcher()
  const { t } = useTranslation()
  const { handleSort, handleFilter, orderBy } = useList()
  const [isLoading, setIsLoading] = useState(false)

  const fetch = useCallback(async () => {
    if (!data.id) return
    setIsLoading(true)
    await getOpportunityContracts
      .mutateAsync(String(data.id))
      .then((data) => setContracts(data))
      .finally(() => setIsLoading(false))
  }, [data])

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <Card>
      <CardContent>
        <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
          <TitleComponent text={t('contracts')} variant={'h3'} paddingLeft={12} />
        </Box>
        <List
          selectable={false}
          columns={[
            {
              label: t('reference'),
              slug: 'reference',
              link: { base: '/contracts', slug: 'id' }
            },
            {
              label: t('center'),
              slug: 'centerName'
            },
            {
              label: t('price'),
              slug: 'price'
            }
          ]}
          items={contracts}
          handleReset={() => handleFilter(fetch, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(fetch, property)}
          isLoading={isLoading}
        />
      </CardContent>
    </Card>
  )
}
