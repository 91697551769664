import { z } from 'zod'

const consumptionItemSchema = z.object({
  freePrice: z.boolean(),
  id: z.string(),
  label: z.string(),
  price: z.number(),
  type: z.string(),
  typeReference: z.string()
})

export type ConsumptionItem = z.infer<typeof consumptionItemSchema>

const consumptionListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(consumptionItemSchema)
})

export type ConsumptionList = z.infer<typeof consumptionListSchema>
